import React from 'react'
import styled from 'styled-components'
import { TotalTitle, TotalValue } from '../../Common/CommonStyled'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const Ratio = styled.div`
  color: ${props => props.theme.colors.primary};
`

interface Props {
  ratio: string
  minRatio?: string
}

export const CollateralizationRatio: React.FC<Props> = props => {
  const { ratio, minRatio = '160', ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>{`Collateralization Ratio (min ${minRatio}%)`}</TotalTitle>
      <Values>
        <TotalValue>
          <Ratio> {ratio}%</Ratio>
        </TotalValue>
      </Values>
    </Wrapper>
  )
}
