import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { InsuredYield } from '../InsuredYield'
import { DetailRow } from '../DetailRow'
import { Button } from '../../Common/Button'
import { TextLight } from '../../Common/CommonStyled'
import { TextWithTooltip } from '../../Common/TextWithTooltip'
import { LOCK_STATES } from '../../Common/TokensDropdownUnlocker'

const Link = styled.a`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ButtonStyled = styled(Button)`
  margin-top: 30px;
`

interface ModalProps {
  isOpen: boolean
  onConfirm: ((e?: any) => void) | undefined
  onRequestClose: ((e?: any) => void) | undefined
  title?: string
}

export const MakeInsuredDepositModal: React.FC<ModalProps> = props => {
  const { onConfirm } = props
  const [isSupply, setSupplyLocked] = useState(LOCK_STATES.LOCKED)

  const unlockPay = useCallback(() => {
    setSupplyLocked(LOCK_STATES.UNLOCKED)
  }, [])

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        dropdownIsDisabled
        isLocked={isSupply}
        onUnlock={unlockPay}
        title={'Supply DAI'}
        titleInfo={[{ title: 'Balance:', value: '2750 USDC' }]}
        value={''}
      />
      <InsuredYield value={'5.42'} />
      <Link href="#">Terms</Link>
      <DetailRow
        title={
          <TextWithTooltip
            text="Deductible"
            tooltipText={
              'Maximum cost you’ll bear in a disaster scenario. The rest is covered by your policy. <a href="#" key="learn-more">Learn more.</a>'
            }
            id="deductible"
            place="right"
          />
        }
        value={['0.00 DAI ', <TextLight key="usd">$0.00</TextLight>]}
      />
      <DetailRow title={'Duration'} value={'3 months'} />
      <ButtonStyled onClick={onConfirm} disabled={isSupply !== LOCK_STATES.UNLOCKED}>
        Confirm
      </ButtonStyled>
    </Modal>
  )
}
