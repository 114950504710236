import React from 'react'
import { ChevronCell } from 'components/Common/ChevronCell'
import { CollateralCell } from 'components/Common/CollateralCell'
import { ColorCell } from 'components/Common/ColorCell'
import { CostCell } from 'components/Common/CostCell'
import { EarnCell } from 'components/Common/EarnCell'
import { TokenIcon } from 'components/Common/TokenIcon'
import TokenBalance from 'components/Common/TokenBalance'
import Percentage from 'components/Common/Percentage'
import Value from 'components/Common/Value'
import FormattedDate from 'components/Common/Date'
import styled from 'styled-components'
import theme from 'theme'
import { EXPIRY_DATE_FORMAT } from 'common/constants'

const ColoredSpan = styled.span<{ color: string }>`
  color: ${props => props.color};
`

export const tokenCellRenderer = (props: any) => {
  const { cellData } = props

  return <TokenIcon token={cellData} />
}

export const tokenBalanceCellRenderer = (props: any) => {
  const {
    cellData: { token, amount, precision = 2 },
  } = props

  return <TokenBalance token={token} value={amount} precision={precision} />
}

export const usdCellRenderer = (props: any) => {
  const { cellData } = props

  return <Value value={cellData}>{val => `$${val}`}</Value>
}

export const usdcCellRenderer = (props: any) => {
  const { cellData } = props

  return <Value value={cellData}>{val => `${val} USDC`}</Value>
}

export const colorCellRenderer = (props: any) => {
  const { cellData } = props

  return <ColorCell {...cellData} />
}

export const statusCellRenderer = (props: any) => {
  const {
    cellData: { value },
    rowData: { status },
  } = props

  return <ColorCell value={value} status={status} />
}

export const percentageCellRenderer = (props: any) => {
  const { cellData } = props

  return <Percentage {...cellData} />
}

export const chevronCellRenderer = (props: any) => {
  const { cellData } = props

  return <ChevronCell status={cellData} />
}

export const costCellRenderer = (props: any) => {
  const { cellData } = props

  return <CostCell value={cellData} />
}

export const earnCellRenderer = (props: any) => {
  const { cellData } = props
  const { value, token } = cellData

  return <EarnCell value={value} token={token} />
}

export const collateralCellRenderer = (props: any) => {
  const { cellData } = props

  return <CollateralCell data={cellData} />
}

export const insuredCellRenderer = (props: any) => {
  const {
    cellData: { status, ...rest },
  } = props

  const percentage = <Percentage {...rest} />

  return <ColorCell value={percentage} status={status} />
}

export const strikePriceCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  return (
    <ColorCell value={usdcCellRenderer(props)} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
  )
}

export const ethPriceCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  return (
    <ColorCell value={usdCellRenderer(props)} status={status === 'error' ? status : ''} holder={balance.amount.gt(0)} />
  )
}
export const protectionCostCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="eth" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerWethCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="weth" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerCompCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="comp" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerBalCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="bal" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerYfiCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="yfi" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerCrvCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="crv" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerUniCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="uni" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerDydxCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="dydx" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerSnxCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="snx" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerDpiCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="dpi" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const protectionCostPerWbtcCellRenderer = (props: any) => {
  const {
    rowData: { status, balance },
  } = props

  const value =
    status === 'error' && balance.amount.gt(0) ? 'Exercise' : <CostCell value={usdCellRenderer(props)} token="wbtc" />
  return <ColorCell value={value} status={status === 'error' ? 'ok' : ''} holder={balance.amount.gt(0)} />
}

export const okChevronCellRenderer = (props: any) => {
  const { cellData } = props

  return <ChevronCell {...cellData} status="ok" />
}

export const expiryRenderer = (props: any) => {
  const {
    cellData: { isExpired, timestamp },
  } = props

  return isExpired ? (
    <ColoredSpan color={theme.colors.secondary}>Expired</ColoredSpan>
  ) : timestamp ? (
    <FormattedDate timestamp={timestamp} format={EXPIRY_DATE_FORMAT} />
  ) : (
    '--'
  )
}

export const ethPriceRenderer = (props: any) => {
  const {
    cellData: value,
    rowData: { status },
  } = props

  return status === 'exercised' ? (
    <ColorCell value="Exercised" status={status} />
  ) : (
    <TokenBalance value={value} token="$" precision={2} />
  )
}
