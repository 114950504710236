import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { GetSellerOptionsByUnderlying, GetSellerOptionsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { useConnection } from 'components/web3'

import { Big } from 'big.js'
import { sortDecExpiry } from './useBuyerEthPuts'
const ALL_OPTIONS = loader('../queries/seller_options_by_underlying.graphql')

export const useSellerOptionsByUnderlying = (
  underlying: string,
  whitelist: string[],
  isPut: Boolean,
  token: string,
) => {
  const { account } = useConnection()
  const [loading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<Maybe<Array<GetSellerOptionsByUnderlying_optionsContracts>>>(null)

  const variables = {
    account,
    underlyings: [underlying],
  }

  const { loading: fetchingData, data: optionData } = useQuery<GetSellerOptionsByUnderlying>(ALL_OPTIONS, {
    variables,
  })

  useEffect(() => {
    let isCancelled = false
    if (!isCancelled && optionData && !fetchingData) {
      const optionsContractsToShow = isPut
        ? optionData.optionsContracts.filter(option => whitelist.includes(option.address))
        : optionData.optionsContracts.filter(
            option => whitelist.includes(option.address) && option.collateral === token,
          )

      setOptions(
        optionsContractsToShow
          .filter(
            (option: GetSellerOptionsByUnderlying_optionsContracts) =>
              option.expiry > Date.now() / 1000 ||
              (option?.vaults?.length && new Big(option?.vaults?.[0]?.collateral || 0).gt(0)),
          )
          .sort(sortDecExpiry),
      )
      setIsLoading(false)
    }

    return () => {
      isCancelled = true
    }
  }, [optionData, fetchingData, whitelist, isPut, token])

  return { options, loading: loading || fetchingData }
}
