import { isValidNetworkId, networkNames } from '.'

export enum LinkType {
  tx = 'tx',
  address = 'address',
}

export const etherscan = ({
  networkId,
  hash,
  type,
}: {
  networkId: Maybe<number>
  hash: string
  type: LinkType
}): string =>
  isValidNetworkId(networkId)
    ? networkId === 4
      ? `https://${networkNames[networkId]}.etherscan.io/${type}/${hash}`
      : `https://etherscan.io/${type}/${hash}`
    : ''

export const etherscanTx = ({ networkId, hash }: { networkId: Maybe<number>; hash: string }) =>
  etherscan({ networkId, hash, type: LinkType.tx })

export const etherscanAddress = ({ networkId, hash }: { networkId: Maybe<number>; hash: string }) =>
  etherscan({ networkId, hash, type: LinkType.address })
