import React, { useCallback, useState, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { useConnection } from '../../web3'
import { truncateStringInTheMiddle, etherscanAddress } from '../../../utils'
import { Modal } from '../../Modals/Modal'
import { ModalInnerCard } from '../../Modals/ModalInnerCard'
import ArrowSVG from './img/arrow.svg'
import { darken } from 'polished'

const ButtonContainer = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 96px;
  border: 2px solid #bdbdbd;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  outline: none;
  padding: 0 18px;
  white-space: nowrap;

  &:hover {
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ConnectionDot = styled.span<{ isConnected: boolean }>`
  background-color: ${props => (props.isConnected ? props.theme.colors.primary : props.theme.colors.error)};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`

const ConnectionDotSeparate = styled(ConnectionDot)`
  margin-right: 10px;
`

const ButtonText = styled.span`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
`

const WalletInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const WalletName = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
`

const AddressWrapper = styled.a`
  align-items: center;
  display: flex;
  text-decoration: none;
`

const Address = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
`

const ArrowImg = styled.img`
  display: block;
  margin-left: 5px;
`

const ModalInnerCardStyled = styled(ModalInnerCard)`
  margin-bottom: 18px;
`

const DisconnectButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.colors.error};
  color: ${props => props.theme.colors.error};
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  font-size: 12px;
  font-weight: normal;
  height: 34px;
  justify-content: center;
  line-height: 1.2;
  margin: 0 auto;
  outline: none;
  padding: 0 50px;

  &:hover {
    border-color: ${props => darken(0.15, props.theme.colors.error)};
    color: ${props => darken(0.15, props.theme.colors.error)};
  }

  &[disabled] {
    border-color: ${props => props.theme.colors.error};
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ButtonConnect: React.FC<ButtonConnectProps> = (props: ButtonConnectProps) => {
  const { connectWallet, connectReadOnly, readOnly, isConnected, isConnecting, account, networkId } = useConnection()
  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false)
  const isConnectedToWallet = isConnected && !readOnly
  const closeModal = useCallback(() => setDisconnectModalOpen(false), [])

  const buttonClickHandler = useCallback(() => {
    if (isConnectedToWallet) {
      setDisconnectModalOpen(true)
    } else {
      connectWallet()
    }
  }, [isConnectedToWallet, setDisconnectModalOpen, connectWallet])

  const handleDisconnect = useCallback(() => {
    if (isConnectedToWallet) {
      connectReadOnly()
      setDisconnectModalOpen(false)
    }
  }, [isConnectedToWallet, connectReadOnly, setDisconnectModalOpen])

  return (
    <>
      <ButtonContainer disabled={isConnecting} onClick={buttonClickHandler} {...props}>
        <ConnectionDotSeparate isConnected={isConnectedToWallet} />
        <ButtonText>{account ? truncateStringInTheMiddle(`${account}`) : 'Connect Wallet'}</ButtonText>
      </ButtonContainer>
      {account && (
        <Modal
          isOpen={isDisconnectModalOpen}
          onRequestClose={closeModal}
          style={{ content: { width: '530px' } }}
          title="Account"
        >
          <ModalInnerCardStyled>
            <WalletInfo>
              <WalletName>Metamask</WalletName>
              <ConnectionDot isConnected={isConnectedToWallet} />
            </WalletInfo>
            <AddressWrapper target="_blank" href={etherscanAddress({ networkId, hash: account })}>
              <Address>{account}</Address>
              <ArrowImg src={ArrowSVG} alt="" />
            </AddressWrapper>
          </ModalInnerCardStyled>
          <DisconnectButton onClick={handleDisconnect}>Disconnect</DisconnectButton>
        </Modal>
      )}
    </>
  )
}

interface ButtonConnectProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
}
