import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Tooltip } from '../Tooltip'

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TooltipStyled = styled(Tooltip)`
  margin-left: 5px;
`

interface Props extends HTMLAttributes<HTMLSpanElement> {
  id: string
  text: any
  tooltipText: string
  place?: string
}

export const TextWithTooltip: React.FC<Props> = props => {
  const { place, id, text, tooltipText, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      {text}
      <TooltipStyled place={place} text={tooltipText} id={id} />
    </Wrapper>
  )
}
