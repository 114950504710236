import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useConnection } from '../../web3'

export enum WarningType {
  ERROR,
  OK,
  WARN,
}

const Wrapper = styled.div<{ type?: WarningType }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  background-color: ${props =>
    props.type === WarningType.ERROR
      ? props.theme.colors.error
      : props.type === WarningType.WARN
      ? props.theme.colors.tertiary
      : props.type === WarningType.OK
      ? props.theme.colors.primary
      : props.theme.colors.gray};
  top: ${props => props.theme.header.heightMobile};
  color: ${props =>
    props.type === WarningType.ERROR || props.type === WarningType.WARN || props.type === WarningType.OK
      ? props.theme.colors.mainBodyBackground
      : props.theme.colors.textColorLight};
  z-index: 1000;
  font-weight: 600;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    top: ${props => props.theme.header.height};
  }

  a {
    font-size: 16px;
    color: ${props =>
      props.type === WarningType.ERROR || props.type === WarningType.WARN || props.type === WarningType.OK
        ? props.theme.colors.mainBodyBackground
        : props.theme.colors.textColorLight};
  }
`

type MyWindow = typeof window & {
  ethereum: any
  web3: any
}

const Warnings = () => {
  const { isConnected, networkId } = useConnection()

  const currentProvider = useMemo(
    () => (window as MyWindow).ethereum || ((window as MyWindow).web3 && (window as MyWindow).web3.currentProvider),
    [],
  )
  const isMmInstalled = useMemo(() => !!currentProvider, [currentProvider])
  const isTestnet = useMemo(() => isConnected && networkId === 4, [isConnected, networkId])

  return !isMmInstalled ? (
    <Wrapper type={WarningType.WARN}>
      <div>
        Please download a web3 wallet like{' '}
        <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
          Metamask
        </a>{' '}
        to use Opyn
      </div>
    </Wrapper>
  ) : isTestnet ? (
    <Wrapper>Note: You are currently connected to the Rinkeby testnet.</Wrapper>
  ) : (
    <></>
  )
}

export default Warnings
