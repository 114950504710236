import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { Button } from '../../Common/Button'
import { LOCK_STATES } from '../../Common/TokensDropdownUnlocker'
import { TextLight } from 'components/Common/CommonStyled'
import {
  ThemeColorTypes,
  isBetween,
  getTokenFromAddress,
  isValidNetworkId,
  calculateCurrentRatio,
  Numeric,
} from 'utils'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { oTokenData, useConnection } from 'hooks'
import { BigNumber } from 'ethers/utils'
import TokenBalance from 'components/Common/TokenBalance'
import { BigSource, Big } from 'big.js'
import Percentage from 'components/Common/Percentage'

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  flex: 1;

  & > div {
    border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  }

  & > div:last-child {
    border-bottom: none;
  }
`

const Row = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
`

interface RatioProps {
  value: Numeric
  minRatio: Numeric
}
const Ratio = styled.div<RatioProps>`
  color: ${props => (props.value > props.minRatio ? props.theme.colors.primary : props.theme.colors.error)};
`

interface ModalProps extends ModalBasicProps {
  isOpen: boolean
  onConfirm?: (tx: any, amount: string, type: string, title: string) => void
  onRequestClose?: (e?: any) => void
  onTransactionFail?: () => void
  bigOTokenBalance?: BigNumber
  option?: SellOTokens_optionsContract
  oTokenData?: oTokenData
}

export const BurnOTokensModal = (props: ModalProps) => {
  const { networkId } = useConnection()
  const [inputOTokens, setInputOTokens] = useState<BigSource>('0')
  const [oTokensToBurn, setOTokensToBurn] = useState<BigNumber>(new BigNumber(0))
  const [isBurning, setIsBurning] = useState(false)

  const { bigOTokenBalance, oTokenData, option, onConfirm, onTransactionFail } = props
  const { minCollateralizationRatioValue } = option || {}
  const { service, collateralToStrikePrice } = oTokenData || {}
  const underlyingToken = isValidNetworkId(networkId) && getTokenFromAddress(networkId, option?.underlying)

  const oTokenSymbol = useMemo(() => {
    if (underlyingToken) {
      return underlyingToken.cToken
        ? `o${underlyingToken.symbol[0].toLowerCase()}${underlyingToken.symbol.slice(1)}`
        : `o${underlyingToken.symbol}`
    }
    return '--'
  }, [underlyingToken])

  const oTokenDecimals = useMemo(() => (option ? -option.oTokenExchangeRateExp : 1), [option])

  const calculateOTokensToBurn = useCallback(
    inputValue => {
      const oTokensToBurn = new BigNumber(new Big(inputValue.toString()).times(`1e${oTokenDecimals}`).toFixed(0))
      setOTokensToBurn(oTokensToBurn)
    },
    [oTokenDecimals],
  )

  const minCollatRatio = useMemo(() => (minCollateralizationRatioValue ? minCollateralizationRatioValue * 10 : 1), [
    minCollateralizationRatioValue,
  ])

  const collateralizationRatio = useMemo(() => {
    if (option && option.vaults && collateralToStrikePrice) {
      const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = option.vaults[0]
      const newOTokenAmount = new Big(mintedOTokens).minus(oTokensToBurn.toString()).toString()
      return Math.round(
        calculateCurrentRatio({
          addedCollateral: new Big(addedCollateral).div('1e18').toString(),
          strikePriceValue: option?.strikePriceValue,
          strikePriceExp: option?.strikePriceExp,
          collateralToStrikePrice,
          mintedOTokens: newOTokenAmount,
        }) * 100,
      )
    }
    return 0
  }, [option, collateralToStrikePrice, oTokensToBurn])

  const bOTokenBalance = new Big(bigOTokenBalance ? bigOTokenBalance.toString() : 0).times(`1e-${oTokenDecimals}`)
  const updateInputOTokens = useCallback(
    (value: string) => {
      if (isBetween(value, 0, bOTokenBalance.toString())) {
        setInputOTokens(value || '0')
        calculateOTokensToBurn(value || '0')
      }
    },
    [calculateOTokensToBurn, bOTokenBalance],
  )

  const setMaxoEthBalance = useCallback(() => {
    setInputOTokens(bOTokenBalance)
    calculateOTokensToBurn(bOTokenBalance)
  }, [calculateOTokensToBurn, bOTokenBalance])

  const burnHandler = useCallback(async () => {
    if (service && oTokensToBurn.gt(0)) {
      setIsBurning(true)
      try {
        const tx = await service.burnOTokens(oTokensToBurn.toString())

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, oTokensToBurn.toString(), 'BurnOTokenAction', 'Confirm Burn oTokens')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          setIsBurning(false)
          onTransactionFail()
        }
      }
    }
  }, [oTokensToBurn, service, onTransactionFail, onConfirm])

  const isBurnDisabled = useMemo(() => !!(oTokensToBurn.eq(0) || isBurning), [oTokensToBurn, isBurning])

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        dropdownIsDisabled
        isLocked={LOCK_STATES.UNLOCKED}
        error={false}
        selectedToken={'otoken'}
        includeOToken={true}
        title={'oTokens to Burn'}
        titleInfo={[
          {
            title: 'oToken Balance:',
            value: (
              <TokenBalance
                value={{ value: bigOTokenBalance || 0, decimals: oTokenDecimals }}
                token={oTokenSymbol}
                precision={4}
              />
            ),
            onClick: setMaxoEthBalance,
          },
        ]}
        value={inputOTokens.toString()}
        onAmountChange={updateInputOTokens}
      />
      <Detail>
        <Row>
          <TextLight>oTokens to Burn</TextLight>
          <div>
            <TokenBalance token={oTokenSymbol} value={inputOTokens.toString()} precision={4} />
          </div>
        </Row>
        <Row>
          <TextLight>Collateralization Ratio</TextLight>
          <Ratio value={collateralizationRatio} minRatio={minCollatRatio}>
            <Percentage value={collateralizationRatio} />
          </Ratio>
        </Row>
      </Detail>
      <Button buttonStyle={ThemeColorTypes.secondary} disabled={isBurnDisabled} onClick={burnHandler}>
        Burn oTokens
      </Button>
    </Modal>
  )
}
