import React from 'react'
import styled from 'styled-components'
import { InnerContainer } from '../InnerContainer'
import { SocialIcons } from '../SocialIcons'

export const Wrapper = styled.footer`
  align-items: center;
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  margin: auto 0 0 0;
  min-height: 70px;
  width: 100%;
  padding: 0 5%;
`

export const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: row;
`

export const Menu = styled.div`
  display: flex;
`

export const Item = styled.a`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin-right: 25px;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const SocialIconsStyled = styled(SocialIcons)`
  margin-left: auto;
`

export const Footer: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Menu>
          <Item href="https://opyn.gitbook.io/opyn-terms-and-privacy/privacy-policy" target="_blank">
            Privacy Policy
          </Item>
          <Item href="https://opyn.gitbook.io/opyn-terms-and-privacy/" target="_blank">
            Terms
          </Item>
          <Item href="https://opyn.gitbook.io/opynv1/faq" target="_blank">
            FAQ
          </Item>
          <Item href="https://opyn.gitbook.io/opyn/security" target="_blank">
            Security
          </Item>
        </Menu>
        <SocialIconsStyled />
      </InnerContainerStyled>
    </Wrapper>
  )
}
