import React, { HTMLAttributes } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { IconInfo } from './img/IconInfo'

const TooltipPopup = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  position: relative;
  white-space: normal;

  > svg {
    display: block;

    path {
      fill: ${props => props.theme.colors.textColorLight};
    }

    &:hover {
      path {
        fill: ${props => props.theme.colors.textColor};
      }
    }
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #bdbdbd;
    border-top-width: 8px;
    bottom: -8px;
  }

  .reactTooltip {
    background-color: #bdbdbd;
    border-radius: 8px;
    color: #fbfbfb;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    max-width: 190px;
    opacity: 1;
    padding: 10px 8px;
    text-align: left;

    > a {
      color: ${props => props.theme.colors.primary};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &.place-left:after {
      border-left-color: #bdbdbd;
    }

    &.place-right:after {
      border-right-color: #bdbdbd;
    }

    &.place-top:after {
      border-top-color: #bdbdbd;
    }

    &.place-bottom:after {
      border-bottom-color: #bdbdbd;
    }

    .multi-line {
      text-align: left;
    }
  }
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  text: string
  id: string
  place?: string
}

export const Tooltip = (props: Props) => {
  const { place = 'top', id, text, ...restProps } = props

  return (
    <TooltipPopup data-tip={text} data-for={id} data-place={place} {...restProps}>
      <IconInfo />
      <ReactTooltip
        className="reactTooltip"
        multiline={true}
        clickable={true}
        html={true}
        delayHide={500}
        id={id}
        effect="solid"
      />
    </TooltipPopup>
  )
}
