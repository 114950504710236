import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import completed from './img/step_completed.svg'
import loading from './img/step_spinner.svg'

const Wrapper = styled.div`
  display: flex;
  margin: 13px 0;
`

const StepIcon = styled.div`
  display: flex;
  justify-content: center;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 4px;
    width: 49%;
    background: ${props => props.theme.colors.gray};
    transform: translateY(13px);
  }
`

const StepBadge = styled.div`
  width: 30px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.colors.textColorLight};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
`
const StepLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: ${props => props.theme.colors.textColorLight};
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ status }: { status: StepStatus }) => {
    switch (status) {
      case 'current':
        return css`
          ${StepIcon}:before {
            background: ${props => props.theme.colors.secondary};
          }

          ${StepBadge} {
            border-color: #fff;
            color: #fff;
            background: ${props => props.theme.colors.secondary};
          }
        `
      case 'loading':
        return css`
          ${StepIcon}:before {
            background: ${props => props.theme.colors.secondary};
          }
          ${StepBadge} {
            background: ${props => props.theme.colors.secondary} url(${loading}) no-repeat center;
            animation: ${spin} 1s linear;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            will-change: transform;
          }
        `
      case 'completed':
        return css`
          ${StepIcon}:before, ${StepIcon}:after {
            background: ${props => props.theme.colors.secondary};
          }

          ${StepBadge} {
            border-color: #fff;
            color: #fff;
            background: ${props => props.theme.colors.secondary} url(${completed}) no-repeat center;
          }
        `
      default:
        break
    }
  }}
`

export type StepStatus = 'required' | 'current' | 'loading' | 'completed'

export interface StepProps {
  label: string
  status: StepStatus
}

interface Props {
  steps: StepProps[]
}

export const Stepper = ({ steps }: Props) => {
  return (
    <Wrapper>
      {steps.map((step, index) => (
        <Step key={`step-${step.label}`} status={step.status}>
          <StepIcon>
            {step.status === 'required' || step.status === 'current' ? (
              <StepBadge>{index + 1}</StepBadge>
            ) : (
              <StepBadge />
            )}
          </StepIcon>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Wrapper>
  )
}
