import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'

import { GetSellerOptionsByUnderlying, GetSellerOptionsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { useConnection } from 'components/web3'
import {
  getTokensByNetwork,
  Token,
  isValidNetworkId,
  BigFloat,
  NetworkId,
  getCompoundEarnAPR,
  calculateCurrentRatio,
  getIsExpired,
  // getTokenPriceCoingecko,
} from 'utils'
import useOracle from './useOracle'
import { useBalances } from '.'
import { OptionsExchange, OracleService } from 'services'
import { COMPOUND_TOKENS } from 'common/constants'
import { Big } from 'big.js'
import { Web3Provider } from 'ethers/providers'

const ALL_OPTIONS = loader('../queries/seller_options_by_underlying.graphql')

const sortDecExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(b.expiry) - parseInt(a.expiry)
  const byTimestamp = parseInt(b.timestamp) - parseInt(a.timestamp)

  return byExpiry ? byExpiry : byTimestamp
}

const getCollateralToStrikePrice = async (strikeAddress: string, oracleContract?: OracleService): Promise<BigFloat> => {
  if (!oracleContract || !strikeAddress) {
    return {
      value: 0,
    }
  }

  const value = await oracleContract.getPrice(strikeAddress)
  // const value = (Number(await getTokenPriceCoingecko(strikeAddress)) * 1e18).toString()

  return {
    value,
    decimals: 18,
  }
}

const getCurrentCollateral = async ({
  option,
  oracleContract,
}: {
  option: GetSellerOptionsByUnderlying_optionsContracts
  oracleContract?: OracleService
}) => {
  if (!option.vaults?.[0]) {
    return 0
  }

  const collateralToStrikePrice = await getCollateralToStrikePrice(option.strike, oracleContract)
  const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = option.vaults[0]
  return calculateCurrentRatio({
    addedCollateral: new Big(addedCollateral).div('1e18').toString(),
    strikePriceValue: option?.strikePriceValue,
    strikePriceExp: option?.strikePriceExp,
    collateralToStrikePrice,
    mintedOTokens,
  })
}

interface Extras {
  earnAPR: BigFloat
  collateralizationRatio: number
  isExpired: boolean
}

type ExtraData = Record<string, Extras>

const getExtras = async (
  data: Array<GetSellerOptionsByUnderlying_optionsContracts>,
  balances: Array<any>,
  networkId: NetworkId,
  account: string,
  library: Web3Provider,
  oracleContract?: OracleService,
) => {
  return await data.reduce(async (acc: Promise<ExtraData>, option: GetSellerOptionsByUnderlying_optionsContracts) => {
    const previous = await acc
    const address = option.id
    const underlying = option.underlying
    const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)

    const [balance] = balances.filter(b => b.cToken.address.toLowerCase() === underlying)
    const [earnAPR, collateralizationRatio, isExpired] = await Promise.all([
      getCompoundEarnAPR({
        networkId,
        optionsExchangeContract,
        oracleContract,
        optionAddress: option.address,
        optionExpiry: option.expiry,
        optionOTokenExchangeRateExp: option.oTokenExchangeRateExp,
        balance,
      }),
      getCurrentCollateral({ option, oracleContract }),
      getIsExpired(library, option.address),
    ])

    return {
      ...previous,
      [address]: { earnAPR, collateralizationRatio, isExpired },
    }
  }, Promise.resolve({} as ExtraData))
}

type OptionsByUnderlying = Record<string, Array<GetSellerOptionsByUnderlying_optionsContracts>>

const groupByUnderlying = (data: Array<GetSellerOptionsByUnderlying_optionsContracts>, extraData: ExtraData) => {
  return data.reduce((acc: OptionsByUnderlying, option: GetSellerOptionsByUnderlying_optionsContracts) => {
    const underlying = option.underlying
    const optionGroup = acc[underlying] || new Array<GetSellerOptionsByUnderlying_optionsContracts>()

    return {
      ...acc,
      [underlying]: [...optionGroup, option]
        .filter(
          () =>
            !extraData[option.address].isExpired ||
            (option?.vaults?.length && new Big(option?.vaults?.[0]?.collateral || 0).gt(0)),
        )
        .sort(sortDecExpiry),
    }
  }, {} as OptionsByUnderlying)
}

export const useSellCompoundSection = () => {
  const { networkId, account = '', library } = useConnection()
  const [loading, setLoading] = useState(true)
  const [optionsByUnderlying, setOptionsByUnderlying] = useState<Maybe<OptionsByUnderlying>>(null)
  const [extraData, setExtraData] = useState<Maybe<ExtraData>>(null)
  const tokens = isValidNetworkId(networkId)
    ? getTokensByNetwork(networkId).filter((token: Token) => !!token.cToken)
    : []
  const underlyings = tokens.map(token => token.address)

  const [balances, balancesLoading] = useBalances(COMPOUND_TOKENS)

  const oracleContract = useOracle()

  const { loading: queryLoading, data } = useQuery<GetSellerOptionsByUnderlying>(ALL_OPTIONS, {
    variables: { account, underlyings },
    skip: !underlyings.length,
  })

  useEffect(() => {
    let isCancelled = false
    if (!isCancelled && data && !queryLoading && extraData) {
      setOptionsByUnderlying(groupByUnderlying(data.optionsContracts, extraData))
    }

    return () => {
      isCancelled = true
    }
  }, [data, queryLoading, extraData])

  useEffect(() => {
    let isCancelled = false
    if (
      data &&
      isValidNetworkId(networkId) &&
      !balancesLoading &&
      balances.length &&
      oracleContract &&
      account !== null &&
      library
    ) {
      setLoading(true)
      getExtras(data.optionsContracts, balances, networkId, account, library, oracleContract).then(extras => {
        if (extras && !isCancelled) {
          setExtraData(extras)
        }
        setLoading(false)
      })
    }

    return () => {
      isCancelled = true
    }
  }, [data, networkId, balances, oracleContract, balancesLoading, account, library])

  return { tokens, optionsByUnderlying, extraData, loading }
}
