import React from 'react'
import { Footer } from './components/Footer'
import { Welcome } from './components/Buy/Welcome'
import { ProtectEarnDevelop } from './components/Buy/ProtectEarnDevelop'
import { Manage } from './components/Buy/Manage'
import { ProtectYourEth } from './components/Buy/ProtectYourEth'
import { Built } from './components/Buy/Built'
import { Protect } from './components/Buy/Protect'
import { Share } from '../../components/Common/Share'
import { GetProtectedSteps } from './components/Buy/GetProtectedSteps'

const BuyLanding = () => (
  <>
    <Welcome />
    <ProtectEarnDevelop />
    <GetProtectedSteps />
    <ProtectYourEth />
    <Built />
    <Manage />
    <Protect />
    <Share />
    <Footer />
  </>
)

export default BuyLanding
