import React, { useCallback, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { Card } from 'components/Common/Card'
import { Button } from 'components/Common/Button'
import { InnerContainer } from 'components/Common/InnerContainer'
import {
  ThemeColorTypes,
  durationGetter,
  convertBigFloatToBig,
  isValidNetworkId,
  getTokenFromAddress,
  NetworkIds,
} from 'utils'
import { useConnection } from 'hooks'
import { useHistory } from 'react-router'
import CompoundIcon from './img/compound.svg'
import UsdcIcon from './img/usdc.svg'
import DaiIcon from './img/dai.svg'
import EthIcon from './img/eth.svg'
import { useSellCompoundSection } from 'hooks/useSellCompoundSection'
import { GetSellerOptionsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { EarnCell } from 'components/Common/EarnCell'
import { Big } from 'big.js'
import SpinnerSVG from './img/spinner.svg'
// restore ETH puts. uncomment this
// import { useSellerEthPutsOrCalls } from 'hooks/useSellerEthPutsAndCalls'
// import FormattedDate from 'components/Common/Date'
// import Value from 'components/Common/Value'
// import { EarnPremiumCell } from 'components/Common/EarnPremiumCell'

const Wrapper = styled.div`
  background-image: linear-gradient(${props => props.theme.colors.secondary}, ${props => props.theme.colors.secondary});
  background-repeat: repeat-x;
  background-size: 1px 450px;
  margin-bottom: 40px;
  padding-top: 45px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 80px;
    padding-top: 65px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.mainBodyBackground};
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 20px;
  text-align: center;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 48px;
  }
`

const ButtonStyledChart = styled(Button)`
  height: 40px;
  min-width: 48px;

  &:first-child {
    margin-right: 16px;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    min-width: 48px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const CardStyled = styled(Card)`
  margin: 0 auto;
  max-width: 100%;
  width: 1200px;
`

const CardTitle = styled.h3<{ icon: string }>`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 30px 50%;
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  padding: 25px 30px 25px 65px;
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
`

const IconImg = styled.img`
  display: block;
  margin: 0 12px 0 0;
`

const IconAndText = styled.div`
  align-items: center;
  display: flex;
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

const THead = styled.thead``

const TBody = styled.tbody``

const TR = styled.tr`
  display: grid;
  grid-template-columns: repeat(6, 16.67%);
  & td:first-child {
    padding-left: 30px;
  }
  & td:last-child {
    padding-right: 30px;
  }
`

const TH = styled.th`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 14px 35px;
  text-align: center;
  white-space: nowrap;
`

const THBottomBorder = styled(TH)`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
`

const THBlueBackground = styled(TH)`
  background: rgba(105, 121, 248, 0.12);
`

const TD = styled.td`
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 14px 35px;
  text-align: center;
`

const TDBottomBorder = styled(TD)`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
`

// const TDRedBackground = styled(TD)`
//   background-color: rgba(223, 95, 103, 0.08);
// `

const TDBlueBackground = styled(TD)`
  background: rgba(105, 121, 248, 0.12);
`

const SpinnerTR = styled.tr`
  height: 65px;
  padding: 14px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg)
  }
`

const SpinnerWrapper = styled.td`
  grid-column: 1 / span 5;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
`

const Spinner = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${SpinAnimation};
  animation-timing-function: linear;
  display: flex;
  justify-content: center;
`

const SpinnerImage = styled.img`
  display: block;
`

export const Loading: React.FC = props => (
  <SpinnerTR>
    <SpinnerWrapper {...props}>
      <Spinner>
        <SpinnerImage src={SpinnerSVG} alt="" />
      </Spinner>
    </SpinnerWrapper>
  </SpinnerTR>
)

const sortDecTimestamp = (a: any, b: any) => {
  const byTimestamp = parseInt(b.timestamp) - parseInt(a.timestamp)

  return byTimestamp
}

export const Welcome: React.FC = props => {
  const { ...restProps } = props
  const { networkId } = useConnection()
  const compountData = useSellCompoundSection()

  // restore ETH puts. uncomment this
  // const puts = useSellerEthPutsOrCalls('put')

  // const calls = useSellerEthPutsOrCalls('call')

  const cOTokens = useMemo(() => {
    const { optionsByUnderlying = {} } = compountData || {}
    return optionsByUnderlying
      ? Object.keys(optionsByUnderlying).flatMap(k => {
          const [first] = optionsByUnderlying[k].sort(sortDecTimestamp)
          return [first]
        })
      : []
  }, [compountData])

  // restore ETH puts. uncomment this
  // const oETHs = useMemo(() => {
  //   const { options = [], extraData } = puts || {}
  //   return extraData && options ? options.filter(op => !extraData[op.id].isExpired) : []
  // }, [puts])

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Title>Grow your ETH and USDC </Title>
        <CardStyled noPadding>
          <CardTitle icon={EthIcon}>Sell ETH Protection</CardTitle>
          <TableContainer>
            {/* <Table>
              <THead>
                <TR>
                  <THBottomBorder>Expiry</THBottomBorder>
                  <THBottomBorder>Strike Price</THBottomBorder>
                  <THBottomBorder>Current ETH Price</THBottomBorder>
                  <THBottomBorder>Collateral Asset</THBottomBorder>
                  <THBlueBackground>Premium to Earrn</THBlueBackground>
                  <THBlueBackground>&nbsp;</THBlueBackground>
                </TR>
              </THead>
              <TBody>
                {!oETHs.length ? (
                  <Loading />
                ) : (
                  oETHs.map(oToken => <EthRow key={oToken.id} oToken={oToken} data={puts} networkId={networkId} />)
                )}
              </TBody>
            </Table> */}

            <div style={{ margin: '10px 40px' }}>
              <h4>
                There was a recent vulnerability with the ETH puts. Affected users, please see{' '}
                <a
                  href="https://medium.com/opyn/opyn-eth-put-exploit-c5565c528ad2?postPublishedType=repub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this Medium post
                </a>
                .
                <br />
                <br />
                Please do not create any new oETH put vaults or buy/sell oETH puts except with the Opyn team, through
                the process defined in the above Medium post.
                <br />
                <br />
                For any questions, see{' '}
                <a href="https://tiny.cc/opyndiscord" target="_blank" rel="noopener noreferrer">
                  Discord
                </a>
              </h4>
              .
            </div>
          </TableContainer>
          <CardTitle icon={CompoundIcon}>Sell Protection for Compound Deposits</CardTitle>
          <TableContainer>
            <Table>
              <THead>
                <TR>
                  <THBottomBorder>Asset</THBottomBorder>
                  <THBottomBorder>Duration</THBottomBorder>
                  <THBottomBorder>
                    Collateralization
                    <br />
                    Requirement
                  </THBottomBorder>
                  <THBottomBorder>
                    Collateral
                    <br />
                    Asset
                  </THBottomBorder>
                  <THBlueBackground>
                    Earn
                    <br />
                    (APR)
                  </THBlueBackground>
                  <THBlueBackground>&nbsp;</THBlueBackground>
                </TR>
              </THead>
              <TBody>
                {!cOTokens.length ? (
                  <Loading />
                ) : (
                  cOTokens.map(oToken => (
                    <CompoundRow key={oToken.id} oToken={oToken} data={compountData} networkId={networkId} />
                  ))
                )}
              </TBody>
            </Table>
          </TableContainer>
        </CardStyled>
      </InnerContainerStyled>
    </Wrapper>
  )
}

const getIcon = (tokenId: string) => (tokenId === 'ETH' ? EthIcon : tokenId === 'CUSDC' ? UsdcIcon : DaiIcon)
const getIconName = (tokenId: string) => (tokenId === 'ETH' ? 'ETH' : tokenId === 'CUSDC' ? 'USDC' : 'DAI')

const earnRenderer = (props: any) => {
  const { option, extras } = props
  const value =
    extras &&
    option &&
    convertBigFloatToBig(extras.earnAPR)
      .div(new Big(option.minCollateralizationRatioValue))
      .times(10)

  return extras && option ? <EarnCell value={value} token={'ETH'} /> : '--'
}

interface SellRowProps {
  oToken: GetSellerOptionsByUnderlying_optionsContracts
  data: any
  networkId: -1 | NetworkIds
}

const CompoundRow = ({ oToken, data, networkId }: SellRowProps) => {
  const { extraData, tokens } = data || {}
  const history = useHistory()

  const goToSell = useCallback(() => history.push(`/sell/${oToken.id}`), [history, oToken.id])

  const underlyingSymbol = tokens.find((t: any) => t.address.toLowerCase() === oToken.underlying.toLowerCase()).symbol

  const collateralToken = isValidNetworkId(networkId) && getTokenFromAddress(networkId, oToken.collateral)

  const extras = extraData && oToken ? extraData[oToken.id] : null

  return (
    <TR>
      <TDBottomBorder>
        <IconAndText>
          <IconImg src={getIcon(underlyingSymbol)} alt="" /> {getIconName(underlyingSymbol)}
        </IconAndText>
      </TDBottomBorder>
      <TDBottomBorder>{durationGetter(oToken)?.value}</TDBottomBorder>
      <TDBottomBorder>{`${oToken.minCollateralizationRatioValue * 10}%`}</TDBottomBorder>
      <TDBottomBorder>{collateralToken ? collateralToken.symbol.toUpperCase() : '--'}</TDBottomBorder>
      <TDBlueBackground>{earnRenderer({ option: oToken, extras })}</TDBlueBackground>
      <TDBlueBackground>
        <ButtonStyledChart onClick={goToSell} buttonStyle={ThemeColorTypes.secondary}>
          Sell protection
        </ButtonStyledChart>
      </TDBlueBackground>
    </TR>
  )
}

// restore ETH puts. uncomment this

// const strikePriceRenderer = (props: any) => {
//   const { extras } = props

//   const value = extras ? `${extras.$strikePrice.toFixed(2)}USDC` : '--'

//   return <Value value={value} />
// }

// const ethPriceRenderer = (props: any) => {
//   const { $ethPrice } = props

//   const value = $ethPrice ? `$${$ethPrice.toFixed(2)}` : '--'

//   return <Value value={value} />
// }

// const earnPremiumRenderer = (props: any) => {
//   const { option, extras } = props
//   const value =
//     extras &&
//     option &&
//     convertBigFloatToBig(extras.earnPremium)
//       .div(new Big(option.minCollateralizationRatioValue))
//       .times(10)

//   return extras && option ? <EarnPremiumCell value={value} token={'USDC'} /> : '--'
// }

// const EthRow = ({ oToken, data, networkId }: SellRowProps) => {
//   const { extraData, $ethPrice } = data || {}
//   const history = useHistory()

//   const goToSell = useCallback(() => history.push(`/sell/${oToken.address}`), [history, oToken.address])

//   const extras = extraData && oToken ? extraData[oToken.id] : null

//   const collateralToken = isValidNetworkId(networkId) && getTokenFromAddress(networkId, oToken.collateral)

//   return (
//     <TR>
//       <TDBottomBorder>
//         <FormattedDate timestamp={oToken.expiry} />
//       </TDBottomBorder>
//       <TDBottomBorder>{strikePriceRenderer({ extras })}</TDBottomBorder>
//       <TDBottomBorder>{ethPriceRenderer({ $ethPrice })}</TDBottomBorder>
//       <TDBottomBorder>{collateralToken ? collateralToken.symbol.toUpperCase() : '--'}</TDBottomBorder>
//       <TDBlueBackground>{earnPremiumRenderer({ option: oToken, extras })}</TDBlueBackground>
//       <TDBlueBackground>
//         <ButtonStyledChart onClick={goToSell} buttonStyle={ThemeColorTypes.secondary}>
//           Sell protection
//         </ButtonStyledChart>
//       </TDBlueBackground>
//     </TR>
//   )
// }
