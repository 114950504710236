import { useState, useMemo } from 'react'
import { ETH_TOKEN, getToken, isValidNetworkId } from 'utils'
import { fromUnixTime } from 'date-fns'
import { format } from 'date-fns-tz'
import { Big } from 'big.js'
import useEthToUsdPrice from './useEthToUSDPrice'

import useOracle from './useOracle'
import { BigNumber } from 'ethers/utils'
import { OptionsExchange } from 'services'
import { EXPIRY_DATE_FORMAT } from 'common/constants'

import { useConnection } from '.'
import { useBuyEthOptions } from './useBuyerEthOptions'
import useAsyncMemo from './useAsyncMemo'

import { InsuredEthOptionsData, sortDecExpiry } from './useBuyerEthPuts'

export const callsToShow = [
  '0xbaf6cfa199fbbe8a9e5198f2af20040c5e7b0333',
  '0x05977ebc26825c0cd6097e0ad7204721516711eb',
  '0x7b936f18cede94eb24389809af9ca046e8c000b3',
  '0xf9aba2e43fb19184408ea3b572a0fd672946f87b',
  '0x2c01078f8348d6fb714175f929378ae4ef856138',
  '0xfc19b36158d9a004d3550b7f29ec57ec5ef2ddc9',
  '0xaee2b2097ed86354abfd4e2361761794c6ddc07b',
  '0xb759e6731df19abd72e0456184890f87dcb6c518',
  '0x7eb6dd0cc2df2eae901f76a151ca82bb7be10d68',
  '0x6ce283b9ffb91a05fcaa6904bcc69032544167db',
  '0x95d52139a62df9abdc9db3996a810c1d319ecd23',
]

export const useBuyerEthCalls = () => {
  const { networkId, account, library } = useConnection()
  const { options, loading: fetchingOptions, error } = useBuyEthOptions('call', callsToShow)

  const [isLoading, setIsLoading] = useState(true)
  const oracleContract = useOracle()
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])
  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])
  const ethToUsdcPrice = useEthToUsdPrice()

  const optionsWithDetail = useAsyncMemo(
    //: InsuredEthOptionsData[] = []
    async () => {
      if (!options || options.length === 0 || !usdc || !daiToken || !library || !oracleContract) {
        return []
      }

      const optionsWithDetail = await Promise.all(
        options?.map(
          async (option): Promise<InsuredEthOptionsData> => {
            const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)
            // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
            let premiumToPay = new BigNumber(0)
            try {
              premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, usdc.address, 1e4)
            } catch {
              // do nothing
            }

            const balance = account
              ? option?.holdersBalances?.find(balance => balance.account.address === account.toLocaleLowerCase())
              : { amount: new BigNumber(0) }

            // 1 base unit usdc can get ? ETH

            const $strikePrice = new Big(1).div(
              new Big(option?.strikePriceValue)
                .times(`1e${option?.strikePriceExp || 0}`)
                .times(`1e${-option?.oTokenExchangeRateExp || 0}`),
            )

            const $ethPrice = ethToUsdcPrice.gt(0)
              ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
              : new Big(0)

            const oToken = {
              address: option?.address,
              symbol: 'ETH',
              decimals: -option?.oTokenExchangeRateExp,
            }

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            return {
              type: 'Call',
              option,
              balance: {
                amount: new BigNumber(balance?.amount || 0).div(new BigNumber($strikePrice.toString())),
                token: oToken,
                precision: 4,
              },
              expiry: format(fromUnixTime(option?.expiry), EXPIRY_DATE_FORMAT, { timeZone }),
              strikePrice: $strikePrice.round(2).toFixed(),
              ethPrice: $ethPrice.round(2).toFixed(),
              protectionCost: new Big(premiumToPay.toString())
                .times(`1e${-option?.oTokenExchangeRateExp}`)
                .times(1e-4)
                .times(`1e-6`)
                .times($strikePrice)
                .round(4)
                .toFixed(),
              status: $strikePrice.gt($ethPrice) ? 'ok' : 'error',
            }
          },
        ) || [],
      )

      setIsLoading(false)
      return optionsWithDetail.sort(sortDecExpiry)
    },
    [],
    [networkId, account, library, options, usdc, daiToken, fetchingOptions, ethToUsdcPrice],
  )

  return { optionsWithDetail, loading: isLoading || fetchingOptions, error }
}
