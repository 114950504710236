import React from 'react'
import styled from 'styled-components'
import { InnerContainer } from '../../../../../components/Common/InnerContainer'
import Chevron from './img/chevron.svg'
import WhitePaper from './img/whitepaper.svg'

const Wrapper = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  background-color: #fbfbfb;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    padding: 40px 0;
    margin-bottom: 40px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.darkGray};
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin: 20px 0;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 40px;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 30px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 50px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const TextContents = styled.div`
  order: 2;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    padding-right: 160px;
    order: 1;
  }
`

const ImgContents = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  order: 1;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    order: 2;
  }
`

const Link = styled.a`
  color: ${props => props.theme.colors.primaryLight};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  text-decoration: none;

  & > svg {
    margin-left: 8px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const Built: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Layout>
          <TextContents>
            <Title>Built on the Convexity Protocol </Title>
            <Text>
              Convexity is a generalized options protocol built on the Ethereum blockchain that allows DeFi users to
              create put and call options. Convexity also provides an easy interface to buy and sell options. Opyn uses
              protective put options to protect against technical and financial risks in DeFi.{' '}
            </Text>
            <Link href="http://convexity.opyn.co" target="_blank">
              Read the whitepaper <img src={Chevron} alt="" />
            </Link>
          </TextContents>
          <ImgContents>
            <img src={WhitePaper} alt="" />
          </ImgContents>
        </Layout>
      </InnerContainerStyled>
    </Wrapper>
  )
}
