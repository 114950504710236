import React from 'react'
import styled from 'styled-components'
import { TotalWrapper, TotalTitle, TotalValue } from '../../Common/CommonStyled'

const Wrapper = styled(TotalWrapper)`
  margin: 25px 0 10px;
`

interface Props {
  value: string
}

export const InsuredYield: React.FC<Props> = props => {
  const { value, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Insured Yield (APR)</TotalTitle>
      <TotalValue>{value}%</TotalValue>
    </Wrapper>
  )
}
