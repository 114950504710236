// restore ETH puts. uncomment this
// import React, { useCallback, useMemo } from 'react'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Card } from 'components/Common/Card'
import { Button } from 'components/Common/Button'
import { InnerContainer } from 'components/Common/InnerContainer'
// import Percentage from 'components/Common/Percentage'
import {
  ThemeColorTypes,
  ThemeColorTypesInverted,
  // CompoundTokens,
  BuyerCompoundOptionData,
  // isBigFloat,
  // BigFloat,
  // durationGetter,
} from 'utils'
import {
  // useBuyerCompoundOptions,
  // restore ETH puts. uncomment this
  // useBuyerEthPuts,
  useBuyerEthCalls,
  InsuredEthOptionsData,
  useBuyerTokenPuts,
  useBuyerTokenCalls,
} from 'hooks'
import { useHistory } from 'react-router'
import TokenBalance from 'components/Common/TokenBalance'
import { CostCell } from 'components/Common/CostCell'
import BalancerIcon from './img/balancer.png'
import YfiIcon from './img/yfi.png'
// import CompoundIcon from './img/compound.svg'
import CompIcon from './img/comp.svg'
import CrvIcon from './img/crv.png'
import UniIcon from './img/uni.png'
import DydxIcon from './img/dydx.jpg'
import SnxIcon from './img/snx.png'
import DpiIcon from './img/dpi.png'
import WbtcIcon from './img/wbtc.png'
// import UsdcIcon from './img/usdc.svg'
// import DaiIcon from './img/dai.svg'
import EthIcon from './img/eth.svg'

const Wrapper = styled.div`
  background-image: linear-gradient(
    ${props => props.theme.colors.primaryLight},
    ${props => props.theme.colors.primaryLight}
  );
  background-repeat: repeat-x;
  background-size: 1px 450px;
  margin-bottom: 40px;
  padding-top: 45px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 80px;
    padding-top: 65px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.darkGray};
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 20px;
  text-align: center;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 48px;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 40px;
  text-align: center;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 24px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 53px;
  width: 100%;
`

const ButtonsWrapperChart = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

// const ButtonStyled = styled(Button)`
//   height: 64px;
//   min-width: 150px;

//   &:first-child {
//     margin-right: 24px;
//   }

//   @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
//     min-width: 208px;
//   }
// `

const ButtonStyledChart = styled(Button)`
  height: 40px;
  min-width: 48px;

  &:first-child {
    margin-right: 16px;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    min-width: 48px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const CardStyled = styled(Card)`
  margin: 0 auto;
  max-width: 100%;
  width: 1200px;
`

// const Icon = styled.img`
//   display: block;
//   margin-left: -615px;
//   margin-top: -47px;
// `

const CardTitle = styled.h3<{ icon: string }>`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: 30px 50%;
  background-size: 35px 35px;
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  padding: 25px 30px 25px 65px;
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
`

// const IconImg = styled.img`
//   display: block;
//   margin: 0 12px 0 0;
// `

// const IconAndText = styled.div`
//   align-items: center;
//   display: flex;
// `

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

const THead = styled.thead``

const TBody = styled.tbody``

// const TRCompound = styled.tr`
//   display: grid;
//   grid-template-columns: repeat(5, 20%);
//   & td:first-child {
//     padding-left: 30px;
//   }
//   & td:last-child {
//     padding-right: 30px;
//   }
// `

const TREthOptions = styled.tr`
  display: grid;
  grid-template-columns: 20% 15% 10% 17% 20% 18%;
  & td:first-child {
    padding-left: 30px;
  }
  & td:last-child {
    padding-right: 30px;
  }
`

const TH = styled.th`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 14px 35px;
  text-align: center;
  white-space: nowrap;
`

const THBottomBorder = styled(TH)`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
`

// const THRedBackground = styled(TH)`
//   background-color: rgba(223, 95, 103, 0.08);
// `

const THGreenBackground = styled(TH)`
  background: rgba(5, 177, 105, 0.12);
`

const TD = styled.td`
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 14px 35px;
  text-align: center;
`

const TDBottomBorder = styled(TD)`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
`

// const TDRedBackground = styled(TD)`
//   background-color: rgba(223, 95, 103, 0.08);
// `

const TDGreenBackground = styled(TD)`
  background: rgba(5, 177, 105, 0.12);
`

export const Welcome: React.FC = props => {
  const { ...restProps } = props
  // const { compoundOptions } = useBuyerCompoundOptions({ useAccount: false })
  // restore ETH puts. uncomment this
  // const { optionsWithDetail: ethPuts } = useBuyerEthPuts()
  const { optionsWithDetail: ethPuts } = useBuyerTokenPuts('weth')
  const { optionsWithDetail: ethCalls } = useBuyerEthCalls()
  const { optionsWithDetail: compPuts } = useBuyerTokenPuts('comp')
  const { optionsWithDetail: balPuts } = useBuyerTokenPuts('bal')
  const { optionsWithDetail: yfiPuts } = useBuyerTokenPuts('yfi')
  const { optionsWithDetail: yfiCalls } = useBuyerTokenCalls('yfi')
  const { optionsWithDetail: crvPuts } = useBuyerTokenPuts('crv')
  const { optionsWithDetail: uniPuts } = useBuyerTokenPuts('uni')
  const { optionsWithDetail: uniCalls } = useBuyerTokenCalls('uni')
  const { optionsWithDetail: dydxPuts } = useBuyerTokenPuts('dydx')
  const { optionsWithDetail: dydxCalls } = useBuyerTokenCalls('dydx')
  const { optionsWithDetail: snxPuts } = useBuyerTokenPuts('snx')
  const { optionsWithDetail: snxCalls } = useBuyerTokenCalls('snx')
  const { optionsWithDetail: dpiPuts } = useBuyerTokenPuts('dpi')
  const { optionsWithDetail: dpiCalls } = useBuyerTokenCalls('dpi')
  const { optionsWithDetail: wbtcPuts } = useBuyerTokenPuts('wbtc')
  const { optionsWithDetail: wbtcCalls } = useBuyerTokenCalls('wbtc')

  // restore ETH puts. uncomment this
  // const allEthOptions = useMemo(() => ethPuts.concat(ethCalls), [ethPuts, ethCalls])

  // const history = useHistory()

  // const goToBuy = useCallback(() => history.push(`/buy`), [history])

  // const goToSell = useCallback(() => history.push(`/sell`), [history])

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Title>Securing Decentralized Finance</Title>
        <Text>Opyn allows you to protect your DeFi deposits and hedge ETH risk.</Text>
        <ButtonsWrapper>
          {/* <ButtonStyled onClick={goToBuy} buttonStyle={ThemeColorTypes.darkGray}>
            Buy Protection
          </ButtonStyled> */}
          {/* <ButtonStyled onClick={goToSell} buttonStyle={ThemeColorTypesInverted.darkGrayInverted}>
            Sell Protection
          </ButtonStyled> */}
        </ButtonsWrapper>
        <CardStyled noPadding>
          <div style={{ fontSize: '1.2em', padding: '20px 40px' }}>
            Opyn v1 was the first options protocol in DeFi and since launch we released more than 150 options. It's been
            a great journey but <b>Opyn team can no longer dedicate resources to v1</b> to focus on Opyn 's future
            developments.
            <b>You can still use Opyn v1 to redeem collateral</b> or trade options on <a href="v2.opyn.co">Opyn v2</a>.
          </div>

          {(ethPuts.length > 0 || ethCalls.length > 0) && (
            <>
              <CardTitle icon={EthIcon}>Hedge ETH Risk</CardTitle>

              {/* <div style={{ margin: '10px 40px' }}>
              <h4>
                There was a recent vulnerability with the ETH puts. Affected users, please see{' '}
                <a
                  href="https://medium.com/opyn/opyn-eth-put-exploit-c5565c528ad2?postPublishedType=repub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this Medium post
                </a>
                .
                <br />
                <br />
                Please do not create any new oETH put vaults or buy/sell oETH puts except with the Opyn team, through the
                process defined in the above Medium post.
                <br />
                <br />
                For any questions, see{' '}
                <a href="https://tiny.cc/opyndiscord" target="_blank" rel="noopener noreferrer">
                  Discord
                </a>
              </h4>
              .
            </div> */}

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current WETH Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {ethPuts.map(optionData => (
                      <InsuredTokenPRow path="wethoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current ETH Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {ethCalls.map(optionData => (
                      <InsuredEthRow key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {(dpiPuts.length > 0 || dpiCalls.length > 0) && (
            <>
              <CardTitle icon={DpiIcon}> Hedge DPI Risk</CardTitle>
              {dpiPuts.length > 0 && (
                <TableContainer>
                  <Table>
                    <THead>
                      <TREthOptions>
                        <THBottomBorder>Expiry</THBottomBorder>
                        <THBottomBorder>Strike Price</THBottomBorder>
                        <THBottomBorder>Type</THBottomBorder>
                        <THBottomBorder>Current DPI Price</THBottomBorder>
                        <THGreenBackground>Protection Cost</THGreenBackground>
                        <THGreenBackground>&nbsp;</THGreenBackground>
                      </TREthOptions>
                    </THead>
                    <TBody>
                      {dpiPuts.map(optionData => (
                        <InsuredTokenPRow path="dpioption" key={optionData.option.address} {...optionData} />
                      ))}
                    </TBody>
                  </Table>
                </TableContainer>
              )}
              {dpiCalls.length > 0 && (
                <>
                  <TableContainer>
                    <Table>
                      <THead>
                        <TREthOptions>
                          <THBottomBorder>Expiry</THBottomBorder>
                          <THBottomBorder>Strike Price</THBottomBorder>
                          <THBottomBorder>Type</THBottomBorder>
                          <THBottomBorder>Current DPI Price</THBottomBorder>
                          <THGreenBackground>Protection Cost</THGreenBackground>
                          <THGreenBackground>&nbsp;</THGreenBackground>
                        </TREthOptions>
                      </THead>
                      <TBody>
                        {dpiCalls.map(optionData => (
                          <InsuredTokenPRow path="dpioption" key={optionData.option.address} {...optionData} />
                        ))}
                      </TBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
          {(uniPuts.length > 0 || uniCalls.length > 0) && (
            <>
              <CardTitle icon={UniIcon}> Hedge UNI Risk</CardTitle>
              {uniPuts.length > 0 && (
                <TableContainer>
                  <Table>
                    <THead>
                      <TREthOptions>
                        <THBottomBorder>Expiry</THBottomBorder>
                        <THBottomBorder>Strike Price</THBottomBorder>
                        <THBottomBorder>Type</THBottomBorder>
                        <THBottomBorder>Current UNI Price</THBottomBorder>
                        <THGreenBackground>Protection Cost</THGreenBackground>
                        <THGreenBackground>&nbsp;</THGreenBackground>
                      </TREthOptions>
                    </THead>
                    <TBody>
                      {uniPuts.map(optionData => (
                        <InsuredTokenPRow path="unioption" key={optionData.option.address} {...optionData} />
                      ))}
                    </TBody>
                  </Table>
                </TableContainer>
              )}

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current UNI Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {uniCalls.map(optionData => (
                      <InsuredTokenPRow path="unioption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {(dydxPuts.length > 0 || dydxCalls.length > 0) && (
            <>
              <CardTitle icon={DydxIcon}> Hedge DYDX Risk</CardTitle>
              {dydxPuts.length > 0 && (
                <TableContainer>
                  <Table>
                    <THead>
                      <TREthOptions>
                        <THBottomBorder>Expiry</THBottomBorder>
                        <THBottomBorder>Strike Price</THBottomBorder>
                        <THBottomBorder>Type</THBottomBorder>
                        <THBottomBorder>Current DYDX Price</THBottomBorder>
                        <THGreenBackground>Protection Cost</THGreenBackground>
                        <THGreenBackground>&nbsp;</THGreenBackground>
                      </TREthOptions>
                    </THead>
                    <TBody>
                      {dydxPuts.map(optionData => (
                        <InsuredTokenPRow path="dydxoption" key={optionData.option.address} {...optionData} />
                      ))}
                    </TBody>
                  </Table>
                </TableContainer>
              )}

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current DYDX Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {dydxCalls.map(optionData => (
                      <InsuredTokenPRow path="dydxoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {(wbtcPuts.length > 0 || wbtcCalls.length > 0) && (
            <>
              <CardTitle icon={WbtcIcon}> Hedge WBTC Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current WBTC Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {wbtcPuts.map(optionData => (
                      <InsuredTokenPRow path="wbtcoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current WBTC Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {wbtcCalls.map(optionData => (
                      <InsuredTokenPRow path="wbtcoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {(snxPuts.length > 0 || snxCalls.length > 0) && (
            <>
              <CardTitle icon={SnxIcon}> Hedge SNX Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current SNX Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {snxPuts.map(optionData => (
                      <InsuredTokenPRow path="snxoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current SNX Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {snxCalls.map(optionData => (
                      <InsuredTokenPRow path="snxoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {(yfiPuts.length > 0 || yfiCalls.length > 0) && (
            <>
              <CardTitle icon={YfiIcon}> Hedge YFI Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current YFI Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {yfiPuts.map(optionData => (
                      <InsuredTokenPRow path="yfioption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current YFI Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {yfiCalls.map(optionData => (
                      <InsuredTokenPRow path="yfioption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {compPuts.length > 0 && (
            <>
              <CardTitle icon={CompIcon}> Hedge COMP Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current COMP Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {compPuts.map(optionData => (
                      <InsuredTokenPRow path="comption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {balPuts.length > 0 && (
            <>
              <CardTitle icon={BalancerIcon}> Hedge BAL Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current BAL Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {balPuts.map(optionData => (
                      <InsuredTokenPRow path="baltion" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {crvPuts.length > 0 && (
            <>
              <CardTitle icon={CrvIcon}> Hedge CRV Risk</CardTitle>
              <TableContainer>
                <Table>
                  <THead>
                    <TREthOptions>
                      <THBottomBorder>Expiry</THBottomBorder>
                      <THBottomBorder>Strike Price</THBottomBorder>
                      <THBottomBorder>Type</THBottomBorder>
                      <THBottomBorder>Current CRV Price</THBottomBorder>
                      <THGreenBackground>Protection Cost</THGreenBackground>
                      <THGreenBackground>&nbsp;</THGreenBackground>
                    </TREthOptions>
                  </THead>
                  <TBody>
                    {crvPuts.map(optionData => (
                      <InsuredTokenPRow path="crvoption" key={optionData.option.address} {...optionData} />
                    ))}
                  </TBody>
                </Table>
              </TableContainer>
            </>
          )}
          {/* <CardTitle icon={CompoundIcon}>Insure Compound Deposits</CardTitle>
          <TableContainer>
            <Table>
              <THead>
                <TRCompound>
                  <THBottomBorder>Asset</THBottomBorder>
                  <THBottomBorder>Duration</THBottomBorder>
                  <THRedBackground>
                    Uninsured Yield
                    <br />
                    (APR)
                  </THRedBackground>
                  <THGreenBackground>
                    Insured Yield
                    <br />
                    (APR)
                    <br />
                  </THGreenBackground>
                  <THGreenBackground>&nbsp;</THGreenBackground>
                </TRCompound>
              </THead>
              <TBody>
                {(Object.keys(compoundOptions) as CompoundTokens[]).map((tokenId: CompoundTokens) => (
                  <InsuredRow key={tokenId} tokenId={tokenId} data={compoundOptions[tokenId]} />
                ))}
              </TBody>
            </Table>
          </TableContainer> */}
        </CardStyled>
      </InnerContainerStyled>
    </Wrapper>
  )
}

// const getIcon = (tokenId: InsuredToken) => (tokenId === 'eth' ? EthIcon : tokenId === 'cusdc' ? UsdcIcon : DaiIcon)
// const getIconName = (tokenId: InsuredToken) => (tokenId === 'eth' ? 'ETH' : tokenId === 'cusdc' ? 'USDC' : 'DAI')

// const percentageCell = (data?: BigFloat) => (data && isBigFloat(data) ? <Percentage {...data} /> : '--')

interface InsuredRowProps {
  tokenId: CompoundToken
  data: BuyerCompoundOptionData
}

// const InsuredRow = ({ tokenId, data }: InsuredRowProps) => {
//   const { option, balance, insuredAPR } = data
//   const { uninsuredAPR } = balance || {}
//   const history = useHistory()
//   //const { isConnected } = useConnection()

//   const goToBuy = useCallback(() => history.push(`/buy/asset/${tokenId}`), [history, tokenId])

//   const goToSell = () => {
//     history.push(`/sell/${option?.address}`)
//   }

//   return (
//     <TRCompound>
//       <TDBottomBorder>
//         <IconAndText>
//           <IconImg src={getIcon(tokenId)} alt="" /> {getIconName(tokenId)}
//         </IconAndText>
//       </TDBottomBorder>
//       <TDBottomBorder>{durationGetter(option)?.value}</TDBottomBorder>
//       <TDRedBackground>{percentageCell(uninsuredAPR)}</TDRedBackground>
//       <TDGreenBackground>{percentageCell(insuredAPR)}</TDGreenBackground>
//       <TDGreenBackground>
//         <ButtonsWrapperChart>
//           <ButtonStyledChart onClick={goToBuy} buttonStyle={ThemeColorTypes.primaryLight}>
//             Buy
//           </ButtonStyledChart>
//           <ButtonStyledChart onClick={goToSell} buttonStyle={ThemeColorTypesInverted.primaryLightInverted}>
//             Sell
//           </ButtonStyledChart>
//         </ButtonsWrapperChart>
//       </TDGreenBackground>
//     </TRCompound>
//   )
// }

const InsuredEthRow = (props: InsuredEthOptionsData) => {
  const {
    expiry,
    strikePrice,
    ethPrice,
    protectionCost,
    option: { address },
    type,
  } = props
  const history = useHistory()

  // const goToBuy = useCallback(() => history.push(`/buy/${address}`), [history, address])

  const goToSell = useCallback(() => history.push(`/sell/${address}`), [history, address])

  return (
    <TREthOptions>
      <TDBottomBorder>{expiry}</TDBottomBorder>
      <TDBottomBorder>{<TokenBalance value={strikePrice} token="usdc" />}</TDBottomBorder>
      <TDBottomBorder>{type}</TDBottomBorder>
      <TDBottomBorder>{<TokenBalance value={ethPrice} token="$" />}</TDBottomBorder>
      <TDGreenBackground>
        {<CostCell value={<TokenBalance value={protectionCost} token="$" />} token="eth" />}
      </TDGreenBackground>
      <TDGreenBackground>
        <ButtonsWrapperChart>
          {/* <ButtonStyledChart onClick={goToBuy} buttonStyle={ThemeColorTypes.primaryLight}>
            Buy
          </ButtonStyledChart> */}
          <ButtonStyledChart onClick={goToSell} buttonStyle={ThemeColorTypesInverted.primaryLightInverted}>
            Sell
          </ButtonStyledChart>
        </ButtonsWrapperChart>
      </TDGreenBackground>
    </TREthOptions>
  )
}

const InsuredTokenPRow = (props: any) => {
  const {
    path,
    expiry,
    strikePrice,
    currentPrice,
    protectionCost,
    option: { address },
    type,
  } = props
  const history = useHistory()

  const goToBuy = useCallback(() => history.push(`/buy/${path}/${address}`), [history, address, path])

  const goToSell = useCallback(() => history.push(`/sell/${path}/${address}`), [history, address, path])

  return (
    <TREthOptions>
      <TDBottomBorder>{expiry}</TDBottomBorder>
      <TDBottomBorder>{<TokenBalance value={strikePrice} token="usdc" />}</TDBottomBorder>
      <TDBottomBorder>{type}</TDBottomBorder>
      <TDBottomBorder>{<TokenBalance value={currentPrice} token="$" />}</TDBottomBorder>
      {/* <TDGreenBackground>
        {<CostCell value={<TokenBalance value={Number(protectionReturn.value) / 10 ** 2} token="$" />} token="usdc" />}
      </TDGreenBackground> */}
      <TDGreenBackground>
        {
          <CostCell
            value={<TokenBalance value={protectionCost} token="$" />}
            token={
              path === 'comption'
                ? 'COMP'
                : path === 'baltion'
                ? 'BAL'
                : path === 'yfioption'
                ? 'YFI'
                : path === 'crvoption'
                ? 'CRV'
                : path === 'unioption'
                ? 'UNI'
                : path === 'dydxoption'
                ? 'DYDX'
                : path === 'snxoption'
                ? 'SNX'
                : path === 'dpioption'
                ? 'DPI'
                : path === 'wbtcoption'
                ? 'WBTC'
                : 'WETH'
            }
          />
        }
      </TDGreenBackground>
      <TDGreenBackground>
        <ButtonsWrapperChart>
          <ButtonStyledChart onClick={goToBuy} buttonStyle={ThemeColorTypes.primaryLight}>
            Buy
          </ButtonStyledChart>
          <ButtonStyledChart onClick={goToSell} buttonStyle={ThemeColorTypesInverted.primaryLightInverted}>
            Sell
          </ButtonStyledChart>
        </ButtonsWrapperChart>
      </TDGreenBackground>
    </TREthOptions>
  )
}
