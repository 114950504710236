import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { Button } from 'components/Common/Button'
import { useConnection, useEthToUsdPrice, oTokenData } from 'hooks'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { ThemeColorTypes, calculateCurrentRatio, ETH_TOKEN } from 'utils'
import { BigNumber } from 'ethers/utils'
import { Big } from 'big.js'
import Slider from 'components/Common/Slider'
import { TextLight } from 'components/Common/CommonStyled'
import DoubleBalance from 'components/Common/DoubleBalance'

const Detail = styled.div`
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  flex: 1;
`

const Row = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
`

interface ModalProps extends ModalBasicProps {
  isOpen: boolean
  onConfirm?: (tx: any, amount: string, type: string, title: string) => void
  onRequestClose?: (e?: any) => void
  onTransactionFail?: () => void
  option?: SellOTokens_optionsContract
  oTokenData?: oTokenData
}

export const AdjustCollateralModal: React.FC<ModalProps> = props => {
  const { account } = useConnection()
  const { oTokenData, option, onConfirm, onTransactionFail } = props
  const { minCollateralizationRatioValue } = option || {}
  const { service, collateralToStrikePrice } = oTokenData || {}
  const [sliderValueShowed, setSliderValueShowed] = useState(0)
  const [sliderValue, setSliderValue] = useState(0)
  const [isAdjusting, setIsAdjusting] = useState(false)

  const ethToUsdcPrice = useEthToUsdPrice()

  const minCollatRatio = useMemo(() => (minCollateralizationRatioValue ? minCollateralizationRatioValue * 10 : 1), [
    minCollateralizationRatioValue,
  ])

  const currentCollateralizationRatio = useMemo(() => {
    if (option && option.vaults && collateralToStrikePrice) {
      const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = option.vaults[0]

      return Math.round(
        calculateCurrentRatio({
          addedCollateral: new Big(addedCollateral).div('1e18').toString(),
          strikePriceValue: option?.strikePriceValue,
          strikePriceExp: option?.strikePriceExp,
          collateralToStrikePrice,
          mintedOTokens,
        }) * 100,
      )
    }
    return 0
  }, [option, collateralToStrikePrice])

  const collateralNeeded = useMemo(() => {
    if (currentCollateralizationRatio === sliderValue) {
      return new Big(0)
    }

    if (option && option.vaults && collateralToStrikePrice) {
      const { strikePriceValue, strikePriceExp, vaults } = option
      const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = vaults[0]

      const collateralToStrikeRatio = new Big(1).div(
        new Big(new BigNumber(collateralToStrikePrice.value).toString()).times(
          `1e-${collateralToStrikePrice.decimals}`,
        ),
      )
      const strikePrice = new Big(strikePriceValue).times(`1e${strikePriceExp}`)
      const collateral = new Big(sliderValue)
        .div(100)
        .times(strikePrice.times(mintedOTokens))
        .div(collateralToStrikeRatio)

      return collateral.times('1e18').minus(addedCollateral)
    }
    return new Big(0)
  }, [sliderValue, currentCollateralizationRatio, option, collateralToStrikePrice])

  const $collateralNeeded = useMemo(() => {
    if (!collateralNeeded || collateralNeeded.eq(0) || ethToUsdcPrice.eq(0)) {
      return new BigNumber(0)
    }
    return (collateralNeeded.lt(0) ? collateralNeeded.times(-1) : collateralNeeded)
      .div(ethToUsdcPrice.toString())
      .round(4)
      .toFixed()
  }, [ethToUsdcPrice, collateralNeeded])

  useEffect(() => {
    setSliderValueShowed(currentCollateralizationRatio)
    setSliderValue(currentCollateralizationRatio)
  }, [currentCollateralizationRatio])

  const sliderHandler = useCallback(event => {
    setSliderValueShowed(Number(event.target.value))
  }, [])

  const sliderMouseUpHandler = useCallback(async event => {
    setSliderValue(Number(event.target.value))
  }, [])

  const adjustHandler = useCallback(async () => {
    const amount = collateralNeeded.lt(0) ? collateralNeeded.times(-1) : collateralNeeded
    if (account && service && amount.gt(0)) {
      setIsAdjusting(true)
      try {
        const tx = collateralNeeded.lt(0)
          ? await service.removeCollateral(amount.toFixed(0))
          : await service.addETHCollateral(account, new BigNumber(amount.toFixed(0)))

        const actionType = collateralNeeded.lt(0) ? 'RemoveCollateralAction' : 'ETHCollateralAddedAction'

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, amount.toFixed(0), actionType, 'Confirm Adjust Collateralization')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          setIsAdjusting(false)
          onTransactionFail()
        }
      }
    }
  }, [account, collateralNeeded, service, onTransactionFail, onConfirm])

  const isButtonDisabled = collateralNeeded.eq(0) || isAdjusting

  return (
    <Modal {...props}>
      <Slider
        title={'Select ETH collateralization ratio'}
        min={minCollatRatio}
        max={300}
        step={1}
        value={sliderValueShowed}
        labelStep={20}
        onChange={sliderHandler}
        onMouseUp={sliderMouseUpHandler}
      />
      <Detail>
        <Row>
          <TextLight>{`${
            collateralNeeded.lt(0) ? 'Redeem' : collateralNeeded.gt(0) ? 'Add' : ''
          } Collateral`}</TextLight>
          <div>
            <DoubleBalance
              left={{
                token: ETH_TOKEN,
                value: {
                  value: collateralNeeded.lt(0) ? collateralNeeded.times(-1).toFixed(0) : collateralNeeded.toFixed(0),
                  decimals: ETH_TOKEN.decimals,
                },
                precision: 5,
              }}
              right={{
                token: '$',
                value: $collateralNeeded.toString(),
              }}
            />
          </div>
        </Row>
      </Detail>
      <Button buttonStyle={ThemeColorTypes.secondary} disabled={isButtonDisabled} onClick={adjustHandler}>
        Confirm
      </Button>
    </Modal>
  )
}
