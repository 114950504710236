import React from 'react'
import styled from 'styled-components'
import Logo from '../Logo'
import MainMenu from '../MainMenu'
import MobileMenu from '../MobileMenu'
import { ButtonConnect } from '../ButtonConnect'
import { InnerContainer } from '../InnerContainer'
import { Link } from 'react-router-dom'

export const Wrapper = styled.header`
  background: ${props => props.theme.header.backgroundColor};
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  box-shadow: ${props => props.theme.header.boxShadow};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: ${props => props.theme.header.heightMobile};
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    height: ${props => props.theme.header.height};
  }
`

export const HeaderInnerContainer = styled(InnerContainer)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

const MainMenuStyled = styled(MainMenu)`
  display: none;
  margin-left: 30px;
  margin-right: auto;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: inherit;
  }
`

const ButtonConnectStyled = styled(ButtonConnect)`
  display: none;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: inherit;
    margin-left: auto;
  }
`

const MobileMenuStyled = styled(MobileMenu)`
  display: inherit;
  margin-left: auto;
  margin-right: 0;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: none;
  }
`

export const Header: React.FC = props => {
  const { ...restProps } = props
  const [isMenuOpen, setMenuState] = React.useState(false)
  const toggleMenu = () => setMenuState(isMenuOpen => !isMenuOpen)

  return (
    <Wrapper {...restProps}>
      <HeaderInnerContainer>
        <Link to="/">
          <Logo />
        </Link>
        <MainMenuStyled />
        <MobileMenuStyled toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
        <ButtonConnectStyled />
      </HeaderInnerContainer>
    </Wrapper>
  )
}
