import React, { useMemo, useState, useCallback } from 'react'
import { Card } from '../Common/Card'
import styled from 'styled-components'
import { Button } from '../Common/Button'
import {
  ThemeColorTypes,
  ETH_TOKEN,
  convertBigFloatToBig,
  addToLocalStorageArray,
  etherscanTx,
  ThemeColorTypesInverted,
} from '../../utils'
import { ProgressBar } from '../Common/ProgressBar'
import { SellOTokens_optionsContract } from '../../types/generatedGQL'
import useEthToUsdPrice from '../../hooks/useEthToUSDPrice'
import { Big } from 'big.js'
import TokenBalance from '../Common/TokenBalance'
import { BigNumber } from 'ethers/utils'
import Value from '../Common/Value'
import { TextLight } from '../Common/CommonStyled'
import useOTokenFromOption from '../../hooks/useOTokenFromOption'
import Percentage from '../Common/Percentage'
import { AdjustCollateralModal } from '../Modals/AdjustCollateralModal'
import { SellHistory } from '../../hooks/useSellingTransactionHistory'
import { ConfirmModal } from '../Modals/ConfirmModal'
import { FailedTransactionModal } from '../Modals/FailedTransactionModal'
import { useConnection } from '../web3'
import { BurnOTokensModal } from 'components/Modals/BurnOTokensModal'
import { useAsyncMemo } from 'hooks'

const Rows = styled.div`
  margin: 0 0 10px;
`

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`
const Title = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

const getButtonsContainersColumns = (buttonsAmount: number): string => {
  let template = ''

  for (let count = 0; count < buttonsAmount; count++) {
    template += '1fr '
  }

  return template
}

const ButtonsContainer = styled.div<{ buttonsAmount: number }>`
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    grid-template-columns: ${props => getButtonsContainersColumns(props.buttonsAmount)};
  }
`

interface CollateralCardProps {
  reload?(): void
  option: SellOTokens_optionsContract
}

export const CollateralCard: React.FC<CollateralCardProps> = (props: CollateralCardProps) => {
  const { option, reload, ...restProps } = props
  const { minCollateralizationRatioValue, vaults } = option || {}
  const { collateral = 0, oTokensIssued = 0 } = vaults?.[0] || {}
  const oTokenFromOption = useOTokenFromOption(option || null)
  const { service, collateralizationRatio, collateralToStrikePrice, loading } = oTokenFromOption
  const ethToUsdcPrice = useEthToUsdPrice()
  const { readOnly, networkId, account } = useConnection()

  const [isAdjustCollateralModalOpen, setAdjustCollateralModalState] = useState(false)
  const [isBurnOTokensModalOpen, setBurnOTokensModaOpenState] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [confirmModalTitle, setConfirmModalTitle] = useState('')
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')
  const [transactionFailed, setTransactionFailed] = useState(false)

  const openAdjustCollateralModal = useCallback(() => setAdjustCollateralModalState(true), [])
  const closeAdjustCollateralModal = useCallback(() => setAdjustCollateralModalState(false), [])
  const openBurnOTokensModal = useCallback(() => setBurnOTokensModaOpenState(true), [])
  const closeBurnOTokensModal = useCallback(() => setBurnOTokensModaOpenState(false), [])
  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])
  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])

  const bigOTokenBalance = useAsyncMemo<BigNumber>(
    async () => {
      if (service && account) {
        return service.getBalanceOf(account)
      }

      return new BigNumber(0)
    },
    new BigNumber(0),
    [account, service],
  )

  const openConfirmModal = useCallback(
    (tx, amount, type, title = 'Confirm Add Collateral') => {
      addToLocalStorageArray('sell-transactions', {
        type,
        amount,
        transactionHash: tx.hash,
        timestamp: Math.round(Date.now() / 1000).toString(),
        pending: true,
      } as SellHistory)

      closeAdjustCollateralModal()
      closeBurnOTokensModal()
      setConfirmModalTitle(title)
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeAdjustCollateralModal, closeBurnOTokensModal],
  )

  const loadingOrNotSold = useMemo(() => loading || !vaults?.length, [loading, vaults])

  const minCollatRatio = useMemo(() => (minCollateralizationRatioValue ? minCollateralizationRatioValue * 10 : 1), [
    minCollateralizationRatioValue,
  ])

  const currCollateralizationRatio = useMemo(
    () => (loadingOrNotSold ? -1 : collateralizationRatio ? Math.round(collateralizationRatio * 100) : minCollatRatio),
    [collateralizationRatio, minCollatRatio, loadingOrNotSold],
  )

  const $collateral = useMemo(() => {
    if (ethToUsdcPrice.eq(0)) {
      return '0'
    }
    return new Big(collateral)
      .div(ethToUsdcPrice.toString())
      .round(4)
      .toFixed()
  }, [ethToUsdcPrice, collateral])

  const aboveMinRatio = currCollateralizationRatio > minCollatRatio
  const safeRatio = currCollateralizationRatio - minCollatRatio >= 10
  const ratioLabel = loadingOrNotSold
    ? ''
    : `${aboveMinRatio ? (safeRatio ? 'SAFE' : 'CAUTION') : 'DANGER'} - ${currCollateralizationRatio}%`

  const strikeToCol = convertBigFloatToBig(collateralToStrikePrice).gt(0)
    ? convertBigFloatToBig(collateralToStrikePrice)
    : 1
  const colToStrikeHumanReadable = new Big(1).div(strikeToCol)
  const liquidationPrice = new Big(minCollatRatio).div(currCollateralizationRatio).times(colToStrikeHumanReadable)

  const info = [
    {
      title: 'Min. Collateralization Requirement',
      value: loading ? '--' : <Percentage value={minCollatRatio.toFixed()} />,
    },
    {
      title: 'Liquidation Price',
      value: loadingOrNotSold ? '--' : <Value value={liquidationPrice.round(2).toFixed()}>{val => `~$${val}`}</Value>,
    },
    {
      title: 'Current ETH Price',
      value: loading ? '--' : <Value value={colToStrikeHumanReadable.round(2).toFixed()}>{val => `$${val}`}</Value>,
    },
  ]

  const progressBarLowerBound = minCollatRatio - 10

  const soldInsurance = !!oTokensIssued
  const isButtonAddDisabled = loadingOrNotSold || isAdjustCollateralModalOpen || readOnly
  const isButtonBurnDisabled = loadingOrNotSold || isBurnOTokensModalOpen || readOnly

  const buttons = [
    ...(soldInsurance
      ? [
          <Button
            key="add-collateral"
            disabled={isButtonAddDisabled}
            buttonStyle={ThemeColorTypes.secondary}
            onClick={openAdjustCollateralModal}
          >
            Adjust Collateral
          </Button>,
          ...(bigOTokenBalance.gt(0)
            ? [
                <Button
                  key="burn-otokens"
                  disabled={isButtonBurnDisabled}
                  buttonStyle={ThemeColorTypesInverted.secondaryInverted}
                  onClick={openBurnOTokensModal}
                >
                  Burn oTokens
                </Button>,
              ]
            : []),
        ]
      : []),
  ]

  return (
    <Card
      {...restProps}
      title="Collateral"
      subtitle={[
        <TokenBalance token={ETH_TOKEN} value={new BigNumber(collateral)} precision={4} key="eth" />,
        <TextLight key="usd">
          <Value value={$collateral}>{val => ` $${val}`}</Value>
        </TextLight>,
      ]}
    >
      <ProgressBar
        lowerBound={progressBarLowerBound}
        higherBound={350}
        progress={currCollateralizationRatio}
        label={ratioLabel}
        alignLabel="float"
        color={aboveMinRatio ? (safeRatio ? 'primary' : 'warning') : 'error'}
        markers={Array.from(Array(7).keys())
          .map((marker: number) => Math.round(progressBarLowerBound + marker * ((350 - progressBarLowerBound) / 6)))
          .map((marker: number, idx: number) => (idx < 6 ? `${marker}%` : `${marker}%+`))}
      />
      <Rows>
        {info.map((item, index) => {
          return (
            <Row key={index}>
              <Title>{item.title}</Title>
              <RowValue>{item.value}</RowValue>
            </Row>
          )
        })}
      </Rows>
      <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
      {isAdjustCollateralModalOpen && (
        <AdjustCollateralModal
          isOpen={isAdjustCollateralModalOpen}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeAdjustCollateralModal}
          title="Adust Collateralization"
          option={option}
          oTokenData={oTokenFromOption}
        />
      )}
      {isBurnOTokensModalOpen && (
        <BurnOTokensModal
          isOpen={isBurnOTokensModalOpen}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeBurnOTokensModal}
          title="Burn oTokens"
          bigOTokenBalance={bigOTokenBalance}
          option={option}
          oTokenData={oTokenFromOption}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={confirmModalTitle}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          themeColor={ThemeColorTypes.secondary}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
    </Card>
  )
}
