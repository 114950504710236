import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { ETH_TOKEN, getToken, isValidNetworkId, Token, getTokenPriceCoingecko } from 'utils'
import { fromUnixTime, format } from 'date-fns'
import { useConnection } from '.'
import { Big } from 'big.js'
import useEthToUsdPrice from './useEthToUSDPrice'
import useOptionsExchange from './useOptionsExchange'
import useAsyncMemo from './useAsyncMemo'
import useOracle from './useOracle'
import { useOptionIsPut } from './useEthOptionType'
import { BigNumber } from 'ethers/utils'
import { useMemo } from 'react'
import { OptionsContract, OptionsContract_optionsContract } from 'types/generatedGQL'
import { useERC20Contract } from './useERC20Contract'
import { DEFAULT_DATE_FORMAT } from 'common/constants'

const OPTIONS_CONTRACT_QUERY = loader('../queries/options_contract.graphql')

interface EthOptionData {
  insured?: BigNumber
  option?: OptionsContract_optionsContract
  balance?: {
    amount: BigNumber
    token: Token
    precision?: number
  }
  expiry?: string
  strikePrice?: string
  rawStrikePrice?: Big
  ethPrice?: string
  protectionCost?: string
  status?: string
}

export const useEthOptionByAddress = (address: Maybe<string>) => {
  const { networkId, account } = useConnection()

  const oracleContract = useOracle()

  const { loading, data, error, refetch: reload } = useQuery<OptionsContract>(OPTIONS_CONTRACT_QUERY, {
    variables: {
      id: address,
      account: account?.toLowerCase() || '',
    },
    skip: !address,
  })

  const option = data?.optionsContract

  const isPut = useOptionIsPut(option)

  const strikePriceInWei = useAsyncMemo<BigNumber>(
    async () => {
      if (!option?.strike) return new BigNumber(0)
      // return (await oracleContract?.getPrice(option?.strike)) || new BigNumber(0)
      let price = await getTokenPriceCoingecko(option?.strike)
      let decimals = option?.strike === '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599' ? 8 : 18
      let priceToWei = BigInt(Number(price) * 10 ** decimals)
      return new BigNumber(priceToWei.toString()) || new BigNumber(0)
    },
    new BigNumber(0),
    [oracleContract, option],
  )
  const ethToUsdcPrice = useEthToUsdPrice()

  const oToken = useERC20Contract(address)
  const optionsExchangeContract = useOptionsExchange(data?.optionsContract?.optionsExchangeAddress)
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])
  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])

  const optionsData = useAsyncMemo(
    async (): Promise<EthOptionData> => {
      if (
        !isValidNetworkId(networkId) ||
        loading ||
        !option ||
        !usdc ||
        !daiToken ||
        !oracleContract ||
        !optionsExchangeContract
      ) {
        return {}
      }

      // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
      const ethToTokenPrice = new Big(Number(await getTokenPriceCoingecko(option?.strike)) * 1e18)

      let premiumToPay = new BigNumber(0)
      try {
        // changed to fix the call premium price error
        // premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, daiToken.address, 1e4)
        premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, usdc.address, 1e4)
      } catch {}
      const balance = option?.holdersBalances?.find(balance => balance.account.address === account)

      const insured = account && oToken ? await oToken?.getBalanceOf(account) : new BigNumber(0)

      const rawStrikePrice =
        ethToTokenPrice && strikePriceInWei.gt(0)
          ? new Big(option?.strikePriceValue || 0)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(strikePriceInWei.toString() || 1)
              .times(`1e${-option?.oTokenExchangeRateExp}`)
              .div(ethToTokenPrice?.toString() || 1)
          : new Big(0)

      const $strikePrice = rawStrikePrice.eq(new Big(0))
        ? new Big(0)
        : isPut
        ? rawStrikePrice
        : new Big(1).div(rawStrikePrice)

      const $ethPrice = ethToUsdcPrice.gt(0)
        ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
        : new Big(0)

      const protectionCostInUSDC = new Big(premiumToPay.toString())
        .times(`1e${-option?.oTokenExchangeRateExp}`)
        .times(1e-4)
        .times(`1e-6`)

      const protectionCost = isPut ? protectionCostInUSDC : protectionCostInUSDC.times($strikePrice)

      return {
        option,
        insured,
        balance: {
          amount: new BigNumber(balance?.amount || 0),
          token: ETH_TOKEN,
          precision: 4,
        },
        rawStrikePrice,
        expiry: format(fromUnixTime(option?.expiry), DEFAULT_DATE_FORMAT),
        strikePrice: $strikePrice.round(2).toFixed(),
        ethPrice: $ethPrice.round(2).toFixed(),
        protectionCost: protectionCost.round(4).toFixed(),
        status: $strikePrice.lt($ethPrice) ? 'ok' : 'error',
      }
    },
    {},
    [strikePriceInWei, networkId, loading, data, optionsExchangeContract, account, daiToken, ethToUsdcPrice, isPut],
  )

  return { optionsData, loading, error, reload, isPut }
}
