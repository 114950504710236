import React from 'react'
import styled from 'styled-components'
import { TotalTitle, TotalValue } from '../../Common/CommonStyled'
import Value from '../../Common/Value'
import { BigFloat } from '../../../utils'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const StyledValue = styled(TotalValue)`
  text-transform: none;
`

interface Props {
  token: string
  value: BigFloat
  title?: any
}

export const OTokensCreated: React.FC<Props> = props => {
  const { token, value, title = 'oTokens Created', ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>{title}</TotalTitle>
      <Values>
        <StyledValue>
          <Value value={value} precision={4}>
            {val => `${val} ${token}`}
          </Value>
        </StyledValue>
      </Values>
    </Wrapper>
  )
}
