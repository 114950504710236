import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { info as TitlesInfo } from './info'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 100%;
  max-height: 30px;
  justify-content: center;
  margin-right: 8px;
`

const Icon = styled.img`
  display: block;
`

const TextWrapper = styled.div``

const Title = styled.h1`
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px;
  padding: 8px 0 0;
`

const Subtitle = styled.h2`
  color: #b3b3b3;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

interface SectionTitleProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  icon?: string
  subtitle?: any
  protocol: string
}

export const SectionTitle: React.FC<SectionTitleProps> = (props: SectionTitleProps) => {
  const { protocol, subtitle = false, ...restProps } = props
  const title = props.title || TitlesInfo[protocol].title || ''
  const icon = props.icon || TitlesInfo[protocol].icon || ''

  return (
    <Wrapper {...restProps}>
      <IconWrapper>
        <Icon width={30} src={icon} alt={title} />
      </IconWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TextWrapper>
    </Wrapper>
  )
}
