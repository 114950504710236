import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/Common/SectionTitle'
import { useParams, useHistory } from 'react-router-dom'
import { ContentsNarrow } from 'components/Common/CommonStyled'
import { SellTokenOptionCard } from 'components/SellTokenOptionCard'
import { useConnection, useSellingTransactionHistory, useAsyncMemo, useTokenOptionIsPut } from 'hooks'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/react-hooks'
import { TransactionsCard } from 'components/TransactionsCard'
import { ThemeColorTypes, getExercised, getIsExpired } from 'utils'
import { ButtonBack } from 'components/Common/ButtonBack'
import theme from 'theme'
import { Share } from '../../components/Common/Share'
import { useLastLocation } from 'react-router-last-location'
import { ZERO_ADDRESS } from '../../common/constants'
const TitleWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr ${props => props.theme.innerScrollContainer.narrowWidth} 1fr;
  margin-bottom: 24px;
`

const TitleStyled = styled(SectionTitle)`
  margin-bottom: 0;
`

const ContentsNarrowStyled = styled(ContentsNarrow)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-right: 20px;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
`
const SELL_OTOKENS = loader('../../queries/sell_otokens.graphql')

export const SellUniOptionDetail: React.FC<any> = () => {
  const history = useHistory()
  const { library, account } = useConnection()
  let { address: oTokenAddress } = useParams<{ address: string }>()
  oTokenAddress = oTokenAddress.toLocaleLowerCase()
  const variables = account
    ? { id: oTokenAddress, tokenAddress: oTokenAddress, account }
    : { id: oTokenAddress, tokenAddress: oTokenAddress, account: ZERO_ADDRESS }
  const { data, refetch } = useQuery(SELL_OTOKENS, {
    variables,
    skip: !oTokenAddress,
  })

  const sellingTransactionHistory = useSellingTransactionHistory(oTokenAddress.toLowerCase())

  const [lastUpdate, setLastUpdate] = useState(Date.now())
  // let isCToken = false

  const reload = useCallback(() => {
    refetch?.()
    setLastUpdate(Date.now())
  }, [refetch, setLastUpdate])

  const { optionsContract: option = null } = data || {}

  const lastLocation = useLastLocation()

  const handleButtonBackClick = useCallback(() => {
    if (lastLocation) {
      history.push(lastLocation.pathname, lastLocation.state)
    } else {
      history.push('/sell')
    }
  }, [history, lastLocation])

  const { exercised } = getExercised(option)
  const isExpired = useAsyncMemo(() => getIsExpired(library, option?.address), false, [library, option])

  const cardStyle = exercised ? { borderColor: theme.colors.secondary } : {}

  const isPut = useTokenOptionIsPut(option)

  const sectionTitle = isPut ? 'Sell UNI Put Options' : 'Sell UNI Call Options'

  return (
    <>
      <TitleWrapper>
        <ButtonBack onClick={handleButtonBackClick} />
        <TitleStyled protocol="uni" title={sectionTitle} />
      </TitleWrapper>
      <ContentsNarrowStyled>
        <Content>
          <Stack>
            <SellTokenOptionCard
              tokenId={'uni'}
              option={option}
              reload={reload}
              style={cardStyle}
              isExpired={isExpired}
            />
          </Stack>
          <Stack>
            <TransactionsCard
              lastUpdate={lastUpdate}
              transactionsCardStyle={ThemeColorTypes.secondary}
              historicalData={sellingTransactionHistory}
            />
          </Stack>
        </Content>
      </ContentsNarrowStyled>
      <Share />
    </>
  )
}
