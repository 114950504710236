import React from 'react'
import styled from 'styled-components'
import { TwitterShareButton, TelegramShareButton, TwitterIcon, TelegramIcon } from 'react-share'

const ButtonsWrapper = styled.div`
  display: block;
  justify-content: center;
  position: fixed;
  width: 30px;
  height: 300px;
  bottom: 100px;
  right: 5px;
`

export const Share: React.FC = () => {
  return (
    <ButtonsWrapper>
      <TwitterShareButton title="Check out Opyn Insurance" url="https://opyn.co">
        <TwitterIcon size={36} path="" round />
      </TwitterShareButton>
      <TelegramShareButton title="Check out Opyn Insurance" url="https://opyn.co">
        <TelegramIcon size={36} path="" round />
      </TelegramShareButton>
    </ButtonsWrapper>
  )
}
