import React, { HTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { Table, Column } from 'react-virtualized/dist/es/Table'
import {
  expiryColumnDefaults,
  chevronColumnDefaults,
  columnDefaults,
  tableDefaults,
  chevronCellRenderer,
  getTokenFromAddress,
  expiryRenderer,
  ethPriceRenderer,
} from 'utils'
import { TableOverflow } from 'components/Common/TableOverflow'
import { useHistory } from 'react-router-dom'
import { Index } from 'react-virtualized'
import { Big } from 'big.js'
import { useEthToUsdcPrice } from 'hooks'
import TokenBalance from 'components/Common/TokenBalance'
import { BigNumber } from 'ethers/utils'
import { TextLight } from 'components/Common/CommonStyled'
import Value from 'components/Common/Value'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import { useConnection } from 'components/web3'

const Wrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TableWrapper = styled(TableOverflow)`
  min-height: ${props => props.maxHeight}px;
`

const ColWithTooltip = styled(TextWithTooltip)`
  justify-content: center;
`

interface SoldProps extends HTMLAttributes<HTMLDivElement> {
  data: any
}

const collaterCellRender = (props: any) => {
  const { cellData } = props
  const { option, extras, ethToUsdcPrice, networkId } = cellData
  const collateralToken = getTokenFromAddress(networkId, option.collateral)

  const { collateral = 0 } = option?.vaults[0] || {}

  const $collateral =
    !extras || (extras && extras.ethToCollateralPrice.eq(0)) || ethToUsdcPrice.eq(0)
      ? '0'
      : new Big(collateral)
          .times(`1e-${collateralToken.decimals}`)
          .times(extras.ethToCollateralPrice.toString())
          .div(ethToUsdcPrice.toString())
          .round(3)
          .toFixed()

  return (
    <Wrapper>
      <TokenBalance token={collateralToken} value={new BigNumber(collateral)} precision={3} key="collateral" />
      <TextLight key="usd">
        <br />
        <Value value={$collateral} precision={3}>
          {val => ` $${val}`}
        </Value>
      </TextLight>
    </Wrapper>
  )
}

const strikePriceRenderer = (props: any) => {
  const { cellData } = props
  const { extras } = cellData

  const value = extras ? `${extras.$strikePrice.toFixed()}USDC` : '--'

  return <Value value={value} />
}

export const Sold: React.FC<SoldProps> = (props: SoldProps) => {
  const { data, ...restProps } = props
  const { networkId } = useConnection()
  const history = useHistory()
  const ethToUsdcPrice = useEthToUsdcPrice()

  const goToAsset = (props: any) => {
    const { rowData } = props

    if (rowData.address) {
      history.push(`/sell/wethoption/${rowData.address}`)
    }
  }

  const { insuredData } = data

  const rowGetter = useCallback(
    (info: Index): any => {
      const { extraData = null, currentPrice = null } = data || {}
      const option = insuredData[info.index]

      const extras = extraData && option ? extraData[option.id] : null

      return {
        id: option.id,
        address: option && option.address,
        collateral: { option, extras, ethToUsdcPrice, networkId },
        expiry: {
          timestamp: option?.expiry,
          isExpired: extras?.isExpired,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        strikePrice: { option: option, extras },
        currentPrice: currentPrice?.round(4).toFixed(),
        status: extras?.exercised ? 'exercised' : 'ok',
      }
    },
    [insuredData, data, ethToUsdcPrice, networkId],
  )

  return (
    <Wrapper {...restProps}>
      <TableWrapper maxHeight={(((insuredData ? insuredData.length : 0) + 2) * 66).toString()}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Table
              {...tableDefaults}
              height={height}
              onRowClick={goToAsset}
              rowCount={insuredData ? insuredData.length : 0}
              rowGetter={rowGetter}
            >
              <Column
                {...columnDefaults}
                label="Collateral Supplied"
                dataKey="collateral"
                cellRenderer={collaterCellRender}
              />
              <Column {...expiryColumnDefaults} label="Expiry" dataKey="expiry" cellRenderer={expiryRenderer} />
              <Column
                {...columnDefaults}
                label={
                  <ColWithTooltip
                    text="Strike Price"
                    tooltipText={
                      'If ETH falls below the strike price, you will have to pay out the protection buyer with some or all your collateral. <a href="https://opyn.gitbook.io/opynv1/faq#what-happens-in-the-case-of-an-adverse-event" target="_blank">Learn more.</a>'
                    }
                    id="strike-price-sold"
                    place="right"
                  />
                }
                dataKey="strikePrice"
                cellRenderer={strikePriceRenderer}
              />
              <Column
                {...columnDefaults}
                label="Current WETH Price"
                dataKey="currentPrice"
                cellRenderer={ethPriceRenderer}
              />
              <Column {...chevronColumnDefaults} cellRenderer={chevronCellRenderer} label="" dataKey="status" />
            </Table>
          )}
        </AutoSizer>
      </TableWrapper>
    </Wrapper>
  )
}
