import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span``

const Token = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

interface CostCellProps {
  value: React.ReactNode
  token?: string
}

export const CostCell: React.FC<CostCellProps> = (props: CostCellProps) => {
  const { value, token = 'dai', ...restProps } = props

  return (
    <Wrapper {...restProps}>
      {value} <Token>/ 1 {token.toLocaleUpperCase()}</Token>
    </Wrapper>
  )
}
