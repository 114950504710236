interface TitleInfo {
  [index: string]: any
}

export const info: TitleInfo = {
  compound: {
    icon: require('./img/compound.svg'),
    title: 'Compound',
  },
  compoundgovernance: {
    icon: require('./img/comp.svg'),
    title: 'Compound',
  },
  eth: {
    icon: require('./img/Ethereum.svg'),
    title: 'eth',
  },
  weth: {
    icon: require('./img/Ethereum.svg'),
    title: 'weth',
  },
  maker: {
    icon: require('./img/maker.svg'),
    title: 'Maker',
  },
  balancer: {
    icon: require('./img/balancer.png'),
    title: 'balancer',
  },
  yfi: {
    icon: require('./img/yfi.png'),
    title: 'yfi',
  },
  uni: {
    icon: require('./img/uni.png'),
    title: 'uni',
  },
  dydx: {
    icon: require('./img/dydx.jpg'),
    title: 'dydx',
  },
  snx: {
    icon: require('./img/snx.png'),
    title: 'snx',
  },
  wbtc: {
    icon: require('./img/wbtc.png'),
    title: 'wbtc',
  },
  dpi: {
    icon: require('./img/dpi.png'),
    title: 'dpi',
  },
  crv: {
    icon: require('./img/crv.png'),
    title: 'crv',
  },
  ethereum: {
    icon: require('./img/Ethereum.svg'),
    title: 'ETH',
  },
}
