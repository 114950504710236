import styled from 'styled-components'
import { ThemeColorTypes } from '../../../utils'

const Link = styled.a<{ themeColor: ThemeColorTypes }>`
  color: ${props => props.theme.colors[ThemeColorTypes[props.themeColor]]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default Link
