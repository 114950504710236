import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { useApolloClient } from '../hooks'

type Props = {
  children: React.ReactNode
}

const Provider = (props: Props) => {
  const client = useApolloClient()

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default Provider
