interface TokenIconDataType {
  [key: string]: any
}

export const TokenIconData: TokenIconDataType = {
  dai: {
    icon: require('./img/dai.svg'),
    title: 'DAI',
  },
  eth: {
    icon: require('./img/eth.svg'),
    title: 'ETH',
  },
  weth: {
    icon: require('./img/eth.svg'),
    title: 'WETH',
  },
  comp: {
    icon: 'https://assets.coingecko.com/coins/images/10775/small/COMP.png?1592625425',
    title: 'COMP',
  },
  bal: {
    icon: require('./img/balancer.png'),
    title: 'BAL',
  },
  yfi: {
    icon: require('./img/yfi.png'),
    title: 'YFI',
  },
  uni: {
    icon: require('./img/uni.png'),
    title: 'UNI',
  },
  dydx: {
    icon: require('./img/dydx.jpg'),
    title: 'DYDX',
  },
  snx: {
    icon: require('./img/snx.png'),
    title: 'SNX',
  },
  wbtc: {
    icon: require('./img/wbtc.png'),
    title: 'WBTC',
  },
  dpi: {
    icon: require('./img/dpi.png'),
    title: 'DPI',
  },
  crv: {
    icon: require('./img/crv.png'),
    title: 'CRV',
  },
  usdc: {
    icon: require('./img/usdc.svg'),
    title: 'USDC',
  },
  otoken: {
    icon: require('./img/otoken.svg'),
    title: 'oToken',
  },
}
