import React from 'react'

export const TwitterIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.47627 15.5225 0 10 0ZM14.975 7.75498L14.9825 8.07374C14.9825 11.3287 12.5063 15.0787 7.97748 15.0787C6.58749 15.0787 5.29371 14.6713 4.20373 13.9725C4.39625 13.995 4.59247 14.0075 4.79124 14.0075C5.945 14.0075 7.00624 13.6137 7.84873 12.9537C6.77125 12.9337 5.86247 12.2212 5.54873 11.2437C5.69997 11.2725 5.85248 11.2862 6.01248 11.2862C6.23748 11.2862 6.45496 11.2575 6.66121 11.2013C5.53496 10.9737 4.68622 9.98002 4.68622 8.78752V8.75627C5.01871 8.94127 5.39746 9.05126 5.80121 9.06503C5.1412 8.62379 4.7062 7.87003 4.7062 7.01627C4.7062 6.56503 4.82746 6.14254 5.03869 5.7788C6.25245 7.26879 8.06744 8.24878 10.1137 8.35129C10.0712 8.17127 10.05 7.98253 10.05 7.79005C10.05 6.43131 11.1524 5.32879 12.5125 5.32879C13.22 5.32879 13.86 5.62752 14.3099 6.10627C14.8699 5.995 15.3975 5.79002 15.8737 5.50877C15.6899 6.08378 15.3 6.56627 14.7912 6.87125C15.2875 6.81249 15.7637 6.67873 16.205 6.48374C15.875 6.97623 15.4562 7.40999 14.975 7.75498Z"
        fill="#BDBDBD"
        className="iconBackground"
      />
    </svg>
  )
}
