import React from 'react'
import styled from 'styled-components'
import TokenBalance, { TokenBalanceProps } from '../TokenBalance'

const TextNegative = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

export interface DoubleBalanceNegativeProps {
  left: TokenBalanceProps
  right: TokenBalanceProps
  separator?: string
}

const DoubleBalanceNegative: React.FC<DoubleBalanceNegativeProps> = ({
  left,
  right,
  separator = ' ',
}: DoubleBalanceNegativeProps) => (
  <>
    <TokenBalance key="left" {...left} />
    <TextNegative key="right">
      {separator}
      <TokenBalance key="left" {...right} />
    </TextNegative>
  </>
)

export default DoubleBalanceNegative
