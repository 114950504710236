import React, { HTMLAttributes, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Card } from 'components/Common/Card'
import { Button } from 'components/Common/Button'
import { TokenIcon } from 'components/Common/TokenIcon'
import Value from 'components/Common/Value'
import TokenBalance from 'components/Common/TokenBalance'
import { EarnPremiumCell } from 'components/Common/EarnPremiumCell'
import {
  ThemeColorTypes,
  ThemeColorTypesInverted,
  isValidNetworkId,
  etherscanTx,
  convertBigFloatToBig,
  addToLocalStorageArray,
  getToken,
  getTokenFromAddress,
  ETH_TOKEN,
  getExercised,
  expiryRenderer,
} from 'utils'

import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { FailedTransactionModal } from 'components/Modals/FailedTransactionModal'
import { TextLight } from 'components/Common/CommonStyled'
import Percentage from 'components/Common/Percentage'
import { useConnection } from 'components/web3'
import {
  useAsyncMemo,
  useUniswapFactory,
  useERC20Contract,
  useSellerInsuredOptions,
  useSellerPremiumsEarned,
  useOTokenFromOption,
  useKnownTokenToUSDPrice,
  SellHistory,
  useTokenOptionIsPut,
  useEthToUsdPrice,
} from 'hooks'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { BigNumber } from 'ethers/utils'
import { Big } from 'big.js'
import { SellTokenOptionModal } from 'components/Modals/SellTokenOptionModal'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import { ReducePositionModal } from 'components/Modals/ReducePositionModal'
import RedeemModal from 'components/Modals/RedeemModal'
import useTokenPrice from 'hooks/useTokenPrice'
import Link from 'components/Common/Link'

const TokenTitle = styled(TokenIcon)`
  margin-bottom: 15px;

  > p {
    font-size: 20px;
    font-weight: 600;
  }
`

const FloatTitle = styled.div`
  flex: 1;
  text-align: right;
  color: ${props => props.theme.colors.secondary};
  font-size: 20px;
  font-weight: 600;
`

const Rows = styled.div`
  margin: 0 0 10px;
`

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Title = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

const getButtonsContainersColumns = (buttonsAmount: number): string => {
  let template = ''

  for (let count = 0; count < buttonsAmount; count++) {
    template += '1fr '
  }

  return template
}

const ButtonsContainer = styled.div<{ buttonsAmount: number }>`
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    grid-template-columns: ${props => getButtonsContainersColumns(props.buttonsAmount)};
  }
`

interface TokenCardProps extends HTMLAttributes<HTMLDivElement> {
  reload?(): void
  option?: SellOTokens_optionsContract
  isExpired: boolean
  tokenId: KnownToken
}

export const SellTokenOptionCard: React.FC<TokenCardProps> = (props: TokenCardProps) => {
  const { option, isExpired, reload, tokenId, ...restProps } = props
  const { vaults, oTokenExchangeRateExp, minCollateralizationRatioValue, strikePriceValue } = option || {}
  const oTokenFromOption = useOTokenFromOption(option || null)
  const premiumsEarned = useSellerPremiumsEarned(option?.address)
  const premiumsEarnedHumanReadable = premiumsEarned
    ? new Big(premiumsEarned[0].toString()).toFixed(4)
    : new BigNumber(0)

  // const { collateralToStrikePrice: strikeAssetPriceInWei } = oTokenFromOption

  const {
    insuredOptions: { earnAPR = { value: 0 }, earnPremium = { value: 0 } },
  } = useSellerInsuredOptions(option, false)

  const isPut = useTokenOptionIsPut(option)
  // const usdcPrice = useEthToUsdPrice()

  const { collateral = 0, oTokensIssued = '0', underlying = 0 } = vaults?.[0] || {}
  const { connectWallet, readOnly, networkId } = useConnection()

  const token = getToken(1, tokenId)

  console.log(token)

  const tokenSymbol = token.symbol

  const [isSellInsuranceModalOpen, setSellInsuranceModalState] = useState(false)
  const [isReducePositionModalOpen, setReducePositionModalState] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [confirmModalTitle, setConfirmModalTitle] = useState('Confirm Sell')
  const [isRedeemUnderlyingModalOpen, setRedeemUnderlyingModalState] = useState(false)
  const [isRedeemCollateralModalOpen, setRedeemCollateralModalState] = useState(false)
  const [transactionFailed, setTransactionFailed] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')

  const underlyingToken =
    isValidNetworkId(networkId) && option?.underlying ? getTokenFromAddress(networkId, option.underlying) : null

  const ethToUsdcPrice = useEthToUsdPrice()

  const $ethPrice = ethToUsdcPrice.gt(0)
    ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
    : new Big(0)

  const tokenToUsdcPrice = useKnownTokenToUSDPrice(tokenId)

  const underlyingToUsdcPrice = tokenId === 'weth' ? $ethPrice : tokenToUsdcPrice

  const strikePrice = strikePriceValue
    ? new Big(strikePriceValue)
        .times(`1e${option?.strikePriceExp || 0}`)
        .times(`1e${-option?.oTokenExchangeRateExp || 0}`)
    : new Big(0)

  const strikePriceHumanReadable = !isPut && strikePrice.gt(0) ? new Big(1).div(strikePrice) : strikePrice

  // const displayEarnPremium = isPut
  //   ? convertBigFloatToBig(earnPremium)
  //   : convertBigFloatToBig(earnPremium).times(strikePriceHumanReadable)

  const displayEarnPremium = convertBigFloatToBig(earnPremium)

  const collateralToken =
    isValidNetworkId(networkId) && option?.collateral ? getTokenFromAddress(networkId, option.collateral) : null

  const collateralToUsdcPrice = useTokenPrice(option?.collateral)

  const handleConnectButton = () => connectWallet()
  const closeSellInsuranceModal = useCallback(() => setSellInsuranceModalState(false), [])
  const openSellInsuranceModal = useCallback(() => setSellInsuranceModalState(true), [])
  const closeReducePositionModal = useCallback(() => setReducePositionModalState(false), [])
  const openReducePositionModal = useCallback(() => setReducePositionModalState(true), [])
  const openRedeemUnderlyingModal = useCallback(() => setRedeemUnderlyingModalState(true), [])
  const openRedeemCollateralModal = useCallback(() => setRedeemCollateralModalState(true), [])

  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])
  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])

  const openConfirmModal = useCallback(
    (tx, amount, premium = '0', type, payoutTokenAddress = '', title = 'Confirm Sell') => {
      addToLocalStorageArray('sell-transactions', {
        type,
        amount,
        premium,
        transactionHash: tx.hash,
        timestamp: Math.round(Date.now() / 1000).toString(),
        pending: true,
        oToken: tx.to,
        payoutTokenAddress,
      } as SellHistory)

      closeSellInsuranceModal()
      closeReducePositionModal()
      setConfirmModalTitle(title)
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeSellInsuranceModal, closeReducePositionModal],
  )

  const uniswapFactoryContract = useUniswapFactory()
  const oTokenERC20Contract = useERC20Contract(option?.address)

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)
        const oTokenBalance =
          (await oTokenERC20Contract?.getBalanceOf(exchangeAddress)) / Number(10 ** -option.oTokenExchangeRateExp)

        let rounding = oTokenBalance > 100 ? 0 : 2

        const displayAvailabeTokens = oTokenBalance.toFixed(rounding)

        return displayAvailabeTokens.toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  // const currentEthPrice =
  //   !isPut && underlyingToken
  //     ? usdcPrice.gt(0)
  //       ? new Big(1).times(new Big(`1e${ETH_TOKEN.decimals}`)).div(new Big(usdcPrice.toString()))
  //       : new Big(1)
  //     : convertBigFloatToBig(strikeAssetPriceInWei).gt(0)
  //     ? new Big(1).div(convertBigFloatToBig(strikeAssetPriceInWei))
  //     : new Big(1)

  const currentEthPrice = useKnownTokenToUSDPrice('eth')

  const currentPrice = tokenId === 'weth' ? currentEthPrice : tokenToUsdcPrice

  const premiumsEarnedUSD = currentEthPrice.times(new Big(premiumsEarnedHumanReadable.toString())).toFixed(2)

  // To display only if not sold insurance
  const minCollatRatio = useMemo(() => (minCollateralizationRatioValue ? minCollateralizationRatioValue * 10 : 1), [
    minCollateralizationRatioValue,
  ])

  const oTokenSymbol = isPut ? `o${tokenSymbol.toUpperCase()}p` : `o${tokenSymbol.toUpperCase()}c`

  const earnPercentage = isPut
    ? convertBigFloatToBig(earnAPR)
        .div(minCollatRatio)
        .times(100)
    : Number(currentPrice) !== 0
    ? convertBigFloatToBig(earnAPR)
        .div(minCollatRatio)
        .times(100)
        .div(currentPrice.toFixed(2))
    : new Big(0)

  const expiry = useMemo(
    () =>
      expiryRenderer({
        cellData: {
          isExpired,
          timestamp: option?.expiry,
        },
      }),
    [option, isExpired],
  )

  const { exercised = false, totalUnderlyingToPay = 0 } = getExercised(option)

  const bTotalUnderlyingToPay = new Big(totalUnderlyingToPay).times(`1e-${underlyingToken?.decimals || 0}`)

  const $totalUnderlyingToPay = bTotalUnderlyingToPay
    .times(underlyingToUsdcPrice)
    .round(4)
    .toFixed()

  const bCollateralToPay = new Big(collateral).times(`1e-${collateralToken?.decimals || 0}`)
  const $collateralToPay = bCollateralToPay
    .div(Number(collateralToUsdcPrice) || 1)
    .round(4)
    .toFixed()

  const sellMoreButtonDisabled = !option || isSellInsuranceModalOpen
  const soldInsurance = !!Number(collateral) || exercised
  const canReducePosition = !!Number(collateral)

  const redeemUnderlying = useCallback(async () => {
    if (oTokenFromOption.service) {
      try {
        const tx = await oTokenFromOption.service.removeUnderlying()
        setRedeemUnderlyingModalState(false)
        openConfirmModal(tx, totalUnderlyingToPay, 'RemoveUnderlyingAction', 'Confirm Redeem')
      } catch {
        onTransactionFail?.()
      }
    }
  }, [oTokenFromOption.service, totalUnderlyingToPay, openConfirmModal, onTransactionFail])

  const redeemCollateral = useCallback(async () => {
    if (oTokenFromOption.service) {
      try {
        const tx = await oTokenFromOption.service.redeemVaultBalance()
        setRedeemCollateralModalState(false)
        openConfirmModal(tx, collateral, 'RedeemVaultBalanceAction', 'Confirm Redeem Collateral')
      } catch {
        onTransactionFail?.()
      }
    }
  }, [oTokenFromOption.service, collateral, openConfirmModal, onTransactionFail])

  const buttons = readOnly
    ? [
        <Button key={'btn-connect'} onClick={handleConnectButton} buttonStyle={ThemeColorTypes.tertiary}>
          Connect Wallet
        </Button>,
      ]
    : isExpired
    ? bCollateralToPay.gt(0)
      ? [
          <Button key="redeem-collateral" buttonStyle={ThemeColorTypes.secondary} onClick={openRedeemCollateralModal}>
            Redeem Collateral
          </Button>,
        ]
      : exercised
      ? [
          <Button key="redeem-Underlying" buttonStyle={ThemeColorTypes.secondary} onClick={openRedeemUnderlyingModal}>
            Redeem {underlyingToken?.symbol.toUpperCase()}
          </Button>,
        ]
      : []
    : soldInsurance
    ? [
        <Button
          key="sell-more-insurance"
          buttonStyle={ThemeColorTypes.secondary}
          disabled={sellMoreButtonDisabled}
          onClick={openSellInsuranceModal}
        >
          Sell more protection
        </Button>,
        ...(exercised
          ? [
              <Button
                key="redeem-Underlying"
                buttonStyle={ThemeColorTypes.secondary}
                onClick={openRedeemUnderlyingModal}
              >
                Redeem {underlyingToken?.symbol.toUpperCase()}
              </Button>,
            ]
          : []),
        ...(canReducePosition
          ? [
              <Button
                key="reduce-position"
                buttonStyle={ThemeColorTypesInverted.secondaryInverted}
                disabled={false} // fixme
                onClick={openReducePositionModal}
              >
                Reduce position
              </Button>,
            ]
          : []),
      ]
    : [
        <Button
          key="sell-insurance"
          buttonStyle={ThemeColorTypes.secondary}
          onClick={openSellInsuranceModal}
          disabled={sellMoreButtonDisabled}
        >
          Continue
        </Button>,
      ]

  const availableTokenInfos =
    !isPut && strikePriceHumanReadable.gt(0) ? (
      [
        <TokenBalance token={oTokenSymbol} value={availableOTokens} key={'oToken'} />,
        <TextLight key="calls">
          <Value value={new Big(availableOTokens).div(strikePriceHumanReadable).toFixed(2)}>
            {val => ` / ${val} Calls`}
          </Value>
        </TextLight>,
      ]
    ) : (
      <TokenBalance token={oTokenSymbol} value={availableOTokens} />
    )

  const insuranceSoldInfo = [
    {
      title: 'Available Liquidity In Uniswap',
      value: availableTokenInfos,
    },
    {
      title: 'Premiums Earned',
      value: [
        <TokenBalance key={ETH_TOKEN.symbol} token={ETH_TOKEN} value={premiumsEarnedHumanReadable} />,
        ' ',
        <TextLight key="usd">
          <Value value={premiumsEarnedUSD}>{val => `$${val}`}</Value>
        </TextLight>,
      ],
    },
    {
      title: 'Collateral',
      value: <TokenBalance token={collateralToken} value={new BigNumber(collateral)} precision={4} key="usdc" />,
    },
    {
      title: isPut ? 'Puts Issued' : 'Call Issued',
      value: (
        <Value
          value={
            !isPut && strikePriceHumanReadable.gt(0)
              ? Number(oTokensIssued * 10 ** oTokenExchangeRateExp) / Number(strikePriceHumanReadable)
              : // ? new BigNumber(oTokensIssued).div(new BigNumber(strikePriceHumanReadable.toString()))
                new BigNumber(oTokensIssued)
          }
          decimals={(oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1}
          precision={4}
        >
          {val => `${val} ${isPut ? 'Puts' : 'Calls'}`}
        </Value>
      ),
    },
    {
      title: (
        <TextWithTooltip
          text="oTokens Issued"
          tooltipText={
            isPut
              ? `Each ${oTokenSymbol} gives the buyer an option to sell 1 ${tokenSymbol}.`
              : `Each ${oTokenSymbol} gives the buyer an option to purchase (1 / Strike Price) ${tokenSymbol}.  For the ${strikePriceHumanReadable} call, each ${oTokenSymbol} corresponds to 1/${strikePriceHumanReadable} ${tokenSymbol}`
          }
          id="issued-otoken"
          place="right"
        />
      ),
      value: (
        <Value
          value={new BigNumber(oTokensIssued)}
          decimals={(oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1}
          precision={4}
        >
          {val => `${val} ${oTokenSymbol || ''}`}
        </Value>
      ),
    },
    {
      title: `Current ${tokenSymbol} Price`,
      value: <TokenBalance token="$" value={currentPrice.toFixed(2)} />,
    },
    {
      title: (
        <TextWithTooltip
          text="Earn (Annualized ROI)"
          tooltipText={`Potential earnings for selling ETH ${
            isPut ? 'put' : 'call'
          } options for a year (annualized ROI). If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>`}
          id="earn-roi"
          place="right"
        />
      ),
      value: [
        <Percentage key="percentage" value={earnPercentage.round(2).toFixed()} />,
        <TextLight key="usd">
          {' / '}
          <Value key="usdc" value={1}>
            {val => `${val} ${collateralToken?.symbol.toUpperCase() || 'USDC'} collateral`}
          </Value>
        </TextLight>,
      ],
    },
    {
      title: (
        <TextWithTooltip
          text="Earn Premium"
          tooltipText={
            'Potential premiums received for selling ETH put options upfront until expiry. If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>'
          }
          id="earn-premium"
          place="right"
        />
      ),
      value: [
        earnPremium ? (
          <EarnPremiumCell key="earn-premium" value={displayEarnPremium} token={collateralToken?.symbol} />
        ) : (
          '--'
        ),
      ],
    },
    {
      title: (
        <TextWithTooltip
          text="Strike Price"
          tooltipText={`If ${tokenSymbol} ${
            isPut ? 'falls below' : 'rises above'
          } the strike price, you will have to pay out the protection buyer with some or all your collateral. <a href="https://opyn.gitbook.io/opynv1/faq#what-happens-in-the-case-of-an-adverse-event" target="_blank">Learn more.</a>`}
          id="strike-price-1"
          place="right"
        />
      ),
      value: <TokenBalance token="USDC" value={strikePriceHumanReadable.toString()} />,
    },
    {
      title: 'Expiry',
      value: expiry,
    },
  ]

  if (exercised) {
    insuranceSoldInfo.push({
      title: `${underlyingToken?.symbol} Vault Balance`,
      value: <TokenBalance token={underlyingToken} value={new BigNumber(underlying)} precision={4} key="value" />,
    })
  }
  const insuranceNotSoldInfo = [
    {
      title: 'Available Liquidity In Uniswap',
      value: availableTokenInfos,
    },
    {
      title: (
        <TextWithTooltip
          text="Earn (Annualized ROI)"
          tooltipText={`Potential earnings for selling ${tokenSymbol} ${
            isPut ? 'put' : 'call'
          } options for a year (annualized ROI). If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>`}
          id="earn-roi-2"
          place="right"
        />
      ),
      value: [
        <Percentage key="percentage" value={earnPercentage.round(2).toFixed()} />,
        <TextLight key="usd">
          {' / '}
          <Value key="usdc" value={1}>
            {val => `${val} ${collateralToken?.symbol.toUpperCase() || 'USDC'} collateral`}
          </Value>
        </TextLight>,
      ],
    },
    {
      title: (
        <TextWithTooltip
          text="Earn Premium"
          tooltipText={`Potential premiums received for selling ${tokenSymbol} put options upfront until expiry. If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>`}
          id="earn-premium-2"
          place="right"
        />
      ),
      value: [
        earnPremium ? (
          <EarnPremiumCell key="earn-premium" value={displayEarnPremium} token={collateralToken?.symbol || 'USDC'} />
        ) : (
          '--'
        ),
      ],
    },
    {
      title: `Current ${tokenSymbol.toUpperCase()} Price`,
      value: <TokenBalance token="$" value={currentPrice.toFixed(2)} />,
    },
    {
      title: (
        <TextWithTooltip
          text="Strike Price"
          tooltipText={`If ${tokenSymbol} ${
            isPut ? 'falls below' : 'rises above'
          } the strike price, you will have to pay out the protection buyer with some or all your collateral. <a href="https://opyn.gitbook.io/opynv1/faq#what-happens-in-the-case-of-an-adverse-event" target="_blank">Learn more.</a>`}
          id="strike-price-2"
          place="right"
        />
      ),
      value: <TokenBalance token="USDC" value={strikePriceHumanReadable.toString()} />,
    },
    {
      title: 'Expiry',
      value: expiry,
    },
  ]

  const insuranceNotSoldInfoToDisplay = insuranceNotSoldInfo
  const info = soldInsurance ? insuranceSoldInfo : insuranceNotSoldInfoToDisplay

  return (
    <>
      <Card {...restProps}>
        <TokenTitle
          token={collateralToken?.symbol.toLowerCase() || 'usdc'}
          title={`${collateralToken?.symbol.toUpperCase() || 'USDC'} Collateral`}
        >
          {' '}
          {exercised && <FloatTitle>Exercised</FloatTitle>}
        </TokenTitle>
        <Rows>
          {info.map((item, index) => {
            return (
              <Row key={index}>
                <Title>{item.title}</Title>
                <RowValue>{item.value}</RowValue>
              </Row>
            )
          })}
        </Rows>
        {buttons.length === 3 ? (
          <>
            <ButtonsContainer buttonsAmount={1}>{buttons[0]}</ButtonsContainer>
            <br />
            <ButtonsContainer buttonsAmount={2}>{[buttons[1], buttons[2]]}</ButtonsContainer>
          </>
        ) : (
          <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
        )}
      </Card>
      {isSellInsuranceModalOpen && (
        <SellTokenOptionModal
          isOpen={isSellInsuranceModalOpen}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeSellInsuranceModal}
          title="Sell Protection"
          tokenId={tokenId}
          option={option}
          strikePrice={strikePriceHumanReadable.toString()}
          oTokenData={oTokenFromOption}
        />
      )}
      {isReducePositionModalOpen && (
        <ReducePositionModal
          isOpen={isReducePositionModalOpen}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeReducePositionModal}
          reload={reload}
          title={'Reduce Position'}
          option={option}
          oTokenData={oTokenFromOption}
          tokenSymbol={`o${tokenSymbol}`}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={confirmModalTitle}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          themeColor={ThemeColorTypes.secondary}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
      {isRedeemUnderlyingModalOpen && (
        <RedeemModal
          isOpen={isRedeemUnderlyingModalOpen}
          onRequestClose={() => setRedeemUnderlyingModalState(false)}
          title={`Redeem ${underlyingToken?.symbol.toUpperCase()}`}
          content={[
            `Your vault was exercised on, so you have received ${underlyingToken?.symbol.toUpperCase()}.`,
            <Link
              key="learn-more"
              href="https://opyn.gitbook.io/opynv1/faq"
              target="_blank"
              themeColor={ThemeColorTypes.secondary}
            >
              Learn more.
            </Link>,
          ]}
          amount={bTotalUnderlyingToPay.round(4).toFixed()}
          $amount={$totalUnderlyingToPay}
          token={underlyingToken}
          onConfirm={redeemUnderlying}
        />
      )}
      {isRedeemCollateralModalOpen && (
        <RedeemModal
          isOpen={isRedeemCollateralModalOpen}
          onRequestClose={() => setRedeemCollateralModalState(false)}
          title="Redeem Collateral"
          amountLabel="Collateral"
          amount={bCollateralToPay.round(4).toFixed()}
          $amount={$collateralToPay}
          token={collateralToken}
          onConfirm={redeemCollateral}
        />
      )}
    </>
  )
}
