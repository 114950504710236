import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { ModalInnerCard } from '../ModalInnerCard'
import { TokensDropdownUnlocker, LOCK_STATES } from '../../Common/TokensDropdownUnlocker'

const Wrapper = styled(ModalInnerCard)<{ error?: boolean }>`
  border-color: ${props => (props.error ? props.theme.colors.error : 'inherit')};
  transition: border-color 0.25s ease-out;
`

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
`

const Title = styled.h2`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
  text-align: left;
`

const TitleInfoItems = styled.div``

const TitleInfoItem = styled.p<{ error: boolean; onClick?(): void }>`
  color: ${props => (props.error ? props.theme.colors.error : props.theme.colors.textColorLight)};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.8;
  margin: 0;
  text-align: left;
  ${props => props.onClick && 'cursor: pointer;'}
`

const Value = styled.input<{ isLocked?: LOCK_STATES }>`
  border: none;
  color: ${props => props.theme.colors.darkGray};
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.2;
  min-width: 0;
  outline: none;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 55px;
  padding-top: 0;
  width: 50%;

  &::placeholder {
    color: ${props => props.theme.colors.textColorLight};
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  &[readOnly] {
    cursor: default;
  }
`

const ValueWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  dropdownIsDisabled?: boolean
  isLocked?: LOCK_STATES
  error?: boolean
  onUnlock?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
  onAmountChange?: (value: string) => void
  inputIsReadOnly?: boolean
  selectedToken?: Maybe<string>
  title: string
  titleInfo: Array<any>
  value?: string
  onPaymentTokenChange?: (token: string) => void
  includeOToken?: boolean
}

export const ModalInnerCardExtended: React.FC<Props> = (props: Props) => {
  const {
    dropdownIsDisabled,
    isLocked = LOCK_STATES.UNLOCKED,
    error = false,
    onUnlock,
    onAmountChange,
    inputIsReadOnly = false,
    selectedToken,
    title,
    titleInfo,
    value,
    onPaymentTokenChange,
    includeOToken,
    ...restProps
  } = props

  return (
    <Wrapper error={error} {...restProps}>
      <TitleWrapper>
        <Title>{title}</Title>
        <TitleInfoItems>
          {titleInfo.map((item, index) => {
            return (
              <TitleInfoItem error={error} key={index} onClick={item.onClick}>
                {item.title} {item.value}
              </TitleInfoItem>
            )
          })}
        </TitleInfoItems>
      </TitleWrapper>
      <ValueWrapper>
        <Value
          isLocked={isLocked}
          onChange={e => onAmountChange?.(e.target.value)}
          placeholder="0.0"
          readOnly={inputIsReadOnly}
          type="text"
          value={value}
        ></Value>
        <TokensDropdownUnlocker
          disabled={dropdownIsDisabled}
          isLocked={isLocked}
          onUnlock={onUnlock}
          selectedToken={selectedToken}
          onTokenChange={onPaymentTokenChange}
          includeOToken={includeOToken}
        />
      </ValueWrapper>
    </Wrapper>
  )
}
