import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { getTokenFromAddress, ETH_TOKEN, getOTokenSymbol, isValidNetworkId } from 'utils'
import { CheckIcon } from 'components/TransactionsCard/img/CheckIcon'
import { RefreshIcon } from 'components/TransactionsCard/img/RefreshIcon'
import { BackArrowIcon } from 'components/TransactionsCard/img/BackArrowIcon'
import { useConnection } from 'components/web3'
import Value from 'components/Common/Value'
import {
  selling_history,
  selling_history_optionsContract,
  selling_history_vaultOpenedActions,
  selling_history_sellOTokensActions,
  selling_history_vaults_actions,
} from 'types/generatedGQL'
import TokenBalance from 'components/Common/TokenBalance'
import { Big } from 'big.js'
import { useOptionIsCall } from './useEthOptionType'
import { useEthOptionByAddress } from './useEthOptionByAddress'

export interface SellHistory {
  id?: string
  type?: string
  __typename?: string
  amount?: string
  burned?: string
  premium?: string
  transactionHash: string
  timestamp: string
  pending?: boolean
  oToken?: string
  payoutTokenAddress?: string
}
const HISTORY = loader('../queries/selling_history.graphql')

// within the same transaction the order should be:
// 4- SellOTokensAction
// 3- IssuedOTokenAction
// 2- ETHCollateralAddedAction
// 1- VaultOpenedAction
const sorter = (a: any, b: any) => {
  if (b.timestamp !== a.timestamp) {
    return b.timestamp - a.timestamp
  } else {
    return b.sameTxOrder - a.sameTxOrder
  }
}

const marshalOpenedVaultActions = (action: selling_history_vaultOpenedActions) => {
  return {
    id: action.id,
    type: (action as any).type,
    timestamp: action.timestamp,
    transactionHash: action.transactionHash,
    sameTxOrder: 1,
  }
}

const filterVaultActions = (action: selling_history_vaults_actions) => {
  const type = (action as any).__typename
  return (
    type === 'ETHCollateralAddedAction' ||
    type === 'ERC20CollateralAddedAction' ||
    type === 'IssuedOTokenAction' ||
    type === 'ExerciseAction' ||
    type === 'RemoveUnderlyingAction' ||
    type === 'RedeemVaultBalanceAction' ||
    type === 'RemoveCollateralAction' ||
    type === 'LiquidateAction' ||
    type === 'BurnOTokenAction'
  )
}
const marshalVaultActions = (action: selling_history_vaults_actions) => {
  const type = (action as any).type || (action as any).__typename
  const amount = (action as any).amount || (action as any).burned
  const sameTxOrder = type === 'ETHCollateralAddedAction' || type === 'ERC20CollateralAddedAction' ? 2 : 3
  return {
    id: action.id,
    type,
    amount,
    timestamp: action.timestamp,
    transactionHash: action.transactionHash,
    sameTxOrder,
  }
}
const marshalSellActions = (action: selling_history_sellOTokensActions) => {
  const type = (action as any).__typename
  return {
    id: action.id,
    type,
    amount: action.oTokensToSell,
    premium: action.payoutTokensReceived,
    payoutTokenAddress: action.payoutTokenAddress,
    timestamp: action.timestamp,
    transactionHash: action.transactionHash,
    sameTxOrder: 4,
    oToken: action.token.address,
  }
}

const marshalActions = (data: selling_history) => {
  const openedVaults = data.vaultOpenedActions.map(marshalOpenedVaultActions)
  const vaultActions =
    (data.vaults.length && data.vaults[0].actions?.filter(filterVaultActions).map(marshalVaultActions)) || []
  const sellActions = data.sellOTokensActions.map(marshalSellActions)

  return [...openedVaults, ...vaultActions, ...sellActions]
}

type ActionsByTx = Record<string, Array<SellHistory>>

const groupByTxHash = (data: Array<SellHistory>) => {
  return data.reduce((acc: ActionsByTx, action: SellHistory) => {
    const transactionHash = action.transactionHash
    const actionGroup = acc[transactionHash] || new Array<SellHistory>()
    return {
      ...acc,
      [transactionHash]: [...actionGroup, action],
    }
  }, {} as ActionsByTx)
}

export const useSellingTransactionHistory = (oTokenAddress: string): [any[], any[], boolean, Function, Maybe<Big>] => {
  const { networkId, account } = useConnection()
  const [history, setHistory] = useState<Array<any>>([])
  const [isPolling, setIsPolling] = useState(false)

  const {
    optionsData: { option },
  } = useEthOptionByAddress(oTokenAddress)
  const isCall = useOptionIsCall(option)

  const parseActions = useCallback(
    (actions: ActionsByTx, oTokenData: selling_history_optionsContract) => {
      const token = getTokenFromAddress(networkId, oTokenData?.underlying)

      const collateralToken =
        isValidNetworkId(networkId) && oTokenData?.collateral
          ? getTokenFromAddress(networkId, oTokenData.collateral)
          : null
      const oTokenSymbol = isCall ? `o${collateralToken?.symbol.toUpperCase()}c` : getOTokenSymbol(token)
      const oTokenExchangeRateExp = (oTokenData && oTokenData.oTokenExchangeRateExp * -1) || 1
      return Object.keys(actions).map(tx => {
        const [lastAction] = actions[tx]
        const type = lastAction.type
        if (type === 'SellOTokensAction') {
          const payoutToken = getTokenFromAddress(networkId, String(lastAction.payoutTokenAddress))
          const payoutTokenDecimals = payoutToken.decimals
          const payoutTokenSymbol = payoutToken.symbol

          const amount = {
            value: lastAction.amount || '0',
            decimals: oTokenExchangeRateExp,
          }
          const premium = {
            value: lastAction.premium || 0,
            decimals: payoutTokenDecimals,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Sold ',
              <Value key="token" precision={8} value={amount}>
                {val => `${val} ${oTokenSymbol}`}
              </Value>,
              ', Earned ',
              <Value key="premium" precision={8} value={premium}>
                {val => `${val} ${payoutTokenSymbol}`}
              </Value>,
              ' Premium',
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'ExerciseAction') {
          return {
            id: lastAction.id,
            icon: BackArrowIcon,
            title: ['Exercised; Paid out buyer'],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'RemoveUnderlyingAction') {
          const amount = {
            value: lastAction.amount || 0,
            decimals: token.decimals,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: ['Redeemed ', <TokenBalance key="token" precision={8} value={amount} token={token} />],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'RedeemVaultBalanceAction') {
          const amount = {
            value: lastAction.amount || 0,
            decimals: collateralToken?.decimals || 0,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Redeemed ',
              <TokenBalance key="token" precision={8} value={amount} token={collateralToken} />,
              ' collateral',
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'RemoveCollateralAction') {
          const amount = {
            value: lastAction.amount || 0,
            decimals: collateralToken?.decimals || 0,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Reduce position, redeeming ',
              <TokenBalance key="token" precision={8} value={amount} token={collateralToken} />,
              ' collateral',
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'LiquidateAction') {
          const amount = {
            value: lastAction.amount || 0,
            decimals: collateralToken?.decimals || 0,
          }
          return {
            id: lastAction.id,
            icon: BackArrowIcon,
            title: [
              'Liquidated, paid out ',
              <TokenBalance key="token" precision={8} value={amount} token={collateralToken} />,
              ' collateral',
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'BurnOTokenAction') {
          const amount = {
            value: lastAction.amount || '0',
            decimals: oTokenExchangeRateExp,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Burned ',
              <Value key="token" precision={8} value={amount}>
                {val => `${val} ${oTokenSymbol}`}
              </Value>,
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else if (type === 'IssuedOTokenAction') {
          const amount = {
            value: lastAction.amount || 0,
            decimals: oTokenExchangeRateExp,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Issued ',
              <Value key="token" precision={8} value={amount}>
                {val => `${val} ${oTokenSymbol}`}
              </Value>,
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        } else {
          // ETHCollateralAddedAction or ERC20CollateralAddedAction
          const amount = {
            value: lastAction.amount || 0,
            decimals: collateralToken?.decimals || ETH_TOKEN.decimals,
          }
          return {
            id: lastAction.id,
            icon: lastAction.pending ? RefreshIcon : CheckIcon,
            title: [
              'Supplied ',
              <Value key="token" precision={8} value={amount}>
                {val => `${val} ${collateralToken?.symbol || ETH_TOKEN.symbol}`}
              </Value>,
            ],
            timestamp: lastAction.timestamp,
            transactionHash: lastAction.transactionHash,
          }
        }
      })
    },
    [networkId, isCall],
  )

  const { loading, data, refetch, startPolling, stopPolling } = useQuery(HISTORY, {
    variables: {
      account,
      oToken: oTokenAddress,
    },
    skip: !account,
  })

  const refresh = useCallback(async () => {
    if (account && oTokenAddress) {
      refetch({
        account,
        oToken: oTokenAddress,
      })
      setIsPolling(true)
      startPolling(3000)
    }
  }, [account, oTokenAddress, refetch, startPolling])

  useEffect(() => {
    const locallyStoredTransactions = JSON.parse(localStorage.getItem('sell-transactions') || '[]') as any[]

    let marshaledActions = data ? marshalActions(data) : []

    const pending = locallyStoredTransactions.filter(
      ({ transactionHash: hash, timestamp, oToken }: SellHistory) =>
        Date.now() / 1000 - Number(timestamp) < 3600 &&
        !marshaledActions.find(({ transactionHash }: SellHistory) => hash === transactionHash) &&
        oToken?.toLowerCase() === oTokenAddress,
    )

    if (pending.length) {
      marshaledActions = marshaledActions.concat(pending)
    } else {
      setIsPolling(false)
      stopPolling()
    }

    localStorage.setItem('sell-transactions', JSON.stringify(pending))

    setHistory(data ? parseActions(groupByTxHash(marshaledActions.sort(sorter)), data.optionsContract) : [])
  }, [data, isPolling, stopPolling, parseActions, oTokenAddress])

  return [history, [], loading, refresh, null]
}
