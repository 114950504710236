import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button } from '../../components/Common/Button'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

const Title = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 50px;
  line-height: 1.2;
  margin: 0 0 10px;
`

const Text = styled.p`
  color: #000;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 25px;
`

export const Error404: React.FC = props => {
  const history = useHistory()
  const goHome = () => history.push('/')

  return (
    <Wrapper {...props}>
      <Title>Page not found</Title>
      <Text>Sorry, we couldn’t find what you’re looking for.</Text>
      <Button onClick={goHome}>Back to Opyn</Button>
    </Wrapper>
  )
}
