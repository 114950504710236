import React from 'react'
import styled from 'styled-components'
import { TwitterIcon } from './img/TwitterIcon'
import { GithubIcon } from './img/GithubIcon'
import { DiscordIcon } from './img/DiscordIcon'
import { MediumIcon } from './img/MediumIcon'

const SocialIconsWrapper = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

const SocialIcon = styled.a`
  text-decoration: none;
  display: block;

  > svg {
    display: block;
  }

  &:hover {
    .iconBackground {
      fill: #333;
    }
  }
`

export const SocialIcons: React.FC = props => {
  const { ...restProps } = props
  const SocialData = [
    {
      url: 'https://twitter.com/opyn_',
      image: <TwitterIcon />,
      title: 'Twitter',
    },
    {
      url: 'https://discord.gg/2NFdXaE',
      image: <DiscordIcon />,
      title: 'Discord',
    },
    {
      url: 'https://github.com/opynfinance',
      image: <GithubIcon />,
      title: 'Github',
    },
    {
      url: 'https://medium.com/opyn',
      image: <MediumIcon />,
      title: 'Medium',
    },
  ]

  return (
    <SocialIconsWrapper {...restProps}>
      {SocialData.map((item, index) => {
        return (
          <SocialIcon target="_blank" href={item.url} key={index} title={item.title}>
            {item.image}
          </SocialIcon>
        )
      })}
    </SocialIconsWrapper>
  )
}
