import React from 'react'
import { Footer } from './components/Footer'
import { Welcome } from './components/Sell/Welcome'
import { Share } from '../../components/Common/Share'
import { SellProtectionSteps } from './components/Sell/SellProtectionSteps'
// restore ETH puts. uncomment this
// import { SellEthProtection } from './components/Sell/SellEthProtection'
import { SellCompoundProtection } from './components/Sell/SellCompoundProtection'
import { Sell } from './components/Sell/Sell'

const SellLanding = () => (
  <>
    <Welcome />
    <SellProtectionSteps />
    <SellCompoundProtection />
    <Sell />
    <Share />
    <Footer />
  </>
)

export default SellLanding
