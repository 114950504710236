import React from 'react'
import fromUnixTime from 'date-fns/fromUnixTime'
import formatDate from 'date-fns-tz/format'
import { EXPIRY_DATE_FORMAT } from 'common/constants'

export interface DateProps {
  timestamp: number
  format?: string
  timeZone?: any
  children?(value: string): void
}

const FormattedDate: React.FC<DateProps> = ({ timestamp, format = EXPIRY_DATE_FORMAT, children }: DateProps) => {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const formattedValue = formatDate(fromUnixTime(timestamp), format, { timeZone })

  return <>{children ? children(formattedValue) : formattedValue}</>
}

export default FormattedDate
