import { useState, useEffect } from 'react'
import { useConnection } from 'components/web3'
import { ETH_TOKEN, getTokenOrPlaceHolder } from 'utils'
import { Big } from 'big.js'

import useOracle from './useOracle'
import { useSellerOptionsByUnderlying } from './useSellerEthOptions'

import { whitelist as optionsToShow } from './useBuyerTokenOptions'
// import { callsToShow } from './useBuyerEthCalls'

import { getExtras, ExtraData } from './useSellerEthPutsAndCalls'
import useEthToUsdPrice from './useEthToUSDPrice'
import useKnownTokenToUSDPrice from './useKnownTokenToUSDPrice'

export const useSellerTokenPutsOrCalls = (type: 'put' | 'call', tokenId: KnownToken) => {
  const { networkId, account = '', library } = useConnection()

  const [loading, setIsLoading] = useState(true)
  const [extraData, setExtras] = useState<Maybe<ExtraData>>(null)

  const oracleContract = useOracle()

  const usdc = getTokenOrPlaceHolder(networkId, 'usdc')
  // development TODO: add this mock.
  // const _tokenId = tokenId === 'dpi' ? 'snx' : tokenId
  const token = getTokenOrPlaceHolder(networkId, tokenId)

  const underlying = type === 'put' ? token.address : usdc.address

  const isPut = type === 'put'

  const { options, loading: fetching } = useSellerOptionsByUnderlying(underlying, optionsToShow, isPut, token.address)

  const ethToUsdcPrice = useEthToUsdPrice()

  const currentEthPrice = ethToUsdcPrice.gt(0)
    ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
    : new Big(0)

  const tokenToUsdcPrice = useKnownTokenToUSDPrice(tokenId)

  const tokenPrice = tokenId === 'weth' ? currentEthPrice : tokenToUsdcPrice

  useEffect(() => {
    let isCancelled = false
    if (!fetching && !isCancelled && options && library && oracleContract) {
      const networkIdOrDefault = networkId === -1 ? 1 : networkId
      getExtras(type, options, networkIdOrDefault, account || '', library, oracleContract).then(extras => {
        setExtras(extras)
        setIsLoading(false)
      })
    }

    return () => {
      isCancelled = true
    }
  }, [options, loading, networkId, oracleContract, account, library, fetching, type])

  return { extraData, options, loading: loading || fetching, currentPrice: tokenPrice }
}
