import React from 'react'
import styled from 'styled-components'
import { TextLight, TotalWrapper, TotalTitle, TotalValue } from '../../Common/CommonStyled'

const Wrapper = styled(TotalWrapper)`
  margin: 15px 0 10px;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const ValueLight = styled(TextLight)`
  margin-left: 5px;
`

interface Props {
  token: string
  value: string
  $value: string
}

export const TotalCost: React.FC<Props> = props => {
  const { token, value = '0', $value = '0', ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Total Cost</TotalTitle>
      <Values>
        <TotalValue>
          {value} {token || ''}
        </TotalValue>
        <TotalValue>
          <ValueLight> ${$value}</ValueLight>
        </TotalValue>
      </Values>
    </Wrapper>
  )
}
