import React, { HTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { Table, Column } from 'react-virtualized/dist/es/Table'
import {
  chevronColumnDefaults,
  columnDefaults,
  tableDefaults,
  chevronCellRenderer,
  tokenCellRenderer,
  compoundTokenMap,
  durationGetter,
  colorCellRenderer,
  ETH_TOKEN,
} from 'utils'
import { TableOverflow } from 'components/Common/TableOverflow'
import { useHistory } from 'react-router-dom'
import { Index } from 'react-virtualized'
import { Big } from 'big.js'
import Percentage from 'components/Common/Percentage'
import { useEthToUsdPrice } from 'hooks'
import TokenBalance from 'components/Common/TokenBalance'
import { BigNumber } from 'ethers/utils'
import { TextLight } from 'components/Common/CommonStyled'
import Value from 'components/Common/Value'

const Wrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TableWrapper = styled(TableOverflow)`
  min-height: 200px;
`

interface SoldProps extends HTMLAttributes<HTMLDivElement> {
  data: any
}

const collateralizationRatioGetter = (option: any, extra: any) => {
  const current = extra ? extra.collateralizationRatio * 100 : null
  const min = option.minCollateralizationRatioValue * 10
  const status = current ? (current > min ? 'ok' : 'error') : '-'

  return {
    value: current ? <Percentage value={current} /> : '--',
    status,
  }
}

const collaterCellRender = (props: any) => {
  const { cellData } = props
  const { option, ethToUsdcPrice } = cellData
  const { collateral = 0 } = option?.vaults[0] || {}

  const $collateral = ethToUsdcPrice.eq(0)
    ? '0'
    : new Big(collateral)
        .div(ethToUsdcPrice.toString())
        .round(4)
        .toFixed()

  return (
    <>
      <TokenBalance token={ETH_TOKEN} value={new BigNumber(collateral)} precision={4} key="eth" />
      &nbsp;
      <TextLight key="usd">
        <Value value={$collateral}>{val => ` $${val}`}</Value>
      </TextLight>
    </>
  )
}

export const Sold: React.FC<SoldProps> = (props: SoldProps) => {
  const { data, ...restProps } = props
  const history = useHistory()
  const ethToUsdcPrice = useEthToUsdPrice()

  const goToAsset = (props: any) => {
    const { rowData } = props

    if (rowData.address) {
      history.push(`/sell/${rowData.address}`)
    }
  }

  const { insuredData } = data

  const rowGetter = useCallback(
    (info: Index): any => {
      const { token = {}, option = {} } = insuredData[info.index] || {}
      const { extraData } = data || {}
      const extra = extraData && option ? extraData[option.address] : null

      return {
        id: token.symbol.toLowerCase(),
        name: compoundTokenMap[token.symbol.toLowerCase() as CompoundToken],
        address: option && option.address,
        collateral: { option, ethToUsdcPrice },
        collateralizationRatio: collateralizationRatioGetter(option, extra),
        duration: durationGetter(option),
        status: 'ok',
      }
    },
    [insuredData, data, ethToUsdcPrice],
  )

  return (
    <Wrapper {...restProps}>
      <TableWrapper>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Table
              {...tableDefaults}
              height={height}
              onRowClick={goToAsset}
              rowCount={insuredData ? insuredData.length : 0}
              rowGetter={rowGetter}
            >
              <Column
                {...columnDefaults}
                cellRenderer={tokenCellRenderer}
                className="alignLeft"
                dataKey="name"
                headerClassName="alignLeft"
                label="Name"
              />
              <Column
                {...columnDefaults}
                label="Collateral Supplied"
                dataKey="collateral"
                cellRenderer={collaterCellRender}
              />
              <Column
                {...columnDefaults}
                dataKey="collateralizationRatio"
                label="Collateralization Ratio"
                width={150}
                cellRenderer={colorCellRenderer}
              />
              <Column {...columnDefaults} label="Expiry" dataKey="duration" cellRenderer={colorCellRenderer} />
              <Column {...chevronColumnDefaults} cellRenderer={chevronCellRenderer} label="" dataKey="status" />
            </Table>
          )}
        </AutoSizer>
      </TableWrapper>
    </Wrapper>
  )
}
