import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { TokenCollateral } from '../TokenCollateral'
import { PremiumsToEarn } from '../PremiumsToEarn'
import { Expiry } from 'components/Common/Expiry'
import { CurrentETHPrice } from '../CurrentETHPrice'
import { StrikePrice } from '../StrikePrice'
import { OTokensCreated } from '../OTokensCreated'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import { Button } from 'components/Common/Button'
import Link from 'components/Common/Link'
import {
  useContracts,
  useConnection,
  Contracts,
  useOptionsExchange,
  useAsyncMemo,
  oTokenData,
  useOptionIsCall,
  useEthToUsdPrice,
} from 'hooks'
import { LOCK_STATES } from 'components/Common/TokensDropdownUnlocker'
import {
  ThemeColorTypes,
  Token,
  convertBigFloatToBig,
  unlockToken,
  getERC20Balance,
  getEthBalance,
  ETH_TOKEN,
  isValidNetworkId,
  getToken,
  getTokenFromAddress,
} from 'utils'
import { BigNumber } from 'ethers/utils'
import TokenBalance from 'components/Common/TokenBalance'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { Big, BigSource } from 'big.js'
import { calculateMaxOptionsToCreate } from 'utils/options'
import { OptionsExchange } from 'services'
import { isValid } from 'date-fns'

const DetailWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  div {
    border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  }

  div:last-child {
    border-bottom: none;
  }
`

const Text = styled.div`
  color: ${props => props.theme.colors.textColorMedium};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 12px;
`

const getPremiumToEarn = async (
  optionsExchangeContract: Maybe<OptionsExchange>,
  oTokensToCreate: BigNumber,
  paymentToken: Maybe<Token>,
  oTokenAddress: string,
) => {
  if (oTokensToCreate.eq(0) || !oTokenAddress || !optionsExchangeContract || !paymentToken) {
    return new BigNumber(0)
  }
  const premium = await optionsExchangeContract?.getPremiumReceived(
    oTokenAddress,
    paymentToken.address,
    Number(oTokensToCreate),
  )
  return premium
}

function calcMaxOTokens(
  inputCollateral: BigSource,
  addedCollateral: Big,
  mintedOTokens: Big,
  option: any,
  ratio: any,
  collateralDecimals = 6,
) {
  if (!option || !inputCollateral || !Number(inputCollateral)) {
    return new BigNumber(0)
  } else {
    const collateralToStrikePrice = { value: 1, decimals: 0 }
    const otokens = calculateMaxOptionsToCreate({
      collateralToAdd: inputCollateral,
      strikePriceValue: option?.strikePriceValue,
      strikePriceExp: option?.strikePriceExp,
      collateralToStrikePrice,
      addedCollateral: new Big(addedCollateral).div(`1e${collateralDecimals}`).toString(),
      mintedOTokens,
      ratio,
    })
    return otokens
  }
}

interface ModalProps {
  isOpen: boolean
  onConfirm?: (tx: any, amount: string, premium: string, type: string, payoutTokenAddress: string) => void
  onTransactionFail?: () => void
  onRequestClose?: (e?: any) => void
  option?: SellOTokens_optionsContract
  oTokenData?: oTokenData
  strikePrice?: string
  title?: string
}

export const SellETHInsuranceModal: React.FC<ModalProps> = props => {
  const { option, onConfirm, onTransactionFail, oTokenData, strikePrice } = props
  const { vaults, oTokenExchangeRateExp } = option || {}
  const { collateral: addedCollateral = 0, oTokensIssued: mintedOTokens = 0 } = vaults?.[0] || {}
  const { account, networkId, library } = useConnection()
  const contracts: Contracts = useContracts()
  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const { service, collateralToStrikePrice: strikeAssetPriceInWei, token: underlyingToken, loading } = oTokenData || {}
  const [payLockState, setPayLockState] = useState(LOCK_STATES.UNLOCKED)

  const [inputCollateral, setInputCollateral] = useState<BigSource>('0')
  const isCall = useOptionIsCall(option)
  const usdcPrice = useEthToUsdPrice()

  const usdcToken = useMemo(() => getToken(isValidNetworkId(networkId) ? networkId : 1, 'usdc'), [networkId])

  const collateralToken = useMemo(() => {
    if (isValid(networkId) && option) return getTokenFromAddress(networkId, option?.collateral)
    else return usdcToken
  }, [networkId, option, usdcToken])

  const collateral = useMemo(() => contracts?.[collateralToken.symbol.toUpperCase()], [collateralToken, contracts])

  const oTokenSymbol = isCall ? 'oETHc' : 'oETHp'

  const currentEthPrice = useMemo(() => {
    return isCall && underlyingToken
      ? usdcPrice.gt(0)
        ? new Big(1).times(new Big(`1e${ETH_TOKEN.decimals}`)).div(new Big(usdcPrice.toString()))
        : new Big(1)
      : convertBigFloatToBig(strikeAssetPriceInWei).gt(0)
      ? new Big(1).div(convertBigFloatToBig(strikeAssetPriceInWei))
      : new Big(1)
  }, [isCall, underlyingToken, usdcPrice, strikeAssetPriceInWei])

  const userCollateralBalance = useAsyncMemo(
    async () => {
      if (!library) return Big(0)
      if (collateralToken.address !== ETH_TOKEN.address) {
        const tokenContract = contracts?.[collateralToken.symbol.toUpperCase()]
        const tokenBalance = await getERC20Balance(account, tokenContract)
        return Big(tokenBalance.toString()).times(`1e-${collateralToken.decimals}`)
      } else {
        const ethBalance = await getEthBalance(account, library)
        return Big(ethBalance.toString()).times(`1e-${collateralToken.decimals}`)
      }
    },
    new Big(0),
    [collateralToken, account, library],
  )

  const updateInputCollateral = (value: string) => {
    if (Number(value) >= 0 || value === '') {
      setInputCollateral(value)
    }
  }

  const setMaxCollateralBalance = () => setInputCollateral(userCollateralBalance)

  const $collateral = useMemo(() => {
    if (!inputCollateral || inputCollateral === '0') {
      return new BigNumber(0)
    }
    const collateralAmt = new Big(inputCollateral)
    const collateralValue = isCall ? collateralAmt.times(currentEthPrice) : collateralAmt
    return collateralValue.round(4).toFixed()
  }, [inputCollateral, isCall, currentEthPrice])

  const unlockPay = useCallback(async () => {
    const spender = option ? option.address : '0x0'
    setPayLockState(LOCK_STATES.LOADING)
    unlockToken(spender, collateral).then(newLockState => {
      setPayLockState(newLockState)
    })
  }, [option, collateral])

  useEffect(() => {
    if (!!collateral && !!account && option?.address && !isCall) {
      const inputVal = new BigNumber('10000000000000000000000000')
      collateral.hasEnoughAllowance(account, option?.address, inputVal).then(unlockLog => {
        setPayLockState(unlockLog ? LOCK_STATES.UNLOCKED : LOCK_STATES.LOCKED)
      })
    } else {
      setPayLockState(LOCK_STATES.UNLOCKED)
    }
  }, [collateral, account, option, isCall])

  const ratio = 100 / 100

  const oTokensToCreate = useMemo(() => {
    return calcMaxOTokens(inputCollateral, addedCollateral, mintedOTokens, option, ratio, collateralToken?.decimals)
  }, [inputCollateral, addedCollateral, mintedOTokens, option, ratio, collateralToken])

  const buttonDisabled =
    !account || !service || loading || oTokensToCreate.lte(0) || new Big(inputCollateral).gt(userCollateralBalance)
  const premiumToEarn = useAsyncMemo(
    async () => {
      if (oTokensToCreate.lte(0)) {
        return { value: new BigNumber(0), decimals: ETH_TOKEN.decimals }
      } else {
        return {
          value: await getPremiumToEarn(optionsExchangeContract, oTokensToCreate, ETH_TOKEN, option?.address),
          decimals: ETH_TOKEN.decimals,
        }
      }
    },
    { value: new BigNumber(0), decimals: ETH_TOKEN.decimals },
    [oTokensToCreate, optionsExchangeContract, ETH_TOKEN, option],
  )

  const $premiumToEarn = useMemo(() => {
    if (premiumToEarn.value.eq(0)) {
      return new BigNumber(0)
    }
    return new Big(premiumToEarn.value.toString())
      .times(currentEthPrice)
      .div(1e18)
      .round(4)
      .toFixed()
  }, [premiumToEarn, currentEthPrice])

  const confirmHandler = useCallback(async () => {
    if (account && service && optionsExchangeContract && option) {
      try {
        const sellERC20CollateralOTokens = vaults?.length
          ? service.addAndSellERC20CollateralOption
          : service.createAndSellERC20CollateralOption

        const sellETHCollateralOTokens = vaults?.length
          ? service.addAndSellETHCollateralOption
          : service.createAndSellETHCollateralOption

        const premium = await getPremiumToEarn(optionsExchangeContract, oTokensToCreate, ETH_TOKEN, option.address)

        const amount = new BigNumber(new Big(inputCollateral).times(`1e${collateralToken.decimals}`).toFixed(0))
        const tx = isCall
          ? await sellETHCollateralOTokens(oTokensToCreate.toString(), account, amount)
          : await sellERC20CollateralOTokens(oTokensToCreate.toString(), amount, account)

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, oTokensToCreate.toString(), premium.toString(), 'SellOTokensAction', ETH_TOKEN.address)
        }
      } catch (error) {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          onTransactionFail()
        }
      }
    }
  }, [
    vaults,
    account,
    service,
    oTokensToCreate,
    inputCollateral,
    onTransactionFail,
    onConfirm,
    collateralToken.decimals,
    option,
    optionsExchangeContract,
    isCall,
  ])

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        title={`${collateralToken.symbol} Collateral`}
        titleInfo={[
          {
            title: 'Balance:',
            value: <TokenBalance value={userCollateralBalance.toString()} token={collateralToken} />,
            onClick: setMaxCollateralBalance,
          },
        ]}
        // inputIsReadOnly
        isLocked={payLockState}
        dropdownIsDisabled
        onUnlock={unlockPay}
        value={inputCollateral.toString()}
        selectedToken={collateralToken.symbol.toLowerCase()}
        onAmountChange={updateInputCollateral}
        error={false}
      />
      <DetailWrapper>
        <TokenCollateral
          collateralSymbol={collateralToken.symbol}
          value={inputCollateral.toString()}
          $value={$collateral.toString()}
        />

        <PremiumsToEarn value={premiumToEarn} $value={$premiumToEarn.toString()} token={'ETH'} />
        <Expiry option={option} />
        <StrikePrice strikePrice={strikePrice} />
        <CurrentETHPrice currPrice={currentEthPrice.round(2).toFixed()} />
        {isCall && (
          <OTokensCreated
            title="Calls Created"
            value={{
              value: oTokensToCreate.div(strikePrice || 1).toString(),
              decimals: (oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1,
            }}
            token={isCall ? 'Calls' : 'Puts'}
          />
        )}
        <OTokensCreated
          title={
            <TextWithTooltip
              text="oTokens Created"
              tooltipText={
                isCall
                  ? `Each oETHc gives the buyer an option to purchase (1 / Strike Price) ETH.  For the ${strikePrice} call, each oETHc corresponds to 1/${strikePrice} ETH`
                  : `Each oETHp gives the buyer an option to sell 1 ETH.`
              }
              id="earn-eth"
              place="right"
            />
          }
          value={{
            value: oTokensToCreate.toString(),
            decimals: (oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1,
          }}
          token={oTokenSymbol || ''}
        />
      </DetailWrapper>
      <Text>
        If the protection buyer exercises, you may lose some or all of your collateral.{' '}
        <Link
          themeColor={ThemeColorTypes.secondary}
          href="https://opyn.gitbook.io/opynv1/faq#how-do-claims-work"
          target="_blank"
        >
          Learn more.
        </Link>
      </Text>
      <Button disabled={buttonDisabled} buttonStyle={ThemeColorTypes.secondary} onClick={confirmHandler}>
        Confirm
      </Button>
    </Modal>
  )
}
