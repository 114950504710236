import React, { ButtonHTMLAttributes } from 'react'
import styled, { withTheme, css } from 'styled-components'
import { darken } from 'polished'
import { ThemeColorTypes, ThemeColorTypesInverted } from '../../../utils'

export const ButtonCSS = css<{ buttonStyle: ButtonStyleProps }>`
  align-items: center;
  background-color: ${props => props.buttonStyle.backgroundColor};
  border-radius: 6px;
  border: 1px solid ${props => props.buttonStyle.borderColor};
  color: ${props => props.buttonStyle.textColor};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  outline: none;
  padding: 0 14px;
  transition: background-color 0.15s ease-out, opacity 0.15s ease-out;
  white-space: nowrap;

  &:hover {
    background-color: ${props => props.buttonStyle.backgroundColorHover};
    border-color: ${props => props.buttonStyle.borderColorHover};
    color: ${props => props.buttonStyle.textColorHover};
  }

  &[disabled] {
    background-color: ${props => props.buttonStyle.backgroundColor};
    border-color: ${props => props.buttonStyle.borderColor};
    color: ${props => props.buttonStyle.textColorHover};
    cursor: not-allowed;
    opacity: 0.2;
  }
`

const ButtonContainer = styled.button<{ buttonStyle: ButtonStyleProps }>`
  ${ButtonCSS}
`

const mapButtonStyle = (buttonStyle: ThemeColorTypes | ThemeColorTypesInverted, theme: any): ButtonStyleProps => {
  return buttonStyle in ThemeColorTypes
    ? {
        backgroundColor: theme.colors[ThemeColorTypes[buttonStyle]],
        backgroundColorHover: darken(0.15, theme.colors[ThemeColorTypes[buttonStyle]]),
        borderColor: theme.colors[ThemeColorTypes[buttonStyle]],
        borderColorHover: darken(0.15, theme.colors[ThemeColorTypes[buttonStyle]]),
        textColor: '#fff',
        textColorHover: '#fff',
      }
    : {
        backgroundColor: 'transparent',
        backgroundColorHover: 'transparent',
        borderColor: theme.colors[ThemeColorTypesInverted[buttonStyle]],
        borderColorHover: darken(0.15, theme.colors[ThemeColorTypesInverted[buttonStyle]]),
        textColor: theme.colors[ThemeColorTypesInverted[buttonStyle]],
        textColorHover: darken(0.15, theme.colors[ThemeColorTypesInverted[buttonStyle]]),
      }
}

interface ButtonStyleProps {
  backgroundColor: string
  backgroundColorHover: string
  borderColor: string
  borderColorHover: string
  textColor: string
  textColorHover: string
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ThemeColorTypes | ThemeColorTypesInverted
  theme?: any
}

const ButtonComponent: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { buttonStyle = ThemeColorTypes.primary, theme, children, disabled = false, onClick, ...restProps } = props

  return (
    <ButtonContainer
      buttonStyle={mapButtonStyle(buttonStyle, theme)}
      disabled={disabled}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </ButtonContainer>
  )
}

export const Button = withTheme(ButtonComponent)
