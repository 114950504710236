import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { getNetworkName, NetworkId } from './utils'

import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const getHttpLink = (networkId: NetworkId | -1) => {
  const envName = `REACT_APP_GRAPH_HTTP_${(getNetworkName(networkId) as string).toUpperCase()}`

  return new HttpLink({
    uri: process.env[envName],
  })
}

const getWsLink = (networkId: NetworkId | -1) => {
  const envName = `REACT_APP_GRAPH_WS_${(getNetworkName(networkId) as string).toUpperCase()}`

  return new WebSocketLink({
    uri: process.env[envName] as string,
    options: {
      reconnect: true,
    },
  })
}

export const getClient = (networkId: NetworkId | -1) => {
  const httpLink = getHttpLink(networkId)
  const wsLink = getWsLink(networkId)

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  return new ApolloClient({
    link,
    cache: new InMemoryCache({ fragmentMatcher }),
  })
}
