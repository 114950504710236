import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #fff;
  border-radius: 13px;
  border: 1px solid ${props => props.theme.borders.borderColor};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  padding: 10px 15px;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const ModalInnerCard: React.FC<Props> = (props: Props) => {
  const { children, ...restProps } = props

  return <Wrapper {...restProps}>{children}</Wrapper>
}
