import { providers } from 'ethers'
import { NetworkId, NetworkIds } from '../../utils'

export enum WalletOptions {
  MetaMask = 'MetaMask',
}
export enum ReadOnlyConnectors {
  Infura = 'Infura',
}

export enum Connectors {
  MetaMask = 'MetaMask',
  Infura = 'Infura',
}

export enum ConnectionState {
  DISCONNECTED = 'Disconnected',
  CONNECTED = 'Connected',
  CONNECTING = 'Connecting',
}

export type ConnectionData = {
  networkId: NetworkId | -1
  networkName: string
  account: string
}

export type Connection = {
  state: ConnectionState
  isConnected: boolean
  isConnecting: boolean
  isDisconnected: boolean
  account: Maybe<string>
  networkId: NetworkIds | -1
  networkName: Maybe<string>
  readOnly: boolean
  connectReadOnly: () => void
  connectWallet: (selectedOption?: WalletOptions) => void
  cleanError: () => void
  error: Maybe<Error>
  library: Maybe<providers.Web3Provider>
  web3Context: any
}
