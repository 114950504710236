import { useState, useEffect } from 'react'
import { useConnection } from '../components/web3'
import { isValidNetworkId, getToken, BigFloat } from '../utils'
import useOracle from './useOracle'
import { oToken, OracleService } from '../services'

import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'

import { GetOTokenToSell } from '../types/generatedGQL'
import Maybe from 'graphql/tsutils/Maybe'

const OTOKEN_TO_SELL = loader('../queries/oToken_to_sell.graphql')

const getCollateralToStrikePrice = async (
  oracleContract: Maybe<OracleService>,
  strikeAddress: Maybe<string>,
): Promise<BigFloat> => {
  if (!oracleContract || !strikeAddress) {
    return {
      value: 0,
    }
  }

  const value = await oracleContract.getPrice(strikeAddress)
  // const value = (Number(await getTokenPriceCoingecko(strikeAddress)) * 1e18).toString()

  return {
    value,
    decimals: 18,
  }
}

const useOToken = (id: KnownToken) => {
  const { library, isConnected, networkId, account } = useConnection()
  const token = isValidNetworkId(networkId) ? getToken(networkId, id as KnownToken) : null
  const [loading, setLoading] = useState(true)
  const [service, setService] = useState<oToken | null>(null)
  const [formatedData, setFormatedData] = useState<any>(null)
  const oracleContract = useOracle()

  const { loading: queryLoading, data, refetch } = useQuery<GetOTokenToSell>(OTOKEN_TO_SELL, {
    variables: { underlying: token?.address, account },
    skip: !account || !token,
  })

  useEffect(() => {
    let isCancelled = false

    if (
      isConnected &&
      library &&
      isValidNetworkId(networkId) &&
      !queryLoading &&
      data &&
      data.optionsContracts.length &&
      oracleContract
    ) {
      setService(new oToken(library, account, data.optionsContracts[0].address))
      setLoading(true)
      getCollateralToStrikePrice(oracleContract, data.optionsContracts[0].strike).then(collateralToStrikePrice => {
        if (!isCancelled) {
          setFormatedData({ ...data.optionsContracts[0], collateralToStrikePrice })
          setLoading(false)
        }
      })
    }

    return () => {
      isCancelled = true
    }
  }, [isConnected, library, networkId, account, queryLoading, data, oracleContract])

  return { service, data: formatedData, loading, refetch }
}

export default useOToken
