import { GetOptionsContractsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { CompoundBalance, BigFloat, Token } from './types'
import { Big, BigSource } from 'big.js'
import { BigNumber } from 'ethers/utils'
import { getProp, sum } from './tools'
import { oToken } from 'services'
import { Web3Provider } from 'ethers/providers'

export const getMaxLoss = (
  balance: CompoundBalance,
  currentOption?: GetOptionsContractsByUnderlying_optionsContracts,
  oTokenBalance?: string,
): BigFloat => {
  if (!currentOption || !balance.amount || !balance.token?.decimals || !balance.cToken?.decimals) {
    return {
      value: 0,
    }
  }

  let totalOToken: Big
  // if there are no protected cTokens (user does not own oTokens) we calculate as if he were to protect the complete amount of cTokens
  if (!oTokenBalance || oTokenBalance === '0') {
    totalOToken = new Big(balance.amount.toString())
      .div(`1e${balance.cToken?.decimals}`)
      .div(`1e${currentOption.oTokenExchangeRateExp}`)
  } else {
    totalOToken = new Big(oTokenBalance)
  }

  // bigCToken * exchangeRate/1e18 = bigToken
  // cToken * (10 ** cTokenDecimals) * exchangeRate/1e18 = token * (10 ** tokenDecimals)
  // i.e.
  // cUSDC * 1e8 * exR/1e18 = USDC * 1e6
  // cUSDC * exR * 1e8 * 1e-18 * 1e-6 = USDC
  // cUSDC * exR * 1e-16 = USDC

  // 1 oToken = (10 ** oTokenExchangeRateExp) cToken
  const oTokenExchangeRate = new Big(`1e${currentOption.oTokenExchangeRateExp}`)

  const totalCTokens = totalOToken.mul(oTokenExchangeRate)
  const totalInCompound = totalCTokens
    .mul(new Big(balance.exchangeRate.toString()))
    .mul(`1e-${balance.cToken.exchangeRateDecimals}`)

  const strikePricePerToken = new Big(currentOption.strikePriceValue).times(`1e${currentOption.strikePriceExp}`)
  const actualValueProtected = totalOToken.times(strikePricePerToken)

  const maxLoss = totalInCompound
    .minus(actualValueProtected) // protectedValue is in Underlying asset, actualValueProtected in Strike asset and as we assume 1 Dai = $1 = 1 USDC, it's safe to do DAI - USDC
    .mul(1000)

  return {
    value: new BigNumber(maxLoss.toFixed(0)),
    decimals: 3,
  }
}

interface MaxOptionsToCreate {
  collateralToAdd: BigSource
  strikePriceValue: BigSource
  strikePriceExp: BigSource
  collateralToStrikePrice: BigFloat
  addedCollateral?: BigSource
  mintedOTokens?: BigSource
  ratio?: number
}

export const calculateMaxOptionsToCreate = ({
  collateralToAdd,
  strikePriceValue,
  strikePriceExp,
  collateralToStrikePrice,
  addedCollateral = 0,
  mintedOTokens = 0,
  ratio = 2,
}: MaxOptionsToCreate) => {
  const collateral = new Big(collateralToAdd.toString()).plus(addedCollateral.toString())
  const collateralToStrikeRatio = new Big(1).div(
    new Big(new BigNumber(collateralToStrikePrice.value).toString()).times(`1e-${collateralToStrikePrice.decimals}`),
  )
  const strikePrice = new Big(strikePriceValue).times(`1e${strikePriceExp}`)
  const max = collateral
    .times(collateralToStrikeRatio)
    .div(strikePrice.times(ratio))
    .minus(new Big(mintedOTokens.toString()))

  Big.RM = 0
  return new BigNumber(max.toFixed(0))
}

interface CurrentRatio {
  addedCollateral: BigSource
  strikePriceValue: BigSource
  strikePriceExp: BigSource
  collateralToStrikePrice: BigFloat
  mintedOTokens: BigSource
}

export const calculateCurrentRatio = ({
  addedCollateral,
  strikePriceValue,
  strikePriceExp,
  collateralToStrikePrice,
  mintedOTokens,
}: CurrentRatio) => {
  if (Number(mintedOTokens) === 0) {
    return Number(0)
  }
  const collateral = new Big(addedCollateral.toString())

  const colTostrikePrice = BigInt(Number(collateralToStrikePrice.value))

  const collateralToStrikeRatio = new Big(1).div(
    new Big(new BigNumber(colTostrikePrice.toString()).toString()).times(`1e-${collateralToStrikePrice.decimals}`),
  )

  const strikePrice = new Big(strikePriceValue).times(`1e${strikePriceExp}`)
  const ratio =
    strikePrice.eq(0) || new Big(mintedOTokens).eq(0)
      ? 0
      : collateral.times(collateralToStrikeRatio).div(strikePrice.times(mintedOTokens))

  return Number(ratio.toFixed(2))
}

export const getOTokenSymbol = (token: Maybe<Token>) => {
  if (!token) {
    return null
  }
  return !token.cToken ? `o${token.symbol.toUpperCase()}` : `o${token.symbol[0].toLowerCase()}${token.symbol.slice(1)}`
}

export const minConllateralization = (option?: { minCollateralizationRatioValue: number }) => {
  return {
    value: !option ? '--' : `min ${option.minCollateralizationRatioValue * 10}%`,
  }
}

export const getExercised = (option: any) => {
  if (option && option?.vaults?.length) {
    const [vault] = option.vaults
    const { underlying } = vault
    const exerciseActions = vault?.actions?.filter((action: any) => action.__typename === 'ExerciseAction') || []

    if (new BigNumber(underlying).gt(0) && exerciseActions.length) {
      return {
        exerciseActions,
        totalUnderlyingToPay: exerciseActions.map(getProp('amtUnderlyingToPay')).reduce(sum, 0),
        exercised: true,
      }
    }
  }

  return {
    exerciseActions: [],
    totalUnderlyingToPay: 0,
    exercised: false,
  }
}

export const getIsExpired = async (library: Maybe<Web3Provider>, address?: Maybe<string>) => {
  if (!library || !address) {
    return false
  }

  const service = new oToken(library, '', address)

  try {
    return service.hasExpired()
  } catch {
    return false
  }
}
