import { useConnection } from 'components/web3'
import { getTokenPrice } from 'utils'
import useOracle from './useOracle'
import useAsyncMemo from './useAsyncMemo'

const useTokenPrice = (fromTokenAddress?: string, toTokenAddress = 'usdc'): string => {
  const { networkId } = useConnection()
  const oracleContract = useOracle()

  return useAsyncMemo(
    async () => {
      return getTokenPrice(networkId, oracleContract, fromTokenAddress, toTokenAddress)
    },
    '0',
    [networkId, oracleContract, fromTokenAddress, toTokenAddress],
  )
}

export default useTokenPrice
