import React from 'react'
import { Wrapper, InnerContainerStyled, Menu, Item, SocialIconsStyled } from '../../../../components/Common/Footer'

export const Footer: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Menu>
          <Item href="https://opyn.gitbook.io/opyn/" target="_blank">
            Developers
          </Item>
          <Item href="https://opyn.gitbook.io/opynv1/faq" target="_blank">
            FAQ
          </Item>
          <Item href="https://opyn.gitbook.io/opyn/security" target="_blank">
            Security
          </Item>
          <Item href="http://convexity.opyn.co" target="_blank">
            Whitepaper
          </Item>
          <Item href="https://airtable.com/shrGVINGvmJaGUIZj" target="_blank">
            Get Updates
          </Item>
          <Item href="https://defipulse.com" target="_blank">
            DeFi Pulse
          </Item>
        </Menu>
        <SocialIconsStyled />
      </InnerContainerStyled>
    </Wrapper>
  )
}
