import { Contract, ethers, Wallet } from 'ethers'
import { BigNumber } from 'ethers/utils'

const optionsExchangeABI = [
  'function premiumToPay(address oTokenAddress, address paymentTokenAddress, uint256 oTokensToBuy) public view returns (uint256)',
  'function premiumReceived(address oTokenAddress, address payoutTokenAddress, uint256 oTokensToSell) public view returns (uint256)',
  'function buyOTokens(address receiver, address oTokenAddress, address paymentTokenAddress, uint256 oTokensToBuy) public payable',
  'function sellOTokens(address receiver, address oTokenAddress, address payoutTokenAddress, uint256 oTokensToSell) public',
]

class OptionsExchange {
  contractAddress: string
  provider: any
  contract: Contract

  constructor(provider: any, signerAddress: Maybe<string>, contractAddress: string) {
    this.contractAddress = contractAddress
    this.provider = provider
    if (signerAddress) {
      const signer: Wallet = provider.getSigner()
      this.contract = new ethers.Contract(contractAddress, optionsExchangeABI, provider).connect(signer)
    } else {
      this.contract = new ethers.Contract(contractAddress, optionsExchangeABI, provider)
    }
  }

  getPremiumToPay = (
    oTokenAddress: string,
    paymentTokenAddress: string,
    oTokensToBuy: number | string,
  ): Promise<BigNumber> => {
    return this.contract.premiumToPay(oTokenAddress, paymentTokenAddress, oTokensToBuy)
  }

  getPremiumReceived = (
    oTokenAddress: string,
    payoutTokenAddress: string,
    oTokensToSell: number,
  ): Promise<BigNumber> => {
    return this.contract.premiumReceived(oTokenAddress, payoutTokenAddress, oTokensToSell)
  }

  buyOTokens = (
    receiver: string,
    oTokenAddress: string,
    paymentTokenAddress: string,
    oTokensToBuy: string,
    overrides?: any,
  ) => {
    const defaultOverrides = {
      // gasPrice: 70000000000,
    }

    return this.contract.buyOTokens(
      receiver,
      oTokenAddress,
      paymentTokenAddress,
      oTokensToBuy,
      // overrides,
      overrides ? { ...defaultOverrides, ...overrides } : defaultOverrides,
    )
  }

  sellOTokens = (receiver: string, oTokenAddress: string, payoutTokenAddress: string, oTokensToSell: string) => {
    return this.contract.sellOTokens(receiver, oTokenAddress, payoutTokenAddress, oTokensToSell)
  }
}

export default OptionsExchange
