import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

const Value = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

interface Props {
  title: any
  value: any
}

export const DetailRow: React.FC<Props> = props => {
  const { title, value, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Wrapper>
  )
}
