import React from 'react'
import styled from 'styled-components'

const InnerContainerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 10px;
  width: ${props => props.theme.themeBreakPoints.xxl};

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    padding: 0 ${props => props.theme.paddings.mainPadding};
  }
`

export const InnerContainer: React.FC = props => {
  const { children, ...restProps } = props

  return <InnerContainerWrapper {...restProps}>{children}</InnerContainerWrapper>
}
