import React from 'react'
import { Numeric, isBigFloat } from '../../../utils'
import { BigNumber, formatUnits } from 'ethers/utils'
import { Big } from 'big.js'

export interface ValueProps {
  value?: Maybe<Numeric>
  decimals?: number
  precision?: number
  placeholder?: any
  children?(value: string): void
  trailingZeros?: boolean
}

const toPrecision = (num: number, precision: number) => {
  const exp = Math.pow(10, precision)

  return new Big(Math.round(num * exp) / exp).toFixed()
}

export const getValue = (
  value: Maybe<Numeric>,
  { decimals = 0, precision = 2, placeholder = '--', trailingZeros = false }: ValueProps = {},
) => {
  if (placeholder && value === null) {
    return placeholder
  }

  let formattedValue

  const format = trailingZeros
    ? (val: number, precision: number) => Number.prototype.toFixed.call(val, precision)
    : toPrecision

  if (typeof value === 'number') {
    formattedValue = format(value, precision)
  } else if (BigNumber.isBigNumber(value)) {
    formattedValue = format(Number(formatUnits(value, decimals)), precision)
  } else if (isBigFloat(value)) {
    let requiredDecimals = 18 - Number(value?.decimals)
    let bigValue = BigInt(Number(value.value) * 10 ** requiredDecimals)
    formattedValue = format(Number(formatUnits(new BigNumber(bigValue.toString()), 18 || 0)), precision)
  } else {
    formattedValue = `${value}`
  }

  return formattedValue
}

const Value: React.FC<ValueProps> = ({
  value = null,
  decimals = 0,
  placeholder = '--',
  children,
  precision = 2,
  trailingZeros = false,
}: ValueProps) => {
  const formattedValue = getValue(value, {
    decimals,
    precision,
    placeholder,
    trailingZeros,
  })

  return children ? children(formattedValue) : formattedValue
}

export default Value
