import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import BackSVG from './img/back.svg'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  transition: opacity 0.15s ease-out;
  width: 40px;

  &:active {
    opacity: 0.5;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.2;
  }
`

const BackImg = styled.img`
  display: block;
`

export const ButtonBack: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = props => {
  const { disabled, onClick, ...restProps } = props
  const history = useHistory()

  return (
    <Wrapper disabled={disabled} onClick={onClick || history.goBack} {...restProps}>
      <BackImg src={BackSVG} alt="" />
    </Wrapper>
  )
}
