import React from 'react'
import styled, { css } from 'styled-components'
import { MainMenuWrapper } from 'components/Common/MainMenu'

const Link = styled.a`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  color: ${props => props.theme.header.color};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  margin: 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    height: ${props => props.theme.header.height};
    justify-content: flex-start;
    margin: 0 0 0 15px;
    padding: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:active {
      opacity: 1;
    }
  }

  ${({ isActive }: { isActive?: boolean }) => {
    if (isActive) {
      return css`
        color: ${props => props.theme.colors.secondary};
      `
    }
  }}
`

interface MenuProps {
  sellClickHandler: () => void
  isSell: boolean
}

const MainMenu = (props: MenuProps) => {
  // const { isSell, sellClickHandler } = props

  return (
    <MainMenuWrapper {...props}>
      {/* <Link isActive={isSell} onClick={sellClickHandler}>
        Sell Protection
      </Link> */}
      <Link href={'https://opyn.gitbook.io/opyn'} target="_blank">
        Develop
      </Link>
      <Link href={'https://airtable.com/shrGVINGvmJaGUIZj'} target="_blank">
        Get Updates
      </Link>
      <Link href={'https://opyn.gitbook.io/opyn/security'} target="_blank">
        Security
      </Link>
      <Link href={'https://opyn.co'} target="_blank">
        Opyn v2
      </Link>
    </MainMenuWrapper>
  )
}
export default MainMenu
