import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { getUnixTime, differenceInDays, fromUnixTime } from 'date-fns'

import {
  CompoundTokens,
  getTokensByNetwork,
  Token,
  NetworkId,
  CompoundBalance,
  isValidNetworkId,
  BigFloat,
  getToken,
  convertOTokenToERC20,
  convertBigFloatToBig,
  BuyerCompoundOptionData,
  getTokenPriceCoingecko,
} from 'utils'
import {
  GetOptionsContractsByUnderlying,
  GetOptionsContractsByUnderlying_optionsContracts,
  GetOptionsContractsByUnderlying_optionsContracts_holdersBalances,
} from 'types/generatedGQL'
import { useConnection } from 'components/web3'
import { useBalances } from './useBalances'
import { COMPOUND_TOKENS, APR_DECIMALS } from 'common/constants'
import { BigNumber } from 'ethers/utils'
import { Big } from 'big.js'
import { OptionsExchange, OracleService } from 'services'
import useOracle from './useOracle'
import { getMaxLoss } from 'utils/options'
import { Web3Provider } from 'ethers/providers'

const OPTION_CONTRACT_BY_UNDERLYING_QUERY = loader('../queries/options_contracts_by_underlying.graphql')
const OPTION_CONTRACT_BY_UNDERLYING_SUBSCRIPTION = loader(
  '../queries/options_contracts_by_underlying.subscription.graphql',
)

const getCompoundTokens = (networkId: number, requiredTokens?: Array<CompoundToken>): Array<Token> =>
  getTokensByNetwork(networkId).filter((token: Token) =>
    (requiredTokens || COMPOUND_TOKENS).includes(token.symbol.toLocaleLowerCase() as CompoundToken),
  )

const getOTokenBalance = (
  options: GetOptionsContractsByUnderlying_optionsContracts,
  account: Maybe<string>,
): Maybe<GetOptionsContractsByUnderlying_optionsContracts_holdersBalances> => {
  return (
    (options &&
      options.holdersBalances?.find(
        balance => balance.account.address.toLocaleLowerCase() === account?.toLocaleLowerCase(),
      )) ||
    null
  )
}

interface OptionAndBalance {
  option: GetOptionsContractsByUnderlying_optionsContracts
  oTokenBalance: Maybe<GetOptionsContractsByUnderlying_optionsContracts_holdersBalances>
}
interface CurrentOptionAndBalance {
  currentOption: GetOptionsContractsByUnderlying_optionsContracts
  allBuyerOptions: OptionAndBalance[]
}

const getOptionWithBalance = (account: Maybe<string>) => (
  option: GetOptionsContractsByUnderlying_optionsContracts,
): OptionAndBalance => ({
  option,
  oTokenBalance: getOTokenBalance(option, account),
})

const optionProtects = (tokenAddress: string) => (option: GetOptionsContractsByUnderlying_optionsContracts) =>
  option.underlying.toLowerCase() === tokenAddress.toLowerCase()

const sortDecExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(b.expiry) - parseInt(a.expiry)
  const byTimestamp = parseInt(b.timestamp) - parseInt(a.timestamp)

  return byExpiry ? byExpiry : byTimestamp
}

const getBuyerOptionsWithBalances = (
  token: Token,
  optionData: GetOptionsContractsByUnderlying,
  account: Maybe<string>,
  useAccount: boolean,
): CurrentOptionAndBalance => {
  const allOptions: GetOptionsContractsByUnderlying_optionsContracts[] = optionData.optionsContracts
    .filter(optionProtects(token.address))
    .sort(sortDecExpiry)

  const [currentOption] = allOptions

  if (account && useAccount) {
    const allBuyerOptions = allOptions
      .map(getOptionWithBalance(account))
      .filter(optionsWithBalance => !!optionsWithBalance.oTokenBalance)

    return {
      currentOption,
      allBuyerOptions,
    }
  }

  return { currentOption, allBuyerOptions: [] }
}

interface AmountInsuredAndPremiumAprParams {
  numberOfDays: number
  paymentTokenToUSDCRate: Big
  cTokenUnderlyingToUSDCRate: Big
  oTokens: string
  premiumToPay: string
  oTokenExchangeRateExp: string
  cTokenExchangeRate: string
  cTokenExchangeRateDecimals: number
}

const getAmountInsuredAndPremiumAPR = ({
  numberOfDays,
  paymentTokenToUSDCRate,
  cTokenUnderlyingToUSDCRate,
  oTokens,
  premiumToPay,
  oTokenExchangeRateExp,
  cTokenExchangeRate,
  cTokenExchangeRateDecimals,
}: AmountInsuredAndPremiumAprParams) => {
  const amountCTokens = new Big(oTokens).times(`1e${oTokenExchangeRateExp}`)
  const scaledExchangeRate = new Big(cTokenExchangeRate).times(`1e-${cTokenExchangeRateDecimals}`)
  const $amountInCompound = scaledExchangeRate.times(amountCTokens).times(cTokenUnderlyingToUSDCRate)
  const $premium = new Big(premiumToPay).times(1e-18).times(paymentTokenToUSDCRate)

  const $premiumAPR = $premium
    .times(365)
    .times(100)
    .div(numberOfDays)
    .div($amountInCompound)

  return {
    $premiumAPR,
    $amountInCompound,
  }
}

const getInsuredAPR = async ({
  networkId,
  account,
  library,
  oracleContract,
  optionsWithBalances,
  currentOption,
  balance,
}: {
  networkId: number
  account?: Maybe<string>
  library?: Maybe<Web3Provider>
  oracleContract?: Maybe<OracleService>
  optionsWithBalances: OptionAndBalance[]
  currentOption: Maybe<GetOptionsContractsByUnderlying_optionsContracts>
  balance: CompoundBalance
}): Promise<BigFloat> => {
  const { uninsuredAPR, exchangeRate, cToken, token } = balance

  if (!isValidNetworkId(networkId) || !oracleContract || !token || !currentOption || !exchangeRate || !library) {
    return {
      value: uninsuredAPR?.value || 0,
      ...(uninsuredAPR?.decimals && { decimals: uninsuredAPR.decimals }),
    }
  }

  if (!optionsWithBalances.length) {
    account = account ? account : null
    const optionsExchangeContract = new OptionsExchange(library, account, currentOption.optionsExchangeAddress)
    const usdcAddress = getToken(networkId, 'usdc').address
    // const ethToUsdcPrice = await oracleContract.getPrice(usdcAddress)
    const ethToUsdcPrice = await getTokenPriceCoingecko(usdcAddress)
    // const ethToTokenPrice = await oracleContract.getPrice(token.address)
    const ethToTokenPrice = await getTokenPriceCoingecko(token.address)
    let premiumToPayInWei = new BigNumber(0)
    try {
      premiumToPayInWei = await optionsExchangeContract?.getPremiumToPay(
        currentOption.address,
        '0x0000000000000000000000000000000000000000',
        1,
      )
    } catch {
      // Do nothing
    }

    const { $premiumAPR } = getAmountInsuredAndPremiumAPR({
      numberOfDays: differenceInDays(fromUnixTime(currentOption.expiry), new Date()),
      paymentTokenToUSDCRate: new Big(1).div(new Big(ethToUsdcPrice.toString()).times(1e-18)), //
      cTokenUnderlyingToUSDCRate: new Big(ethToTokenPrice.toString()).div(new Big(ethToUsdcPrice.toString())),
      oTokens: '1',
      premiumToPay: premiumToPayInWei.toString(),
      oTokenExchangeRateExp: currentOption.oTokenExchangeRateExp,
      cTokenExchangeRate: exchangeRate.toString(),
      cTokenExchangeRateDecimals: cToken?.exchangeRateDecimals || 0,
    })
    const scaledPremiumToPayAPR = $premiumAPR.times(`1e${APR_DECIMALS}`).toFixed(0)

    const rawInsuredYield = new BigNumber(uninsuredAPR.value).sub(scaledPremiumToPayAPR)

    return {
      value: rawInsuredYield,
      decimals: APR_DECIMALS,
    }
  } else {
    const [num, den] = optionsWithBalances
      .flatMap(
        ({ option }) =>
          option.buyActions?.map(buyAction =>
            getAmountInsuredAndPremiumAPR({
              numberOfDays: differenceInDays(fromUnixTime(option.expiry), fromUnixTime(buyAction.timestamp)),
              paymentTokenToUSDCRate: new Big(buyAction.paymentTokenPrice).div(new Big(buyAction.usdcPrice)),
              cTokenUnderlyingToUSDCRate: new Big(buyAction.cTokenUnderlyingPrice).div(new Big(buyAction.usdcPrice)),
              oTokens: buyAction.oTokensToBuy,
              premiumToPay: buyAction.premiumPaid,
              oTokenExchangeRateExp: option.oTokenExchangeRateExp,
              cTokenExchangeRate: buyAction.exchangeRateCurrent,
              cTokenExchangeRateDecimals: cToken?.exchangeRateDecimals || 0,
            }),
          ) || [],
      )
      .reduce(
        ([num, den], item) => [
          num.plus(item.$premiumAPR.times(item.$amountInCompound)),
          den.plus(item.$amountInCompound),
        ],
        [new Big(0), new Big(1)],
      )

    const totalPremiumPaidAPR = num.div(den)
    const scaledPremiumToPayAPR = totalPremiumPaidAPR.times(`1e${APR_DECIMALS}`).toFixed(0)
    const rawInsuredYield = new BigNumber(uninsuredAPR.value).sub(scaledPremiumToPayAPR)

    return {
      value: rawInsuredYield,
      decimals: APR_DECIMALS,
    }
  }
}

const getInsured = (optionsWithBalances: OptionAndBalance[], balance: CompoundBalance) => {
  if (
    !balance.amountInCompound ||
    new Big(balance.amountInCompound.toString()).eq(0) ||
    !balance.token?.decimals ||
    !balance.cToken?.decimals ||
    !balance?.exchangeRate
  ) {
    return 0
  }

  const insuredERC20Amount: Big = optionsWithBalances.reduce((acc, { option, oTokenBalance }) => {
    const ERC20Balance = convertOTokenToERC20(
      oTokenBalance?.amount || 0,
      balance.cToken,
      option.oTokenExchangeRateValue,
      option.oTokenExchangeRateExp,
      balance.exchangeRate,
      balance.token,
    )
    return acc.plus(convertBigFloatToBig(ERC20Balance))
  }, new Big(0))

  const bAmountInCompound = new Big(balance.amountInCompound.toString()).times(`1e${-balance.token.decimals}`)

  const insuredRatio = insuredERC20Amount.div(bAmountInCompound)

  return insuredRatio.lt(1) ? insuredRatio.round(7).toFixed() : 1
}

const getFormattedOptionsData = async (
  options: GetOptionsContractsByUnderlying,
  balances: Array<any>,
  networkId: NetworkId,
  account: Maybe<string>,
  useAccount: boolean,
  requiredTokens?: Array<CompoundToken>,
  oracleContract?: Maybe<OracleService>,
  library?: Maybe<Web3Provider>,
): Promise<Record<CompoundTokens, BuyerCompoundOptionData>> => {
  const tokens = getCompoundTokens(networkId, requiredTokens).filter((token: Token) => token.cToken)
  const getOptionsData = async (token: Token): Promise<BuyerCompoundOptionData> => {
    const { currentOption, allBuyerOptions } = getBuyerOptionsWithBalances(token, options, account, useAccount)
    const [balance] = balances.filter(b => b.id.toLocaleUpperCase() === token.symbol)

    const oTokenBalance =
      account &&
      allBuyerOptions.reduce((acc, { oTokenBalance }) => {
        return {
          ...acc,
          amount: new Big(acc.amount || 0).plus(oTokenBalance?.amount || 0).toString(),
        }
      }, {} as GetOptionsContractsByUnderlying_optionsContracts_holdersBalances)

    const insured = account && getInsured(allBuyerOptions, balance)

    const maxLoss =
      account &&
      allBuyerOptions.reduce(
        (acc: BigFloat, { option, oTokenBalance }) => {
          const partialMaxLoss = getMaxLoss(balance, option, oTokenBalance?.amount || '0')

          if (!!acc || !partialMaxLoss) {
            return {
              value: 0,
              decimals: 0,
            }
          }

          return {
            value: convertBigFloatToBig(acc)
              .plus(convertBigFloatToBig(partialMaxLoss))
              .times(`1e${partialMaxLoss.decimals || 0}`)
              .toFixed(),
            decimals: partialMaxLoss.decimals,
          }
        },
        {
          value: 0,
          decimals: 0,
        },
      )

    const insuredAPR = await getInsuredAPR({
      networkId,
      account,
      library,
      oracleContract,
      optionsWithBalances: allBuyerOptions,
      currentOption,
      balance,
    })

    return {
      option: currentOption,
      balance,
      oTokenBalance,
      insured,
      insuredAPR,
      maxLoss,
    } as BuyerCompoundOptionData
  }

  return await tokens.reduce(async (acc, token: Token) => {
    const previousTokens = await acc

    return {
      ...previousTokens,
      [token.symbol.toLocaleLowerCase()]: await getOptionsData(token),
    }
  }, Promise.resolve({} as CompoundOptions))
}

const initializeCompoundOptions = (requiredTokens?: Array<CompoundToken>) => {
  return (requiredTokens || COMPOUND_TOKENS).reduce((acc, t) => {
    return {
      ...acc,
      [t]: {} as BuyerCompoundOptionData,
    }
  }, {} as CompoundOptions)
}

type CompoundOptions = Record<CompoundTokens, BuyerCompoundOptionData>

interface HookProps {
  requiredTokens?: Array<CompoundToken>
  useAccount?: boolean
}

export const useBuyerCompoundOptions = (props?: HookProps) => {
  const { requiredTokens, useAccount = true } = props || {}
  const { networkId, account, library } = useConnection()
  const [balances, balancesLoading, reloadBalances] = useBalances(requiredTokens || COMPOUND_TOKENS)
  const [underlyings, setUnderlyings] = useState<Array<string>>([])
  const [loading, setLoading] = useState(true)
  const { data: options, error: optionsError, subscribeToMore } = useQuery<GetOptionsContractsByUnderlying>(
    OPTION_CONTRACT_BY_UNDERLYING_QUERY,
    {
      variables: { underlyings, now: getUnixTime(Date.now()), account: account || '' },
    },
  )
  const [compoundOptions, setCompoundOptions] = useState<CompoundOptions>(initializeCompoundOptions())
  const oracleContract = useOracle()

  useEffect(() => {
    if (isValidNetworkId(networkId)) {
      setUnderlyings(() => {
        return getCompoundTokens(networkId, requiredTokens).map(({ address }) => address)
      })
    }
  }, [networkId, requiredTokens])

  useEffect(() => {
    if (underlyings.length && subscribeToMore) {
      return subscribeToMore({
        document: OPTION_CONTRACT_BY_UNDERLYING_SUBSCRIPTION,
        variables: { underlyings, now: getUnixTime(Date.now()), account: account || '' },
        updateQuery: (_prev, { subscriptionData }) => subscriptionData.data,
      })
    }
  }, [underlyings, account, subscribeToMore])

  useEffect(() => {
    let isCancelled = false
    if (isValidNetworkId(networkId) && !balancesLoading && !optionsError && balances.length && options) {
      setLoading(true)
      getFormattedOptionsData(
        options,
        balances,
        networkId,
        account,
        useAccount,
        requiredTokens,
        oracleContract,
        library,
      ).then(compoundOptions => {
        if (!isCancelled) {
          setCompoundOptions(compoundOptions)
          setLoading(false)
        }
      })
    }

    return () => {
      isCancelled = true
    }
  }, [
    library,
    balances,
    oracleContract,
    balancesLoading,
    options,
    optionsError,
    networkId,
    account,
    useAccount,
    requiredTokens,
  ])

  return {
    compoundOptions,
    loading,
    reload: reloadBalances,
  }
}
