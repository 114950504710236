import React, { useCallback, useState, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Card } from '../../components/Common/Card'
import { Button } from '../../components/Common/Button'
import { TokenIcon } from '../../components/Common/TokenIcon'
import { TextWithTooltip } from '../../components/Common/TextWithTooltip'
import { InsuranceProgressBar } from '../InsuranceProgressBar'
import {
  ThemeColorTypes,
  ThemeColorTypesInverted,
  BuyerCompoundOptionData,
  compoundTokenMap,
  CompoundTokens,
  ETH_TOKEN,
  etherscanTx,
  addToLocalStorageArray,
  convertBigFloatToBig,
  BuyOTokenAction,
} from '../../utils'
import { BuyInsuranceModal } from '../Modals/BuyInsuranceModal'
import { ConfirmModal } from '../Modals/ConfirmModal'
import { MakeInsuredDepositModal } from '../Modals/MakeInsuredDepositModal'
import Percentage from '../Common/Percentage'
import { useConnection } from '../web3'
import { formatDistanceToNow, fromUnixTime } from 'date-fns'
import { BigNumber } from 'ethers/utils'
import useAsyncMemo from '../../hooks/useAsyncMemo'
import useOptionsExchange from '../../hooks/useOptionsExchange'
import useUniswapFactory from '../../hooks/useUniswapFactory'
import { useERC20Contract } from '../../hooks/useERC20Contract'
import { FailedTransactionModal } from '../Modals/FailedTransactionModal'
import { Big } from 'big.js'
import DoubleBalance from '../Common/DoubleBalance'
import TokenBalance from '../Common/TokenBalance'

const TokenTitle = styled(TokenIcon)`
  margin-bottom: 15px;

  > p {
    font-size: 20px;
    font-weight: 600;
  }
`

const Rows = styled.div`
  margin: 0 0 10px;
`

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Title = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

const Token = styled.span`
  text-transform: uppercase;
`

const getButtonsContainersColumns = (buttonsAmount: number): string => {
  let template = ''

  for (let count = 0; count < buttonsAmount; count++) {
    template += '1fr '
  }

  return template
}

const ButtonsContainer = styled.div<{ buttonsAmount: number }>`
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    grid-template-columns: ${props => getButtonsContainersColumns(props.buttonsAmount)};
  }
`

interface TokenCardProps extends HTMLAttributes<HTMLDivElement> {
  insuranceData: BuyerCompoundOptionData
  tokenCardStyle?: ThemeColorTypes
  tokenCardStyleInverted?: ThemeColorTypesInverted
  cTokenId: string
  totalPaid: Maybe<Big>
  reload?(): void
}

export const TokenCard: React.FC<TokenCardProps> = (props: TokenCardProps) => {
  const { insuranceData, cTokenId, tokenCardStyle = ThemeColorTypes.primary, totalPaid, reload, ...restProps } = props
  const { connectWallet, readOnly, account, networkId } = useConnection()
  const { option, balance, insured, insuredAPR, maxLoss } = insuranceData
  const { token, amountInCompound, exchangeRate } = balance || {}

  const $amountInCompound = new Big(new BigNumber(amountInCompound || 0).toString())
    .times(`1e-${token?.decimals || 0}`)
    .round(2)
    .toFixed()

  const bMaxLoss = convertBigFloatToBig(maxLoss)

  const [isBuyInsuranceModalOpen, setBuyInsuranceModalState] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [transactionFailed, setTransactionFailed] = useState(false)
  const [isMakeInsuredDepositModalOpen, setMakeInsuredDepositModalState] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')

  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const oTokenERC20Contract = useERC20Contract(option?.address)
  const uniswapFactoryContract = useUniswapFactory()

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)

        const oTokenBalance =
          (await oTokenERC20Contract?.getBalanceOf(exchangeAddress)) / Number(10 ** -option.oTokenExchangeRateExp)

        let rounding = oTokenBalance > 100 ? 0 : 2

        const displayAvailabeTokens = oTokenBalance.toFixed(rounding)

        return displayAvailabeTokens.toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  const oTokenSymbol = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract) {
        return await oTokenERC20Contract?.symbol()
      }
      return ''
    },
    '',
    [oTokenERC20Contract],
  )

  const handleConnectButton = () => connectWallet()
  const closeBuyInsuranceModal = useCallback(() => setBuyInsuranceModalState(false), [])
  const openBuyInsuranceModal = useCallback(() => setBuyInsuranceModalState(true), [])
  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])

  const openConfirmModal = useCallback(
    (oTokensToBuy, paymentTokenAddress, bigPremiumToPay, paymentTokenPrice, usdcPrice) => {
      if (optionsExchangeContract && account && option?.address && paymentTokenAddress && oTokensToBuy) {
        let overrides
        if (paymentTokenAddress === ETH_TOKEN.address) {
          overrides = {
            value: bigPremiumToPay,
          }
        }

        const tx = optionsExchangeContract?.buyOTokens(
          account,
          option?.address,
          paymentTokenAddress,
          oTokensToBuy,
          overrides,
        )

        tx.then((tx: any) => {
          addToLocalStorageArray('buy-transactions', {
            transactionHash: tx.hash,
            timestamp: Math.round(Date.now() / 1000).toString(),
            oTokensToBuy: Math.trunc(oTokensToBuy).toString(),
            exchangeRateCurrent: new BigNumber(exchangeRate || 0).toString(),
            paymentTokenAddress,
            premiumPaid: bigPremiumToPay.toString(),
            paymentTokenPrice,
            usdcPrice,
            token: {
              oTokenExchangeRateValue: option.oTokenExchangeRateValue,
              oTokenExchangeRateExp: option.oTokenExchangeRateExp,
            },
            pending: true,
          } as BuyOTokenAction)

          closeBuyInsuranceModal()
          setConfirmModalState(true)
          setRecentTransactionHash(tx.hash)
          tx.wait().then(closeConfirmModal)
        }).catch(() => setTransactionFailed(true))
      }
    },
    [closeBuyInsuranceModal, account, option, optionsExchangeContract, exchangeRate, closeConfirmModal],
  )
  const closeMakeInsuredDepositModal = useCallback(() => setMakeInsuredDepositModalState(false), [])
  //const openMakeInsuredDepositModal = useCallback(() => setMakeInsuredDepositModalState(true), [])

  const bInsured = new Big(insured || 0)
  const insuredPercentage = bInsured
    .times(100)
    .round(2, 1)
    .toFixed()

  const buttons = readOnly
    ? [
        <Button key={'btn-connect'} onClick={handleConnectButton} buttonStyle={ThemeColorTypes.tertiary}>
          Connect Wallet
        </Button>,
      ]
    : !amountInCompound || (amountInCompound as BigNumber).eq(0)
    ? [
        // <Button key={'insurance-deposit'} disabled onClick={openMakeInsuredDepositModal} buttonStyle={tokenCardStyle}>
        //   Make insured deposit
        // </Button>,
      ]
    : bInsured.eq(1)
    ? [
        // <Button key={'insurance-deposit'} disabled onClick={openMakeInsuredDepositModal} buttonStyle={tokenCardStyle}>
        //   Make insured deposit
        // </Button>,
        // <Button key={'withdraw-cancel'} buttonStyle={tokenCardStyleInverted}>
        //   Withdraw and cancel
        // </Button>,
      ]
    : bInsured.eq(0)
    ? [
        <Button key={'buy'} onClick={openBuyInsuranceModal} buttonStyle={tokenCardStyle}>
          Buy Insurance
        </Button>,
        // <Button key={'cancel'} buttonStyle={tokenCardStyleInverted}>
        //   cancel insurance
        // </Button>,
      ]
    : [
        <Button key={'buy'} onClick={openBuyInsuranceModal} buttonStyle={tokenCardStyle}>
          Buy Insurance
        </Button>,
      ]

  const info = [
    {
      title: 'Available Liquidity In Uniswap',
      value: <TokenBalance token={oTokenSymbol} value={availableOTokens} />,
    },
    {
      title: [<Token key="token">{compoundTokenMap[cTokenId as CompoundTokens]}</Token>, ' in Compound'],
      value: (
        <DoubleBalance
          left={{
            token: token,
            value: amountInCompound,
          }}
          right={{
            token: '$',
            value: $amountInCompound,
          }}
        />
      ),
    },
    {
      title: 'Insured Yield (APR)',
      value: <Percentage value={insuredAPR || 0} />,
    },
    ...(bInsured.gt(0)
      ? [
          {
            title: 'Total Premium Paid',
            value: `$${totalPaid ? totalPaid.toFixed(4) : 0}`,
          },
        ]
      : []),
    {
      title: 'Remaining Duration',
      value: !option ? '--' : formatDistanceToNow(fromUnixTime(option.expiry)),
    },
    {
      title: (
        <TextWithTooltip
          text="Max Loss"
          tooltipText={
            'Maximum loss you can face. The rest is covered by your policy. <a href="https://opyn.gitbook.io/opynv1/faq#what-is-max-loss" target="_blank">Learn more.</a>'
          }
          id="mas-loss"
          place="right"
        />
      ),
      value: (
        <DoubleBalance
          left={{
            token: token?.symbol,
            value: bMaxLoss.toFixed(),
            precision: 3,
          }}
          right={{
            token: '$',
            value: bMaxLoss.toFixed(),
          }}
        />
      ), // 1 DAI = $1 = 1 USDC
    },
  ]

  return (
    <>
      <Card {...restProps}>
        {/* Obviously this will change depending on context */}
        <TokenTitle token={compoundTokenMap[cTokenId as CompoundTokens]} />
        <InsuranceProgressBar insuredPercentage={insuredPercentage} balance={amountInCompound} token={token} />
        <Rows>
          {info.map((item, index) => {
            return (
              <Row key={index}>
                <Title>{item.title}</Title>
                <RowValue>{item.value}</RowValue>
              </Row>
            )
          })}
        </Rows>
        <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
      </Card>
      {isBuyInsuranceModalOpen && (
        <BuyInsuranceModal
          cTokenId={cTokenId as CompoundToken}
          isOpen={isBuyInsuranceModalOpen}
          onConfirm={openConfirmModal}
          onRequestClose={closeBuyInsuranceModal}
          title="Buy Insurance"
          insured={insured}
          balance={balance}
          option={option}
        />
      )}
      {isMakeInsuredDepositModalOpen && (
        <MakeInsuredDepositModal
          isOpen={isMakeInsuredDepositModalOpen}
          onConfirm={closeMakeInsuredDepositModal}
          onRequestClose={closeMakeInsuredDepositModal}
          title="Make Insured Deposit"
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={'Confirming Insurance'}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal isOpen={transactionFailed} onRequestClose={() => setTransactionFailed(false)} />
      )}
    </>
  )
}
