import React from 'react'
import styled, { keyframes } from 'styled-components'
import SpinnerSVG from './img/spinner.svg'

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg)
  }
`

const Wrapper = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const Spinner = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${SpinAnimation};
  animation-timing-function: linear;
  display: flex;
  justify-content: center;
`

const SpinnerImage = styled.img`
  display: block;
`

export const InlineLoading: React.FC = props => (
  <Wrapper {...props}>
    <Spinner>
      <SpinnerImage src={SpinnerSVG} alt="" />
    </Spinner>
  </Wrapper>
)
