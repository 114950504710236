import styled from 'styled-components'

const getButtonsContainersColumns = (buttonsAmount: number): string => {
  let template = ''

  for (let count = 0; count < buttonsAmount; count++) {
    template += '1fr '
  }

  return template
}

export const ButtonsContainer = styled.div<{ buttonsAmount: number }>`
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    grid-template-columns: ${props => getButtonsContainersColumns(props.buttonsAmount)};
  }
`
