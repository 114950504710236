import React, { HTMLAttributes, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Card } from 'components/Common/Card'
import { Button } from 'components/Common/Button'
import { TokenIcon } from 'components/Common/TokenIcon'
import Value from 'components/Common/Value'
import TokenBalance from 'components/Common/TokenBalance'
import { ThemeColorTypes, durationGetter, etherscanTx, convertBigFloatToBig, addToLocalStorageArray } from 'utils'
import { SellInsuranceModal } from 'components/Modals/SellInsuranceModal'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { FailedTransactionModal } from 'components/Modals/FailedTransactionModal'
import { TextLight } from 'components/Common/CommonStyled'
import Percentage from 'components/Common/Percentage'
import { useConnection } from 'components/web3'
import useAsyncMemo from '../../hooks/useAsyncMemo'
import useUniswapFactory from '../../hooks/useUniswapFactory'
import { useERC20Contract } from '../../hooks/useERC20Contract'
import { useSellerCompoundOptions, useOTokenFromOption, SellHistory } from 'hooks'
import { Big } from 'big.js'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { BigNumber } from 'ethers/utils'

const TokenTitle = styled(TokenIcon)`
  margin-bottom: 15px;

  > p {
    font-size: 20px;
    font-weight: 600;
  }
`

const Rows = styled.div`
  margin: 0 0 10px;
`

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Title = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

/*const Token = styled.span`
  text-transform: uppercase;
`*/

const getButtonsContainersColumns = (buttonsAmount: number): string => {
  let template = ''

  for (let count = 0; count < buttonsAmount; count++) {
    template += '1fr '
  }

  return template
}

const ButtonsContainer = styled.div<{ buttonsAmount: number }>`
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    grid-template-columns: ${props => getButtonsContainersColumns(props.buttonsAmount)};
  }
`

interface TokenCardProps extends HTMLAttributes<HTMLDivElement> {
  reload?(): void
  option?: SellOTokens_optionsContract
  isExpired: boolean
}

export const SellCompoundCard: React.FC<TokenCardProps> = (props: TokenCardProps) => {
  const { option, isExpired, reload, ...restProps } = props
  const { vaults, oTokenExchangeRateExp, minCollateralizationRatioValue } = option || {}
  const oTokenFromOption = useOTokenFromOption(option || null)
  const { token } = oTokenFromOption

  const oTokenERC20Contract = useERC20Contract(option?.address)
  const uniswapFactoryContract = useUniswapFactory()

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)

        return (await oTokenERC20Contract?.getBalanceOf(exchangeAddress))
          .div(new BigNumber(10 ** -option.oTokenExchangeRateExp))
          .toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  const {
    compoundOptions: { earnAPR = { value: 0 } },
  } = useSellerCompoundOptions(option)

  const { oTokensIssued = 0 } = vaults?.[0] || {}
  const { connectWallet, readOnly, networkId } = useConnection()

  const [isSellInsuranceModalOpen, setSellInsuranceModalState] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [transactionFailed, setTransactionFailed] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')

  const handleConnectButton = () => connectWallet()
  const closeSellInsuranceModal = useCallback(() => setSellInsuranceModalState(false), [])
  const openSellInsuranceModal = useCallback(() => setSellInsuranceModalState(true), [])
  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])
  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])

  const openConfirmModal = useCallback(
    (tx, amount, type) => {
      addToLocalStorageArray('sell-transactions', {
        type,
        amount,
        transactionHash: tx.hash,
        timestamp: Math.round(Date.now() / 1000).toString(),
        pending: true,
      } as SellHistory)

      closeSellInsuranceModal()
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeSellInsuranceModal],
  )

  // To display only if not sold insurance
  const minCollatRatio = useMemo(() => (minCollateralizationRatioValue ? minCollateralizationRatioValue * 10 : 1), [
    minCollateralizationRatioValue,
  ])

  const oTokenSymbol: Maybe<string> = useMemo(() => {
    if (!token) {
      return null
    }
    return !token.cToken ? `o${token.symbol}` : `o${token.symbol[0].toLowerCase()}${token.symbol.slice(1)}`
  }, [token])

  const earnPercentage = convertBigFloatToBig(earnAPR)
    .div(new Big(minCollatRatio))
    .mul(100)

  const humanReadableDuration = useMemo(() => durationGetter(option)?.value, [option])

  const sellMoreButtonDisabled = !option || isSellInsuranceModalOpen

  const soldInsurance = !!oTokensIssued
  const buttons = readOnly
    ? [
        <Button key={'btn-connect'} onClick={handleConnectButton} buttonStyle={ThemeColorTypes.tertiary}>
          Connect Wallet
        </Button>,
      ]
    : soldInsurance
    ? [
        <Button
          key="sell-more-insurance"
          buttonStyle={ThemeColorTypes.secondary}
          disabled={sellMoreButtonDisabled}
          onClick={openSellInsuranceModal}
        >
          Sell more insurance
        </Button>,
      ]
    : [
        <Button
          key="sell-insurance"
          buttonStyle={ThemeColorTypes.secondary}
          onClick={openSellInsuranceModal}
          disabled={sellMoreButtonDisabled}
        >
          Sell insurance
        </Button>,
      ]

  const SellModal = SellInsuranceModal
  const insuranceSoldInfo = [
    // {
    //   title: 'Premiums Earned',
    //   value: [
    //     <TokenBalance key={ETH_TOKEN.symbol} token={ETH_TOKEN} value={'0.3'} />,
    //     ' ',
    //     <TextLight key="usd">
    //       <Value value="37.40">{val => `$${val}`}</Value>
    //     </TextLight>,
    //   ],
    // },
    {
      title: 'Available Liquidity In Uniswap',
      value: <TokenBalance token={oTokenSymbol} value={availableOTokens} />,
    },
    {
      title: 'Remaining Duration',
      value: humanReadableDuration,
    },

    {
      title: 'oTokens Issued',
      value: [
        <Value
          key={'token'}
          value={new BigNumber(oTokensIssued)}
          decimals={(oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1}
          precision={4}
        >
          {val => `${val} ${oTokenSymbol || ''}`}
        </Value>,
      ],
    },
  ]

  const insuranceNotSoldInfoCompound = [
    {
      title: 'Available Liquidity In Uniswap',
      value: <TokenBalance token={oTokenSymbol} value={availableOTokens} />,
    },
    {
      title: 'Earn',
      value: [
        <Percentage key="percentage" value={earnPercentage.round(2).toFixed()} />,
        <TextLight key="usd">
          {' / '}
          <Value key="eth" value={1}>
            {val => `${val} ETH collateral`}
          </Value>
        </TextLight>,
      ],
    },
    {
      title: 'Min. Collateralization Requirement',
      value: <Percentage value={minCollatRatio.toFixed()} />,
    },
    {
      title: 'Remaining Duration',
      value: humanReadableDuration,
    },
  ]

  const insuranceNotSoldInfoToDisplay = insuranceNotSoldInfoCompound
  const info = soldInsurance ? insuranceSoldInfo : insuranceNotSoldInfoToDisplay
  let tokenTitle = ''
  if (token?.symbol.toLocaleLowerCase() === 'cdai') {
    tokenTitle = 'dai'
  } else if (token?.symbol.toLocaleLowerCase() === 'cusdc') {
    tokenTitle = 'usdc'
  }

  return (
    <>
      <Card {...restProps}>
        <TokenTitle token={tokenTitle} />
        <Rows>
          {info.map((item, index) => {
            return (
              <Row key={index}>
                <Title>{item.title}</Title>
                <RowValue>{item.value}</RowValue>
              </Row>
            )
          })}
        </Rows>
        <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
      </Card>
      {isSellInsuranceModalOpen && (
        <SellModal
          isOpen={isSellInsuranceModalOpen}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeSellInsuranceModal}
          title="Sell Insurance"
          option={option}
          oTokenData={oTokenFromOption}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={'Confirm Sell'}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          themeColor={ThemeColorTypes.secondary}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
    </>
  )
}
