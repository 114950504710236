import { NetworkIds } from '../../../utils'

export const truncateStringInTheMiddle = (str: string, strLength = 41, strPositionStart = 8, strPositionEnd = 8) => {
  if (str.length > strLength) {
    return `${str.substr(0, strPositionStart)}...${str.substr(str.length - strPositionEnd, str.length)}`
  }
  return str
}

export const getNetworkName = (networkId: number) => {
  const networkName = Object.keys(NetworkIds).find(key => (NetworkIds as any)[key] === networkId)
  const networkNameCase = networkName && networkName.substr(0, 1).toUpperCase() + networkName.substr(1).toLowerCase()

  return networkNameCase
}
