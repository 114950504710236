import { useState, useEffect, useMemo } from 'react'

import {
  InsuredTokens,
  NetworkId,
  isValidNetworkId,
  SellerOptionData,
  getTokenFromAddress,
  getEthOptionEarnAPR,
  getCollateralEarnPremiums,
} from 'utils'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { useConnection } from 'components/web3'
import { OptionsExchange } from 'services'
import useOptionsExchange from './useOptionsExchange'

interface PremiumAPRArgs {
  numberOfDays: number
  premiumToPay: string
  oTokenExchangeRateExp: string
  strikePrice: string
}

const calculateOptionData = async (
  option: any,
  networkId: NetworkId,
  isCall: boolean,
  tokenId?: InsuredToken,
  optionsExchangeContract?: Maybe<OptionsExchange>,
): Promise<Maybe<SellerOptionData>> => {
  const getOptionsData = async (): Promise<SellerOptionData> => {
    const earnAPR = await getEthOptionEarnAPR({ option, networkId, optionsExchangeContract, isCall })
    const earnPremium = await getCollateralEarnPremiums({ option, networkId, optionsExchangeContract, isCall })

    return {
      option,
      earnAPR,
      earnPremium,
    } as SellerOptionData
  }

  return await getOptionsData()
}

type InsuredOptions = Record<InsuredTokens, SellerOptionData>

export const useSellerInsuredOptions = (option: Maybe<SellOTokens_optionsContract> = null, isCall: boolean) => {
  const { networkId, account } = useConnection()
  const [loading, setLoading] = useState(true)
  const [insuredOptions, setInsuredOptions] = useState<SellerOptionData>({} as SellerOptionData)

  const underlying = useMemo(() => option && getTokenFromAddress(networkId, option.underlying), [networkId, option])

  const tokenId = underlying?.symbol.toLocaleLowerCase() as InsuredTokens

  const tokens = useMemo(() => (tokenId ? [tokenId as InsuredTokens] : []), [tokenId])

  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)

  useEffect(() => {
    let isCancelled = false
    if (option && isValidNetworkId(networkId) && option && optionsExchangeContract) {
      setLoading(true)
      calculateOptionData(option, networkId, isCall, tokenId, optionsExchangeContract).then(insuredOptions => {
        if (insuredOptions && !isCancelled) {
          setInsuredOptions(insuredOptions)
        }
        setLoading(false)
      })
    }

    return () => {
      isCancelled = true
    }
  }, [networkId, tokenId, optionsExchangeContract, option, account, tokens, isCall])

  return {
    insuredOptions,
    loading,
  }
}
