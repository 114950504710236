import React from 'react'
import TokenBalance, { TokenBalanceProps } from '../TokenBalance'
import { TextLight } from '../CommonStyled'

export interface DoubleBalanceProps {
  left: TokenBalanceProps
  right: TokenBalanceProps
  separator?: string
}

const DoubleBalance: React.FC<DoubleBalanceProps> = ({ left, right, separator = ' ' }: DoubleBalanceProps) => (
  <>
    <TokenBalance key="left" {...left} />
    <TextLight key="right">
      {separator}
      <TokenBalance key="left" {...right} />
    </TextLight>
  </>
)

export default DoubleBalance
