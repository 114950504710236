const theme = {
  fonts: {
    defaultSize: '14px',
    fontFamily: `'Inter', 'Helvetica Neue', 'Arial', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', '-apple-system', 'BlinkMacSystemFont', sans-serif`,
    fontFamilyCode: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
  },
  colors: {
    activeListItemBackground: '#fafafa',
    gray: '#F2F2F2',
    darkGray: '#333',
    darkGrayInverted: '#333',
    error: '#DF5F67',
    mainBodyBackground: '#fff',
    primary: '#05B169',
    primaryInverted: '#05B169',
    primaryLight: '#4FC2A0',
    primaryLightInverted: '#4FC2A0',
    secondary: '#6979F8',
    secondaryInverted: '#6979F8',
    tertiary: '#CD6116',
    tertiaryInverted: '#CD6116',
    textColor: '#4F4F4F',
    textColorMedium: '#828282',
    textColorLight: '#BDBDBD',
    warning: '#FDA800',
  },
  cards: {
    backgroundColor: '#fff',
    border: '1px solid #F2F2F2',
    borderRadius: '8px',
    boxShadow: 'none',
    paddingHorizontal: '17px',
    paddingVertical: '20px',
    textColor: '#333',
    titleColor: '#333',
  },
  header: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    height: '100px',
    heightMobile: '60px',
    color: '#333',
  },
  themeBreakPoints: {
    lg: '992px',
    md: '768px',
    sm: '480px',
    xl: '1024px',
    xs: '320px',
    xxl: '1280px',
    xxxl: '1366px',
  },
  borders: {
    borderColor: '#F2F2F2',
    commonBorderRadius: '5px',
  },
  paddings: {
    mainPadding: '15px',
  },
  innerScrollContainer: {
    narrowWidth: '900px',
    maxWidth: '1016px',
  },
  table: {
    th: {
      backgroundOK: 'rgba(5, 177, 105, 0.07)',
    },
    td: {
      color: '#333',
    },
  },
  modalStyle: {
    content: {
      backgroundColor: '#fff',
      borderColor: 'transparent',
      borderRadius: '8px',
      bottom: 'auto',
      boxShadow: '0 7px 64px rgba(0, 0, 0, 0.07)',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '0',
      height: 'fit-content',
      left: 'auto',
      margin: 'auto 0',
      maxWidth: '100%',
      overflow: 'hidden',
      paddingBottom: '15px',
      paddingHorizontal: '20px',
      paddingTop: '15px',
      position: 'relative',
      right: 'auto',
      top: 'auto',
      width: '462px',
      zIndex: '123456789',
    },
    overlay: {
      alignItems: 'unset',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'auto',
      padding: '10px',
      zIndex: '12345',
    },
  },
}

export default theme
