import { Contract, ethers, Wallet } from 'ethers'

// import { getLogger } from '../utils'

// const logger = getLogger('Services::UniswapFactory')

const uniswapFactoryABI = ['function getExchange(address token) external view returns (address exchange)']

class UniswapFactory {
  contractAddress: string
  provider: any
  contract: Contract

  constructor(provider: any, signerAddress: Maybe<string>, contractAddress: string) {
    this.contractAddress = contractAddress
    this.provider = provider
    if (signerAddress) {
      const signer: Wallet = provider.getSigner()
      this.contract = new ethers.Contract(contractAddress, uniswapFactoryABI, provider).connect(signer)
    } else {
      this.contract = new ethers.Contract(contractAddress, uniswapFactoryABI, provider)
    }
  }

  getExchange = (address: string) => {
    return this.contract.getExchange(address)
  }
}

export default UniswapFactory
