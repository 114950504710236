import { useState, useCallback, useEffect, useMemo } from 'react'
import { useContracts } from './useContracts'
import { BigNumber } from 'ethers/utils'
import { APR_DECIMALS, BLOCKS_PER_DAY, EXCHANGE_RATE_DECIMALS } from '../common/constants'
import { CompoundTokens, getToken, getTokenOrPlaceHolder, CompoundBalance, compoundTokens } from '../utils'
import { ERC20Service } from '../services'
import { useConnection } from '../components/web3'
import { Big } from 'big.js'

const compoundTokenMap: Record<CompoundTokens, KnownToken> = {
  cdai: 'dai',
  cusdc: 'usdc',
}

const getBalancePlaceHolder = (id: KnownToken, networkId: number): CompoundBalance => ({
  id,
  token: getTokenOrPlaceHolder(networkId, compoundTokenMap[id as CompoundTokens]),
  cToken: getTokenOrPlaceHolder(networkId, id),
  protocol: 'compound',
  amount: 0,
  amountInCompound: 0,
  exchangeRate: 0,
  status: '',
  uninsuredAPR: {
    value: 0,
  },
})

const getAccountBalance = async (contract: ERC20Service, account: Maybe<string>) => {
  let amountInCompound = new BigNumber(0)

  const [amount, exchangeRate] = await Promise.all([
    account ? contract.getBalanceOf(account) : new BigNumber(0),
    contract.getExchangeRate(),
  ])
  amountInCompound = new BigNumber(
    new Big(amount.toString())
      .times(exchangeRate.toString())
      .times(`1e-${EXCHANGE_RATE_DECIMALS}`)
      .toFixed(0),
  )

  return {
    amountInCompound,
    amount,
    exchangeRate,
  }
}

/**
 * This hook can only be used by components under the `Web3ReactConnector` component. Otherwise it will throw.
 */
export const useBalances = (tokens: Array<CompoundToken>) => {
  const { networkId, account, isConnected } = useConnection()
  const placeHolders = useMemo(() => compoundTokens.map(id => getBalancePlaceHolder(id, networkId)), [networkId])
  const [balances, setBalances] = useState<any>(placeHolders)
  const [loading, setLoading] = useState<boolean>(false)
  const [reloadCounter, setReloadCounter] = useState<number>(0)
  const contracts: any = useContracts()

  const getCompoundBalance = useCallback(
    async (id: CompoundToken): Promise<CompoundBalance> => {
      const contract = contracts[id.toUpperCase()]

      if (!isConnected || !contract) {
        return getBalancePlaceHolder(id, networkId)
      }
      const cToken = getToken(networkId, id)
      let rawSupplyRatePerBlock, amountInCompound, amount, exchangeRate

      try {
        ;[rawSupplyRatePerBlock, { amountInCompound, amount, exchangeRate }] = await Promise.all([
          contract.getSupplyRatePerBlock(),
          getAccountBalance(contract, account),
        ])
      } catch (err) {
        return getBalancePlaceHolder(id, networkId)
      }

      // APR (anual percentage rate) = Periodic Rate × Number of Periods in a Year
      // APY (anual percentage yield) = ((1 + Periodic Rate) ^ Number of Periods) − 1
      const suplyRate = new Big(rawSupplyRatePerBlock.toString()).times(`1e-${EXCHANGE_RATE_DECIMALS}`)
      const dailyRate = suplyRate.times(BLOCKS_PER_DAY)
      const rawUninsuredYield = dailyRate
        .plus(1)
        .pow(365)
        .minus(1)
        .times(`1e${EXCHANGE_RATE_DECIMALS}`)

      return {
        id,
        protocol: 'compound',
        token: getToken(networkId, compoundTokenMap[id] as KnownToken),
        cToken,
        amount,
        amountInCompound,
        exchangeRate,
        status: '',
        uninsuredAPR: {
          value: new BigNumber(rawUninsuredYield.toFixed(0)),
          decimals: APR_DECIMALS,
        },
      }
    },
    [account, contracts, networkId, isConnected],
  )

  const reload = () => setReloadCounter(counter => counter + 1)

  useEffect(() => {
    let isCancelled = false

    setLoading(true)
    Promise.all(tokens.map(getCompoundBalance)).then(balances => {
      if (!isCancelled) {
        setBalances(balances)
        setLoading(false)
      }
    })

    return () => {
      isCancelled = true
    }
  }, [isConnected, contracts, getCompoundBalance, tokens, reloadCounter])

  return [balances, loading, reload]
}
