import { Big } from 'big.js'
import { OptionsExchange, OracleService } from '../services'
import { CompoundBalance, BigFloat, isValidNetworkId, getToken } from '.'
import { BigNumber } from 'ethers/utils'
import { differenceInHours, fromUnixTime } from 'date-fns'
import { getTokenPriceCoingecko } from 'utils'

interface PremiumAPRArgs {
  numberOfHours: number
  paymentTokenToUSDCRate: Big
  cTokenUnderlyingToUSDCRate: Big
  premiumToPay: string
  oTokenExchangeRateExp: string
  cTokenExchangeRate: string
  cTokenExchangeRateDecimals: number
}

const getPremiumAPR = ({
  numberOfHours,
  paymentTokenToUSDCRate,
  cTokenUnderlyingToUSDCRate,
  premiumToPay,
  oTokenExchangeRateExp,
  cTokenExchangeRate,
  cTokenExchangeRateDecimals,
}: PremiumAPRArgs) => {
  const amountCTokens = new Big(`1e${oTokenExchangeRateExp}`)
  const scaledExchangeRate = new Big(cTokenExchangeRate).times(`1e-${cTokenExchangeRateDecimals}`)
  const $amountInCompound = scaledExchangeRate.times(amountCTokens).times(cTokenUnderlyingToUSDCRate)
  const $premium = new Big(premiumToPay).times(1e-18).times(paymentTokenToUSDCRate)

  const $premiumAPR = $premium
    .times(365)
    .times(24)
    .times(100)
    .div(numberOfHours)
    .div($amountInCompound)

  return {
    value: $premiumAPR.toFixed(0),
    decimals: 0,
  }
}

export const getCompoundEarnAPR = async ({
  networkId,
  optionsExchangeContract,
  oracleContract,
  optionAddress,
  optionExpiry,
  optionOTokenExchangeRateExp,
  balance,
}: {
  networkId: number
  optionsExchangeContract?: OptionsExchange
  oracleContract?: OracleService
  optionAddress?: string
  optionExpiry?: number
  optionOTokenExchangeRateExp?: string
  balance: CompoundBalance
}): Promise<BigFloat> => {
  const { uninsuredAPR, exchangeRate, cToken, token } = balance

  if (
    !isValidNetworkId(networkId) ||
    !optionsExchangeContract ||
    // !oracleContract ||
    !token ||
    !optionAddress ||
    !optionExpiry ||
    !optionOTokenExchangeRateExp ||
    !exchangeRate
  ) {
    return {
      value: uninsuredAPR?.value || 0,
      ...(uninsuredAPR?.decimals && { decimals: uninsuredAPR.decimals }),
    }
  }

  const usdcAddress = getToken(networkId, 'usdc').address
  // const ethToUsdcPrice = await oracleContract.getPrice(usdcAddress)
  // const ethToTokenPrice = await oracleContract.getPrice(token.address)
  const ethToUsdcPrice = Number(await getTokenPriceCoingecko(usdcAddress)) * 1e18
  const ethToTokenPrice = Number(await getTokenPriceCoingecko(token.address)) * 1e18

  let premiumToPayInWei = new BigNumber(0)
  try {
    premiumToPayInWei = await optionsExchangeContract?.getPremiumToPay(
      optionAddress,
      '0x0000000000000000000000000000000000000000',
      1,
    )
  } catch {
    // Do nothing
  }

  return getPremiumAPR({
    numberOfHours: differenceInHours(fromUnixTime(optionExpiry), new Date()),
    paymentTokenToUSDCRate: new Big(1).div(new Big(ethToUsdcPrice.toString()).times(1e-18)), //
    cTokenUnderlyingToUSDCRate: new Big(ethToTokenPrice.toString()).div(new Big(ethToUsdcPrice.toString())),
    premiumToPay: premiumToPayInWei.toString(),
    oTokenExchangeRateExp: optionOTokenExchangeRateExp,
    cTokenExchangeRate: exchangeRate.toString(),
    cTokenExchangeRateDecimals: cToken?.exchangeRateDecimals || 0,
  })
}

/**
 * Get amount of premium (in collateral) earn by putting down 1 collateral
 * @param param0
 */
export const getCollateralEarnPremiums = async ({
  option,
  networkId,
  optionsExchangeContract,
  isCall,
}: {
  option: any
  networkId: number
  optionsExchangeContract?: Maybe<OptionsExchange>
  isCall: boolean
}): Promise<BigFloat> => {
  if (!isValidNetworkId(networkId) || !optionsExchangeContract) {
    return {
      value: 0,
    }
  }

  const collateralToken = getToken(networkId, 'usdc')

  // For a call: how much can I earn on 1 ETH
  // For a put: how much can I earn on 1 USDC

  const rawStrikePrice = new Big(option.strikePriceValue)
    .times(`1e${option.strikePriceExp}`)
    .times(`1e${option.oTokenExchangeRateExp * -1}`) // 1oETH -> strikePrice DAI

  // oTokensPerCollateral:
  // put option: how much oToken can be mint wtih 1 USDC
  // call option: how much oToken can be mint with 1 ETH
  const oTokensPerCollateral = new Big(1).div(rawStrikePrice).times(`1e${option.oTokenExchangeRateExp * -1}`)

  let premiumPerOTokenInBaseCollateral = new BigNumber(0)
  try {
    premiumPerOTokenInBaseCollateral = await optionsExchangeContract?.getPremiumReceived(
      option.address,
      collateralToken.address,
      1e4,
    )
  } catch (error) {
    // Do nothing
  }

  if (isCall) {
    // const usdcAddress = getToken(networkId, 'usdc').address
    // const ethToUsdcPrice = Number(await getTokenPriceCoingecko(usdcAddress)) * 1e18

    const premiumToPay = new Big(premiumPerOTokenInBaseCollateral.toString())
      .times(`1e${-option?.oTokenExchangeRateExp}`)
      .times(1e-4)
      .times(`1e-6`)
      .div(rawStrikePrice)
      .times(10000)

    return {
      value: premiumToPay.toFixed(0),
      decimals: 2,
    }
  }

  const premiumPerCollateral = oTokensPerCollateral
    .times(new Big(premiumPerOTokenInBaseCollateral.toString()))
    .div(new Big(`1e${collateralToken.decimals}`).toString())
    .div(1e4)

  const premiumPercentage = premiumPerCollateral.times(100) // Scale it up by 100 for decimals

  return {
    value: premiumPercentage.times(100).toFixed(0),
    decimals: 2,
  }
}

export const getEthOptionEarnAPR = async ({
  option,
  networkId,
  optionsExchangeContract,
  isCall,
}: {
  option: any
  networkId: number
  optionsExchangeContract?: Maybe<OptionsExchange>
  isCall: boolean
}): Promise<BigFloat> => {
  const premiumPerCollateralBF = await getCollateralEarnPremiums({ option, networkId, optionsExchangeContract, isCall })
  const premiumPerCollateral = new Big(`${premiumPerCollateralBF.value}e-${premiumPerCollateralBF.decimals}`)

  let duration = differenceInHours(fromUnixTime(option.expiry), new Date())

  duration = duration > 0 ? duration : 1

  const premiumAPR = premiumPerCollateral
    .times(365)
    .times(24)
    .div(duration)

  return {
    value: premiumAPR.times(100).toFixed(0),
    decimals: 2,
  }
}
