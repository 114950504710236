import React, { HTMLAttributes, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Card } from '../Common/Card'
import { Button } from '../Common/Button'
import { Loading } from '../Common/Loading'
import {
  ThemeColorTypes,
  BuyerCompoundOptionData,
  // formatBigNumber,
  getToken,
  isValidNetworkId,
  etherscanTx,
  // addToLocalStorageArray,
  // ExerciseAction,
} from '../../utils'
import { useConnection } from '../web3'
import { useExerciseData } from '../../hooks'
import { ExerciseModal } from '../Modals/ExerciseModal'
import { FailedTransactionModal } from '../Modals/FailedTransactionModal'
import { ConfirmModal } from '../Modals/ConfirmModal'

const Text = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

// const TextNoMargin = styled(Text)`
//   margin: 0;
// `

const Link = styled.a<{ themeColor: ThemeColorTypes }>`
  color: ${props => props.theme.colors[ThemeColorTypes[props.themeColor]]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

// const SwitchContainer = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 0 12px;

//   &:last-child {
//     margin-bottom: 0;
//   }
// `

// eslint-disable-next-line
const Token = styled.span`
  text-transform: uppercase;
`

interface ClaimCardProps extends HTMLAttributes<HTMLDivElement> {
  claimCardStyle?: ThemeColorTypes
  token: string
  insuranceData: BuyerCompoundOptionData
  loading: boolean
  underlyingToken: string
  reload?(): void
}

export const ClaimCard: React.FC<ClaimCardProps> = (props: ClaimCardProps) => {
  const { networkId } = useConnection()
  const {
    insuranceData,
    loading,
    token,
    claimCardStyle = ThemeColorTypes.primary,
    underlyingToken,
    reload,
    ...restProps
  } = props

  const underlying = useMemo(() => {
    if (isValidNetworkId(networkId)) {
      return getToken(networkId, underlyingToken as KnownToken)
    }
    return null
  }, [networkId, underlyingToken])

  const oTokenAddress = insuranceData && insuranceData.option ? insuranceData.option.address : null

  const { exerciseData, loading: exerciseDataLoading, refetch } = useExerciseData(
    underlying && underlying.address,
    oTokenAddress,
  )

  const [isExerciseModalOpen, setExerciseModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [transactionFailed, setTransactionFailed] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')

  const openExerciseModal = useCallback(() => setExerciseModalOpen(true), [])
  const closeExerciseModal = useCallback(() => setExerciseModalOpen(false), [])
  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])
  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])
  const openConfirmModal = useCallback(
    tx => {
      closeExerciseModal()
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeExerciseModal],
  )

  // const { balance, insured, maxLoss } = insuranceData
  // const { amountInCompound } = balance || {}
  // const showChart = useMemo(() => {
  //   return readOnly || (amountInCompound && (amountInCompound as BigNumber).gt(0) && new Big(insured || 0).eq(0))
  // }, [readOnly, amountInCompound, insured])

  // const chartAmountInCompound = useMemo(() => {
  //   if (amountInCompound && (amountInCompound as BigNumber).gt(0) && balance?.token) {
  //     return formatBigNumber(amountInCompound as BigNumber, balance?.token?.decimals)
  //   }

  //   return '0'
  // }, [amountInCompound, balance])

  // const chartInsuredAmount = useMemo(() => {
  //   if (
  //     amountInCompound &&
  //     (amountInCompound as BigNumber).gt(0) &&
  //     balance?.token &&
  //     maxLoss &&
  //     maxLoss?.value &&
  //     maxLoss?.decimals
  //   ) {
  //     return new Big(amountInCompound.toString())
  //       .div(`1e${balance.token.decimals}`)
  //       .minus(new Big(maxLoss.value.toString()).div(`1e${maxLoss?.decimals}`))
  //       .toFixed(2)
  //       .toString()
  //   }

  //   return '0'
  // }, [amountInCompound, balance, maxLoss])

  // eslint-disable-next-line
  const isClaimDisabled = useMemo(
    () => exerciseDataLoading || !exerciseData.oTokensToExercise || exerciseData?.oTokensToExercise?.lte(0),
    [exerciseDataLoading, exerciseData],
  )

  return (
    <>
      <Card {...restProps} title="Claim">
        {loading ? (
          <Loading />
        ) : (
          //: showChart ? (
          //   <>
          //     <Text>
          //       If there is an issue with your <Token>{token}</Token> deposit on Compound, you can make a claim at any time
          //       to receive immediate payout.{' '}
          //       <Link themeColor={claimCardStyle} href="#">
          //         Learn more about what’s covered.
          //       </Link>
          //     </Text>
          //     <br />
          //     <br />
          //     <InsuranceChart amountInCompound={chartAmountInCompound} insuredAmount={chartInsuredAmount} />
          //   </>
          // )
          <>
            <Text>
              If there is an issue with your <Token>{token}</Token> deposit on Compound, immediately receive payment.{' '}
              <Link
                themeColor={claimCardStyle}
                href="https://opyn.gitbook.io/opynv1/faq#what-risks-are-covered"
                target="_blank"
              >
                Learn more about what’s covered.
              </Link>
            </Text>
            {/* <Text>
              {' '}
              Exercise is temporarily paused for this series while we work with Maker to reboot their v1 oracle.{' '}
              <Link
                themeColor={ThemeColorTypes.primary}
                href="https://twitter.com/opyn_/status/1302777960823357440"
                target="_blank"
              >
                Learn more here.
              </Link>{' '}
            </Text> */}
            {/* <SwitchContainer>
              <TextNoMargin>Get notified about issues.</TextNoMargin>
              <Switch />
            </SwitchContainer> */}
            <Button buttonStyle={claimCardStyle} disabled={isClaimDisabled} onClick={openExerciseModal}>
              Claim
            </Button>
            {/* <Button buttonStyle={claimCardStyle} disabled={true} onClick={openExerciseModal}>
              Claim
            </Button> */}
          </>
        )}
      </Card>
      {isExerciseModalOpen && (
        <ExerciseModal
          isOpen={isExerciseModalOpen}
          onConfirm={openConfirmModal}
          onRequestClose={closeExerciseModal}
          onTransactionFail={onTransactionFail}
          title={`Claim ${token.toUpperCase()} on Compound`}
          tokenId={token}
          exerciseData={exerciseData}
          refetch={refetch}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={'Confirming Claim'}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
    </>
  )
}
