import React from 'react'
import styled from 'styled-components'

type CardStyledProps = {
  noPadding?: boolean
  maxHeight?: string
}

type TitleProps = {
  titleAlign?: string
}

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  subtitle?: React.ReactNode
  children: React.ReactNode
  noPadding?: boolean
  titleAlign?: string
  maxHeight?: string
}

const CardStyled = styled.div<CardStyledProps>`
  background-color: ${props => props.theme.cards.backgroundColor};
  border-radius: ${props => props.theme.cards.borderRadius};
  border: ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.cards.boxShadow};
  display: flex;
  flex-direction: column;
  position: relative;
  ${props => props.maxHeight && 'max-height: ' + props.maxHeight + ';'}
  ${props =>
    props.noPadding
      ? 'padding: 0'
      : 'padding: ' + props.theme.cards.paddingVertical + ' ' + props.theme.cards.paddingHorizontal + ';'}
`

const Title = styled.h2<TitleProps>`
  color: ${props => props.theme.cards.titleColor};
  ${props => props.titleAlign && `text-align: ${props.titleAlign};`}
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
`

const Subtitle = styled.span`
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
`

const Body = styled.div`
  color: ${props => props.theme.cards.textColor};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`

const Head = styled.div<{ title?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${props => (props.title ? 'space-between' : 'flex-start')};
  margin: 0 0 15px;
  padding: 0;
`

export const Card = ({
  title,
  subtitle,
  children,
  noPadding = false,
  titleAlign = 'left',
  ...restProps
}: CardProps) => {
  return (
    <CardStyled noPadding={noPadding} {...restProps}>
      <Head title={title}>
        {title && <Title titleAlign={titleAlign}>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Head>
      <Body>{children}</Body>
    </CardStyled>
  )
}
