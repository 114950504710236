import React from 'react'
import Value from '../Value'
import { Numeric } from '../../../utils'

export interface PercentageProps {
  value: Numeric
  decimals?: number
  precision?: number
  placeholder?: any
}

const Percentage: React.FC<PercentageProps> = (props: PercentageProps) => (
  <Value {...props}>{(val: string) => <>{Number(val) > 0 ? val : '0'}%</>}</Value>
)

export default Percentage
