import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../../Common/SectionTitle'
import { Loading } from '../../Common/Loading'
import { Available } from './Available'
import { Sold } from './Sold'
import { useSellerTokenPutsOrCalls } from '../../../hooks/useSellerTokenPutsOrCalls'

const SubTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 10px;
`

const LoadingWrapper = styled.div`
  position: relative;
  height: 100px;
`

export const CrvPutSection: React.FC = () => {
  const data = useSellerTokenPutsOrCalls('put', 'crv')
  const { options = null, loading } = data

  const { availableData, insuredData } = useMemo(() => {
    const initial = { availableData: [], insuredData: [] }
    const { options = null } = data || {}
    if (!options) {
      return initial
    }

    return options.reduce((acc: any, option: any) => {
      let availableData = acc.availableData
      let insuredData = acc.insuredData
      if (!option.vaults.length) {
        availableData = [...availableData, option]
      } else {
        insuredData = [...insuredData, option]
      }
      return { availableData, insuredData }
    }, initial)
  }, [data])

  return loading || insuredData.length > 0 || availableData.length > 0 ? (
    <>
      <SectionTitle
        protocol="crv"
        title="Sell Protective Put Options on CRV"
        subtitle="Earn premiums on USDC by providing protection to others on their CRV"
      />
      {(!options || loading) && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {!!insuredData.length && !loading && (
        <>
          <SubTitle>Insurance Sold</SubTitle>
          <Sold data={{ ...data, insuredData }} />
        </>
      )}
      {!!availableData.length && !loading && (
        <>
          <SubTitle>Available</SubTitle>
          <Available data={{ ...data, availableData }} />
        </>
      )}
    </>
  ) : (
    <></>
  )
}
