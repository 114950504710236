import { useState, useEffect } from 'react'
import { useConnection } from 'components/web3'
import {
  isValidNetworkId,
  getTokenFromAddress,
  BigFloat,
  calculateCurrentRatio,
  Token,
  getTokenPriceCoingecko,
} from 'utils'
import useOracle from './useOracle'
import { oToken, OracleService } from 'services'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { Big } from 'big.js'

const getCollateralToStrikePrice = async (
  oracleContract: Maybe<OracleService>,
  strikeAddress: Maybe<string>,
): Promise<BigFloat> => {
  if (!oracleContract || !strikeAddress) {
    return {
      value: 0,
    }
  }

  const oracleValue = await oracleContract.getPrice(strikeAddress)
  const apiValue = (Number(await getTokenPriceCoingecko(strikeAddress)) * 1e18).toString()

  // const value = await oracleContract.getPrice(strikeAddress)
  // const value = (Number(await getTokenPriceCoingecko(strikeAddress)) * 1e18).toString()

  const value = oracleValue.toString() !== '0' ? oracleValue : apiValue

  return {
    value,
    decimals: 18,
  }
}

export interface oTokenData {
  service: Maybe<oToken>
  collateralToStrikePrice: BigFloat
  collateralizationRatio: number
  token: Maybe<Token>
  loading: boolean
}

export const useOTokenFromOption = (option: Maybe<SellOTokens_optionsContract>) => {
  const { library, isConnected, networkId, account } = useConnection()
  const token = isValidNetworkId(networkId) && option ? getTokenFromAddress(networkId, option.underlying, null) : null
  const [loading, setLoading] = useState(true)
  const [service, setService] = useState<oToken | null>(null)
  const [collateralToStrikePrice, setCollateralToStrikePrice] = useState<BigFloat>({ value: 0 })
  const [collateralizationRatio, setCollateralizationRatio] = useState(0)
  const oracleContract = useOracle()

  useEffect(() => {
    let isCancelled = false

    if (isConnected && library && isValidNetworkId(networkId) && oracleContract && option) {
      setService(new oToken(library, account, option.address))
      setLoading(true)
      getCollateralToStrikePrice(oracleContract, option.strike).then(collateralToStrikePrice => {
        if (!isCancelled) {
          setCollateralizationRatio(() => {
            if (!option.vaults?.[0]) {
              return 0
            }
            const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = option.vaults[0]
            return calculateCurrentRatio({
              addedCollateral: new Big(addedCollateral).div('1e18').toString(),
              strikePriceValue: option?.strikePriceValue,
              strikePriceExp: option?.strikePriceExp,
              collateralToStrikePrice,
              mintedOTokens,
            })
          })
          setCollateralToStrikePrice(collateralToStrikePrice)
          setLoading(false)
        }
      })
    }

    return () => {
      isCancelled = true
    }
  }, [isConnected, library, networkId, account, option, oracleContract])

  return { service, collateralToStrikePrice, collateralizationRatio, token, loading } as oTokenData
}

export default useOTokenFromOption
