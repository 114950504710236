import React from 'react'
import styled from 'styled-components'

export const getColorByStatus = (status: string, holder = true, _default = '#E0E0E0'): string => {
  if (status === 'ok' && holder) {
    return '#05B169'
  } else if (status === 'error' && holder) {
    return '#DF5F67'
  } else if (status === 'exercised') {
    return '#6979F8'
  }

  return _default
}

const Wrapper = styled.span<{ status: string }>`
  color: ${props => props.status || props.theme.table.td.color};
  white-space: normal;
  text-align: center;
`

interface ColorCellProps {
  value: any
  status?: string
  holder?: boolean
}

export const ColorCell: React.FC<ColorCellProps> = (props: ColorCellProps) => {
  const { status = '', value, holder, ...restProps } = props

  return (
    <Wrapper status={status && getColorByStatus(status, holder, '')} {...restProps}>
      {value}
    </Wrapper>
  )
}
