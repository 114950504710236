import { useState, useEffect } from 'react'
import { GetSellerOptionsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { useConnection } from 'components/web3'
import {
  BigFloat,
  NetworkId,
  getEthOptionEarnAPR,
  getCollateralEarnPremiums,
  calculateCurrentRatio,
  ETH_TOKEN,
  getExercised,
  getIsExpired,
  getTokenFromAddress,
  getTokenOrPlaceHolder,
  getTokenPriceCoingecko,
} from 'utils'

import useEthToUsdPrice from './useEthToUSDPrice'
import useOracle from './useOracle'
import { useSellerOptionsByUnderlying } from './useSellerEthOptions'
import { OptionsExchange, OracleService } from 'services'
import { Big } from 'big.js'
import { BigNumber } from 'ethers/utils'
import { Web3Provider } from 'ethers/providers'

import { putsToShow } from './useBuyerEthPuts'
import { callsToShow } from './useBuyerEthCalls'

const getCollateralToStrikePrice = async (strikeAddress: string, oracleContract?: OracleService): Promise<BigFloat> => {
  if (!oracleContract || !strikeAddress) {
    return {
      value: 0,
    }
  }

  const oracleValue = await oracleContract.getPrice(strikeAddress)
  const coingeckoValue = (Number(await getTokenPriceCoingecko(strikeAddress)) * 1e18).toString()

  const value = oracleValue.toString() !== '0' ? oracleValue : coingeckoValue

  return {
    value,
    decimals: 18,
  }
}

const getCurrentCollateral = async ({
  option,
  oracleContract,
}: {
  option: GetSellerOptionsByUnderlying_optionsContracts
  oracleContract: OracleService
}) => {
  if (!option.vaults?.[0]) {
    return 0
  }

  const collateralToStrikePrice = await getCollateralToStrikePrice(option.strike, oracleContract)
  const { collateral: addedCollateral, oTokensIssued: mintedOTokens } = option.vaults[0]
  return calculateCurrentRatio({
    addedCollateral: new Big(addedCollateral).div('1e18').toString(),
    strikePriceValue: option?.strikePriceValue,
    strikePriceExp: option?.strikePriceExp,
    collateralToStrikePrice,
    mintedOTokens,
  })
}

type CachedPrices = Record<string, BigNumber>
const cachedPrices: CachedPrices = {}

const getEthToTokenPrice = async (token: string, oracleContract: OracleService) => {
  let ethToStrikePrice = cachedPrices[token]
  if (!ethToStrikePrice) {
    // ethToStrikePrice = await oracleContract.getPrice(token)
    let decimals = token === '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599' ? 8 : 18
    let value = await getTokenPriceCoingecko(token)
    ethToStrikePrice = new BigNumber(BigInt(parseInt(value) * 10 ** decimals).toString())
    cachedPrices[token] = ethToStrikePrice
  }

  return ethToStrikePrice
}

// const getCallStrikePrice = (option: GetSellerOptionsByUnderlying_optionsContracts, underlyingDecimals: number) => {
//   return new Big(`1e${-option?.oTokenExchangeRateExp}`)
//     .times(`1e${underlyingDecimals}`)
//     .div(new Big(option?.strikePriceValue || 0).times(`1e${option.strikePriceExp || 0}`))
//     .div(`1e${ETH_TOKEN.decimals}`)
// }

const getCallStrikePrice = (option: GetSellerOptionsByUnderlying_optionsContracts, underlyingDecimals: number) => {
  return new Big(1).div(
    new Big(option?.strikePriceValue)
      .times(`1e${option?.strikePriceExp || 0}`)
      .times(`1e${-option?.oTokenExchangeRateExp || 0}`),
  )
}

const getPutStrikePrice = (option: GetSellerOptionsByUnderlying_optionsContracts) => {
  return new Big(option?.strikePriceValue || 0)
    .times(`1e${option?.strikePriceExp || 0}`)
    .times(`1e${-option?.oTokenExchangeRateExp}`)
}

interface Extras {
  earnAPR: BigFloat
  collateralizationRatio: number
  $strikePrice: Big
  ethToCollateralPrice: BigNumber
  isExpired: boolean
  exerciseActions: any[]
  totalUnderlyingToPay: number
  exercised: boolean
}

export type ExtraData = Record<string, Extras>

export const getExtras = async (
  type: 'call' | 'put',
  data: Array<GetSellerOptionsByUnderlying_optionsContracts>,
  networkId: NetworkId,
  account: string,
  library: Web3Provider,
  oracleContract: OracleService,
) => {
  return await data.reduce(async (acc: Promise<ExtraData>, option: GetSellerOptionsByUnderlying_optionsContracts) => {
    const previous = await acc
    const address = option.id
    const underlyingToken = getTokenFromAddress(networkId, option.underlying)
    const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)

    const isCall = type === 'call' ? true : false

    const [
      earnAPR,
      earnPremium,
      collateralizationRatio,
      $strikePrice,
      ethToCollateralPrice,
      isExpired,
    ] = await Promise.all([
      getEthOptionEarnAPR({
        option,
        networkId,
        optionsExchangeContract,
        isCall,
      }),
      getCollateralEarnPremiums({
        option,
        networkId,
        optionsExchangeContract,
        isCall,
      }),
      getCurrentCollateral({ option, oracleContract }),
      type === 'put' ? getPutStrikePrice(option) : getCallStrikePrice(option, underlyingToken.decimals),
      getEthToTokenPrice(option.collateral, oracleContract),
      getIsExpired(library, option.address),
    ])

    const exercised = getExercised(option)

    return {
      ...previous,
      [address]: {
        earnAPR,
        earnPremium,
        collateralizationRatio,
        $strikePrice,
        ethToCollateralPrice,
        isExpired,
        ...exercised,
      },
    }
  }, Promise.resolve({} as ExtraData))
}

export const useSellerEthPutsOrCalls = (type: 'put' | 'call') => {
  const { networkId, account = '', library } = useConnection()

  const [loading, setIsLoading] = useState(true)
  const [extraData, setExtras] = useState<Maybe<ExtraData>>(null)

  const oracleContract = useOracle()

  const usdc = getTokenOrPlaceHolder(networkId, 'usdc')
  const underlying = type === 'put' ? ETH_TOKEN.address : usdc.address

  const isPut = type === 'put'

  const { options, loading: fetching } = useSellerOptionsByUnderlying(
    underlying,
    type === 'put' ? putsToShow : callsToShow,
    isPut,
    ETH_TOKEN.address,
  )

  const [$ethPrice, setEthPrice] = useState<Maybe<Big>>(null)
  const ethToUsdcPrice = useEthToUsdPrice()
  useEffect(() => {
    if (ethToUsdcPrice.gt(0)) {
      setEthPrice(new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString()))
    }
  }, [ethToUsdcPrice])

  useEffect(() => {
    let isCancelled = false
    if (!fetching && !isCancelled && options && library && oracleContract) {
      const networkIdOrDefault = networkId === -1 ? 1 : networkId
      getExtras(type, options, networkIdOrDefault, account || '', library, oracleContract).then(extras => {
        setExtras(extras)
        setIsLoading(false)
      })
    }

    return () => {
      isCancelled = true
    }
  }, [options, loading, networkId, oracleContract, account, library, fetching, type])

  return { extraData, options, loading: loading || fetching, $ethPrice }
}
