import React, { HTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { Table, Column } from 'react-virtualized/dist/es/Table'
import {
  chevronColumnDefaults,
  columnDefaults,
  tableDefaults,
  chevronCellRenderer,
  tokenCellRenderer,
  compoundTokenMap,
  durationGetter,
  colorCellRenderer,
  minConllateralization,
  convertBigFloatToBig,
} from 'utils'
import { TableOverflow } from 'components/Common/TableOverflow'
import { useHistory } from 'react-router-dom'
import { Index } from 'react-virtualized'
import { Big } from 'big.js'
import { EarnCell } from 'components/Common/EarnCell'

const Wrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TableWrapper = styled(TableOverflow)`
  min-height: 200px;
`

interface AvailableProps extends HTMLAttributes<HTMLDivElement> {
  data: any
}

const earnRenderer = (props: any) => {
  const { cellData } = props
  const { option, extras } = cellData
  const value =
    extras &&
    option &&
    convertBigFloatToBig(extras.earnAPR)
      .div(new Big(option.minCollateralizationRatioValue))
      .times(10)

  return extras && option ? <EarnCell value={value} token={'ETH'} /> : '--'
}

export const Available: React.FC<AvailableProps> = (props: AvailableProps) => {
  const { data, ...restProps } = props
  const history = useHistory()

  const goToAsset = (props: any) => {
    const { rowData } = props

    if (rowData.address) {
      history.push(`/sell/${rowData.address}`)
    }
  }

  const { availableData } = data

  const rowGetter = useCallback(
    (info: Index): any => {
      const { tokens = [], optionsByUnderlying = {} } = availableData || {}
      const { extraData = null } = data || {}
      const cToken = tokens[info.index]
      const underlyingAddress = cToken && cToken.address.toLowerCase()
      const [currentOption] =
        cToken && optionsByUnderlying[underlyingAddress] ? optionsByUnderlying[underlyingAddress] : []

      const extras = extraData && currentOption ? extraData[currentOption.id] : null

      return {
        id: cToken.symbol.toLowerCase(),
        name: compoundTokenMap[cToken.symbol.toLowerCase() as CompoundToken],
        address: currentOption && currentOption.address,
        earn: { option: currentOption, extras },
        minCollateralization: minConllateralization(currentOption),
        duration: durationGetter(currentOption),
        status: 'ok',
      }
    },
    [availableData, data],
  )

  return (
    <Wrapper {...restProps}>
      <TableWrapper>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Table
              {...tableDefaults}
              height={height}
              onRowClick={goToAsset}
              rowCount={availableData && availableData.tokens ? availableData.tokens.length : 0}
              rowGetter={rowGetter}
            >
              <Column
                {...columnDefaults}
                cellRenderer={tokenCellRenderer}
                className="alignLeft"
                dataKey="name"
                headerClassName="alignLeft"
                label="Name"
              />
              <Column {...columnDefaults} label="Earn (Annualized ROI)" dataKey="earn" cellRenderer={earnRenderer} />
              <Column
                {...columnDefaults}
                dataKey="minCollateralization"
                label="Collateralization Requirement"
                width={150}
                cellRenderer={colorCellRenderer}
              />
              <Column {...columnDefaults} label="Expiry" dataKey="duration" cellRenderer={colorCellRenderer} />
              <Column {...chevronColumnDefaults} cellRenderer={chevronCellRenderer} label="" dataKey="status" />
            </Table>
          )}
        </AutoSizer>
      </TableWrapper>
    </Wrapper>
  )
}
