import { useMemo } from 'react'
import { getToken } from '../utils'
import { useConnection } from '../components/web3'

/**
 * Return True if the COMP / BAL option is eth option
 */
export const useTokenOptionIsPut = (option: { underlying?: string; strike?: string } | null | undefined) => {
  const { networkId } = useConnection()
  const isPut = useMemo(() => {
    if (networkId === -1) return true
    if (!option) return true
    // const comp = getToken(networkId, 'comp')
    const usdc = getToken(networkId, 'usdc')
    const dai = getToken(networkId, 'dai')
    const isPut =
      // option?.underlying === comp.address &&
      option?.strike === usdc.address || option?.strike === dai.address
    return isPut
  }, [option, networkId])

  return isPut
}
