import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ContentsWide } from '../../components/Common/CommonStyled'
import { SectionTitle } from '../../components/Common/SectionTitle'
import { Button } from '../../components/Common/Button'
import { ModalInnerCardExtended } from '../../components/Modals/ModalInnerCardExtended'
import { Big, BigSource } from 'big.js'
import { BigNumber } from 'ethers/utils'
import { useConnection } from '../../hooks'
import { ETH_TOKEN, getBaseToken } from '../../utils'
import { getValue } from '../../components/Common/Value'
import useAsyncMemo from '../../hooks/useAsyncMemo'
import { Web3Provider } from 'ethers/providers'
import { useParams } from 'react-router'
import useOToken from '../../hooks/useOToken'
import { calculateMaxOptionsToCreate } from '../../utils'

const Link = styled.a`
  color: #05b169;
  text-decoration: none;
  margin-bottom: 15px;

  &:hover {
    text-decoration: underline;
  }
`
const ButtonStyled = styled(Button)`
  margin-top: 20px;
  margin-bottom: 50px;
`

const validateDeposit = (value: string, max: BigSource) => {
  try {
    const bigValue = new Big(value || 0)
    return !isNaN(Number(value)) && bigValue.gt(-1) && bigValue.lte(max)
  } catch {
    return false
  }
}

const getETHBalance = async (account: Maybe<string>, library: Maybe<Web3Provider>): Promise<BigNumber> => {
  if (account) {
    try {
      const signer = await library?.getSigner()
      return signer?.getBalance() || new BigNumber(0)
    } catch {
      // Do nothing
    }
  }
  return new BigNumber(0)
}

export const CreateOTokens: React.FC = props => {
  const { ...restProps } = props
  const { account, networkId, library } = useConnection()
  const { id } = useParams<{ id: string }>()
  const { service, data, loading } = useOToken(id as KnownToken)
  const { collateralToStrikePrice, strikePriceValue, strikePriceExp, vaults } = data || {}
  const { collateral: addedCollateral = '0', oTokensIssued: mintedOTokens = 0 } = vaults?.[0] || {}
  const token = getBaseToken(networkId, id) || { symbol: id.slice(1) }
  const [inputDeposit, setInputDeposit] = useState('0.00')

  const bigBalance = useAsyncMemo(async () => getETHBalance(account, library), new BigNumber(0), [account, library])

  const balance = useMemo(() => getValue(bigBalance, { decimals: ETH_TOKEN.decimals, precision: 2 }), [bigBalance])

  const updateDeposit = useCallback(
    deposit => {
      if (validateDeposit(deposit, balance)) {
        setInputDeposit(deposit || '0')
      }
    },
    [balance],
  )

  const belowThreshold = new Big(`1e-${ETH_TOKEN.decimals}`).gt(inputDeposit)

  const buttonDisabled = belowThreshold || !account || !service || loading

  const createOTokens = useCallback(async () => {
    if (account && service) {
      const maxTokens = calculateMaxOptionsToCreate({
        collateralToAdd: inputDeposit,
        strikePriceValue,
        strikePriceExp,
        collateralToStrikePrice,
        addedCollateral: new Big(addedCollateral).div('1e18').toString(),
        mintedOTokens,
      })
      const addCollateral = vaults.length ? service.addETHCollateralOption : service.createETHCollateralOption

      setInputDeposit('0')

      await addCollateral(
        maxTokens.toString(),
        account,
        new BigNumber(new Big(inputDeposit).times(`1e${ETH_TOKEN.decimals}`).toFixed(0)),
      )
    }
  }, [
    account,
    service,
    inputDeposit,
    strikePriceValue,
    strikePriceExp,
    collateralToStrikePrice,
    addedCollateral,
    mintedOTokens,
    vaults,
  ])

  return (
    <ContentsWide {...restProps}>
      <SectionTitle
        protocol="ethereum"
        subtitle={`Provide insurance for ${token?.symbol.toLocaleUpperCase()} deposits on Compound and earn premiums`}
      />

      <ModalInnerCardExtended
        title={`Deposit ETH Collateral`}
        titleInfo={[
          { title: 'Initial Collateralization Ratio: ', value: '200%' },
          { title: 'Minimum Collateralization Ratio: ', value: '160%' },
        ]}
        dropdownIsDisabled
        selectedToken={'eth'}
        value={inputDeposit}
        onAmountChange={updateDeposit}
        error={belowThreshold}
      />

      <ButtonStyled onClick={createOTokens} disabled={buttonDisabled}>
        Create oTokens
      </ButtonStyled>

      <h3>
        After you create oTokens (in this case oc{token?.symbol.toLocaleUpperCase()}, protecting Compound{' '}
        {token?.symbol.toLocaleUpperCase()} deposits), you can earn by:
      </h3>
      <ul>
        <li>
          <Link href={`https://uniswap.exchange/swap?inputCurrency=${data ? data.address : ''}`} target="_blank">
            {' '}
            Selling oTokens on Uniswap{' '}
          </Link>
        </li>
        <li>
          <Link href={`https://uniswap.exchange/add-liquidity?token=${data ? data.address : ''}`} target="_blank">
            {' '}
            Being an LP on Uniswap{' '}
          </Link>
        </li>
      </ul>
    </ContentsWide>
  )
}
