import React from 'react'
import styled from 'styled-components'
import { TextLight, TotalTitle, TotalValue } from '../../Common/CommonStyled'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const ValueLight = styled(TextLight)`
  margin-left: 5px;
`

interface Props {
  value: string
  $value: string
}

export const EthCollateral: React.FC<Props> = props => {
  const { value, $value, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>ETH Collateral</TotalTitle>
      <Values>
        <TotalValue>{`${value} ETH`}</TotalValue>
        <TotalValue>
          <ValueLight> ${$value}</ValueLight>
        </TotalValue>
      </Values>
    </Wrapper>
  )
}
