import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { ETH_TOKEN, getToken, isValidNetworkId, Token, getTokenPriceCoingecko } from 'utils'
import { fromUnixTime, format } from 'date-fns'
import { useConnection } from '.'
import { Big } from 'big.js'
import useEthToUsdPrice from './useEthToUSDPrice'
import useKnownTokenToUSDPrice from './useKnownTokenToUSDPrice'
import useOptionsExchange from './useOptionsExchange'
import useAsyncMemo from './useAsyncMemo'
import useOracle from './useOracle'
import { BigNumber } from 'ethers/utils'
import { useMemo } from 'react'
import { OptionsContract, OptionsContract_optionsContract } from 'types/generatedGQL'
import { useERC20Contract } from './useERC20Contract'
import { useTokenOptionIsPut } from './useTokenOptionIsPut'
import { DEFAULT_DATE_FORMAT } from 'common/constants'

const OPTIONS_CONTRACT_QUERY = loader('../queries/options_contract.graphql')

interface TokenOptionData {
  insured?: BigNumber
  option?: OptionsContract_optionsContract
  balance?: {
    amount: BigNumber
    token: Token
    precision?: number
  }
  expiry?: string
  strikePrice?: string
  rawStrikePrice?: Big
  currentPrice?: string
  protectionCost?: string
  status?: string
}

export const useTokenOptionByAddress = (address: Maybe<string>, tokenId: KnownToken) => {
  const { networkId, account } = useConnection()

  const oracleContract = useOracle()

  const { loading, data, error, refetch: reload } = useQuery<OptionsContract>(OPTIONS_CONTRACT_QUERY, {
    variables: {
      id: address,
      account: account?.toLowerCase() || '',
    },
    skip: !address,
  })

  const option = data?.optionsContract

  const isPut = useTokenOptionIsPut(option)

  const strikePriceInWei = useAsyncMemo<BigNumber>(
    async () => {
      if (!option?.strike) return new BigNumber(0)
      // return (await oracleContract?.getPrice(option?.strike)) || new BigNumber(0)

      let price = await getTokenPriceCoingecko(option?.strike)
      // let decimals = getToken(networkId, tokenId)?.decimals
      let priceToWei = (Number(price) * 10 ** 4).toFixed()
      return new BigNumber(priceToWei.toString()) || new BigNumber(0)
    },
    new BigNumber(0),
    [oracleContract, option],
  )

  const ethToUsdcPrice = useEthToUsdPrice()

  const currentEthPrice = ethToUsdcPrice.gt(0)
    ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
    : new Big(0)

  const tokenToUsdcPrice = useKnownTokenToUSDPrice(tokenId)

  const oToken = useERC20Contract(address)
  const optionsExchangeContract = useOptionsExchange(data?.optionsContract?.optionsExchangeAddress)

  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])

  const optionsData = useAsyncMemo(
    async (): Promise<TokenOptionData> => {
      if (
        !isValidNetworkId(networkId) ||
        loading ||
        !option ||
        !daiToken ||
        !usdc ||
        !oracleContract ||
        !optionsExchangeContract
      ) {
        return {}
      }

      const tokenPrice = tokenId === 'weth' ? currentEthPrice : tokenToUsdcPrice

      // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
      const ethToTokenPrice = new Big(Number(await getTokenPriceCoingecko(option?.strike)) * 1e18)

      const rawStrikePrice =
        ethToTokenPrice && strikePriceInWei.gt(0)
          ? new Big(option?.strikePriceValue)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(`1e${-option?.oTokenExchangeRateExp || 0}`)
          : new Big(0)

      const $strikePrice = rawStrikePrice.eq(new Big(0))
        ? new Big(0)
        : isPut
        ? rawStrikePrice
        : new Big(1).div(rawStrikePrice)

      let premiumToPay = new BigNumber(0)
      try {
        const amountToBuy = new Big(new Big(1).times(`1e${-option?.oTokenExchangeRateExp || 0}`).toFixed(0))
        const oTokensToBuy = isPut ? amountToBuy : amountToBuy.times(new Big($strikePrice))
        premiumToPay = await optionsExchangeContract?.getPremiumToPay(
          option.address,
          usdc.address,
          Number(oTokensToBuy.toString()),
        )
      } catch {}
      const balance = option?.holdersBalances?.find(balance => balance.account.address === account)

      const insured = account && oToken ? await oToken?.getBalanceOf(account) : new BigNumber(0)

      // const protectionCostInUSDC = new Big(premiumToPay.toString())
      //   .times(`1e${-option?.oTokenExchangeRateExp}`)
      //   .times(`1e-18`)
      //   .times(1e-4)

      const protectionCostInUSDC = new Big(premiumToPay.toString()).times(`1e-${usdc.decimals}`)

      // const protectionCost = isPut ? protectionCostInUSDC : protectionCostInUSDC.times($strikePrice)
      const protectionCost = protectionCostInUSDC

      return {
        option,
        insured,
        balance: {
          amount: new BigNumber(balance?.amount || 0),
          token: ETH_TOKEN,
          precision: 4,
        },
        rawStrikePrice,
        expiry: format(fromUnixTime(option?.expiry), DEFAULT_DATE_FORMAT),
        strikePrice: $strikePrice.round(2).toFixed(),
        currentPrice: tokenPrice.round(2).toFixed(),
        protectionCost: protectionCost.round(4).toFixed(),
        status: $strikePrice.lt(tokenPrice) ? 'ok' : 'error',
      }
    },
    {},
    [strikePriceInWei, networkId, loading, data, optionsExchangeContract, account, daiToken, usdc, isPut],
  )

  return { optionsData, loading, error, reload, isPut }
}
