import { StepProps, StepStatus } from '../Stepper'

export type StepKeys = 'burn' | 'redeem'

export type StepsState = { [k in StepKeys]: StepProps }

interface Action {
  type: StepStatus
  payload: StepKeys
}

function stepsReducer(state: StepsState, action: Action) {
  if (
    action.type !== 'required' &&
    action.type !== 'current' &&
    action.type !== 'loading' &&
    action.type !== 'completed'
  ) {
    throw new Error()
  }
  const nextStep = {
    ...state[action.payload],
    status: action.type,
  }
  const nextState = {
    ...state,
    [action.payload]: nextStep,
  }
  return nextState
}

export default stepsReducer
