import React from 'react'
import styled from 'styled-components'

import { Big } from 'big.js'
import Value from '../Value'

const Wrapper = styled.div`
  text-align: center;
`

const Token = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

interface EarnPremiumCellProps {
  value?: Big
  token?: string
}

export const EarnPremiumCell: React.FC<EarnPremiumCellProps> = (props: EarnPremiumCellProps) => {
  const { value, token, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <Value value={value ? '$' + value.div(100).toFixed(4) : '$' + 0} />
      <Token>
        {' / '} <br />
        <Value value={1}>{val => `${val} ${token?.toUpperCase()} collateral`}</Value>
      </Token>
    </Wrapper>
  )
}
