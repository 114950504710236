import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Header } from './components/Header'
import BuyLanding from './BuyLanding'
import SellLanding from './SellLanding'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100vw;
`

export const LandingPage: React.FC = () => {
  const [isSell, setIsSell] = useState(false)
  const sellMenuHandler = useCallback(() => setIsSell(true), [])
  const iconMenuHandler = useCallback(() => setIsSell(false), [])

  return (
    <Wrapper>
      <Header sellClickHandler={sellMenuHandler} isSell={isSell} homeIconClickHandler={iconMenuHandler} />
      {isSell ? <SellLanding /> : <BuyLanding />}
    </Wrapper>
  )
}
