import React from 'react'
import styled from 'styled-components'
import { Token, Numeric } from '../../../utils'

import Value from '../Value'

const Symbol = styled.span`
  text-transform: uppercase;
`

export interface TokenBalanceProps {
  token?: Token | string | null
  value?: Numeric
  precision?: number
  placeholder?: any
}

const TokenBalance: React.FC<TokenBalanceProps> = ({ token, value, precision = 2 }: TokenBalanceProps) => {
  const render = (val: string) =>
    typeof token === 'string' ? (
      token === '$' ? (
        `${token}${val}`
      ) : token.charAt(0) === 'o' ? (
        (token.length > 3 && token.slice(1, 4) === 'ETH') || token === 'oToken' ? (
          `${val} ${token}`
        ) : (
          `${val} ${token.charAt(0).concat(token.slice(1).toLocaleUpperCase())}`
        )
      ) : token === 'Calls' ? (
        `${val} ${token}`
      ) : (
        `${val} ${token.toLocaleUpperCase()}`
      )
    ) : (
      <>
        {val}&nbsp;<Symbol key="symbol">{token?.symbol}</Symbol>
      </>
    )
  const decimals = typeof token === 'string' ? 0 : (token && token.decimals) || 0

  return (
    <Value value={value || 0} decimals={decimals} precision={precision}>
      {render}
    </Value>
  )
}

export default TokenBalance
