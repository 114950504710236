import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface SliderProps extends HTMLAttributes<HTMLInputElement> {
  min: number
  max: number
  step: number
  value: number
  title?: string
  labelStep?: number
}

const SliderInput = styled.input.attrs(props => ({
  type: 'range',
  min: props.min,
  max: props.max,
  step: props.step,
}))`
  width: 100%;
  min-height: 36px;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 1ms;

  // Remove native focus indicator
  :focus {
    box-shadow: none;
    outline: none;
  }

  ::-moz-focus-outer {
    border: 0;
  }

  // Thumb
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5em;
    height: 3em;
    background: #05b169;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    border-radius: 150px;
    margin-top: -12px;
  }

  ::-moz-range-thumb {
    position: relative;
    width: 1.5em;
    height: 3em;
    background: #05b169;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    border-radius: 150px;
    margin-top: -12px;
  }

  ::-ms-thumb {
    width: 1.5em;
    height: 3em;
    background: #05b169;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    border-radius: 150px;
    margin-top: -12px;
  }

  // Track
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    background: ${props => props.theme.colors.gray}
      linear-gradient(${props => props.theme.colors.primary}, ${props => props.theme.colors.primary}) no-repeat;
    border-radius: 150px;
    pointer-events: none;

    ${({ min, max, value }: SliderProps) => {
      if (min && max && value) {
        const percentage = ((value - min) * 100) / (max - min)
        return css`
          background-size: ${percentage}%;
        `
      }
    }}
  }

  ::-moz-range-track {
    width: 100%;
    height: 11px;
    background: ${props => props.theme.colors.gray};
    border-radius: 150px;
  }

  ::-moz-range-progress {
    height: 11px;
    background: ${props => props.theme.colors.primary};
    border-radius: 150px;
    margin-top: 0;
  }

  ::-ms-track {
    width: 100%;
    height: 11px;
    margin-top: 10px;
    box-sizing: border-box;
    background: ${props => props.theme.colors.gray};
    border: 0 transparent;
    border-radius: 150px;
  }

  ::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
    border-radius: 150px;
  }

  ::-ms-tooltip {
    display: none;
  }
`

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  /* padding: 13px 0; */
`

const ProgressValues = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProgressValue = styled.span`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
`

interface ToolTipProps {
  position: number
}

const ToolTipWrapper = styled.div`
  height: 13px;
  position: relative;
`

const ToolTipValue = styled.div`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  display: inline-block;
  position: absolute;
  left: ${(props: ToolTipProps) => props.position}%;
  transform: translateX(-${(props: ToolTipProps) => (props.position * 70) / 100}%);
  word-break: normal;
  white-space: nowrap;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 41px;
  color: ${props => props.theme.colors.textColorLight};
`

const Slider: React.FC<SliderProps> = (props: SliderProps) => {
  const { min, max, value, title, labelStep = 10 } = props

  const currentPos = ((value - min) * 100) / (max - min)

  return (
    <SliderWrapper>
      {title && <Title>{title}</Title>}
      <ToolTipWrapper>
        <ToolTipValue position={currentPos}>{`${value}%`}</ToolTipValue>
      </ToolTipWrapper>
      <SliderInput {...props} />
      <ProgressValues>
        {Array.from({ length: (max - min) / labelStep + 1 }, (v, i) => i).map((num, i) => {
          return <ProgressValue key={`progress-${num}`}>{`${min + i * labelStep}%`}</ProgressValue>
        })}
      </ProgressValues>
    </SliderWrapper>
  )
}

export default Slider
