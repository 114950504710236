import React from 'react'

export const GithubIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="iconBackground"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M10.0001 0C4.47791 0 0 4.47754 0 10.0013C0 14.4202 2.86533 18.1692 6.83867 19.4917C7.33844 19.5842 7.52192 19.2747 7.52192 19.0105C7.52192 18.772 7.51265 17.9842 7.50834 17.1485C4.72631 17.7535 4.13927 15.9684 4.13927 15.9684C3.68437 14.8124 3.02894 14.505 3.02894 14.505C2.12163 13.8843 3.09733 13.897 3.09733 13.897C4.10151 13.9674 4.63026 14.9277 4.63026 14.9277C5.52217 16.4567 6.96965 16.0146 7.5403 15.7591C7.63006 15.1128 7.88921 14.6713 8.1752 14.4217C5.95405 14.1688 3.61913 13.3112 3.61913 9.47893C3.61913 8.387 4.00977 7.4948 4.64947 6.79439C4.54564 6.54232 4.20335 5.52525 4.74635 4.14762C4.74635 4.14762 5.58609 3.87882 7.49708 5.17281C8.29476 4.95121 9.15024 4.84008 10.0001 4.83627C10.8499 4.84008 11.7061 4.95121 12.5052 5.17281C14.4139 3.87882 15.2525 4.14762 15.2525 4.14762C15.7968 5.52525 15.4544 6.54232 15.3505 6.79439C15.9917 7.4948 16.3797 8.387 16.3797 9.47893C16.3797 13.3203 14.0403 14.1661 11.8135 14.4137C12.1722 14.7241 12.4918 15.3328 12.4918 16.2659C12.4918 17.6039 12.4802 18.6809 12.4802 19.0105C12.4802 19.2767 12.6602 19.5885 13.1671 19.4903C17.1383 18.1664 20 14.4187 20 10.0013C20 4.47754 15.5227 0 10.0001 0Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78753 14.3597C3.76551 14.4096 3.68734 14.4243 3.61614 14.3902C3.54361 14.3576 3.50287 14.2899 3.52638 14.24C3.54791 14.1888 3.62624 14.1747 3.6986 14.2087C3.7713 14.2415 3.8127 14.3099 3.78753 14.3597Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1926 14.8116C4.14491 14.8558 4.05168 14.8353 3.98842 14.7654C3.92301 14.6956 3.91076 14.6024 3.95911 14.5575C4.00829 14.5133 4.09871 14.534 4.16429 14.6037C4.2297 14.6743 4.24245 14.7669 4.1926 14.8116Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58687 15.3876C4.5256 15.4301 4.42541 15.3902 4.36348 15.3013C4.30221 15.2124 4.30221 15.1057 4.3648 15.063C4.4269 15.0202 4.5256 15.0587 4.58836 15.1469C4.64946 15.2372 4.64946 15.344 4.58687 15.3876Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12704 15.9441C5.07222 16.0045 4.95548 15.9883 4.87003 15.9058C4.78259 15.8252 4.75825 15.7107 4.81323 15.6503C4.8687 15.5896 4.98611 15.6067 5.07222 15.6885C5.159 15.769 5.18549 15.8843 5.12704 15.9441Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.87222 16.2672C5.84804 16.3455 5.7356 16.3811 5.62233 16.3478C5.50923 16.3136 5.43521 16.2218 5.45806 16.1426C5.48158 16.0638 5.59451 16.0267 5.70861 16.0623C5.82155 16.0964 5.89574 16.1875 5.87222 16.2672Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69065 16.3271C6.69347 16.4096 6.59742 16.478 6.47852 16.4793C6.35896 16.4822 6.26225 16.4154 6.26093 16.3343C6.26093 16.251 6.35482 16.1832 6.47438 16.1812C6.59328 16.1789 6.69065 16.2452 6.69065 16.3271Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45218 16.1975C7.46642 16.278 7.38379 16.3606 7.26572 16.3825C7.14964 16.4038 7.04216 16.3541 7.02742 16.2743C7.01302 16.1918 7.09714 16.1092 7.21306 16.0878C7.3313 16.0673 7.43711 16.1156 7.45218 16.1975Z"
        fill="#BDBDBD"
      />
    </svg>
  )
}
