import React from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { Button } from 'components/Common/Button'
import { ThemeColorTypes, Token } from 'utils'
import DoubleBalance from 'components/Common/DoubleBalance'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 20px;
  color: ${props => props.theme.colors.textColorMedium};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.8;
  text-align: left;
`

const Rows = styled.div`
  margin: 0 0 10px;
`
const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  padding: 12px 0;
`

const RowTitle = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

interface ModalProps extends ModalBasicProps {
  onRequestClose?: (e?: any) => void
  isOpen: boolean
  content?: React.ReactNode
  amount?: string
  $amount?: string
  token?: Maybe<Token>
  amountLabel?: string
  onConfirm?: () => void
}

const RedeemModal = (props: ModalProps) => {
  const { content, amountLabel = '', amount, $amount, token, onConfirm, ...restProps } = props

  return (
    <Modal {...restProps}>
      {content && <Content>{content}</Content>}

      <Rows>
        <Row>
          <RowTitle>{amountLabel || token?.symbol?.toLocaleUpperCase()}</RowTitle>
          <RowValue>
            <DoubleBalance left={{ value: amount, token }} right={{ value: $amount, token: '$' }} />
          </RowValue>
        </Row>
      </Rows>

      <Button onClick={onConfirm} buttonStyle={ThemeColorTypes.secondary}>
        Redeem
      </Button>
    </Modal>
  )
}

export default RedeemModal
