import React, { useState, useEffect, useCallback } from 'react'
import { useWeb3Context } from 'web3-react'

import { WalletOptions, Connectors, ConnectionState, Connection, getNetworkName, ReadOnlyConnectors } from '../'

export const storageKeySelectedProvider = 'web3SelectedProvider'

type Props = {
  children: React.ReactNode
}

const ConnectionContext = React.createContext<Maybe<Connection>>(null)

/**
 * This hook can only be used by components under the `Web3ReactConnector` component. Otherwise it will throw.
 */
export const useConnection = (): Connection => {
  const context = React.useContext(ConnectionContext)

  if (!context) {
    throw new Error('Component rendered outside the provider tree')
  }

  return context
}

const Web3ReactConnector: React.FC<Props> = props => {
  const context = useWeb3Context()
  const [state, setState] = useState(ConnectionState.DISCONNECTED)
  const [readOnly, setReadOnly] = useState(true)
  const [error, setError] = useState<Maybe<Error>>(null)
  const [initiated, setInitiated] = useState(false)

  const connect = useCallback(
    (connector: string) => {
      if (connector in Connectors) {
        if (connector in WalletOptions) {
          localStorage.setItem(storageKeySelectedProvider, connector)
        } else {
          localStorage.removeItem(storageKeySelectedProvider)
        }
        context.setConnector(connector)
        setState(ConnectionState.CONNECTING)
        setReadOnly(connector in ReadOnlyConnectors)
      } else {
        throw new Error(`Invalid connector '${connector}'`)
      }
    },
    [context],
  )

  const disconnect = useCallback(
    (clearLocalStorage = true) => {
      if (clearLocalStorage) {
        localStorage.removeItem(storageKeySelectedProvider)
      }
      context.unsetConnector()
      setState(ConnectionState.DISCONNECTED)
      setReadOnly(true)
    },
    [context],
  )

  // componentDidMount
  useEffect(() => {
    if (!initiated) {
      // Check in localStorage if there is a chosen provider
      const storageChosenProvider = localStorage.getItem(storageKeySelectedProvider)
      if (storageChosenProvider && storageChosenProvider in WalletOptions) {
        connect(storageChosenProvider)
      } else {
        connect('Infura')
      }
      setInitiated(true)
    }
  }, [connect, initiated])

  // componentDidUpdate
  useEffect(() => {
    if (context.active) {
      setState(readOnly || context.account ? ConnectionState.CONNECTED : ConnectionState.CONNECTING)
    } else {
      setState(ConnectionState.DISCONNECTED)
    }
  }, [connect, readOnly, context.active, context.account, context.networkId])

  useEffect(() => {
    if (context.error) {
      disconnect()
      setError(new Error('User denied account authorization'))
    }
  }, [context.error, disconnect, setError])

  const connection: Connection = {
    account: context.account || '',
    networkId: context.networkId || 1,
    networkName: getNetworkName(context.networkId || 1) || '',
    state,
    readOnly,
    error,
    isConnected: state === ConnectionState.CONNECTED,
    isConnecting: state === ConnectionState.CONNECTING,
    isDisconnected: state === ConnectionState.DISCONNECTED,
    connectReadOnly: connect.bind(null, 'Infura'),
    connectWallet: (wallet = WalletOptions.MetaMask) => connect(wallet),
    cleanError: setError.bind(null, null),
    library: context.library,
    web3Context: context,
  }

  return <ConnectionContext.Provider value={connection}>{props.children}</ConnectionContext.Provider>
}

export default Web3ReactConnector
