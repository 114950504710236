import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/Common/SectionTitle'
import { ThemeColorTypes, etherscanTx } from 'utils'
import { useParams, useHistory } from 'react-router-dom'
import {
  useConnection,
  useTokenOptionByAddress,
  useAsyncMemo,
  useBuyingTransactionHistoryByOToken,
  useUniswapFactory,
  useERC20Contract,
} from 'hooks'
import { ContentsNarrow } from 'components/Common/CommonStyled'
import { ButtonBack } from 'components/Common/ButtonBack'
import { GenericTokenCard, InfoRow } from 'components/GenericTokenCard'
import { BigNumber } from 'ethers/utils'
import DoubleBalance from 'components/Common/DoubleBalance'
import { Big } from 'big.js'
import Value from 'components/Common/Value'
import { TextLight } from 'components/Common/CommonStyled'
import TokenBalance from 'components/Common/TokenBalance'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import { Button } from 'components/Common/Button'
import { ExerciseCard } from 'components/ExerciseCard'
import { TransactionsCard } from 'components/TransactionsCard'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { BuyETHInsuranceModal } from 'components/Modals/BuyETHInsuranceModal'
import { useLastLocation } from 'react-router-last-location'
import { FailedTransactionModal } from 'components/Modals/FailedTransactionModal'
import { Share } from '../../components/Common/Share'

const TitleWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr ${props => props.theme.innerScrollContainer.narrowWidth} 1fr;
  margin-bottom: 24px;
`

const TitleStyled = styled(SectionTitle)`
  margin-bottom: 0;
`

const ContentsNarrowStyled = styled(ContentsNarrow)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-right: 20px;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const BuyUniOptionDetail: React.FC<any> = () => {
  const { address } = useParams<{ address: string }>()
  const { connectWallet, networkId, readOnly } = useConnection()
  const history = useHistory()

  const { optionsData, loading, reload: reloadOptionsData, isPut } = useTokenOptionByAddress(address, 'uni')
  const { currentPrice, protectionCost, expiry, strikePrice, option, rawStrikePrice } = optionsData

  const insured =
    optionsData && optionsData?.insured
      ? isPut
        ? optionsData?.insured
        : optionsData?.insured.div(strikePrice || '1')
      : new BigNumber(0)

  const uniswapFactoryContract = useUniswapFactory()
  const oTokenERC20Contract = useERC20Contract(option?.address)

  const lastLocation = useLastLocation()

  const [isBuyProtectionModalOpen, setBuyProtectionModalState] = useState(false)

  const [transactionFailed, setTransactionFailed] = useState(false)
  const closeBuyProtectionModal = useCallback(() => setBuyProtectionModalState(false), [])
  const openBuyProtectionModal = useCallback(() => setBuyProtectionModalState(true), [])

  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')
  const [confirmModalTitle, setConfirmModalTitle] = useState('Confirm Protection')

  const buyingTransactionHistory = useBuyingTransactionHistoryByOToken(address)

  const handleConnectButton = () => connectWallet()

  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)
        const oTokenBalance =
          (await oTokenERC20Contract?.getBalanceOf(exchangeAddress)) / Number(10 ** -option.oTokenExchangeRateExp)
        let rounding = oTokenBalance > 100 ? 0 : 2
        const displayAvailabeTokens = oTokenBalance.toFixed(rounding)

        return displayAvailabeTokens.toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  const $insured = new Big(insured.toString())
    .times(`1e-${-option?.oTokenExchangeRateExp || 0}`)
    .times(currentPrice || 0)
    .round(2)
    .toFixed()

  const handleButtonBackClick = useCallback(() => {
    if (lastLocation) {
      history.push(lastLocation.pathname, lastLocation.state)
    } else {
      history.push('/buy')
    }
  }, [history, lastLocation])

  // to be used later
  const [lastUpdate, setLastUpdate] = useState(Date.now())
  const reload = useCallback(() => {
    reloadOptionsData?.()
    setLastUpdate(Date.now())
  }, [reloadOptionsData])

  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload()
  }, [reload])

  const openConfirmModal = useCallback(
    (tx, title = 'Confirm Protection') => {
      closeBuyProtectionModal()
      setConfirmModalTitle(title)
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeBuyProtectionModal],
  )

  const isProtected = insured.gt(0)

  const oToken = {
    address: option?.address,
    symbol: 'UNI',
    decimals: -option?.oTokenExchangeRateExp,
  }

  const oTokenSymbol = isPut ? 'oUNIp' : 'oUNIc'

  const availableTokenInfos =
    isPut || !strikePrice ? (
      <TokenBalance token={oTokenSymbol} value={availableOTokens} />
    ) : (
      [
        <TokenBalance token={oTokenSymbol} value={availableOTokens} key={'oToken'} />,
        <TextLight key="calls">
          <Value value={new Big(availableOTokens).div(strikePrice).toFixed(2)}>{val => ` / ${val} Calls`}</Value>
        </TextLight>,
      ]
    )

  const oTokenOwnedHumanReadable =
    optionsData && optionsData.insured
      ? new Big(optionsData?.insured.toString()).times(`1e${option?.oTokenExchangeRateExp}`)
      : new Big(1)

  const tokenCardProtectedRows: InfoRow[] = [
    {
      title: 'Available Liquidity In Uniswap',
      value: availableTokenInfos,
    },
    {
      title:
        isPut || !strikePrice || !insured ? (
          'UNI Protected'
        ) : (
          <TextWithTooltip
            text="UNI Covered"
            tooltipText={`You own ${oTokenOwnedHumanReadable.toFixed(
              3,
            )} oUNIc, which cover ${oTokenOwnedHumanReadable.div(strikePrice).toFixed(4)} ETH`}
            id="protected"
            place="right"
          />
        ),
      value: (
        <DoubleBalance
          left={{
            token: oToken,
            value: insured,
            precision: 4,
          }}
          right={{
            token: '$',
            value: $insured,
            precision: 4,
          }}
        />
      ),
    },
    {
      title: 'Protection Cost',
      value: (
        <DoubleBalance
          left={{
            token: '$',
            value: Number(protectionCost),
          }}
          right={{
            token: 'UNI',
            value: 1,
          }}
          separator=" / "
        />
      ),
    },
    {
      title: 'Total Premium Paid',
      value: `$${buyingTransactionHistory[4] ? buyingTransactionHistory[4].toFixed(4) : 0}`,
    },
    {
      title: 'Expiry',
      value: expiry,
    },
    {
      title: 'Current UNI Price',
      value: <TokenBalance token="$" value={currentPrice} />,
    },
    {
      title: (
        <TextWithTooltip
          text="Strike Price"
          tooltipText={
            isPut
              ? "If UNI falls below the strike price, you can redeem your UNI for the value of the strike price. <a href='https://opyn.gitbook.io/opynv1/faq' target='_blank'>Learn more.</a>"
              : "If UNI rises above the strike price, you can buy UNI at the strike price. <a href='https://opyn.gitbook.io/opynv1/faq' target='_blank'>Learn more.</a>"
          }
          id="strike-price"
          place="right"
        />
      ),
      value: <TokenBalance token="usdc" value={strikePrice} />,
    },
  ]

  const tokenCardUnprotectedRows: InfoRow[] = [
    {
      title: 'Available Liquidity In Uniswap',
      value: availableTokenInfos,
    },
    {
      title: 'Protection Cost',
      value: (
        <DoubleBalance
          left={{
            token: '$',
            value: Number(protectionCost),
          }}
          right={{
            token: 'UNI',
            value: 1,
          }}
          separator=" / "
        />
      ),
    },
    {
      title: 'Expiry',
      value: expiry,
    },
    {
      title: 'Current UNI Price',
      value: <TokenBalance token="$" value={currentPrice} />,
    },
    {
      title: (
        <TextWithTooltip
          text="Strike Price"
          tooltipText={
            isPut
              ? "If UNI falls below the strike price, you can redeem your UNI for the value of the strike price. <a href='https://opyn.gitbook.io/opynv1/faq' target='_blank'>Learn more.</a>"
              : "If UNI rises above the strike price, you can buy UNI at the strike price. <a href='https://opyn.gitbook.io/opynv1/faq' target='_blank'>Learn more.</a>"
          }
          id="strike-price"
          place="right"
        />
      ),
      value: <TokenBalance token="usdc" value={strikePrice} />,
    },
  ]

  const disableModal = currentPrice && Number(currentPrice) > 0 ? false : true

  const buttons = readOnly
    ? [
        <Button key={'btn-connect'} onClick={handleConnectButton} buttonStyle={ThemeColorTypes.tertiary}>
          Connect Wallet
        </Button>,
      ]
    : [
        <Button
          key={'buy'}
          onClick={openBuyProtectionModal}
          buttonStyle={ThemeColorTypes.primary}
          disabled={disableModal}
        >
          Continue Purchase
        </Button>,
      ]

  const tokenCardRows = isProtected ? tokenCardProtectedRows : tokenCardUnprotectedRows

  return (
    <>
      <TitleWrapper>
        <ButtonBack onClick={handleButtonBackClick} />
        <TitleStyled
          protocol="uni"
          title={isPut ? 'Buy Protective Put Options on UNI' : 'Buy Protective Call Options on UNI'}
        />
      </TitleWrapper>
      <ContentsNarrowStyled>
        <Content>
          <Stack>
            <GenericTokenCard tokenId={'uni'} rows={tokenCardRows} buttons={buttons} />
            {isBuyProtectionModalOpen && (
              <BuyETHInsuranceModal
                isPut={isPut}
                isOpen={isBuyProtectionModalOpen}
                onConfirm={openConfirmModal}
                onTransactionFail={onTransactionFail}
                onRequestClose={closeBuyProtectionModal}
                title="Buy Protection"
                insured={insured}
                option={option}
                strikePrice={strikePrice}
              />
            )}
            {isConfirmModalOpen && (
              <ConfirmModal
                title={confirmModalTitle}
                isOpen={isConfirmModalOpen}
                onRequestClose={closeConfirmModal}
                url={etherscanTx({
                  networkId,
                  hash: recentTransactionHash,
                })}
              />
            )}
          </Stack>
          <Stack>
            {!readOnly && (
              <ExerciseCard
                isPut={isPut}
                strikePrice={rawStrikePrice}
                displayStrikePrice={strikePrice}
                loading={loading}
                reload={reload}
                optionsData={optionsData}
                rawHistory={buyingTransactionHistory[1]}
              />
            )}
            {!readOnly && <TransactionsCard lastUpdate={lastUpdate} historicalData={buyingTransactionHistory} />}
          </Stack>
        </Content>
      </ContentsNarrowStyled>
      <Share />
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
    </>
  )
}
