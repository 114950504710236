export const LOGGER_ID: string = process.env.REACT_APP_LOGGER_ID || 'opyn-insurance'
export const INFURA_PROJECT_ID: string = process.env.REACT_APP_INFURA_PROJECT_ID || 'a572b01d36094d899cdcc6aa9eb2afc8'
export const EXCHANGE_RATE_DECIMALS = 18
export const APR_DECIMALS = 16 // 18 - 2 (for the percentage)
export const BLOCKS_PER_DAY = 5760
export const MAKER_TOKENS: Array<KnownToken> = ['dai', 'usdc']
export const COMPOUND_TOKENS: Array<CompoundToken> = ['cusdc', 'cdai']
export const KNOWN_TOKENS: Array<KnownToken> = [...COMPOUND_TOKENS, ...MAKER_TOKENS]
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const EXPIRY_DATE_FORMAT = 'MMM do yyyy HH:mm zzz'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
