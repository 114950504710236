import React from 'react'
// import { CompoundInsured } from '../../components/BuyDash/CompoundInsured'
import { WethInsured } from '../../components/BuyDash/BuyWethPut'
import { UniInsured } from '../../components/BuyDash/BuyUniPut'
import { UniCall } from '../../components/BuyDash/BuyUniCall'
import { DydxInsured } from '../../components/BuyDash/BuyDydxPut'
import { DydxCall } from '../../components/BuyDash/BuyDydxCall'
import { WbtcInsured } from '../../components/BuyDash/BuyWbtcPut'
import { WbtcCall } from '../../components/BuyDash/BuyWbtcCall'
import { SnxInsured } from '../../components/BuyDash/BuySnxPut'
import { SnxCall } from '../../components/BuyDash/BuySnxCall'
import { DpiInsured } from '../../components/BuyDash/BuyDpiPut'
import { DpiCall } from '../../components/BuyDash/BuyDpiCall'
import { COMPInsured } from '../../components/BuyDash/BuyCompPut'
import { BalInsured } from '../../components/BuyDash/BuyBalPut'
import { YfiInsured } from '../../components/BuyDash/BuyYfiPut'
import { YfiCall } from '../../components/BuyDash/BuyYfiCall'
import { CrvInsured } from '../../components/BuyDash/BuyCrvPut'
import { ContentsWide } from '../../components/Common/CommonStyled'
// restore ETH puts. uncomment this
// import { ETHPut } from '../../components/BuyDash/BuyETHPut'
import { ETHCall } from '../../components/BuyDash/BuyETHCall'
import { Share } from '../../components/Common/Share'

const Buy: React.FC = props => {
  const { ...restProps } = props

  return (
    <ContentsWide {...restProps}>
      {/* <ETHPut /> */}
      {/* <div style={{ margin: '10px 40px' }}>
        <h4>
          There was a recent vulnerability with the ETH puts. Affected users, please see{' '}
          <a
            href="https://medium.com/opyn/opyn-eth-put-exploit-c5565c528ad2?postPublishedType=repub"
            target="_blank"
            rel="noopener noreferrer"
          >
            this Medium post
          </a>
          .
          <br />
          <br />
          Please do not create any new oETH put vaults or buy/sell oETH puts except with the Opyn team, through the
          process defined in the above Medium post.
          <br />
          <br />
          For any questions, see{' '}
          <a href="https://tiny.cc/opyndiscord" target="_blank" rel="noopener noreferrer">
            Discord
          </a>
        </h4>
        .
      </div> */}
      <WethInsured />
      <ETHCall />
      <DpiInsured />
      <DpiCall />
      <UniInsured />
      <UniCall />
      <DydxInsured />
      <DydxCall />
      <WbtcInsured />
      <WbtcCall />
      <SnxInsured />
      <SnxCall />
      <YfiInsured />
      <YfiCall />
      <COMPInsured />
      <BalInsured />
      <CrvInsured />
      {/* <CompoundInsured /> */}
      <Share />
    </ContentsWide>
  )
}

export { Buy }
