import React, { HTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { Table, Column } from 'react-virtualized/dist/es/Table'
import {
  expiryColumnDefaults,
  chevronColumnDefaults,
  columnDefaults,
  tableDefaults,
  chevronCellRenderer,
  convertBigFloatToBig,
  expiryRenderer,
} from 'utils'
import { TableOverflow } from 'components/Common/TableOverflow'
import { useHistory } from 'react-router-dom'
import { Index } from 'react-virtualized'
import { Big } from 'big.js'
import { EarnCell } from 'components/Common/EarnCell'
import { EarnPremiumCell } from 'components/Common/EarnPremiumCell'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import Value from 'components/Common/Value'

const Wrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TableWrapper = styled(TableOverflow)`
  min-height: ${props => props.maxHeight}px;
`

const ColWithTooltip = styled(TextWithTooltip)`
  justify-content: center;
`

interface AvailableProps extends HTMLAttributes<HTMLDivElement> {
  data: any
}

const earnRenderer = (props: any) => {
  const { cellData } = props
  const { option, extras } = cellData
  const value =
    extras &&
    option &&
    convertBigFloatToBig(extras.earnAPR)
      .div(new Big(option.minCollateralizationRatioValue))
      .times(10)

  return extras && option ? <EarnCell value={value} token={'USDC'} /> : '--'
}

const earnPremiumRenderer = (props: any) => {
  const { cellData } = props
  const { option, extras } = cellData

  const value =
    extras &&
    option &&
    convertBigFloatToBig(extras.earnPremium)
      .div(new Big(option.minCollateralizationRatioValue))
      .times(10)

  return extras && option ? <EarnPremiumCell value={value} token={'USDC'} /> : '--'
}
const strikePriceRenderer = (props: any) => {
  const { cellData } = props
  const { extras } = cellData

  const value = extras ? `${extras.$strikePrice.toFixed()}USDC` : '--'

  return <Value value={value} />
}

const ethPriceRenderer = (props: any) => {
  const { cellData } = props

  const value = cellData ? `$${cellData.toFixed(2)}` : '--'

  return <Value value={value} />
}

export const Available: React.FC<AvailableProps> = (props: AvailableProps) => {
  const { data, ...restProps } = props
  const history = useHistory()

  const goToAsset = (props: any) => {
    const { rowData } = props

    if (rowData.address) {
      history.push(`/sell/unioption/${rowData.address}`)
    }
  }

  const { availableData } = data

  // let dataToShow = availableData && availableData.length > 0 ? [availableData[0]] : availableData
  // availableData = dataToShow

  const rowGetter = useCallback(
    (info: Index): any => {
      const { extraData = null, currentPrice = null } = data || {}
      const option = availableData[info.index]

      const extras = extraData && option ? extraData[option.id] : null

      return {
        id: option.id,
        address: option && option.address,
        earn: { option, extras },
        expiry: {
          timestamp: option?.expiry,
          isExpired: extras?.isExpired,
        },
        strikePrice: { option, extras },
        currentPrice: currentPrice,
        status: 'ok',
      }
    },
    [availableData, data],
  )

  return (
    <Wrapper {...restProps}>
      <TableWrapper maxHeight={(((availableData ? availableData.length : 0) + 2) * 66).toString()}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Table
              {...tableDefaults}
              height={height}
              onRowClick={goToAsset}
              rowCount={availableData ? availableData.length : 0}
              rowGetter={rowGetter}
            >
              <Column
                {...columnDefaults}
                label={
                  <ColWithTooltip
                    text="Earn (Annualized ROI)"
                    tooltipText={
                      'Potential earnings for selling UNI put options for a year (annualized percentage). If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>'
                    }
                    id="earn-eth-put-roi"
                    place="right"
                  />
                }
                dataKey="earn"
                cellRenderer={earnRenderer}
              />
              <Column
                {...columnDefaults}
                label={
                  <ColWithTooltip
                    text="Earn Premium"
                    tooltipText={
                      'Earnings received upfront for selling UNI put options until expiry. If someone exercises their option, you could lose some or all of your collateral. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>'
                    }
                    id="earn-eth-put-premium"
                    place="right"
                  />
                }
                dataKey="earn"
                cellRenderer={earnPremiumRenderer}
              />
              <Column {...expiryColumnDefaults} label="Expiry" dataKey="expiry" cellRenderer={expiryRenderer} />
              <Column
                {...columnDefaults}
                label={
                  <ColWithTooltip
                    text="Strike Price"
                    tooltipText={
                      'If UNI falls below the strike price, you will have to pay out the protection buyer with some or all your collateral. <a href="https://opyn.gitbook.io/opynv1/faq#what-happens-in-the-case-of-an-adverse-event" target="_blank">Learn more.</a>'
                    }
                    id="strike-price"
                    place="right"
                  />
                }
                dataKey="strikePrice"
                cellRenderer={strikePriceRenderer}
              />
              <Column
                {...columnDefaults}
                label="Current UNI Price"
                dataKey="currentPrice"
                cellRenderer={ethPriceRenderer}
              />
              <Column {...chevronColumnDefaults} cellRenderer={chevronCellRenderer} label="" dataKey="status" />
            </Table>
          )}
        </AutoSizer>
      </TableWrapper>
    </Wrapper>
  )
}
