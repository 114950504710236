import { useState, useMemo, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GetOptionsContractsByUnderlying, GetOptionsContractsByUnderlying_optionsContracts } from 'types/generatedGQL'
import {
  ETH_TOKEN,
  Token,
  getCollateralEarnPremiums,
  BigFloat,
  getToken,
  isValidNetworkId,
  getTokenOrPlaceHolder,
} from 'utils'
import { fromUnixTime } from 'date-fns'
import { format } from 'date-fns-tz'
import { Big } from 'big.js'
import useEthToUsdPrice from './useEthToUSDPrice'
import useKnownTokenToUSDPrice from './useKnownTokenToUSDPrice'
import { loader } from 'graphql.macro'
import useOracle from './useOracle'
import { BigNumber } from 'ethers/utils'
import { getUnixTime } from 'date-fns'
import { OptionsExchange } from 'services'
import { EXPIRY_DATE_FORMAT } from 'common/constants'

import { useConnection } from '.'
import useAsyncMemo from './useAsyncMemo'

const OPTION_CONTRACT_BY_UNDERLYING_QUERY = loader('../queries/options_contracts_by_underlying.graphql')

export interface InsuredTokenOptionsData {
  option: GetOptionsContractsByUnderlying_optionsContracts
  balance: {
    amount: BigNumber
    token: Token
    precision: number
  }
  expiry: string
  strikePrice: string
  currentPrice: string
  protectionCost: string
  protectionReturn: BigFloat
  status: string
  type: string
}

export const whitelist = [
  '0xd12a0a8b672477d76a1d5451b72a0ba8b1e624c4',
  '0x2bf4bda049275b33d182d51995d2abecdf9f5c7a',
  '0x7aa5e66a3cd1054c3a465ef25b33c9ba47cb8c99',
  '0x5562c33c383f6386be4f6dcdbd35a3a99bbcfde6',
  '0xd1cec2f67fdc4c60e0963515dfc3343f31e32e47',
  '0x9215bd49b59748419eac6bad9dbe247df06ebdb9',
  '0x15844029b2c2bf24506e9937739a9a912f1e4354',
  '0xc6b11850241c5127eab73af4b6c68bc267cbbff4',
  '0xe951ebe6b4420ab3f4844cf36dedd263d095b416',
  '0x31f88266301b08631f9f0e33fd5c43c2a5d1e5b2',
  '0x0578779e746d7186253a36cf651ea786acfcf087',
  '0xdb0991dfc7e828b5a2837dc82d68e16490562c8d',
  '0x452b421be5b30f0c6ad8c3f03c06bdaab4f5c56c',
  '0x71d97cde37fae42e429f5a37c5d25ad9080f4c2d',
  '0x22003eede2efd08370dc4cf4f58c26844a1f96ab',
  '0x73c2a775d0f1517701422b6f47413270099245de',
  '0xd2ca7c3285c784270c4a2b69d6343bc11f163690',
  '0x6181cc7d990bb518fb9a9427994b5c74fa6c9b54',
  '0xeed9c24a7e9c952b1eff581c0c0f71ac09c341e9',
  '0x4506f130344da58567b6a61a5731fa2c71db3ea4',
  '0x8d25b3843e3918d0f8d1687f0d3c733065d8d32f',
  '0xd1467d8e6eff2381d62e770cf021e6addbf5d6d7',
  '0x88137b53b70064d674058e37873a6683ce281045',
  '0x8722d9558077dc8424352f4407bc704f62c386f7',
  '0x1f22cba297de123dcf007a517cb97721b0736148',
  '0x5baa0a75f9b4c06564616e2079b1518f6267acff',
  '0x7f3b45c11abcb3b05d266910f34024855d501ef0',
  '0x26a353e41b2d34534a1f2856ef059c760ea62ccf',
  '0xbe57edba57748adf92dc9a391532ed8740fe9054',
  '0x21d2125bbb6eb40c1e004604ee9a0dd1e085337a',
  '0xf950d2fdfda2914b9414b6f61895a77bf954fe23',
  '0x921e31a811c27167c501a8cc25ccbbead0a5dc68',
  '0xe446a3247f2144aea33d5e776f2ecedb812a5a40',
  '0x83cbbc045bd86ca1435e9d3e2f5fe29373d532ce',
  '0xbc8b60a19a29e1c016d26ee375cbe1341d57f4ee',
  '0x9b5c2a33037b3f0ee5a864543b636d62387bb3ac',
  '0x0414daf8966732a63bdf926d2fa29d73b468f516',
  '0x2862d540782b4a55da029440a7dd20b12f08707d',
  '0x1cb15ad7dc5231b5f6310b1cc549289c285c31c1',
  '0x93db4d252a8f375c03e66faee84e88a90ad75aeb',
  '0xbc1e2390d693ad617142500e3b7094606433c234',
  '0x0376ae94db1bad7774eb2ba646ff61715b4b5d82',
  '0x9e22b1c5804f7ac179b77de79a32e458a0ecb651',
  '0xa8e65d1a3df5c0852f05cd1894b6196457e17c9c',
  '0x77fe93a60a579e4ed52159ae711794c6fb7cdea7',
  '0xda4c285ce9796fb4c35f99d6066ce11ec18ec4cc',
  '0xc06aba6a3e75f451adf743d394383fedf15cf316',
  '0xd047035ef71496e2b83dd2063e2fc50a8331873d',
  '0x9f7ed030f02f3adfe37d74d1e7dacf4bfbec3ea0',
  '0xef99e80d6963d801b1f2b4c61f780082d2642152',
  '0xe17900d324fb41821cc38499063b2e3bbae6c27e',
  '0xab9a844588e8f9008a7d13b6584cab1fea717129',
  '0xddac24d12dd07e4e2b772cfc64e3eb47e79c74a3',
  '0x76e73543a14de519575d5efea050f9958b0b484f',
  '0xc7918dc45836d5fc417f93ef63da52d001f033f3',
  '0x8c7134237f9eab3605e98de20055b1763886e151',
  '0x2e40fe398483ddc20e296c2a75e5cea07f629b9c',
  '0x9b07bf9529e24aab772d4bdd1efcd75aed08e708',
  '0xa19e70501f141aaa002fe701299dab88829b3197',
  '0xc4ff62bc115843d15a3bd33efcbf245cb52640e8',
  '0xcad0c9aff9f44f86bcce10f8ffeb14b3d199997f',
  '0x8e6cd902e34ccace8c808826c375cfb5b581b4c6',
  '0x2c6eaf03c30ba5881673187c2eb197f40679fcf9',
  '0x481307536e4ceb04c3967705fa4cd822714bbeff',
  '0xc5a978372ba0fdf9028425894f2da152f1ae68f8',
  '0x5d1e7db9dbe660f8b8e5463f5b46236054b228c7',
  '0x079439f960836accd84345c767ea116d133b22c3',
  '0x9af6a0523402f88eec9d348fde6b16b22725a8b4',
  '0x24e48faba1e5685cc73477873f3734745bd16b99',
  '0x537e5e300e4888e13461a73095a42da43fac3c86',
  '0x85a7a676d610a95865e842d2754b1fd335e55767',
  '0xd473a895943f9dd0cae3dd26d19414302f4978b9',
  '0xea81e7e022e5fe7a19b5a0ed3d5e5eac01d5ed37',
  '0xa6863cc87203ea93fafcb8cde8d9143f6b44a638',
  '0xb5ed8cbf7992b1917f097f7ce06b577909ce44ae',
  '0xd186f45f87bf6dbb8e4902ae25603adaa388c2c1',
  '0x1bdf10a91b6f08c2fdae7135d2492b79e0774c96',
  '0x451e414f1de9b682c5f638a088810f5936d4dc15',
  '0x7f978b6395c5fcf44bbc110f077b292962fe1bf5',
  '0x76d3c393c28b79445a05c2c399800642ddd05544',
  '0x63ecc618d2e605cdcd0a28ec4ac00eb5cc500dc6',
  '0x093ea3f329a82bcb2986dfc42ac0e5399dd03676',
  '0x40a7fd740213a4d2db52918e1e556efcaa737282',
]

const sortDecExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(a.expiry) - parseInt(b.expiry)
  const byTimestamp = parseInt(a.timestamp) - parseInt(b.timestamp)
  const byStrike = parseInt(a.strikePriceValue) - parseInt(b.strikePriceValue)

  return byExpiry ? byExpiry : byTimestamp ? byTimestamp : byStrike
}

export const useBuyerTokenPuts = (tokenId: ERC20Token) => {
  const { networkId, account, library } = useConnection()

  const [loading, setIsLoading] = useState(true)

  const ethToUsdcPrice = useEthToUsdPrice()

  const $ethPrice = ethToUsdcPrice.gt(0)
    ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
    : new Big(0)

  const tokenToUsdcPrice = useKnownTokenToUSDPrice(tokenId)

  // Development TODO: add this mock
  // const _tokenId = tokenId === 'dpi' ? 'snx' : tokenId

  const currentPrice = tokenId === 'weth' ? $ethPrice : tokenToUsdcPrice

  const oracleContract = useOracle()
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])
  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])
  const [options, setOptions] = useState<Maybe<Array<GetOptionsContractsByUnderlying_optionsContracts>>>(null)
  const underlying = getToken(networkId === -1 ? 1 : networkId, tokenId)

  const variables = {
    underlyings: [underlying.address],
    now: getUnixTime(Date.now()),
    account: account || '',
  }

  const { loading: fetchingData, data: optionData, error } = useQuery<GetOptionsContractsByUnderlying>(
    OPTION_CONTRACT_BY_UNDERLYING_QUERY,
    { variables },
  )

  useEffect(() => {
    let isCancelled = false

    if (
      isCancelled ||
      !isValidNetworkId(networkId) ||
      !optionData ||
      !optionData.optionsContracts ||
      fetchingData ||
      optionData.optionsContracts.length === 0
    )
      return

    const optionsContractsToShow = optionData.optionsContracts.filter(option =>
      whitelist.includes(option.address.toLowerCase()),
    )

    optionsContractsToShow.sort(sortDecExpiry)
    setOptions(optionsContractsToShow)
    setIsLoading(false)

    return () => {
      isCancelled = true
    }
  }, [networkId, optionData, fetchingData])

  // const ethToUsdcPrice = useEthToUsdcPrice()
  const optionsWithDetail = useAsyncMemo(
    async () => {
      if (!options || !daiToken || !library || !oracleContract) {
        return []
      }

      const result = await Promise.all(
        options?.map(
          async (option): Promise<InsuredTokenOptionsData> => {
            const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)
            // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
            let premiumToPay = new BigNumber(0)
            try {
              premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, daiToken.address, 1e4)
            } catch {
              // do nothing
            }

            const balance = account
              ? option?.holdersBalances?.find(balance => balance.account.address === account.toLocaleLowerCase())
              : { amount: new BigNumber(0) }

            const $strikePrice = new Big(option?.strikePriceValue || 0)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(`1e${-option?.oTokenExchangeRateExp}`)

            const tokenProtected = {
              address: option?.address,
              symbol: tokenId,
              decimals: -option?.oTokenExchangeRateExp,
            }

            const isCall = false

            const protectionReturn = await getCollateralEarnPremiums({
              option,
              networkId,
              optionsExchangeContract,
              isCall,
            })

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            return {
              option,
              type: 'Put',
              balance: {
                amount: new BigNumber(balance?.amount || 0),
                token: tokenProtected,
                precision: 4,
              },
              expiry: format(fromUnixTime(option?.expiry), EXPIRY_DATE_FORMAT, { timeZone }),
              strikePrice: $strikePrice.round(2).toFixed(),
              currentPrice: currentPrice.round(2).toFixed(),
              protectionCost: new Big(premiumToPay.toString())
                .times(`1e${-option?.oTokenExchangeRateExp}`)
                .times(1e-4)
                .times(`1e-18`)
                .round(4)
                .toFixed(),
              protectionReturn: protectionReturn,
              status: $strikePrice.lt(currentPrice) ? 'ok' : 'error',
            }
          },
        ) || [],
      )
      setIsLoading(false)
      return result
    },
    [],
    [networkId, account, daiToken, library, usdc, options, fetchingData],
  )

  return {
    optionsWithDetail,
    // reload,
    loading: fetchingData || loading,
    error,
  }
}

export const useBuyerTokenCalls = (tokenId: ERC20Token) => {
  const { networkId, account, library } = useConnection()

  const [loading, setIsLoading] = useState(true)

  const ethToUsdcPrice = useEthToUsdPrice()

  const $ethPrice = ethToUsdcPrice.gt(0)
    ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
    : new Big(0)

  const tokenToUsdcPrice = useKnownTokenToUSDPrice(tokenId)

  // Development TODO: add this mock
  // const _tokenId = tokenId === 'dpi' ? 'snx' : tokenId

  const currentPrice = tokenId === 'weth' ? $ethPrice : tokenToUsdcPrice

  const oracleContract = useOracle()
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])
  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])
  const [options, setOptions] = useState<Maybe<Array<GetOptionsContractsByUnderlying_optionsContracts>>>(null)
  const underlying = getTokenOrPlaceHolder(networkId, 'usdc')
  const collateral = getToken(networkId, tokenId)

  const variables = {
    underlyings: [underlying.address],
    now: getUnixTime(Date.now()),
    account: account || '',
  }

  const { loading: fetchingData, data: optionData, error } = useQuery<GetOptionsContractsByUnderlying>(
    OPTION_CONTRACT_BY_UNDERLYING_QUERY,
    { variables },
  )

  useEffect(() => {
    let isCancelled = false

    if (
      isCancelled ||
      !isValidNetworkId(networkId) ||
      !optionData ||
      !optionData.optionsContracts ||
      fetchingData ||
      optionData.optionsContracts.length === 0
    )
      return

    const optionsContractsToShow = optionData.optionsContracts.filter(
      option => whitelist.includes(option.address.toLowerCase()) && option.collateral === collateral.address,
    )

    optionsContractsToShow.sort(sortDecExpiry)
    setOptions(optionsContractsToShow)
    setIsLoading(false)

    return () => {
      isCancelled = true
    }
  }, [networkId, optionData, fetchingData, collateral.address])

  // const ethToUsdcPrice = useEthToUsdcPrice()
  const optionsWithDetail = useAsyncMemo(
    async () => {
      if (!options || !daiToken || !library || !oracleContract) {
        return []
      }

      const result = await Promise.all(
        options?.map(
          async (option): Promise<InsuredTokenOptionsData> => {
            const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)
            // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
            let premiumToPay = new BigNumber(0)
            try {
              premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, daiToken.address, 1e4)
            } catch {
              // do nothing
            }

            const balance = account
              ? option?.holdersBalances?.find(balance => balance.account.address === account.toLocaleLowerCase())
              : { amount: new BigNumber(0) }

            // const $strikePrice = new Big(`1e${-option?.oTokenExchangeRateExp}`)
            //   .times(`1e${underlying.decimals}`)
            //   .div(new Big(option?.strikePriceValue || 0).times(`1e${option.strikePriceExp || 0}`))
            //   .div(`1e${decimals}`)

            const $strikePrice = new Big(1).div(
              new Big(option?.strikePriceValue)
                .times(`1e${option?.strikePriceExp || 0}`)
                .times(`1e${-option?.oTokenExchangeRateExp || 0}`),
            )

            const tokenProtected = {
              address: option?.address,
              symbol: tokenId,
              decimals: -option?.oTokenExchangeRateExp,
            }

            const isCall = true

            const protectionReturn = await getCollateralEarnPremiums({
              option,
              networkId,
              optionsExchangeContract,
              isCall,
            })

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            if (usdc) {
              const amountToBuy = new Big(new Big(1).times(`1e${-option?.oTokenExchangeRateExp || 0}`).toFixed(0))
              const oTokensToBuy = amountToBuy.times(new Big($strikePrice))
              try {
                premiumToPay = await optionsExchangeContract?.getPremiumToPay(
                  option.address,
                  usdc?.address,
                  Number(oTokensToBuy.toString()),
                )
              } catch {
                premiumToPay = new BigNumber(0)
              }
            }

            return {
              option,
              type: 'Call',
              balance: {
                amount: new BigNumber(balance?.amount || 0).div(new BigNumber($strikePrice.toString())),
                token: tokenProtected,
                precision: 4,
              },
              expiry: format(fromUnixTime(option?.expiry), EXPIRY_DATE_FORMAT, { timeZone }),
              strikePrice: $strikePrice.round(2).toFixed(),
              currentPrice: currentPrice.round(2).toFixed(),
              protectionCost: new Big(premiumToPay.toString()).times(`1e-${usdc?.decimals}`).toString(),
              protectionReturn: protectionReturn,
              status: $strikePrice.lt(currentPrice) ? 'ok' : 'error',
            }
          },
        ) || [],
      )
      setIsLoading(false)
      return result
    },
    [],
    [networkId, account, daiToken, library, usdc, options, fetchingData],
  )

  return {
    optionsWithDetail,
    // reload,
    loading: fetchingData || loading,
    error,
  }
}
