import React, { useMemo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/Common/SectionTitle'
import { ClaimCard } from 'components/ClaimCard'
import { TokenCard } from 'components/TokenCard'
import { TransactionsCard } from 'components/TransactionsCard'
import { ThemeColorTypes, ThemeColorTypesInverted, compoundTokenMap, CompoundTokens } from 'utils'
import { useParams, useHistory } from 'react-router-dom'
import { useBuyerCompoundOptions, useBuyingTransactionHistory } from 'hooks'
import { ContentsNarrow } from 'components/Common/CommonStyled'
import { ButtonBack } from 'components/Common/ButtonBack'
import { useLastLocation } from 'react-router-last-location'
import { Share } from '../../components/Common/Share'

const TitleWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr ${props => props.theme.innerScrollContainer.narrowWidth} 1fr;
  margin-bottom: 24px;
`

const TitleStyled = styled(SectionTitle)`
  margin-bottom: 0;
`

const ContentsNarrowStyled = styled(ContentsNarrow)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    flex-direction: row;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-right: 20px;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const BuyCompoundAssetDetail: React.FC<any> = () => {
  const { id } = useParams<{ id: string }>()
  const tokens = useMemo(() => [id as CompoundToken], [id])
  const { compoundOptions, loading, reload: reloadCompoundOptions } = useBuyerCompoundOptions({
    requiredTokens: tokens,
  })
  const history = useHistory()
  const protocol = useMemo(() => (id === 'dai' ? 'maker' : 'compound'), [id])
  const buyingTransactionHistory = useBuyingTransactionHistory(id.toLowerCase() as KnownToken)

  const insuranceData = useMemo(() => compoundOptions[id as CompoundToken], [compoundOptions, id])

  const lastLocation = useLastLocation()

  const handleButtonBackClick = useCallback(() => {
    if (lastLocation) {
      history.push(lastLocation.pathname, lastLocation.state)
    } else {
      history.push('/buy')
    }
  }, [history, lastLocation])
  const [lastUpdate, setLastUpdate] = useState(Date.now())

  const reload = () => {
    reloadCompoundOptions?.()
    setLastUpdate(Date.now())
  }

  /* this is not really necessary for now, but you'll probably have to use it later */
  const sectionColors = ThemeColorTypes.primary
  const sectionColorsInverted = ThemeColorTypesInverted.primaryInverted

  return (
    <>
      <TitleWrapper>
        <ButtonBack onClick={handleButtonBackClick} />
        <TitleStyled protocol={protocol} title={'Buy Insurance on Compound'} />
      </TitleWrapper>
      <ContentsNarrowStyled>
        <Content>
          <Stack>
            <TokenCard
              insuranceData={insuranceData}
              tokenCardStyle={sectionColors}
              tokenCardStyleInverted={sectionColorsInverted}
              cTokenId={id}
              totalPaid={buyingTransactionHistory[4]}
              reload={reload}
            />
          </Stack>
          <Stack>
            <ClaimCard
              loading={loading}
              insuranceData={insuranceData}
              token={compoundTokenMap[id as CompoundTokens]}
              claimCardStyle={sectionColors}
              underlyingToken={id}
              reload={reload}
            />
            <TransactionsCard
              transactionsCardStyle={sectionColors}
              lastUpdate={lastUpdate}
              historicalData={buyingTransactionHistory}
            />
          </Stack>
        </Content>
      </ContentsNarrowStyled>
      <Share />
    </>
  )
}
