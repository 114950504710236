import React from 'react'
import styled from 'styled-components'
import { TokenIconData } from './data'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`

const Icon = styled.div<{ image: string; size: string }>`
  background-image: url(${props => props.image});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
`

const Text = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 5px;
`

interface TokenInfoProps {
  size?: string
  textStyle?: any
  token: string
  title?: string
  children?: React.ReactNode
}

export const TokenIcon: React.FC<TokenInfoProps> = (props: TokenInfoProps) => {
  const { size = '36', token, textStyle, children, title, ...restProps } = props
  const tokenInfo = TokenIconData[token]
  const titleToShow = title ? title : tokenInfo.title

  return tokenInfo ? (
    <Wrapper {...restProps}>
      <Icon size={size} image={tokenInfo.icon} />
      <Text style={textStyle}>{titleToShow}</Text>
      {children}
    </Wrapper>
  ) : null
}
