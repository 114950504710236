import React from 'react'

interface IconProps {
  color?: string
}

export const CheckIcon: React.FC<IconProps> = (props: IconProps) => {
  const { color = '#ccc' } = props

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0007 19.1663C4.93804 19.1663 0.833984 15.0623 0.833984 9.99967C0.833984 4.93706 4.93804 0.833008 10.0007 0.833008C15.0633 0.833008 19.1673 4.93706 19.1673 9.99967C19.1673 15.0623 15.0633 19.1663 10.0007 19.1663ZM10.0006 17.4997C14.1428 17.4997 17.5006 14.1418 17.5006 9.99967C17.5006 5.85754 14.1428 2.49967 10.0006 2.49967C5.85851 2.49967 2.50064 5.85754 2.50064 9.99967C2.50064 14.1418 5.85851 17.4997 10.0006 17.4997ZM12.7447 6.91042L8.33397 11.3212L6.42323 9.41042L5.24472 10.5889L8.33397 13.6782L13.9232 8.08893L12.7447 6.91042Z"
        fill={color}
      />
    </svg>
  )
}
