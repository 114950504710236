import React from 'react'
import styled from 'styled-components'
import { TextLight, TotalTitle, TotalValue } from '../../Common/CommonStyled'
import Value from '../../Common/Value'
import { BigFloat } from '../../../utils/types'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const ValueLight = styled(TextLight)`
  margin-left: 5px;
`

interface Props {
  value: BigFloat
  $value: string
  token: string
}

export const PremiumsToEarn: React.FC<Props> = props => {
  const { value, $value, token, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Premiums to Earn</TotalTitle>
      <Values>
        <TotalValue>
          <Value {...value} precision={8}>
            {(val: any) => `${val} ${token.toLocaleUpperCase()}`}
          </Value>
        </TotalValue>
        <TotalValue>
          <ValueLight> ${$value}</ValueLight>
        </TotalValue>
      </Values>
    </Wrapper>
  )
}
