import { Connectors } from 'web3-react'

import { infuraNetworkURL, supportedNetworkIds } from '../utils/networks'

const { InjectedConnector, NetworkOnlyConnector } = Connectors

const MetaMask = new InjectedConnector({
  supportedNetworks: supportedNetworkIds,
})

const Infura = new NetworkOnlyConnector({
  providerURL: infuraNetworkURL,
})

export default {
  Infura,
  MetaMask,
}
