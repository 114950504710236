import { BigNumber, getAddress, formatUnits, BigNumberish } from 'ethers/utils'

import { getLogger } from './logger'
import { differenceInDays, differenceInMonths, differenceInYears, addYears, addMonths } from 'date-fns'
import { Token, BigFloat } from './types'
import { EXCHANGE_RATE_DECIMALS } from '../common/constants'
import { BigSource, Big } from 'big.js'
import { ETH_TOKEN } from './networks'
import { ERC20Service, oToken } from '../services'
import { Web3Provider } from 'ethers/providers'
import { LOCK_STATES } from '../components/Common/TokensDropdownUnlocker'

const logger = getLogger('Tools')

export const truncateStringInTheMiddle = (str: string, strLength = 41, strPositionStart = 8, strPositionEnd = 8) => {
  if (str.length > strLength) {
    return `${str.substr(0, strPositionStart)}...${str.substr(str.length - strPositionEnd, str.length)}`
  }
  return str
}

export const formatDate = (date: Date): string => {
  const dateParts = date.toString().split(/\s+/)
  return dateParts.slice(1, 6).join(' ')
}

export const divBN = (a: BigNumber, b: BigNumber, scale = 10000): number => {
  return (
    a
      .mul(scale)
      .div(b)
      .toNumber() / scale
  )
}

export const mulBN = (a: BigNumber, b: number, scale = 10000): BigNumber => {
  return a.mul(Math.round(b * scale)).div(scale)
}

export const isAddress = (address: string): boolean => {
  try {
    getAddress(address)
  } catch (e) {
    logger.log(`Address '${address}' doesn't exist`)
    return false
  }
  return true
}

export const formatBigNumber = (value: BigNumber, decimals: number, precision = 2): string =>
  Number(formatUnits(value, decimals)).toFixed(precision)

export const isContract = async (provider: any, address: string): Promise<boolean> => {
  const code = await provider.getCode(address)
  return code && code !== '0x'
}

export const delay = (timeout: number) => new Promise(res => setTimeout(res, timeout))

export const durationInDays = (since: number, to: number) => {
  const diffInYears = differenceInYears(to, since)
  const diffInMonths = differenceInMonths(to, addYears(since, diffInYears))
  const diffInDays = differenceInDays(to, addMonths(addYears(since, diffInYears), diffInMonths))

  return (
    (diffInYears ? `${diffInYears + ' year' + (diffInYears > 1 ? 's' : '')} ` : '') +
    (diffInMonths
      ? `${diffInYears ? (diffInDays ? ', ' : 'and ') : ''}${diffInMonths + ' month' + (diffInMonths > 1 ? 's' : '')} `
      : '') +
    (diffInDays
      ? `${diffInYears || diffInMonths ? 'and ' : ''}${diffInDays + ' day' + (diffInDays > 1 ? 's' : '')}`
      : '')
  )
}

export const durationGetter = (option?: { expiry: number }) => {
  return {
    value: !option ? '--' : durationInDays(Date.now(), option.expiry * 1000),
  }
}

export const convertERC20ToCToken = (
  amount: BigSource,
  token: Maybe<Token>,
  exchangeRate: Maybe<BigNumberish>,
  cToken: Maybe<Token>,
): BigFloat => {
  if (!exchangeRate || !token || !cToken || !amount) {
    return {
      value: 0,
    }
  }
  return {
    value: new BigNumber(
      new Big(amount)
        .times(`1e${token.decimals}`)
        .times(`1e${EXCHANGE_RATE_DECIMALS}`)
        .div(new BigNumber(exchangeRate).toString())
        .toFixed(0),
    ),
    decimals: Number(cToken.decimals),
  }
}

export const convertCTokenToOToken = (
  amount: BigSource,
  cToken: Token,
  oTokenExchangeRateValue: number,
  oTokenExchangeRateExp: number,
): string => {
  if (!amount || !oTokenExchangeRateExp || !oTokenExchangeRateValue || !cToken) {
    return '0'
  }

  return new Big(amount)
    .div(oTokenExchangeRateValue)
    .times(`1e${-oTokenExchangeRateExp}`)
    .times(`1e-${cToken.decimals}`)
    .round(0, 3)
    .toFixed()
}

/**
 * @description Get the amount of oToken needed to exercise up to 'amount' underlying
 * This method round down to the closes oToken amount, if we round up, user won't have enough underlying to exercise.
 * @param amount
 * @param underlyingToken
 * @param oTokenExchangeRateValue
 * @param oTokenExchangeRateExp
 */
export const convertUnderlyingToOToken = (
  amount: BigSource,
  underlyingToken: Token,
  oTokenExchangeRateValue: number,
  oTokenExchangeRateExp: number,
): string => {
  if (!amount || !oTokenExchangeRateExp || !oTokenExchangeRateValue || !underlyingToken) {
    return '0'
  }

  return new Big(amount)
    .div(oTokenExchangeRateValue)
    .times(`1e${-oTokenExchangeRateExp}`)
    .times(`1e-${underlyingToken.decimals}`)
    .round(0, 0)
    .toFixed()
}

export const convertOTokenToCToken = (
  amount: BigSource,
  cToken: Maybe<Token>,
  oTokenExchangeRateValue: number | string,
  oTokenExchangeRateExp: number | string,
): BigFloat => {
  if (!amount || !oTokenExchangeRateValue || !oTokenExchangeRateExp || !cToken) {
    return {
      value: 0,
    }
  }
  return {
    value: new BigNumber(
      new Big(amount)
        .times(oTokenExchangeRateValue)
        .times(`1e${cToken.decimals}`)
        .times(`1e${oTokenExchangeRateExp}`)
        .toFixed(),
    ),
    decimals: cToken.decimals,
  }
}

export const convertOTokenToUnderlying = (
  amount: BigSource,
  underlyingToken: Maybe<Token>,
  oTokenExchangeRateValue: number | string,
  oTokenExchangeRateExp: number | string,
): BigFloat => {
  return convertOTokenToCToken(amount, underlyingToken, oTokenExchangeRateValue, oTokenExchangeRateExp)
}

export const convertCTokenToERC20 = (
  amount: BigNumberish,
  cToken: Maybe<Token>,
  exchangeRate: Maybe<BigNumberish>,
  token: Maybe<Token>,
): BigFloat => {
  if (!amount || !cToken || !exchangeRate || !token) {
    return {
      value: 0,
    }
  }

  return {
    value: new BigNumber(amount).mul(exchangeRate).div((10 ** EXCHANGE_RATE_DECIMALS).toFixed(0)),
    decimals: Number(token.decimals),
  }
}

export const convertOTokenToERC20 = (
  amount: BigSource,
  cToken: Maybe<Token>,
  oTokenExchangeRateValue: number | string,
  oTokenExchangeRateExp: number | string,
  exchangeRate: Maybe<BigNumberish>,
  token: Maybe<Token>,
): BigFloat => {
  const cTokenAmount = convertOTokenToCToken(amount, cToken, oTokenExchangeRateValue, oTokenExchangeRateExp)

  return convertCTokenToERC20(cTokenAmount.value, cToken, exchangeRate, token)
}

export const addToLocalStorageArray = (key: string, ...elements: any[]) => {
  const stored = JSON.parse(localStorage.getItem(key) || '[]')
  localStorage.setItem(key, JSON.stringify([...stored, ...elements]))
}

export const convertBigFloatToBig = (num?: BigFloat) => {
  return new Big(new BigNumber(num?.value || 0).toString()).times(`1e${-(num?.decimals || 0)}`)
}

export const getEthBalance = async (account: Maybe<string>, library?: Maybe<Web3Provider>): Promise<BigNumber> => {
  if (account) {
    try {
      const signer = await library?.getSigner()
      return signer?.getBalance() || new BigNumber(0)
    } catch {
      // Do nothing
    }
  }
  return new BigNumber(0)
}

export const getERC20Balance = async (
  account: Maybe<string>,
  contract?: Maybe<ERC20Service>,
  library?: Maybe<Web3Provider>,
): Promise<BigNumber> => {
  if (account) {
    try {
      if (contract?.tokenAddress === ETH_TOKEN.address) {
        return getEthBalance(account, library)
      } else {
        return contract?.getBalanceOf(account) || new BigNumber(0)
      }
    } catch {
      // Do nothing
    }
  }
  return new BigNumber(0)
}

export const unlockToken = async (spender: Maybe<string>, contract: Maybe<ERC20Service>) => {
  if (spender) {
    try {
      await contract?.approveUnlimited(spender)
      return LOCK_STATES.UNLOCKED
    } catch {
      // Do nothing
    }
  }
  return LOCK_STATES.LOCKED
}

export const unlockOToken = async (spender: Maybe<string>, contract: Maybe<oToken>, amount: BigNumber) => {
  if (spender) {
    try {
      await contract?.approve(spender, amount)
      return LOCK_STATES.UNLOCKED
    } catch {
      // Do nothing
    }
  }
  return LOCK_STATES.LOCKED
}

export const unlockUnlimitedOToken = async (spender: Maybe<string>, contract: Maybe<oToken>) => {
  if (spender) {
    try {
      await contract?.approveUnlimited(spender)
      return LOCK_STATES.UNLOCKED
    } catch {
      // Do nothing
    }
  }
  return LOCK_STATES.LOCKED
}

export enum COMPARISON_MODES {
  inclusive,
  exclusive,
  minInclusive,
  maxInclusive,
}

export const isBetween = (
  value: BigSource,
  min: BigSource,
  max: BigSource,
  mode: COMPARISON_MODES = COMPARISON_MODES.inclusive,
) => {
  const gt = mode === COMPARISON_MODES.inclusive || mode === COMPARISON_MODES.minInclusive ? 'gte' : 'gt'
  const lt = mode === COMPARISON_MODES.inclusive || mode === COMPARISON_MODES.maxInclusive ? 'lte' : 'lt'
  try {
    const bigValue = new Big(value || 0)
    return !isNaN(Number(value)) && bigValue[gt](min) && bigValue[lt](max)
  } catch {
    return false
  }
}

export const orderByProp = (property: string) => (a: any, b: any) => b[property] - a[property]

export const getProp = (property: string) => (elem: any) => elem[property]

export const sum = (a: number, b: number) => a + b
