import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Card } from 'components/Common/Card'
import { TokenIcon } from 'components/Common/TokenIcon'
import { ButtonsContainer } from 'components/Common/ButtonContainer'

const TokenTitle = styled(TokenIcon)`
  margin-bottom: 15px;

  > p {
    font-size: 20px;
    font-weight: 600;
  }
`

const Rows = styled.div`
  margin: 0 0 10px;
`

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Title = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`

const RowValue = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

export interface InfoRow {
  title: React.ReactNode
  value: React.ReactNode
}

interface GenericTokenCardProps extends HTMLAttributes<HTMLDivElement> {
  tokenId: KnownToken
  rows: InfoRow[]
  buttons: React.ReactNode[]
}

export const GenericTokenCard: React.FC<GenericTokenCardProps> = (props: GenericTokenCardProps) => {
  const { tokenId, rows, buttons, ...restProps } = props

  return (
    <>
      <Card {...restProps}>
        <TokenTitle token={tokenId} />
        <Rows>
          {rows.map((item, index) => {
            return (
              <Row key={index}>
                <Title>{item.title}</Title>
                <RowValue>{item.value}</RowValue>
              </Row>
            )
          })}
        </Rows>
        <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
      </Card>
    </>
  )
}
