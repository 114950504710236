import React from 'react'
import styled from 'styled-components'
import { TotalTitle, TotalValue } from '../CommonStyled'
import FormattedDate from '../Date'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const StyledTotalValue = styled(TotalValue)`
  text-transform: none;
`

interface Props {
  option: any
}

export const Expiry: React.FC<Props> = props => {
  const { option, ...restProps } = props

  const expiry = option?.expiry

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Expiry</TotalTitle>
      <Values>
        <StyledTotalValue>{expiry ? <FormattedDate timestamp={expiry} /> : '--'}</StyledTotalValue>
      </Values>
    </Wrapper>
  )
}
