import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { ArrowBreak } from '../ArrowBreak'
import { PoweredByUniswap } from '../PoweredByUniswap'
import { TotalCost } from '../TotalCost'
import { DetailRow } from '../DetailRow'
import { Button } from 'components/Common/Button'
import { TextLight } from 'components/Common/CommonStyled'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import {
  useContracts,
  useConnection,
  useOptionsExchange,
  useAsyncMemo,
  useUniswapFactory,
  useERC20Contract,
  useEthToUsdPrice,
  Contracts,
} from 'hooks'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import {
  isERC20Token,
  getToken,
  ETH_TOKEN,
  isValidNetworkId,
  getERC20Balance,
  unlockToken,
  getEthBalance,
  BuyOTokenAction,
  getTokenFromAddress,
  addToLocalStorageArray,
} from 'utils'
import { BigNumber } from 'ethers/utils'
import Value from 'components/Common/Value'
import TokenBalance from 'components/Common/TokenBalance'
import { OptionsContract_optionsContract } from 'types/generatedGQL'
import { Big, BigSource } from 'big.js'
import { LOCK_STATES } from 'components/Common/TokensDropdownUnlocker'
import FormattedDate from 'components/Common/Date'

const TermsWrapper = styled.div`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 15px;
`

const APPROVALS_QUERY = loader('../../../queries/approvals.graphql')

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  font-size: 11px;
  height: 30px;
`

const BoldText = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

interface ModalProps {
  isOpen: boolean
  isPut?: boolean
  onConfirm?: (tx: any, title: string) => void
  onTransactionFail?: () => void
  onRequestClose?: (e?: any) => void
  title?: string
  insured?: any
  option?: OptionsContract_optionsContract
  strikePrice?: string // call option will be human readable strike price
}

const getPaymentToken = (payWith: string, networkId: number) => {
  if (isERC20Token(payWith)) {
    return getToken(networkId, payWith)
  }
  return ETH_TOKEN
}

export const BuyETHInsuranceModal: React.FC<ModalProps> = props => {
  const { onConfirm, onTransactionFail, option, strikePrice, isPut } = props
  const { account, networkId, library } = useConnection()
  const [payWith, setPayWith] = useState('eth')
  const [payLockState, setPayLockState] = useState(LOCK_STATES.UNLOCKED)
  const isPayLocked = payLockState !== LOCK_STATES.UNLOCKED
  const contracts: Contracts = useContracts()
  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const uniswapFactoryContract = useUniswapFactory()
  const oTokenERC20Contract = useERC20Contract(option?.address)
  const paymentTokenContract = contracts?.[payWith.toLocaleUpperCase()]
  const underlyingToken = getTokenFromAddress(networkId, option?.underlying || ETH_TOKEN)
  const strikeToken = getTokenFromAddress(networkId, option?.strike || ETH_TOKEN)
  const underlyingERC20 = useERC20Contract(underlyingToken.address)
  const strikeERC20 = useERC20Contract(strikeToken.address)

  const bigTokenBalance = useAsyncMemo<BigNumber>(
    async () => {
      if (underlyingToken.address === ETH_TOKEN.address || strikeToken.address === ETH_TOKEN.address) {
        return await getEthBalance(account, library)
      } else if (account) {
        const tokenBalance = isPut
          ? await underlyingERC20?.getBalanceOf(account)
          : await strikeERC20?.getBalanceOf(account)
        return tokenBalance || new BigNumber(0)
      } else {
        return new BigNumber(0)
      }
    },
    new BigNumber(0),
    [account, library],
  )

  const bInitAmountToProtect = useMemo(() => new Big(bigTokenBalance.toString()).times(`1e-${ETH_TOKEN.decimals}`), [
    bigTokenBalance,
  ])

  const roundedInitAmountToProtect = bInitAmountToProtect.round(2, 0).toFixed()

  const [inputAmountToProtect, setInputAmountToProtect] = useState<BigSource>(roundedInitAmountToProtect)

  const updateInputAmount = (value: string) => {
    // if (isBetween(value, 0, bigUninsuredBalance.toString())) {
    setInputAmountToProtect(value)
    // }
  }

  const setMaxInputAmountToProtect = () => {
    setInputAmountToProtect(bInitAmountToProtect.toString())
  }

  const oTokensToBuy = useMemo(() => {
    if (!inputAmountToProtect || Number.isNaN(Number(inputAmountToProtect))) {
      return new Big(0)
    }
    const amountToBuy = new Big(
      new Big(inputAmountToProtect).times(`1e${-option?.oTokenExchangeRateExp || 0}`).toFixed(0),
    )
    return isPut ? amountToBuy : amountToBuy.times(new Big(strikePrice || '1'))
  }, [inputAmountToProtect, option, isPut, strikePrice])

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)

        return (await oTokenERC20Contract?.getBalanceOf(exchangeAddress)).toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  const insufficientLiquidity = oTokensToBuy.gt(availableOTokens)

  const belowThreshold = new Big(`1e-${ETH_TOKEN?.decimals}`).gt(
    Number.isNaN(Number(inputAmountToProtect)) || !inputAmountToProtect ? 0 : inputAmountToProtect,
  ) // || noBalance

  const errorMessage = insufficientLiquidity && 'Insufficient liquidity. Please enter a smaller amount to protect.'

  const paymentToken = useMemo(() => getPaymentToken(payWith, networkId), [networkId, payWith])

  const { data, refetch } = useQuery(APPROVALS_QUERY, {
    variables: {
      account,
    },
    skip: !account || !payWith,
  })

  useEffect(() => {
    if (isERC20Token(payWith)) {
      const unlockLog = !!data?.approvals?.find(
        ({ approvedTokenAddress, spender }: { approvedTokenAddress: string; spender: string }) =>
          approvedTokenAddress === paymentToken.address && spender === option?.optionsExchangeAddress,
      )

      setPayLockState(unlockLog ? LOCK_STATES.UNLOCKED : LOCK_STATES.LOCKED)
    } else {
      setPayLockState(LOCK_STATES.UNLOCKED)
    }
  }, [payWith, data, paymentToken, option])

  const bigPaymentTokenBalance = useAsyncMemo<BigNumber>(
    async () => getERC20Balance(account, paymentTokenContract, library),
    new BigNumber(0),
    [paymentTokenContract, account],
  )

  const [bigPremiumToPay, premiumToPay] = useAsyncMemo(
    async () => {
      const bigPremiumToPay =
        oTokensToBuy.eq(0) || insufficientLiquidity
          ? new BigNumber(0)
          : (await optionsExchangeContract?.getPremiumToPay(
              option?.address,
              paymentToken.address,
              Number(oTokensToBuy.toString()),
            )) || new BigNumber(0)

      const premiumToPay = new Big(bigPremiumToPay.toString()).times(`1e-${paymentToken.decimals}`).toFixed(2)

      return [bigPremiumToPay, premiumToPay]
    },
    [new BigNumber(0), '0'],
    [optionsExchangeContract, option, paymentToken, insufficientLiquidity, oTokensToBuy],
  )

  const ethToUsdcPrice = useEthToUsdPrice()

  const $premiumToPay = useAsyncMemo(
    async () => {
      if (payWith !== 'eth') {
        return premiumToPay
      }
      if (!isValidNetworkId(networkId) || ethToUsdcPrice.eq(0)) {
        return '0'
      }

      return new Big(bigPremiumToPay.toString()).div(ethToUsdcPrice.toString()).toFixed(4)
    },
    premiumToPay,
    [networkId, premiumToPay, ethToUsdcPrice],
  )

  const insufficientBalance = useMemo(
    () => new Big(bigPaymentTokenBalance.toString()).times(`1e-${paymentToken.decimals}`).lt(premiumToPay),
    [bigPaymentTokenBalance, paymentToken, premiumToPay],
  )

  const oTokenSymbol = isPut ? `o${underlyingToken.symbol}p` : `o${strikeToken.symbol}c`
  const token = useMemo(() => (isPut ? underlyingToken : strikeToken), [isPut, underlyingToken, strikeToken])

  const unlockPay = useCallback(async () => {
    const spender = option?.optionsExchangeAddress
    if (spender) {
      setPayLockState(LOCK_STATES.LOADING)
      unlockToken(spender, paymentTokenContract).then(newLockState => {
        setPayLockState(newLockState)
        refetch()
      })
    }
  }, [paymentTokenContract, refetch, option])

  const confirmHandler = useCallback(async () => {
    if (optionsExchangeContract && account && option?.address && ethToUsdcPrice && paymentToken && oTokensToBuy) {
      const paymentTokenPrice = paymentToken.address === ETH_TOKEN.address ? '1e18' : '1'
      const usdcPrice = paymentToken.address === ETH_TOKEN.address ? ethToUsdcPrice.toString() : '1'

      try {
        let overrides
        if (paymentToken.address === ETH_TOKEN.address) {
          overrides = {
            value: bigPremiumToPay,
          }
        }

        const tx = await optionsExchangeContract?.buyOTokens(
          account,
          option?.address,
          paymentToken.address,
          oTokensToBuy.toString(),
          overrides,
        )

        addToLocalStorageArray('buy-transactions', {
          transactionHash: tx.hash,
          timestamp: Math.round(Date.now() / 1000).toString(),
          oTokensToBuy: oTokensToBuy.toString(),
          exchangeRateCurrent: '0',
          paymentTokenAddress: paymentToken.address,
          premiumPaid: bigPremiumToPay.toString(),
          paymentTokenPrice,
          usdcPrice,
          token: {
            oTokenExchangeRateValue: option.oTokenExchangeRateValue,
            oTokenExchangeRateExp: option.oTokenExchangeRateExp,
            underlying: underlyingToken.address,
          },
          pending: true,
        } as BuyOTokenAction)

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, 'Confirm Protection')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          onTransactionFail()
        }
      }
    }
  }, [
    account,
    optionsExchangeContract,
    option,
    oTokensToBuy,
    onConfirm,
    onTransactionFail,
    ethToUsdcPrice,
    paymentToken,
    bigPremiumToPay,
    underlyingToken.address,
  ])

  const confirmButtonDisabled = isPayLocked || insufficientLiquidity || belowThreshold || insufficientBalance

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        title={`Protect ${token?.symbol}`}
        titleInfo={[
          {
            title: 'Balance:',
            value: (
              <TokenBalance
                value={bInitAmountToProtect
                  .round(2)
                  .toFixed()
                  .toString()}
                token={token}
              />
            ),
            onClick: setMaxInputAmountToProtect,
          },
        ]}
        dropdownIsDisabled
        value={inputAmountToProtect.toString()}
        selectedToken={token?.symbol.toLocaleLowerCase()}
        onAmountChange={updateInputAmount}
        error={insufficientLiquidity}
      />
      <ArrowBreak />
      <ModalInnerCardExtended
        inputIsReadOnly
        isLocked={payLockState}
        error={isPayLocked || insufficientBalance}
        onUnlock={unlockPay}
        selectedToken={payWith}
        onPaymentTokenChange={setPayWith}
        title={'Pay with'}
        titleInfo={[{ title: 'Balance:', value: <TokenBalance value={bigPaymentTokenBalance} token={paymentToken} /> }]}
        value={premiumToPay}
      />
      <PoweredByUniswap />
      <TotalCost value={premiumToPay} token={payWith} $value={$premiumToPay} />
      <TermsWrapper>Terms</TermsWrapper>
      <DetailRow
        title="oTokens To Buy"
        value={`${oTokensToBuy.times(`1e${option?.oTokenExchangeRateExp}`)} ${oTokenSymbol}`}
      />
      <DetailRow
        title={
          <TextWithTooltip
            text="Strike Price"
            tooltipText={
              isPut
                ? `The amount of USDC you will get back per 1 ${underlyingToken.symbol} <a href="https://opyn.gitbook.io/opynv1/faq#what-is-max-loss" target="_blank">Learn more.</a>`
                : `The amount of USDC you need to pay for 1 ${underlyingToken.symbol} <a href="https://opyn.gitbook.io/opynv1/faq#what-is-max-loss" target="_blank">Learn more.</a>`
            }
            id="mas-loss"
            place="right"
          />
        }
        value={[
          <Value key="strike-price-usdc" value={strikePrice} decimals={3} precision={4}>
            {val => `${val} USDC`}
          </Value>,
          <TextLight key="usd">
            <Value value={strikePrice} decimals={3} precision={4}>
              {val => ` $${val}`}
            </Value>
          </TextLight>,
        ]}
      />
      <DetailRow title="Expiry" value={option?.expiry ? <FormattedDate timestamp={option.expiry} /> : '--'} />

      <ErrorWrapper>{errorMessage}</ErrorWrapper>

      <BoldText>
        WARNING: you must manually exercise before the expiry date if you want to exercise. You cannot exercise after
        expiry. By confirming the transaction, you agree that you understand this.
      </BoldText>

      <Button onClick={confirmHandler} disabled={confirmButtonDisabled}>
        Confirm
      </Button>
    </Modal>
  )
}
