import React from 'react'
import styled from 'styled-components'

const Value = styled.span``

const Usd = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

interface CollateralCellProps {
  data: any
}

export const CollateralCell: React.FC<CollateralCellProps> = (props: CollateralCellProps) => {
  const { data, ...restProps } = props
  const { usd, value } = data

  return (
    <Value {...restProps}>
      {value} <Usd>{usd}</Usd>
    </Value>
  )
}
