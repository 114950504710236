import { Contract, ethers, Wallet } from 'ethers'
import { BigNumber } from 'ethers/utils'

const oracleABI = ['function getPrice(address asset) public view returns (uint256)']

class OracleService {
  contractAddress: string
  provider: any
  contract: Contract

  constructor(provider: any, signerAddress: Maybe<string>, contractAddress: string) {
    this.contractAddress = contractAddress
    this.provider = provider
    if (signerAddress) {
      const signer: Wallet = provider.getSigner()
      this.contract = new ethers.Contract(contractAddress, oracleABI, provider).connect(signer)
    } else {
      this.contract = new ethers.Contract(contractAddress, oracleABI, provider)
    }
  }

  getPrice = (asset: string): Promise<BigNumber> => {
    return this.contract.getPrice(asset)
  }
}

export default OracleService
