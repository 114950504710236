import React from 'react'
// import styled from 'styled-components'
import TokenBalance, { TokenBalanceProps } from '../TokenBalance'

// const TextPositive = styled.span`
//   color: ${props => props.theme.colors.primary};
// `

export interface DoubleBalancePositiveProps {
  left: TokenBalanceProps
  right: TokenBalanceProps
  separator?: string
}

const DoubleBalancePositive: React.FC<DoubleBalancePositiveProps> = ({
  left,
  right,
  separator = ' ',
}: DoubleBalancePositiveProps) => (
  <>
    <TokenBalance key="left" {...left} />
    {/* <TextPositive key="right">
      {separator}
      +<TokenBalance key="left" {...right} />
    </TextPositive> */}
  </>
)

export default DoubleBalancePositive
