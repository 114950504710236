import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { GetOptionsContractsByUnderlying, GetOptionsContractsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { ETH_TOKEN, isValidNetworkId } from 'utils'
import { getUnixTime } from 'date-fns'
import { useConnection } from '.'
import { getTokenOrPlaceHolder } from 'utils'

const OPTION_CONTRACT_BY_UNDERLYING_QUERY = loader('../queries/options_contracts_by_underlying.graphql')
const OPTION_CONTRACT_BY_UNDERLYING_SUBSCRIPTION = loader(
  '../queries/options_contracts_by_underlying.subscription.graphql',
)

const sortDecExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(a.expiry) - parseInt(b.expiry)
  const byTimestamp = parseInt(a.timestamp) - parseInt(b.timestamp)
  const byStrike = parseInt(a.strikePriceValue) - parseInt(b.strikePriceValue)

  return byExpiry ? byExpiry : byTimestamp ? byTimestamp : byStrike
}

export const useBuyEthOptions = (type: string, whitelist: string[]) => {
  const [loading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<Maybe<Array<GetOptionsContractsByUnderlying_optionsContracts>>>(null)
  const { networkId, account } = useConnection()

  const usdc = getTokenOrPlaceHolder(networkId, 'usdc')

  const variables = {
    underlyings: type === 'put' ? [ETH_TOKEN.address] : [usdc.address],
    now: getUnixTime(Date.now()),
    account: account || '',
  }

  const { loading: fetchingData, data: optionData, subscribeToMore, error } = useQuery<GetOptionsContractsByUnderlying>(
    OPTION_CONTRACT_BY_UNDERLYING_QUERY,
    { variables },
  )

  const reload = subscribeToMore({
    document: OPTION_CONTRACT_BY_UNDERLYING_SUBSCRIPTION,
    variables,
    updateQuery: (_prev, { subscriptionData }) => subscriptionData.data,
  })

  useEffect(() => {
    let isCancelled = false

    if (
      isCancelled ||
      !isValidNetworkId(networkId) ||
      !optionData ||
      !optionData.optionsContracts ||
      fetchingData ||
      optionData.optionsContracts.length === 0
    )
      return

    const optionsContractsToShow = optionData.optionsContracts.filter(option =>
      whitelist.includes(option.address.toLowerCase()),
    )

    // sort options by expiry than by strike price
    optionsContractsToShow.sort(sortDecExpiry)
    setOptions(optionsContractsToShow)
    setIsLoading(false)

    return () => {
      isCancelled = true
    }
  }, [networkId, optionData, whitelist, fetchingData])

  return {
    options,
    reload,
    loading: fetchingData || loading,
    error,
  }
}
