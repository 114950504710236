import { useConnection } from 'components/web3'
import { useMemo } from 'react'
import { isValidNetworkId, getUniswapFactoryAddressByNetwork } from 'utils'
import UniswapFactory from 'services/UniswapFactory'

export const useUniswapFactory = () => {
  const { library, networkId, account } = useConnection()

  return useMemo(() => {
    if (!library || !isValidNetworkId(networkId)) {
      return null
    }

    if (account) {
      return new UniswapFactory(library, account, getUniswapFactoryAddressByNetwork(networkId))
    } else {
      return new UniswapFactory(library, '', getUniswapFactoryAddressByNetwork(networkId))
    }
  }, [library, networkId, account])
}

export default useUniswapFactory
