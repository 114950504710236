import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { HashRouter as Router, Route, Switch, RouteProps } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { LandingPage } from '../../sections/LandingPage'
import { Buy } from '../../sections/Buy'
import { Sell } from '../../sections/Sell'
import { BuyCompoundAssetDetail } from '../../sections/BuyCompoundAssetDetail'
// restore ETH puts. uncomment this
import { BuyEthAssetDetail } from '../../sections/BuyEthAssetDetail'
import { BuyWethOptionDetail } from '../../sections/BuyWethOptionDetail'
import { BuyUniOptionDetail } from '../../sections/BuyUniOptionDetail'
import { BuyDydxOptionDetail } from '../../sections/BuyDydxOptionDetail'
import { BuyWbtcOptionDetail } from '../../sections/BuyWbtcOptionDetail'
import { BuySnxOptionDetail } from '../../sections/BuySnxOptionDetail'
import { BuyDpiOptionDetail } from '../../sections/BuyDpiOptionDetail'
import { BuyCompOptionDetail } from '../../sections/BuyCompOptionDetail'
import { BuyBalOptionDetail } from '../../sections/BuyBalOptionDetail'
import { BuyYfiOptionDetail } from '../../sections/BuyYfiOptionDetail'
import { BuyCrvOptionDetail } from '../../sections/BuyCrvOptionDetail'
// restore ETH puts. uncomment this
import { SellAssetDetail } from '../../sections/SellAssetDetail'
import { SellWethOptionDetail } from '../../sections/SellWethOptionDetail'
import { SellUniOptionDetail } from '../../sections/SellUniOptionDetail'
import { SellDydxOptionDetail } from '../../sections/SellDydxOptionDetail'
import { SellWbtcOptionDetail } from '../../sections/SellWbtcOptionDetail'
import { SellSnxOptionDetail } from '../../sections/SellSnxOptionDetail'
import { SellDpiOptionDetail } from '../../sections/SellDpiOptionDetail'
import { SellCompOptionDetail } from '../../sections/SellCompOptionDetail'
import { SellBalOptionDetail } from '../../sections/SellBalOptionDetail'
import { SellYfiOptionDetail } from '../../sections/SellYfiOptionDetail'
import { SellCrvOptionDetail } from '../../sections/SellCrvOptionDetail'
import { CreateOTokens } from '../../sections/CreateOTokens'
import { Error404 } from '../../sections/Error404'
import { COMPOUND_TOKENS } from '../../common/constants'
import ApolloProvider from '../../utils/ApolloProvider'
import Web3ReactConnector from '../web3/Web3ReactConnector'

import { Footer } from '../Common/Footer'
import { GlobalStyle } from '../Common/GlobalStyle'
import { Header } from '../Common/Header'
import { InnerContainer } from '../Common/InnerContainer'
import { MainScroll } from '../Common/MainScroll'
import Warnings from '../Common/Warnings'
import theme from '../../theme'
import 'sanitize.css'
import 'react-virtualized/styles.css'

import GA from 'utils/GoogleAnalytics'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

interface RouteWithLayoutProps extends RouteProps {
  component?: any
}

const DefaultLayout: React.FC<RouteWithLayoutProps> = props => {
  const { component: Component, ...restProps } = props

  return (
    <Route
      {...restProps}
      render={matchProps => (
        <Wrapper>
          <Header />
          <Warnings />
          <MainScroll>
            <InnerContainerStyled>
              <Component {...matchProps} />
            </InnerContainerStyled>
            <Footer />
          </MainScroll>
        </Wrapper>
      )}
    />
  )
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Web3ReactConnector>
        <ApolloProvider>
          <GlobalStyle />
          <Router>
            {GA.init() && <GA.RouteTracker />}
            <LastLocationProvider>
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <DefaultLayout exact path="/buy" component={Buy} />
                <DefaultLayout exact path="/sell" component={Sell} />
                <DefaultLayout
                  path={`/buy/asset/:id(${COMPOUND_TOKENS.join('|')})`}
                  component={BuyCompoundAssetDetail}
                />
                <DefaultLayout path={`/buy/wethoption/:address`} component={BuyWethOptionDetail} />
                <DefaultLayout path={`/buy/comption/:address`} component={BuyCompOptionDetail} />
                <DefaultLayout path={`/buy/baltion/:address`} component={BuyBalOptionDetail} />
                <DefaultLayout path={`/buy/yfioption/:address`} component={BuyYfiOptionDetail} />
                <DefaultLayout path={`/buy/crvoption/:address`} component={BuyCrvOptionDetail} />
                <DefaultLayout path={`/buy/unioption/:address`} component={BuyUniOptionDetail} />
                <DefaultLayout path={`/buy/dydxoption/:address`} component={BuyDydxOptionDetail} />
                <DefaultLayout path={`/buy/wbtcoption/:address`} component={BuyWbtcOptionDetail} />
                <DefaultLayout path={`/buy/snxoption/:address`} component={BuySnxOptionDetail} />
                <DefaultLayout path={`/buy/dpioption/:address`} component={BuyDpiOptionDetail} />
                <DefaultLayout path={`/buy/:address`} component={BuyEthAssetDetail} />
                <DefaultLayout path={`/sell/asset/:id(${COMPOUND_TOKENS.join('|')})`} component={CreateOTokens} />
                <DefaultLayout path={`/sell/wethoption/:address`} component={SellWethOptionDetail} />
                <DefaultLayout path={`/sell/comption/:address`} component={SellCompOptionDetail} />
                <DefaultLayout path={`/sell/baltion/:address`} component={SellBalOptionDetail} />
                <DefaultLayout path={`/sell/yfioption/:address`} component={SellYfiOptionDetail} />
                <DefaultLayout path={`/sell/crvoption/:address`} component={SellCrvOptionDetail} />
                <DefaultLayout path={`/sell/unioption/:address`} component={SellUniOptionDetail} />
                <DefaultLayout path={`/sell/dydxoption/:address`} component={SellDydxOptionDetail} />
                <DefaultLayout path={`/sell/wbtcoption/:address`} component={SellWbtcOptionDetail} />
                <DefaultLayout path={`/sell/snxoption/:address`} component={SellSnxOptionDetail} />
                <DefaultLayout path={`/sell/dpioption/:address`} component={SellDpiOptionDetail} />
                <DefaultLayout path={`/sell/:address`} component={SellAssetDetail} />
                <Route path="/landing" component={LandingPage} />
                <DefaultLayout component={Error404} />
              </Switch>
            </LastLocationProvider>
          </Router>
        </ApolloProvider>
      </Web3ReactConnector>
    </ThemeProvider>
  )
}

export default App
