import React from 'react'

interface IconProps {
  color?: string
}

export const BackArrowIcon: React.FC<IconProps> = (props: IconProps) => {
  const { color = '#ccc' } = props

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM18 10C18 14.411 14.411 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10ZM5.293 9.293C4.902 9.684 4.902 10.316 5.293 10.707L8.293 13.707C8.684 14.098 9.316 14.098 9.707 13.707C10.098 13.316 10.098 12.684 9.707 12.293L8.414 11H14C14.553 11 15 10.552 15 10C15 9.448 14.553 9 14 9H8.414L9.707 7.707C9.902 7.512 10 7.256 10 7C10 6.744 9.902 6.488 9.707 6.293C9.316 5.902 8.684 5.902 8.293 6.293L5.293 9.293Z"
        fill={color}
      />
    </svg>
  )
}
