import React from 'react'
import styled from 'styled-components'
import { getColorByStatus } from '../ColorCell'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

interface ChevronCellProps {
  status?: string
}

export const ChevronCell: React.FC<ChevronCellProps> = (props: ChevronCellProps) => {
  const { status, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.04938 8L0.404014 2.35463C-0.134641 1.81598 -0.134641 0.942644 0.404014 0.40399C0.942668 -0.134665 1.816 -0.134665 2.35465 0.40399L8.97534 7.02468C9.514 7.56334 9.514 8.43667 8.97534 8.97532L2.35465 15.596C1.816 16.1347 0.942668 16.1347 0.404014 15.596C-0.134641 15.0574 -0.134641 14.184 0.404014 13.6454L6.04938 8Z"
          fill={status ? getColorByStatus(status) : ''}
        />
      </svg>
    </Wrapper>
  )
}
