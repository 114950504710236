import React from 'react'
import { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import {
  getTokenFromAddress,
  getBaseToken,
  getNetworkTokenAddresses,
  getToken,
  isValidNetworkId,
  convertOTokenToERC20,
  BuyOTokenAction,
  ExerciseAction,
  NetworkId,
} from '../utils'
import { CheckIcon } from '../components/TransactionsCard/img/CheckIcon'
import { RefreshIcon } from '../components/TransactionsCard/img/RefreshIcon'
import { useConnection } from '../components/web3'
import Value from '../components/Common/Value'
import DoubleBalance from '../components/Common/DoubleBalance'
import { Big } from 'big.js'

const HISTORY = loader('../queries/buying_history.graphql')

const sorter = (a: any, b: any) => b.timestamp - a.timestamp

const getPremiumPaid = (action: BuyOTokenAction, networkId: NetworkId | -1) => {
  const { paymentTokenAddress, premiumPaid, paymentTokenPrice } = action
  let { usdcPrice } = action
  if (usdcPrice === '0') usdcPrice = (1e18).toString()
  const paymentToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, paymentTokenAddress) : null

  return new Big(premiumPaid)
    .times(new Big(paymentTokenPrice).div(usdcPrice))
    .div(`1e${paymentToken ? paymentToken.decimals : 1}`)
}

const getTotalPaid = (buyActions: Array<BuyOTokenAction>, networkId: NetworkId) => {
  return buyActions.reduce((acc, action) => {
    return acc.plus(getPremiumPaid(action, networkId))
  }, new Big(0))
}

export const useBuyingTransactionHistory = (tokenId: KnownToken): [any[], any[], boolean, Function, Maybe<Big>] => {
  const { networkId, account } = useConnection()
  const cToken = isValidNetworkId(networkId) ? getToken(networkId, tokenId) : null
  const token = isValidNetworkId(networkId) ? getBaseToken(networkId, tokenId) : null

  const approvalsFilter = useCallback(
    ({ approvedTokenAddress }: { approvedTokenAddress: string }) =>
      getNetworkTokenAddresses(networkId).includes(approvedTokenAddress),
    [networkId],
  )

  const parseApproval = useCallback(
    ({ id, approvedTokenAddress, timestamp, transactionHash }) => ({
      id,
      icon: CheckIcon,
      title: ['Unlocked ', getTokenFromAddress(networkId, approvedTokenAddress).symbol],
      timestamp,
      transactionHash,
    }),
    [networkId],
  )

  const parseBuyOTokenAction = useCallback(
    (action: BuyOTokenAction) => {
      const {
        id,
        oTokensToBuy,
        transactionHash,
        timestamp,
        exchangeRateCurrent,
        token: { oTokenExchangeRateValue = '1', oTokenExchangeRateExp = '0' },
        pending,
      } = action
      const ERC20Amount = convertOTokenToERC20(
        oTokensToBuy,
        cToken,
        oTokenExchangeRateValue,
        oTokenExchangeRateExp,
        exchangeRateCurrent,
        token,
      )

      const $premiumPaid = getPremiumPaid(action, networkId)

      return {
        id,
        icon: pending ? RefreshIcon : CheckIcon,
        title: [
          <Value key="token" decimals={ERC20Amount.decimals} precision={8} value={ERC20Amount.value}>
            {val => `${val} ${token?.symbol}`}
          </Value>,
          ' insured, ',
          `$${$premiumPaid.toFixed(4)} paid`,
        ],
        timestamp,
        transactionHash,
      }
    },
    [token, cToken, networkId],
  )
  const parseExerciseAction = useCallback(
    ({
      id,
      transactionHash,
      timestamp,
      amtCollateralToPay,
      optionsContract: { collateral = '' },
      collateralPrice,
      usdcPrice,
      pending,
    }: ExerciseAction) => {
      const collateralToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, collateral) : null
      const $amtCollateralToPay = new Big(amtCollateralToPay)
        .div(usdcPrice)
        .times(collateralPrice)
        .times(`1e-${collateralToken?.decimals || 0}`)
        .round(4)
        .toFixed()

      return {
        id,
        icon: pending ? RefreshIcon : CheckIcon,
        title: [
          'Claimed ',
          <DoubleBalance
            key="token"
            left={{
              token: collateralToken,
              value: new Big(amtCollateralToPay)
                .times(`1e-${collateralToken?.decimals || 0}`)
                .round(4)
                .toFixed(),
            }}
            right={{
              token: '$',
              value: $amtCollateralToPay,
            }}
          />,
        ],
        timestamp,
        transactionHash,
      }
    },
    [networkId],
  )

  const locallyStoredBuy = JSON.parse(localStorage.getItem('buy-transactions') || '[]') as any[]
  const locallyStoredExercise = JSON.parse(localStorage.getItem('exercise-transactions') || '[]') as any[]
  const { loading, data, refetch } = useQuery(HISTORY, {
    variables: {
      account,
      token: cToken?.address,
    },
    skip: !account || !cToken,
  })

  const refresh = useCallback(async () => {
    if (account && cToken) {
      refetch({
        account,
        token: cToken?.address,
      })
    }
  }, [account, cToken, refetch])

  let buyOTokenActions = [...(data?.buyOTokensActions || [])]
  let exerciseActions = [...(data?.exerciseActions || [])]

  const pendingBuy = locallyStoredBuy.filter(
    ({ transactionHash: hash, timestamp }: BuyOTokenAction) =>
      Date.now() / 1000 - Number(timestamp) < 3600 &&
      !buyOTokenActions.find(({ transactionHash }: BuyOTokenAction) => hash === transactionHash),
  )

  if (pendingBuy.length) {
    buyOTokenActions = buyOTokenActions.concat(pendingBuy)
  }

  localStorage.setItem('buy-transactions', JSON.stringify(pendingBuy))

  const pendingExercise = locallyStoredExercise.filter(
    ({ transactionHash: hash, timestamp }: ExerciseAction) =>
      Date.now() / 1000 - Number(timestamp) < 3600 &&
      !exerciseActions.find(({ transactionHash }: ExerciseAction) => hash === transactionHash),
  )

  if (pendingExercise.length) {
    exerciseActions = exerciseActions.concat(pendingExercise)
  }

  localStorage.setItem('exercise-transactions', JSON.stringify(pendingExercise))

  const history = data
    ? [
        ...data.approvals.filter(approvalsFilter).map(parseApproval),
        ...buyOTokenActions.map(parseBuyOTokenAction),
        ...exerciseActions.map(parseExerciseAction),
      ].sort(sorter)
    : []

  const rawData = data ? [...buyOTokenActions, ...exerciseActions].sort(sorter) : []

  const totalPaid = isValidNetworkId(networkId) ? getTotalPaid(buyOTokenActions, networkId) : null

  return [history, rawData, loading, refresh, totalPaid]
}
