import { useState, useEffect, useMemo } from 'react'
import { differenceInDays, fromUnixTime } from 'date-fns'
import {
  CompoundTokens,
  NetworkId,
  CompoundBalance,
  isValidNetworkId,
  BigFloat,
  getToken,
  SellerCompoundOptionData,
  getTokenFromAddress,
  Token,
  getTokenPriceCoingecko,
} from '../utils'
import { GetOptionsContractsByUnderlying_optionsContracts, SellOTokens_optionsContract } from '../types/generatedGQL'
import { useConnection } from '../components/web3'
import { useBalances } from './useBalances'
import { BigNumber } from 'ethers/utils'
import { Big } from 'big.js'
import { OptionsExchange, OracleService } from '../services'
import useOptionsExchange from './useOptionsExchange'
import useOracle from './useOracle'

interface PremiumAPRArgs {
  numberOfDays: number
  paymentTokenToUSDCRate: Big
  cTokenUnderlyingToUSDCRate: Big
  premiumToPay: string
  oTokenExchangeRateExp: string
  cTokenExchangeRate: string
  cTokenExchangeRateDecimals: number
}

const getPremiumAPR = ({
  numberOfDays,
  paymentTokenToUSDCRate,
  cTokenUnderlyingToUSDCRate,
  premiumToPay,
  oTokenExchangeRateExp,
  cTokenExchangeRate,
  cTokenExchangeRateDecimals,
}: PremiumAPRArgs) => {
  const amountCTokens = new Big(`1e${oTokenExchangeRateExp}`)
  const scaledExchangeRate = new Big(cTokenExchangeRate).times(`1e-${cTokenExchangeRateDecimals}`)
  const $amountInCompound = scaledExchangeRate.times(amountCTokens).times(cTokenUnderlyingToUSDCRate)
  const $premium = new Big(premiumToPay).times(1e-18).times(paymentTokenToUSDCRate)

  const $premiumAPR = $premium
    .times(365)
    .times(100)
    .div(numberOfDays)
    .div($amountInCompound)

  return {
    value: $premiumAPR.toFixed(0),
    decimals: 0,
  }
}

const getEarnAPR = async ({
  networkId,
  optionsExchangeContract,
  oracleContract,
  currentOption,
  balance,
}: {
  networkId: number
  optionsExchangeContract?: Maybe<OptionsExchange>
  oracleContract?: Maybe<OracleService>
  currentOption: Maybe<GetOptionsContractsByUnderlying_optionsContracts>
  balance: CompoundBalance
}): Promise<BigFloat> => {
  const { uninsuredAPR, exchangeRate, cToken, token } = balance

  if (
    !isValidNetworkId(networkId) ||
    !optionsExchangeContract ||
    !oracleContract ||
    !token ||
    !currentOption ||
    !exchangeRate
  ) {
    return {
      value: uninsuredAPR?.value || 0,
      ...(uninsuredAPR?.decimals && { decimals: uninsuredAPR.decimals }),
    }
  }
  const usdcAddress = getToken(networkId, 'usdc').address
  // const ethToUsdcPrice = await oracleContract.getPrice(usdcAddress)
  // const ethToTokenPrice = await oracleContract.getPrice(token.address)
  const ethToUsdcPrice = Number(await getTokenPriceCoingecko(usdcAddress)) * 1e18
  const ethToTokenPrice = Number(await getTokenPriceCoingecko(token.address)) * 1e18

  let premiumToPayInWei = new BigNumber(0)
  try {
    premiumToPayInWei = await optionsExchangeContract?.getPremiumToPay(
      currentOption.address,
      '0x0000000000000000000000000000000000000000',
      1,
    )
  } catch {
    // Do nothing
  }

  return getPremiumAPR({
    numberOfDays: differenceInDays(fromUnixTime(currentOption.expiry), new Date()),
    paymentTokenToUSDCRate: new Big(1).div(new Big(ethToUsdcPrice.toString()).times(1e-18)), //
    cTokenUnderlyingToUSDCRate: new Big(ethToTokenPrice.toString()).div(new Big(ethToUsdcPrice.toString())),
    premiumToPay: premiumToPayInWei.toString(),
    oTokenExchangeRateExp: currentOption.oTokenExchangeRateExp,
    cTokenExchangeRate: exchangeRate.toString(),
    cTokenExchangeRateDecimals: cToken?.exchangeRateDecimals || 0,
  })
}

const calculateOptionData = async (
  option: any,
  balances: Array<any>,
  networkId: NetworkId,
  tokenId?: CompoundToken,
  optionsExchangeContract?: Maybe<OptionsExchange>,
  oracleContract?: Maybe<OracleService>,
): Promise<Maybe<SellerCompoundOptionData>> => {
  const token = tokenId && isValidNetworkId(networkId) ? getToken(networkId, tokenId) : null
  const getOptionsData = async (token: Maybe<Token>): Promise<SellerCompoundOptionData> => {
    const [balance] = balances.filter(b => b.id.toLocaleUpperCase() === token?.symbol)

    const earnAPR = await getEarnAPR({
      networkId,
      optionsExchangeContract,
      oracleContract,
      currentOption: option,
      balance,
    })

    return {
      option,
      earnAPR,
    } as SellerCompoundOptionData
  }

  return token && (await getOptionsData(token))
}

type CompoundOptions = Record<CompoundTokens, SellerCompoundOptionData>

export const useSellerCompoundOptions = (option: Maybe<SellOTokens_optionsContract> = null) => {
  const { networkId, account } = useConnection()
  const [loading, setLoading] = useState(true)
  const [compoundOptions, setCompoundOptions] = useState<SellerCompoundOptionData>({} as SellerCompoundOptionData)

  const underlying = useMemo(() => option && getTokenFromAddress(networkId, option.underlying), [networkId, option])

  const tokenId = underlying?.symbol.toLocaleLowerCase() as CompoundTokens

  const tokens = useMemo(() => (tokenId ? [tokenId as CompoundTokens] : []), [tokenId])

  const [balances, balancesLoading, reloadBalances] = useBalances(tokens)

  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const oracleContract = useOracle()

  useEffect(() => {
    let isCancelled = false
    if (
      option &&
      isValidNetworkId(networkId) &&
      !balancesLoading &&
      balances.length &&
      option &&
      optionsExchangeContract
    ) {
      setLoading(true)
      calculateOptionData(option, balances, networkId, tokenId, optionsExchangeContract, oracleContract).then(
        compoundOptions => {
          if (compoundOptions && !isCancelled) {
            setCompoundOptions(compoundOptions)
          }
          setLoading(false)
        },
      )
    }

    return () => {
      isCancelled = true
    }
  }, [networkId, tokenId, balances, optionsExchangeContract, oracleContract, balancesLoading, option, account, tokens])

  return {
    compoundOptions,
    loading,
    reload: reloadBalances,
  }
}
