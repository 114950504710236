import { useConnection } from 'components/web3'
import { isValidNetworkId, getToken } from 'utils'
import useOracle from './useOracle'
import useAsyncMemo from './useAsyncMemo'
import { BigNumber } from 'ethers/utils'

const _default = new BigNumber(0)

export const useEthToDaiPrice = (): BigNumber => {
  const { networkId } = useConnection()
  const oracleContract = useOracle()

  return useAsyncMemo(
    async () => {
      if (!isValidNetworkId(networkId) || !oracleContract) {
        return _default
      }

      const daiAddress = getToken(networkId, 'dai').address
      const price = await oracleContract.getPrice(daiAddress)
      // const price = new BigNumber(Number(await getTokenPriceCoingecko(daiAddress)) * 1e18)

      return price
    },
    _default,
    [networkId, oracleContract],
  )
}

export default useEthToDaiPrice
