import React, { useCallback, ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { InnerContainer } from 'components/Common/InnerContainer'
import CompoundSVG from './img/1.svg'
import DaiSVG from './img/dai.svg'
import EtherSVG from './img/ether.svg'
import USDCSvg from './img/3.svg'
import oETH from './img/oETH.svg'
import oDAI from './img/oDAI.svg'
import oUSDC from './img/oUSDC.svg'
import Checkmark from './img/checkmark.svg'
import { ThemeColorTypes } from 'utils'
import { Button } from 'components/Common/Button'
import { useHistory } from 'react-router'

const Wrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 80px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.darkGray};
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 40px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const IconWrapper = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 16px;
  height: 72px;
  justify-content: center;
  width: 72px;

  img {
    display: block;
  }
`

const LayoutList = styled.div`
  & > div:first-child {
    margin-top: 20px;
  }
`
const ListItem = styled.div`
  margin-top: 30px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }
`

const ListItemContent = styled.div``
const ContentTile = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 20px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 40px;
  }
`

const ContentItem = styled.div`
  align-items: center;
  color: #828282;
  display: flex;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 13px;

  img {
    margin-right: 12px;
  }
`

const ListItemButtons = styled.div`
  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

const ButtonWrapper = styled.div`
  width: 248px;

  button {
    width: 248px;
  }
`
const IconsGroup = styled.div`
  display: flex;
  align-items: left;

  ${IconWrapper} {
    margin: 16px 16px 16px 0;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    align-items: right;
    ${IconWrapper} {
      margin: 0 0 16px 16px;
    }
  }
`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url?: string
  path?: string
}

const ButtonPath: React.FC<ButtonProps> = props => {
  const { path, children } = props
  const history = useHistory()
  const goToPath = useCallback(() => history.push(path || ''), [path, history])

  return (
    <Button buttonStyle={ThemeColorTypes.primaryLight} onClick={goToPath}>
      {children}
    </Button>
  )
}

const ButtonUrl: React.FC<ButtonProps> = props => {
  const { url, children } = props
  const goToURL = useCallback(() => window.open(url), [url])

  return (
    <Button buttonStyle={ThemeColorTypes.primaryLight} onClick={goToURL}>
      {children}
    </Button>
  )
}

const items = [
  {
    title: 'Buy Protection',
    bulletone: 'Protect USDC and DAI Compound deposits',
    bullettwo: 'Protect yourself against ETH downside, volatility, and flash crashes',
    button: <ButtonPath path={'/buy'}>Buy Protection</ButtonPath>,
    icons: [CompoundSVG, DaiSVG, EtherSVG, USDCSvg],
  },
  {
    title: 'Sell Protection',
    bulletone: 'Earn premiums on ETH for protecting Compound',
    bullettwo: 'Earn premiums on USDC by selling ETH protective put options',
    button: <ButtonPath path={'/sell'}>Sell protection</ButtonPath>,
    icons: [USDCSvg, EtherSVG],
  },
  {
    title: 'Develop',
    bulletone: 'Secure your users against DeFi risks',
    bullettwo: 'Build with options on Ethereum (oTokens)',
    button: <ButtonUrl url="https://opyn.gitbook.io/opyn/">Explore documentation</ButtonUrl>,
    icons: [oETH, oDAI, oUSDC],
  },
]

export const ProtectEarnDevelop: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Title>Products</Title>
        <LayoutList>
          {items.map((item, index) => {
            return (
              <ListItem key={index}>
                <ListItemContent>
                  <ContentTile>{item.title}</ContentTile>
                  <ContentItem>
                    <img src={Checkmark} alt="" /> {item.bulletone}
                  </ContentItem>
                  <ContentItem>
                    <img src={Checkmark} alt="" /> {item.bullettwo}
                  </ContentItem>
                </ListItemContent>
                <ListItemButtons>
                  <IconsGroup>
                    {item.icons?.map((icon, index) => (
                      <IconWrapper key={`icon-${index}`}>
                        <img src={icon} alt="" />
                      </IconWrapper>
                    ))}
                  </IconsGroup>
                  <ButtonWrapper>{item.button}</ButtonWrapper>
                </ListItemButtons>
              </ListItem>
            )
          })}
        </LayoutList>
      </InnerContainerStyled>
    </Wrapper>
  )
}
