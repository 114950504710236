import React from 'react'

export const DropdownIcon = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.195604 1.34127L3.30405 4.96779C3.59608 5.30849 4.10901 5.34795 4.44971 5.05592C4.4813 5.02884 4.51077 4.99938 4.53784 4.96779L7.64629 1.34127C7.93832 1.00057 7.89886 0.487634 7.55816 0.195604C7.4109 0.0693811 7.22334 0 7.02939 0L0.8125 0C0.363769 0 0 0.363769 0 0.8125C0 1.00645 0.0693811 1.19401 0.195604 1.34127Z"
      fill="#333333"
    />
  </svg>
)
