import React from 'react'
import styled from 'styled-components'

import { Big } from 'big.js'
import Percentage from '../Percentage'
import Value from '../Value'

const Wrapper = styled.div`
  text-align: center;
`

const Token = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

interface EarnCellProps {
  value?: Big
  token?: string
}

export const EarnCell: React.FC<EarnCellProps> = (props: EarnCellProps) => {
  const { value, token, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <Percentage value={value ? value.round(2).toFixed() : 0} />
      <Token>
        {' / '} <br />
        <Value value={1}>{val => `${val} ${token} collateral`}</Value>
      </Token>
    </Wrapper>
  )
}
