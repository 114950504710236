import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import Slider from 'components/Common/Slider'
import { EthCollateral } from '../EthCollateral'
import { CollateralizationRatio } from '../CollateralizationRatio'
import { PremiumsToEarn } from '../PremiumsToEarn'
import { Duration } from '../Duration'
import { OTokensCreated } from '../OTokensCreated'
import { Button } from 'components/Common/Button'
import Link from 'components/Common/Link'
import { useConnection, useOptionsExchange, useAsyncMemo, useEthToUsdPrice } from 'hooks'
import { ETH_TOKEN, ThemeColorTypes, BigFloat, Token, isBetween } from 'utils'
import { BigNumber } from 'ethers/utils'
import TokenBalance from 'components/Common/TokenBalance'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { Big, BigSource } from 'big.js'
import { Web3Provider } from 'ethers/providers'
import { calculateMaxOptionsToCreate } from 'utils/options'
import { OptionsExchange, oToken } from 'services'

const DetailWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  div {
    border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  }

  div:last-child {
    border-bottom: none;
  }
`

const Text = styled.div`
  color: ${props => props.theme.colors.textColorMedium};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 12px;
`

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  font-size: 11px;
  height: 30px;
`

const getEthBalance = async (account: Maybe<string>, library: Maybe<Web3Provider>): Promise<BigNumber> => {
  if (account) {
    try {
      const signer = await library?.getSigner()
      return signer?.getBalance() || new BigNumber(0)
    } catch {
      // Do nothing
    }
  }
  return new BigNumber(0)
}

const getPremiumToEarn = async (
  optionsExchangeContract: Maybe<OptionsExchange>,
  oTokensToCreate: BigNumber,
  oTokenAddress: string,
) => {
  if (oTokensToCreate.eq(0) || !oTokenAddress || !optionsExchangeContract) {
    return new BigNumber(0)
  }
  const premium = await optionsExchangeContract?.getPremiumReceived(
    oTokenAddress,
    ETH_TOKEN.address,
    Number(oTokensToCreate),
  )

  return premium
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface oTokenData {
  service: Maybe<oToken>
  collateralToStrikePrice: BigFloat
  collateralizationRatio: number
  token: Maybe<Token>
  loading: boolean
}

interface ModalProps {
  isOpen: boolean
  onConfirm?: (tx: any, amount: string, type: string) => void
  onTransactionFail?: () => void
  onRequestClose?: (e?: any) => void
  option?: SellOTokens_optionsContract
  oTokenData?: oTokenData
  title?: string
}

export const SellInsuranceModal: React.FC<ModalProps> = props => {
  const { option, onConfirm, onTransactionFail, oTokenData } = props
  const { vaults, oTokenExchangeRateExp } = option || {}
  const { collateral: addedCollateral = 0, oTokensIssued: mintedOTokens = 0 } = vaults?.[0] || {}
  const { account, library } = useConnection()
  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const { service, collateralToStrikePrice, collateralizationRatio = 0, token, loading } = oTokenData || {}

  const [pendingConfirm, setPendingConfirm] = useState(false)
  const [sliderValueShowed, setSliderValueShowed] = useState(0)
  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    if (!loading) {
      setSliderValueShowed(collateralizationRatio * 100 || 200)
      setSliderValue(collateralizationRatio * 100 || 200)
    }
  }, [loading, collateralizationRatio])

  const [inputCollateral, setInputCollateral] = useState<BigSource>('0')
  const [oTokensToCreate, setOTokensToCreate] = useState(new BigNumber(0))
  const [premiumToEarn, setPremiumToEarn] = useState({ value: new BigNumber(0), decimals: ETH_TOKEN.decimals })

  const oTokenSymbol: Maybe<string> = useMemo(() => {
    if (!token) {
      return null
    }
    return !token.cToken ? `o${token.symbol}` : `o${token.symbol[0].toLowerCase()}${token.symbol.slice(1)}`
  }, [token])

  const bMinCollateralizationRatio = useMemo(() => {
    if (!option) {
      return new Big(0)
    }
    return new Big(option?.minCollateralizationRatioValue).times(`1e${option?.minCollateralizationRatioExp}`)
  }, [option])

  const bigEthBalance = useAsyncMemo<BigNumber>(async () => getEthBalance(account, library), new BigNumber(0), [
    account,
    library,
  ])

  const bEthBalance = new Big(bigEthBalance.toString()).times(`1e-18`)
  const updateInputCollateral = (value: string) => {
    if (isBetween(value, 0, bEthBalance.toString())) {
      setInputCollateral(value || '0')
    }
  }

  const setMaxEthBalance = () => setInputCollateral(bEthBalance)

  const ethToUsdcPrice = useEthToUsdPrice()

  const $collateral = useMemo(() => {
    if (!inputCollateral || inputCollateral === '0' || ethToUsdcPrice.eq(0)) {
      return new BigNumber(0)
    }
    return new Big(inputCollateral)
      .times(`1e18`)
      .div(ethToUsdcPrice.toString())
      .round(4)
      .toFixed()
  }, [ethToUsdcPrice, inputCollateral])

  const sliderHandler = useCallback(event => {
    setSliderValueShowed(event.target.value)
  }, [])

  const sliderMouseUpHandler = useCallback(async event => {
    setSliderValue(event.target.value)
  }, [])

  const confirmHandler = useCallback(async () => {
    if (account && service) {
      setPendingConfirm(true)
      try {
        const sellOTokens = vaults?.length
          ? service.addAndSellETHCollateralOption
          : service.createAndSellETHCollateralOption

        const amount = new BigNumber(new Big(inputCollateral).times(`1e${ETH_TOKEN.decimals}`).toFixed(0))
        const tx = await sellOTokens(oTokensToCreate.toString(), account, amount)

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, amount.toString(), 'SellOTokensAction')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          setPendingConfirm(false)
          onTransactionFail()
        }
      }
    }
  }, [vaults, account, service, oTokensToCreate, inputCollateral, onTransactionFail, onConfirm])

  const buttonDisabled = !account || !service || loading || oTokensToCreate.lte(0) || pendingConfirm

  const errorMessage =
    oTokensToCreate.eq(0) && !!Number(inputCollateral) && `Not enough collateral for the chosen ratios`

  const calculateOTokensToCreate = useCallback(
    ratio => {
      if (!option || !inputCollateral || !Number(inputCollateral) || !collateralToStrikePrice?.value) {
        return new BigNumber(0)
      } else {
        const otokens = calculateMaxOptionsToCreate({
          collateralToAdd: inputCollateral,
          strikePriceValue: option?.strikePriceValue,
          strikePriceExp: option?.strikePriceExp,
          collateralToStrikePrice,
          addedCollateral: new Big(addedCollateral).div('1e18').toString(),
          mintedOTokens,
          ratio,
        })
        return otokens.lt(0) ? new BigNumber(0) : otokens
      }
    },
    [collateralToStrikePrice, inputCollateral, addedCollateral, mintedOTokens, option],
  )

  useEffect(() => {
    setOTokensToCreate(calculateOTokensToCreate(sliderValue / 100))
  }, [calculateOTokensToCreate, inputCollateral, sliderValue])

  useEffect(() => {
    if (oTokensToCreate.lte(0)) {
      setPremiumToEarn({ value: new BigNumber(0), decimals: ETH_TOKEN.decimals })
    } else {
      getPremiumToEarn(optionsExchangeContract, oTokensToCreate, option?.address).then(premium => {
        setPremiumToEarn({
          value: premium,
          decimals: ETH_TOKEN.decimals,
        })
      })
    }
  }, [oTokensToCreate, setPremiumToEarn, optionsExchangeContract, option])

  const $premiumToEarn = useMemo(() => {
    if (premiumToEarn.value.eq(0) || ethToUsdcPrice.eq(0)) {
      return new BigNumber(0)
    }
    return new Big(premiumToEarn.value.toString()).div(ethToUsdcPrice.toString()).toFixed(4)
  }, [ethToUsdcPrice, premiumToEarn])

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        title="ETH Collateral"
        titleInfo={[
          {
            title: 'Balance:',
            value: <TokenBalance value={bigEthBalance} token={ETH_TOKEN} precision={4} />,
            onClick: setMaxEthBalance,
          },
        ]}
        dropdownIsDisabled
        value={inputCollateral.toString()}
        selectedToken={'eth'}
        onAmountChange={updateInputCollateral}
        error={false}
      />
      <Slider
        title={'Select initial ETH collateralization ratio'}
        min={Number(bMinCollateralizationRatio.times(100).toFixed())}
        max={Number(
          bMinCollateralizationRatio
            .times(100)
            .plus(50)
            .toFixed(),
        )}
        step={1}
        value={sliderValueShowed}
        onChange={sliderHandler}
        onMouseUp={sliderMouseUpHandler}
      />
      <DetailWrapper>
        <EthCollateral value={inputCollateral.toString()} $value={$collateral.toString()} />
        <CollateralizationRatio
          ratio={sliderValue.toString()}
          minRatio={Number(bMinCollateralizationRatio.times(100)).toFixed()}
        />
        <PremiumsToEarn value={premiumToEarn} $value={$premiumToEarn.toString()} token={'ETH'} />
        <Duration expiry={option?.expiry} />
        <OTokensCreated
          value={{
            value: oTokensToCreate.toString(),
            decimals: (oTokenExchangeRateExp && oTokenExchangeRateExp * -1) || 1,
          }}
          token={oTokenSymbol || ''}
        />
      </DetailWrapper>
      <Text>
        In case of a claim, you may lose some or all of your collateral.{' '}
        <Link
          themeColor={ThemeColorTypes.secondary}
          href="https://opyn.gitbook.io/opynv1/faq#how-do-claims-work"
          target="_blank"
        >
          Learn more.
        </Link>
      </Text>

      <ErrorWrapper>{errorMessage}</ErrorWrapper>

      <Button disabled={buttonDisabled} buttonStyle={ThemeColorTypes.secondary} onClick={confirmHandler}>
        Confirm
      </Button>
    </Modal>
  )
}
