import React from 'react'

export const MediumIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 32 32"
      height="20"
      version="1.1"
      viewBox="0 0 32 32"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="iconBackground"
        d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
        fill="#BDBDBD"
      />
      <path
        d="M24.959,11.076l-5.76-2.88c-0.026-0.013-0.055-0.019-0.083-0.02c-0.002,0-0.004-0.001-0.006-0.001    c-0.07,0-0.14,0.035-0.177,0.096l-3.55,5.769l3.963,6.44l5.669-9.212C25.056,11.201,25.031,11.112,24.959,11.076z"
        fill="#FFFFFF"
      />
      <polygon fill="#FFFFFF" points="13.505,12.013 13.505,18.23 19.031,20.992" />
      <path d="M19.514,21.23l5.074,2.537c0.277,0.138,0.504-0.002,0.504-0.311v-11.29L19.514,21.23z" fill="#FFFFFF" />
      <path
        d="M12.895,11.105L7.58,8.448L7.244,8.28C7.196,8.256,7.15,8.244,7.108,8.244    c-0.059,0-0.109,0.023-0.145,0.066C6.929,8.352,6.908,8.412,6.908,8.487v12.13c0,0.206,0.151,0.451,0.336,0.543l5.222,2.611    c0.073,0.036,0.142,0.053,0.204,0.053c0.176,0,0.299-0.136,0.299-0.365V11.226C12.97,11.175,12.941,11.128,12.895,11.105z"
        fill="#FFFFFF"
      />
    </svg>
  )
}
