import { useState, useMemo } from 'react'
import { GetOptionsContractsByUnderlying_optionsContracts } from 'types/generatedGQL'
import { ETH_TOKEN, Token, getToken, isValidNetworkId } from 'utils'
import { fromUnixTime } from 'date-fns'
import { format } from 'date-fns-tz'
import { Big } from 'big.js'
import useEthToUsdPrice from './useEthToUSDPrice'

import useOracle from './useOracle'
import { BigNumber } from 'ethers/utils'

import { OptionsExchange } from 'services'
import { EXPIRY_DATE_FORMAT } from 'common/constants'

import { useConnection } from '.'
import { useBuyEthOptions } from './useBuyerEthOptions'
import useAsyncMemo from './useAsyncMemo'

export interface InsuredEthOptionsData {
  option: GetOptionsContractsByUnderlying_optionsContracts
  balance: {
    amount: BigNumber
    token: Token
    precision: number
  }
  expiry: string
  strikePrice: string
  ethPrice: string
  protectionCost: string
  status: string
  type: string
}

export const putsToShow = [
  '0x461cd647add2159e85ad57141cb5371566fceed3',
  '0x6c79f10543c7886c6946b8a996f824e474bac8f2',
  '0xc8f95e13eff0165e5e381ea1878c56d93562d7d7',
  '0xaefc7b368f7b536c9e5e3f342bf534931ce58584',
  '0xecf6f2fd8d2a8e17397066348d449566d5407174',
  '0xd79ab5a71fa2099ef30df0e8a81f8c200aac36f1',
  '0x684a1d736e934a45f6f5d00c62ddf7a0b7d2064b',
  '0x750aea5fbcd4986f1390ef4d2b98ecce690061fa',
  '0xb036f902ff0e7847a6f2be49045a10ce55a9aa87',
  '0x7cac16770dd5f2a59859a395a492224f05a846b6',
  '0x4edf4f93a45fad46e5a2450bfcffe8ff53d0a5b2',
  '0xd48723715cc1ca4fb10427a9a298094ec1e08adb',
  '0x96a4392e1045f97c2311ea767a3f00191f68c436',
  '0xfbcaac56ab9c43ecd7733e1da1b20ab7f9a936aa',
  '0x6c60c2a9abb2ca13d5a65fd5aecd362007458d40',
  '0xecf154b61e1d6ede932550efadb6d9c07b36bb4f',
  '0x37a252b46b226da97e4b61f799620acfca76b206',
  '0xa337bce3112a1b11cb1a916e3b7b6a5ccb61a15d',
  '0x7fd612bdadbe878f4553735993fb1715c64496b6',
  '0x20a968112598c7923cd9b300a7197e84cf16662a',
  '0xe3a2c34fa2f59ffa95c4acd1e5663633d45bc3ad',
  '0x591f2e6f02a84510b9299bab16d2dc92b0370993',
  '0xe59605f0dd6051a9074a10d08664a377db220d5d',
  '0x3cbfc1397def0602c2d211c70a1c0c38cedb5448',
  '0x8b0c0a128a99437ca855dd245d5b5eeded97d9c2',
  '0x951d51baefb72319d9fbe941e1615938d89abfe2',
]

export const sortDecExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(a.expiry) - parseInt(b.expiry)
  const byTimestamp = parseInt(a.timestamp) - parseInt(b.timestamp)
  const byStrike = parseInt(a.strikePriceValue) - parseInt(b.strikePriceValue)

  return byExpiry ? byExpiry : byTimestamp ? byTimestamp : byStrike
}

export const useBuyerEthPuts = () => {
  const { networkId, account, library } = useConnection()

  const [loading, setIsLoading] = useState(true)
  const { options, loading: fetchingOptions, error } = useBuyEthOptions('put', putsToShow)

  const oracleContract = useOracle()
  const usdc = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'usdc') : null), [networkId])
  const daiToken = useMemo(() => (isValidNetworkId(networkId) ? getToken(networkId, 'dai') : null), [networkId])
  const ethToUsdcPrice = useEthToUsdPrice()
  const optionsWithDetail = useAsyncMemo(
    async () => {
      if (!options || !usdc || !daiToken || !library || !oracleContract) {
        return []
      }

      const result = await Promise.all(
        options?.map(
          async (option): Promise<InsuredEthOptionsData> => {
            const optionsExchangeContract = new OptionsExchange(library, account, option.optionsExchangeAddress)
            // const ethToTokenPrice = await oracleContract?.getPrice(option?.strike)
            let premiumToPay = new BigNumber(0)
            try {
              // premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, daiToken.address, 1e4)
              premiumToPay = await optionsExchangeContract?.getPremiumToPay(option.address, usdc.address, 1e4)
            } catch {
              // do nothing
            }

            const balance = account
              ? option?.holdersBalances?.find(balance => balance.account.address === account.toLocaleLowerCase())
              : { amount: new BigNumber(0) }

            const $strikePrice = new Big(option?.strikePriceValue || 0)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(`1e${-option?.oTokenExchangeRateExp}`)

            const $ethPrice = ethToUsdcPrice.gt(0)
              ? new Big(`1e${ETH_TOKEN.decimals}`).div(ethToUsdcPrice.toString())
              : new Big(0)

            const oToken = {
              address: option?.address,
              symbol: 'ETH',
              decimals: -option?.oTokenExchangeRateExp,
            }

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            return {
              option,
              type: 'Put',
              balance: {
                amount: new BigNumber(balance?.amount || 0),
                token: oToken,
                precision: 4,
              },
              expiry: format(fromUnixTime(option?.expiry), EXPIRY_DATE_FORMAT, { timeZone }),
              strikePrice: $strikePrice.round(2).toFixed(),
              ethPrice: $ethPrice.round(2).toFixed(),
              protectionCost: new Big(premiumToPay.toString())
                .times(`1e${-option?.oTokenExchangeRateExp}`)
                .times(1e-4)
                .times(`1e-6`)
                .round(4)
                .toFixed(),
              status: $strikePrice.lt($ethPrice) ? 'ok' : 'error',
            }
          },
        ) || [],
      )
      setIsLoading(false)
      return result
    },
    [],
    [networkId, account, daiToken, ethToUsdcPrice, library, usdc, options, fetchingOptions],
  )

  return {
    optionsWithDetail,
    // reload,
    loading: fetchingOptions || loading,
    error,
  }
}
