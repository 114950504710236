import React from 'react'
import styled from 'styled-components'
import PoweredSVG from './img/powered.svg'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 5px 12px 0 0;
`

const ArrowImage = styled.img`
  display: block;
`

export const PoweredByUniswap: React.FC = props => {
  return (
    <Wrapper {...props}>
      <ArrowImage src={PoweredSVG} alt="" />
    </Wrapper>
  )
}
