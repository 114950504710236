import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../../Common/SectionTitle'
import { Available } from './Available'
import { Sold } from './Sold'
import { useSellCompoundSection } from '../../../hooks/useSellCompoundSection'
import { Token } from '../../../utils'
import { Loading } from '../../Common/Loading'

const SubTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 10px;
`

const LoadingWrapper = styled.div`
  position: relative;
  height: 100px;
`

export const CompoundSection: React.FC = () => {
  const data = useSellCompoundSection()
  const { optionsByUnderlying = null, extraData } = data

  const { availableData, insuredData } = useMemo(() => {
    const { tokens = [], optionsByUnderlying = null } = data || {}
    if (!optionsByUnderlying) {
      return { availableData: {}, insuredData: [] }
    }

    const availableData = tokens.reduce((acc: any, token: Token) => {
      const underlyingAddress = token.address.toLowerCase()
      const soldOptions =
        optionsByUnderlying[underlyingAddress] &&
        optionsByUnderlying[underlyingAddress].filter((option: any) => option.vaults.length)
      if (soldOptions && !soldOptions.length) {
        const availableTokens = acc.tokens || []
        const availableOptions = acc.optionsByUnderlying || {}
        return {
          ...acc,
          tokens: [...availableTokens, token],
          optionsByUnderlying: {
            ...availableOptions,
            [underlyingAddress]: optionsByUnderlying[underlyingAddress],
          },
          extraData,
        }
      } else {
        return acc
      }
    }, {})

    const insuredData = tokens
      .reduce((acc: any, token: Token) => {
        const underlyingAddress = token.address.toLowerCase()
        const soldOptions = optionsByUnderlying[underlyingAddress].filter((option: any) => option.vaults.length)
        return [
          ...acc,
          soldOptions.map((option: any) => {
            return { option, token, extraData: extraData?.[underlyingAddress] }
          }),
        ]
      }, [])
      .flat()

    return { insuredData, availableData }
  }, [data, extraData])

  console.log(insuredData)

  return insuredData.length > 0 ? (
    <>
      <SectionTitle
        protocol="compound"
        title="Sell Insurance on Compound"
        subtitle="Earn premiums on ETH by providing insurance to others on their Compound deposits"
      />
      {!optionsByUnderlying && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {!!insuredData.length && (
        <>
          <SubTitle>Insurance Sold</SubTitle>
          <Sold data={{ ...data, insuredData }} />
        </>
      )}
      {!!Object.keys(availableData).length && (
        <>
          <SubTitle>Available</SubTitle>
          <Available data={{ ...data, availableData }} />
        </>
      )}
    </>
  ) : (
    <></>
  )
}
