import React, { HTMLAttributes, useState, useCallback, createRef, useEffect } from 'react'
import styled from 'styled-components'
import { DropdownIcon } from './img/DropdownIcon'
import { TokenIcon } from '../TokenIcon'
import { TokenIconData } from '../TokenIcon/data'

const Wrapper = styled.div<{ disabled: boolean }>`
  background: #fff;
  border-radius: 500px;
  border: 1px solid ${props => props.theme.borders.borderColor};
  color: ${props => props.theme.colors.darkGray};
  min-height: 26px;
  min-width: 130px;
  outline: none;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  position: relative;
  user-select: none;

  &:focus-within {
    .dropdown {
      display: block;
    }
  }
`

const CurrentItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  height: 100%;
  justify-content: space-between;
  line-height: 1.2;
  padding: 0 9px;
`

const Dropdown = styled.div`
  background: #fff;
  border-radius: 13px;
  border: 1px solid ${props => props.theme.borders.borderColor};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  display: none;
  min-width: 100%;
  position: absolute;
  top: calc(100% + 3px);
`

const Item = styled('div')<any>`
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  cursor: pointer;
  padding: 7px 9px;
  width: 100%;

  &:hover {
    background-color: #fcfcfc;
  }

  &:first-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  &:last-child {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-bottom: none;
  }
`

const HelperFocusItem = styled.div`
  height: 0;
  line-height: 0;
  position: absolute;
  width: 0;
  z-index: -1;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean
  selectedToken?: Maybe<string>
  onTokenChange?: (token: string) => void
  includeOToken?: boolean
}

export const TokensDropdown: React.FC<Props> = (props: Props) => {
  const { disabled = false, selectedToken, onTokenChange, includeOToken = false, ...restProps } = props
  const filteredList = ['eth', 'dai', 'usdc']
  const tokensList = Object.keys(TokenIconData)
    .filter(k => includeOToken || k !== 'otoken')
    .filter(token => filteredList.includes(token))
  const itemRef = createRef<HTMLDivElement>()
  const [currentToken, setCurrentToken] = useState(selectedToken || tokensList[0])

  useEffect(() => {
    setCurrentToken(token => selectedToken || token)
  }, [selectedToken])

  const selectItem = useCallback(
    (e: any) => {
      if (disabled) return
      const selected = tokensList?.[e?.currentTarget?.getAttribute?.('data-index')]

      setCurrentToken(token => {
        if (token !== selected) {
          onTokenChange?.call(null, selected)
        }
        return selected
      })

      // close dropdown
      if (itemRef.current) {
        itemRef.current.focus()
      }
    },
    [itemRef, disabled, tokensList, onTokenChange],
  )

  return (
    <>
      <HelperFocusItem tabIndex={-1} ref={itemRef} />
      <Wrapper tabIndex={-1} disabled={disabled} {...restProps}>
        <CurrentItem>
          <TokenIcon size="15" token={currentToken} textStyle={{ fontSize: '9px', fontWeight: '500' }} />
          {disabled ? null : <DropdownIcon />}
        </CurrentItem>
        <Dropdown className="dropdown">
          {tokensList.map((item, index) => {
            return (
              <Item onClick={selectItem} data-index={index} key={index}>
                <TokenIcon size="15" token={item} textStyle={{ fontSize: '9px', fontWeight: '500' }} />
              </Item>
            )
          })}
        </Dropdown>
      </Wrapper>
    </>
  )
}
