import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 20px;
  position: relative;
`

const Label = styled.div<{ color?: string; alignLabel: string; progress: number }>`
  display: inline-block;
  color: ${props => (props.color && props.theme.colors[props.color]) || props.theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 5px;

  ${props => props.alignLabel !== 'float' && `text-align: ${props.alignLabel};`}
  ${props => props.alignLabel === 'float' && (props.progress <= 85 ? 'text-align: left;' : 'text-align: right;')}
  ${props =>
    props.alignLabel === 'float' &&
    props.progress > 15 &&
    props.progress < 85 &&
    `
  position: absolute;
  left: ${props.progress}%;
  transform: translateX(-50%)
  `}
`

const Bar = styled.div`
  background-color: #f2f2f2;
  border-radius: 150px;
  height: 11px;
  overflow: hidden;
  position: relative;
`

const Progress = styled.div<{ color?: string; progress?: number }>`
  height: 11px;
  width: ${props => props.progress || 0}%;
  background-color: ${props => (props.color && props.theme.colors[props.color]) || props.theme.colors.primary};
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.25s ease-out;
  border-radius: 5px;
`

const ProgressValues = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0;
`

const ProgressValue = styled.span`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
`

export interface ProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  progress?: number | string
  lowerBound?: number
  higherBound?: number
  markers?: string[]
  markerCount?: number
  color?: string
  label?: React.ReactNode
  alignLabel?: 'left' | 'right' | 'start' | 'end' | 'float'
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  lowerBound = 0,
  higherBound = 100,
  markerCount = 6,
  markers,
  color,
  label,
  alignLabel = 'start',
  ...restProps
}) => {
  const length = higherBound - lowerBound

  const realMarkers = markers?.length
    ? markers
    : Array.from(Array(markerCount).keys())
        .map((marker: number) => Math.round(lowerBound + marker * (length / (markerCount - 1))))
        .map((marker: number) => `${marker}%`)

  const progressPercent = Number(progress) < 0 ? 0 : (100 * (Number(progress) - lowerBound)) / length

  return (
    <Wrapper {...restProps}>
      <LabelWrapper>
        <Label alignLabel={alignLabel} progress={progressPercent} color={color}>
          {label}
        </Label>
      </LabelWrapper>
      <Bar>
        <Progress progress={progressPercent} color={color} />
      </Bar>
      <ProgressValues>
        {realMarkers.map(marker => (
          <ProgressValue key={marker}>{marker}</ProgressValue>
        ))}
      </ProgressValues>
    </Wrapper>
  )
}
