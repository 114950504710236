import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'components/Common/Button'
import { InnerContainer } from 'components/Common/InnerContainer'
import { ThemeColorTypes } from 'utils'
import { useHistory } from 'react-router'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  background-repeat: repeat-x;
  padding: 55px;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.mainBodyBackground};
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 15px;
  text-align: center;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 48px;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.mainBodyBackground};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 50px;
  text-align: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  height: 64px;
  min-width: 150px;

  &:first-child {
    margin-right: 24px;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    min-width: 208px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

export const Sell: React.FC = props => {
  const { ...restProps } = props
  const history = useHistory()

  const goToSell = useCallback(() => history.push(`/sell`), [history])

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Title>Sell protection</Title>
        <Text>Earn meaningful premiums by providing protection.</Text>
        <ButtonsWrapper>
          <ButtonStyled onClick={goToSell} buttonStyle={ThemeColorTypes.darkGray}>
            Get started
          </ButtonStyled>
        </ButtonsWrapper>
      </InnerContainerStyled>
    </Wrapper>
  )
}
