import { OracleService } from '../services'
import { NetworkId, getToken, isValidNetworkId } from './networks'
import { getTokenPriceCoingecko } from 'utils'
import { Big } from 'big.js'

export const getTokenPrice = async (
  networkId: NetworkId | -1,
  oracleContract: Maybe<OracleService>,
  fromTokenAddress?: string,
  toTokenAddress = 'usdc',
) => {
  if (!isValidNetworkId(networkId) || !oracleContract || !fromTokenAddress) {
    return '0'
  }
  let ethToToTokenPrice

  if (toTokenAddress === 'usdc') {
    // ethToToTokenPrice = await oracleContract.getPrice(getToken(networkId, 'usdc')?.address)
    ethToToTokenPrice = await getTokenPriceCoingecko(getToken(networkId, 'usdc')?.address)
  } else {
    // ethToToTokenPrice = await oracleContract.getPrice(toTokenAddress)
    ethToToTokenPrice = await getTokenPriceCoingecko(toTokenAddress)
  }

  let ethToFromTokenPrice
  if (fromTokenAddress === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
    // ethToFromTokenPrice = await oracleContract.getPrice('0x0000000000000000000000000000000000000000')
    ethToFromTokenPrice = await getTokenPriceCoingecko('0x0000000000000000000000000000000000000000')
  } else {
    // ethToFromTokenPrice = await oracleContract.getPrice(fromTokenAddress)
    ethToFromTokenPrice = await getTokenPriceCoingecko(fromTokenAddress)
  }

  return new Big(ethToToTokenPrice.toString()).div(ethToFromTokenPrice.toString()).toString()
}
