import { Contract, ethers, Wallet } from 'ethers'
import { BigNumberish, BigNumber } from 'ethers/utils'

const oTokenABI = [
  'function balanceOf(address marketMaker) external view returns (uint256)',
  'function createETHCollateralOption(uint256 amtToCreate, address receiver) external payable',
  'function addETHCollateralOption(uint256 amtToCreate, address receiver) public payable',
  'function createAndSellETHCollateralOption(uint256 amtToCreate, address receiver) external payable',
  'function addAndSellETHCollateralOption(uint256 amtToCreate, address receiver) public payable',
  'function addETHCollateral(address vaultOwner) public payable',
  'function exercise(uint256 oTokensToExercise, address[] vaultsToExerciseFrom) public payable',
  'function createAndSellERC20CollateralOption(uint256 amtToCreate, uint256 amtCollateral, address receiver) external',
  'function addAndSellERC20CollateralOption(uint256 amtToCreate, uint256 amtCollateral, address receiver) external',
  'function createERC20CollateralOption(uint256 amtToCreate, uint256 amtCollateral, address receiver) external',
  'function addERC20CollateralOption(uint256 amtToCreate, uint256 amtCollateral, address receiver) external',
  'function allowance(address owner, address spender) public view returns (uint256)',
  'function hasVault(address owner) public view returns (bool)',
  'function removeUnderlying() public',
  'function approve(address spender, uint256 amount) external returns (bool)',
  'function burnOTokens(uint256 amtToBurn) public',
  'function removeCollateral(uint256 amtToRemove) public',
  'function redeemVaultBalance() public',
  'function hasExpired() public view returns (bool)',
]

class oToken {
  contractAddress: string
  provider: any
  contract: Contract

  constructor(provider: any, signerAddress: Maybe<string>, contractAddress: string) {
    this.contractAddress = contractAddress
    this.provider = provider
    if (signerAddress) {
      const signer: Wallet = provider.getSigner()
      this.contract = new ethers.Contract(contractAddress, oTokenABI, provider).connect(signer)
    } else {
      this.contract = new ethers.Contract(contractAddress, oTokenABI, provider)
    }
  }

  createETHCollateralOption = (amtToCreate: string, receiver: string, value: BigNumberish): Promise<any> => {
    return this.contract.createETHCollateralOption(amtToCreate, receiver, { value })
  }

  addETHCollateralOption = (amtToCreate: string, receiver: string, value: BigNumberish): Promise<any> => {
    return this.contract.addETHCollateralOption(amtToCreate, receiver, { value })
  }

  createAndSellETHCollateralOption = (amtToCreate: string, receiver: string, value: BigNumberish): Promise<any> => {
    return this.contract.createAndSellETHCollateralOption(amtToCreate, receiver, { value })
  }

  addAndSellETHCollateralOption = (amtToCreate: string, receiver: string, value: BigNumberish): Promise<any> => {
    return this.contract.addAndSellETHCollateralOption(amtToCreate, receiver, { value })
  }

  addETHCollateral = (receiver: string, value: BigNumberish): Promise<any> => {
    return this.contract.addETHCollateral(receiver, { value })
  }

  removeUnderlying = (): Promise<any> => {
    return this.contract.removeUnderlying()
  }

  redeemVaultBalance = (): Promise<any> => {
    return this.contract.redeemVaultBalance()
  }

  exercise = (oTokensToExercise: string, addresses: string[], overrides?: any) => {
    const defaultOverrides = {
      // gasPrice: 70000000000
    }
    return this.contract.exercise(
      oTokensToExercise,
      addresses,
      // overrides,
      overrides ? { ...defaultOverrides, ...overrides } : defaultOverrides,
    )
  }

  hasVault = (receiver: string): Promise<any> => {
    return this.contract.hasVault(receiver)
  }

  createAndSellERC20CollateralOption = (
    amtToCreate: string,
    amtCollateral: BigNumberish,
    receiver: string,
  ): Promise<any> => {
    return this.contract.createAndSellERC20CollateralOption(amtToCreate, amtCollateral, receiver)
  }

  addAndSellERC20CollateralOption = (
    amtToCreate: string,
    amtCollateral: BigNumberish,
    receiver: string,
  ): Promise<any> => {
    return this.contract.addAndSellERC20CollateralOption(amtToCreate, amtCollateral, receiver)
  }

  createERC20CollateralOption = (amtToCreate: string, amtCollateral: BigNumberish, receiver: string): Promise<any> => {
    return this.contract.createERC20CollateralOption(amtToCreate, amtCollateral, receiver)
  }

  addERC20CollateralOption = (amtToCreate: string, amtCollateral: BigNumberish, receiver: string): Promise<any> => {
    return this.contract.addERC20CollateralOption(amtToCreate, amtCollateral, receiver)
  }

  burnOTokens = (amtToBurn: BigNumberish): Promise<any> => {
    return this.contract.burnOTokens(amtToBurn)
  }

  removeCollateral = (amtToRemove: BigNumberish): Promise<any> => {
    return this.contract.removeCollateral(amtToRemove)
  }

  hasExpired = (): Promise<boolean> => {
    return this.contract.hasExpired()
  }

  // ERC20

  allowance = (owner: string, spender: string): Promise<any> => {
    return this.contract.allowance(owner, spender)
  }

  getBalanceOf = async (address: string): Promise<any> => {
    return this.contract.balanceOf(address)
  }

  /**
   * Approve `spender` to transfer `amount` tokens on behalf of the connected user.
   */
  approve = async (spender: string, amount: BigNumber): Promise<any> => {
    const transactionObject = await this.contract.approve(spender, amount, {
      value: '0x0',
    })
    await this.provider.waitForTransaction(transactionObject.hash)
  }

  approveUnlimited = async (spender: string): Promise<any> => {
    const transactionObject = await this.contract.approve(spender, ethers.constants.MaxUint256, {
      value: '0x0',
    })
    await this.provider.waitForTransaction(transactionObject.hash)
  }
}

export default oToken
