import React from 'react'
import styled from 'styled-components'
import { InnerContainer } from 'components/Common/InnerContainer'

const Wrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 80px;
  }
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  color: ${props => props.theme.colors.darkGray};
  margin: 0 0 13px 0;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin: 0 0 48px 0;
  }
`

const StepsWrapper = styled.div`
  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: flex;
    justify-content: space-between;
  }
`

const Step = styled.div`
  display: flex;
  margin-top: 13px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin: 0;
  }
`

const StepBadge = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(79, 194, 160, 0.2);
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 41px;
`

const StepDescription = styled.div`
  flex: 1;
  margin-left: 20px;
`

const DescriptionTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 13px 0;
`

const DesciptionText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.textColorMedium};
`

const Card = styled.div`
  background-color: ${props => props.theme.cards.backgroundColor};
  border-radius: ${props => props.theme.cards.borderRadius};
  border: ${props => props.theme.cards.border};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 41px;
`

export const GetProtectedSteps: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Card>
          <Title>Get protected in 3 simple steps:</Title>
          <StepsWrapper>
            <Step>
              <StepBadge>1</StepBadge>
              <StepDescription>
                <DescriptionTitle>Choose Protection</DescriptionTitle>
                <DesciptionText>Hedge ETH risk or protect Compound deposits</DesciptionText>
              </StepDescription>
            </Step>
            <Step>
              <StepBadge>2</StepBadge>
              <StepDescription>
                <DescriptionTitle>Select coverage amount</DescriptionTitle>
                <DesciptionText>Protect all or a portion of your ETH holdings or Compound deposits</DesciptionText>
              </StepDescription>
            </Step>
            <Step>
              <StepBadge>3</StepBadge>
              <StepDescription>
                <DescriptionTitle>Instantaneous redemption</DescriptionTitle>
                <DesciptionText>Sell or exercise oTokens at any time to redeem your coverage amount</DesciptionText>
              </StepDescription>
            </Step>
          </StepsWrapper>
        </Card>
      </InnerContainerStyled>
    </Wrapper>
  )
}
