import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useConnection } from '../components/web3'
import { selling_history } from '../types/generatedGQL'

const HISTORY = loader('../queries/selling_history.graphql')

const premiumEarnedActions = (data: selling_history) => {
  let premiums = 0
  for (let i = 0; i < data.sellOTokensActions.length; i++) {
    premiums = premiums + Number(data.sellOTokensActions[i].payoutTokensReceived)
  }
  return premiums / 10 ** 18
}

export const useSellerPremiumsEarned = (oTokenAddress: string): [number] => {
  const { account } = useConnection()

  const { data } = useQuery(HISTORY, {
    variables: {
      account,
      oToken: oTokenAddress,
    },
    skip: !account,
  })

  const premiumEarned = data ? premiumEarnedActions(data) : 0

  return [premiumEarned]
}
