import React from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { Button } from '../../Common/Button'
import Link from '../../Common/Link'
import { useHistory } from 'react-router'
import { ThemeColorTypes } from '../../../utils'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
`

const Title = styled.h1`
  flex-grow: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 15px;
  padding: 0 10px;
  text-align: center;
`

const TitleInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ButtonStyled = styled(Button)`
  margin-top: 15px;
`

const Text = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  margin: 15px 0;
  text-align: start;
`
interface ModalProps {
  isOpen: boolean
  url?: string
  onRequestClose: ((e?: any) => void) | undefined
  themeColor?: ThemeColorTypes
}

export const FailedTransactionModal: React.FC<ModalProps> = props => {
  const { url, onRequestClose, themeColor = ThemeColorTypes.primary } = props
  const history = useHistory()

  const goToDashboard = () => {
    onRequestClose?.()
    history.push('/buy', true)
  }

  return (
    <Modal style={{ content: { width: '410px' } }} {...props}>
      <TitleWrapper>
        <TitleInner>
          <Title>Transaction Failed</Title>
        </TitleInner>
      </TitleWrapper>
      <Text>
        There was an issue with your transaction. Please try again.
        <Link target="_blank" href={url || '#'} as="a" themeColor={themeColor}>
          &nbsp;Learn more.
        </Link>
      </Text>
      <ButtonStyled onClick={goToDashboard} buttonStyle={themeColor}>
        Back to dashboard
      </ButtonStyled>
    </Modal>
  )
}
