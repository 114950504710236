import { useState, useEffect } from 'react'
import { useConnection } from '../components/web3'
import { getClient } from '../apolloClientConfig'

export const useApolloClient = () => {
  const { networkId } = useConnection()
  const [client, setClient] = useState(getClient(networkId))

  useEffect(() => setClient(getClient(networkId)), [networkId])

  return client
}
