import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../../Common/SectionTitle'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import { Table, Column } from 'react-virtualized/dist/es/Table'
import {
  expiryColumnDefaults,
  columnDefaults,
  tableDefaults,
  tokenBalanceCellRenderer,
  protectionCostPerWbtcCellRenderer,
  chevronColumnDefaults,
  strikePriceCellRenderer,
  ethPriceCellRenderer,
  okChevronCellRenderer,
} from '../../../utils'
import { TableOverflow } from '../../Common/TableOverflow'
import { Loading } from '../../Common/Loading'
import { Index } from 'react-virtualized'
import { TextWithTooltip } from '../../Common/TextWithTooltip'
import { useBuyerTokenPuts } from '../../../hooks'
import { useConnection } from '../../web3'
import { useHistory } from 'react-router'
const Wrapper = styled.div`
  margin-bottom: 30px;
`
const LoadingWrapper = styled.div`
  position: relative;
  height: 100px;
`

const TableWrapper = styled(TableOverflow)`
  min-height: ${props => props.maxHeight}px;
`

export const WbtcInsured: React.FC = props => {
  const { ...restProps } = props
  const history = useHistory()
  const { isConnected } = useConnection()
  const { optionsWithDetail, loading } = useBuyerTokenPuts('wbtc')

  const goToAsset = useCallback(
    (props: any) => {
      const { rowData } = props
      if (isConnected) {
        history.push(`/buy/wbtcoption/${rowData.option.address}`)
      }
    },
    [isConnected, history],
  )

  const rowGetterPut = useCallback(
    (info: Index): any => {
      return optionsWithDetail?.[info.index]
    },
    [optionsWithDetail],
  )

  return optionsWithDetail.length > 0 ? (
    <Wrapper {...restProps}>
      <SectionTitle
        protocol="wbtc"
        title="Buy Protective WBTC Put Option"
        subtitle="Hedge yourself against WBTC decreasing in value."
      />
      {loading && optionsWithDetail.length === 0 ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <TableWrapper maxHeight={((optionsWithDetail.length + 2) * 66).toString()}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <Table
                {...tableDefaults}
                height={height}
                onRowClick={goToAsset}
                rowCount={optionsWithDetail.length}
                rowGetter={rowGetterPut}
              >
                <Column
                  {...columnDefaults}
                  cellRenderer={tokenBalanceCellRenderer}
                  dataKey="balance"
                  label="Protected WBTC"
                />
                <Column {...expiryColumnDefaults} label="Expiry" dataKey="expiry" />
                <Column
                  {...columnDefaults}
                  cellRenderer={strikePriceCellRenderer}
                  dataKey="strikePrice"
                  label={
                    <TextWithTooltip
                      id="strike-price-put"
                      text="Strike Price"
                      tooltipText="If WBTC falls below the strike price, you can redeem your WBTC for the value of the strike price. <a href='https://opyn.gitbook.io/opynv1/faq' target='_blank'>Learn more.</a>"
                      place="right"
                    />
                  }
                />
                <Column
                  {...columnDefaults}
                  cellRenderer={ethPriceCellRenderer}
                  dataKey="currentPrice"
                  label="Current WBTC Price"
                />
                <Column
                  {...columnDefaults}
                  className="ok"
                  dataKey="protectionCost"
                  headerClassName="ok"
                  label="Protection Cost"
                  cellRenderer={protectionCostPerWbtcCellRenderer}
                />
                <Column
                  {...chevronColumnDefaults}
                  cellRenderer={okChevronCellRenderer}
                  className="ok"
                  dataKey="status"
                  headerClassName="ok"
                  label=""
                />
              </Table>
            )}
          </AutoSizer>
        </TableWrapper>
      )}
    </Wrapper>
  ) : (
    <></>
  )
}
