import React, { HTMLAttributes, useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Card } from 'components/Common/Card'
import { Button } from 'components/Common/Button'
import { Loading } from 'components/Common/Loading'
import { BigNumber } from 'ethers/utils'
import TokenBalance, { TokenBalanceProps } from 'components/Common/TokenBalance'
import {
  ThemeColorTypes,
  ETH_TOKEN,
  etherscanTx,
  ThemeColorTypesInverted,
  isValidNetworkId,
  getToken,
  BigFloat,
  getTokenFromAddress,
  isBigFloat,
} from 'utils'
import { ExerciseModal } from 'components/Modals/ExerciseModal'
import { useExerciseData, useConnection, useOptionsExchange } from 'hooks'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { FailedTransactionModal } from 'components/Modals/FailedTransactionModal'
import { ButtonsContainer } from 'components/Common/ButtonContainer'
import { Big } from 'big.js'
import { SellOETHEarlyModal } from 'components/Modals/SellOETHEarlyModal'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'

const Text = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const BoldText = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Link = styled.a<{ themeColor: ThemeColorTypes }>`
  color: ${props => props.theme.colors[ThemeColorTypes[props.themeColor]]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const PayoffWraper = styled.div`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  line-height: 20px;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  padding-bottom: 12px;

  span {
    justify-content: left;
  }
`

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
`

const PositivePayoff = styled.span`
  color: ${props => props.theme.colors.primary};
`
const NegativePayoff = styled.span`
  color: ${props => props.theme.colors.error};
`

const Amount = (props: TokenBalanceProps) => {
  const { value, ...rest } = props
  let isNegative = false
  let newValue
  if (isBigFloat(value)) {
    const amount = new Big(value.value.toString())
    isNegative = amount.lt(0)
    newValue = {
      value: new BigNumber(isNegative ? amount.times(-1).toString() : amount.toString()),
      decimals: value.decimals,
    }
  } else {
    const amount = Number(value)
    isNegative = amount < 0
    newValue = isNegative ? amount * -1 : amount
  }

  return isNegative ? (
    <NegativePayoff>
      - <TokenBalance value={newValue} {...rest} />
    </NegativePayoff>
  ) : (
    <PositivePayoff>
      <TokenBalance value={newValue} {...rest} />
    </PositivePayoff>
  )
}

interface ExerciseCardProps extends HTMLAttributes<HTMLDivElement> {
  isPut?: boolean
  strikePrice?: Big // 0.004 for call
  displayStrikePrice?: string // 250 for call
  loading: boolean
  optionsData?: any
  rawHistory?: any[]
  reload?(): void
}

export const ExerciseCard: React.FC<ExerciseCardProps> = (props: ExerciseCardProps) => {
  const { loading, strikePrice, displayStrikePrice, reload, optionsData, rawHistory, isPut, ...restProps } = props
  const { networkId } = useConnection()
  const usdToken = useMemo(() => isValidNetworkId(networkId) && getToken(networkId, 'usdc'), [networkId])

  const { option } = optionsData

  const underlyingToken = useMemo(() => {
    return option && isValidNetworkId(networkId) && getTokenFromAddress(networkId, option.underlying)
  }, [networkId, option])

  const oTokenAddress = optionsData ? (optionsData.option ? optionsData.option.address : null) : null
  const insured = optionsData && optionsData?.insured ? optionsData?.insured : new BigNumber(0)
  const underlyingPrice =
    optionsData && optionsData?.ethPrice
      ? optionsData?.ethPrice
      : optionsData?.currentPrice
      ? optionsData?.currentPrice
      : 0
  const { exerciseData, refetch: refetchExerciseData } = useExerciseData(
    underlyingToken ? underlyingToken.address : ETH_TOKEN.address,
    oTokenAddress,
  )

  const optionsExchange = useOptionsExchange(option?.optionsExchangeAddress)

  const [isExerciseModalOpen, setExerciseModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalState] = useState(false)
  const [transactionFailed, setTransactionFailed] = useState(false)
  const [recentTransactionHash, setRecentTransactionHash] = useState<string>('')
  const [isCancelProtectionModalOpen, setCancelProtectionModalState] = useState(false)
  const [confirmModalTitle, setConfirmModalTitle] = useState('Confirm Exercise')
  const [sellEarlyPayoff, setSellEarlyPayoff] = useState<BigFloat>({ value: '0', decimals: 1 })
  const [exercisePayoff, setExercisePayoff] = useState(0)

  const openExerciseModal = useCallback(() => setExerciseModalOpen(true), [])
  const closeExerciseModal = useCallback(() => setExerciseModalOpen(false), [])
  const closeCancelProtectionModal = useCallback(() => setCancelProtectionModalState(false), [])
  const openCancelProtectionModal = useCallback(() => setCancelProtectionModalState(true), [])

  const closeConfirmModal = useCallback(() => {
    setConfirmModalState(false)
    reload?.()
  }, [reload])
  const onTransactionFail = useCallback(() => setTransactionFailed(true), [])
  const openConfirmModal = useCallback(
    (tx, title = 'Confirm Exercise') => {
      closeExerciseModal()
      closeCancelProtectionModal()
      setConfirmModalTitle(title)
      setConfirmModalState(true)
      setRecentTransactionHash(tx.hash)
      tx.wait().then(closeConfirmModal)
    },
    [closeConfirmModal, closeExerciseModal, closeCancelProtectionModal],
  )

  const strikeSymbol = useMemo(() => {
    if (!option || !option.strike) return 'USDC'
    return getTokenFromAddress(networkId, option.strike).symbol
  }, [option, networkId])

  const underlyingSymbol = useMemo(() => {
    if (!option || !option.underlying) return 'ETH'
    return getTokenFromAddress(networkId, option.underlying).symbol
  }, [option, networkId])

  const oTokenSymbol = isPut ? `o${underlyingSymbol}p` : `o${strikeSymbol}c`

  useEffect(() => {
    if (option && insured.gt(0) && optionsExchange && usdToken) {
      optionsExchange.getPremiumReceived(option.address, usdToken.address, insured.toString()).then(premiun => {
        setSellEarlyPayoff({ value: premiun, decimals: usdToken.decimals })
      })
    }
  }, [option, insured, optionsExchange, usdToken])

  useEffect(() => {
    if (
      rawHistory?.length &&
      isValidNetworkId(networkId) &&
      insured.gt(0) &&
      underlyingPrice &&
      strikePrice &&
      option
    ) {
      const $premiumPaid = rawHistory.reduce((acc, action) => {
        if (action.type === 'BuyOTokensAction') {
          const { paymentTokenAddress, premiumPaid, paymentTokenPrice } = action
          let { usdcPrice } = action
          const paymentToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, paymentTokenAddress) : null
          if (usdcPrice === '0') usdcPrice = (1e18).toString()
          const $amount = new Big(premiumPaid)
            .times(new Big(paymentTokenPrice).div(usdcPrice))
            .div(`1e${paymentToken ? paymentToken.decimals : 1}`)

          return acc.plus($amount)
        }
        if (action.type === 'SellOTokensAction') {
          const { payoutTokenAddress, payoutTokensReceived, usdcPrice, payoutTokenPrice } = action
          const payoutToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, payoutTokenAddress) : null
          const $amount = new Big(payoutTokensReceived)
            .times(new Big(payoutTokenPrice).div(usdcPrice))
            .div(`1e${payoutToken ? payoutToken.decimals : 1}`)

          return acc.minus($amount)
        }
        if (action.type === 'ExerciseAction') {
          const {
            amtCollateralToPay,
            optionsContract: { collateral },
            collateralPrice,
            usdcPrice,
          } = action
          const collateralToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, collateral) : null
          const $amount = new Big(amtCollateralToPay)
            .div(usdcPrice)
            .times(collateralPrice)
            .times(`1e-${collateralToken?.decimals || 0}`)

          return acc.minus($amount)
        }
        return acc
      }, new Big(0))

      const oTokenAmount = new Big(insured.toString()).times(`1e-${-option?.oTokenExchangeRateExp || 0}`)

      const $insured = isPut ? oTokenAmount.times(underlyingPrice || 0) : oTokenAmount

      const $exerciseRefund = isPut
        ? oTokenAmount.times(strikePrice.toString())
        : oTokenAmount.times(strikePrice).times(underlyingPrice)

      // FIX THIS!
      setExercisePayoff(
        Number(
          $exerciseRefund
            .minus($insured)
            .minus($premiumPaid)
            .round(2)
            .toFixed(),
        ),
      )
    }
  }, [option, rawHistory, networkId, insured, underlyingPrice, strikePrice, isPut])

  const buttons = [
    <Button
      key={'sell-early'}
      onClick={openCancelProtectionModal}
      buttonStyle={ThemeColorTypes.primary}
      disabled={insured.eq(0)}
    >
      Sell Early
    </Button>,
    <Button
      key={'exercise'}
      buttonStyle={ThemeColorTypesInverted.primaryInverted}
      onClick={openExerciseModal}
      disabled={insured.eq(0)}
    >
      Exercise
    </Button>,
  ]

  return (
    <>
      <Card {...restProps} title="Sell or Exercise">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PayoffWraper>
              <TextWithTooltip
                text={<Title>Current Payoffs</Title>}
                tooltipText={
                  isPut
                    ? `If ${underlyingSymbol} loses value, you can sell or exercise your ${oTokenSymbol} to redeem value. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>`
                    : `If ${strikeSymbol} increases in value, you can sell or exercise your ${oTokenSymbol} to redeem value. <a href="https://opyn.gitbook.io/opynv1/faq" target="_blank">Learn more.</a>`
                }
                id="current-payoffs"
                place="right"
              />
              <Row>
                <div>Sell early</div>
                <Amount value={sellEarlyPayoff} token={'$'} />
              </Row>
              <Row>
                <div>Exercise</div>
                <Amount value={exercisePayoff} token={'$'} />
              </Row>
            </PayoffWraper>
            <Text>
              {isPut
                ? `If ${underlyingSymbol} is below $${displayStrikePrice}, exercise by sending your ${underlyingSymbol} and ${oTokenSymbol} to the protocol to
              receive ${displayStrikePrice} ${strikeSymbol} per ${underlyingSymbol} up until expiry. You must click the button below to exercise. `
                : `If ${strikeSymbol} is above $${displayStrikePrice}, exercise by sending your ${underlyingSymbol} and ${oTokenSymbol} to the protocol to
              buy 1 ${strikeSymbol} at $${displayStrikePrice} up until expiry. You must click the button below to exercise. `}
              <Link themeColor={ThemeColorTypes.primary} href="https://opyn.gitbook.io/opynv1/faq" target="_blank">
                Learn more about exercising.
              </Link>
            </Text>
            <BoldText>
              WARNING: you must exercise before the expiry date if you want to exercise. You cannot exercise after
              expiry.
            </BoldText>
            {underlyingSymbol === 'WETH' ? (
              <Text>
                {' '}
                You must convert your ETH to WETH to exercise.{' '}
                <Link themeColor={ThemeColorTypes.primary} href="https://1inch.exchange/#/ETH/WETH" target="_blank">
                  {' '}
                  You can wrap ETH here.{' '}
                </Link>
              </Text>
            ) : null}
            <ButtonsContainer buttonsAmount={buttons.length}>{buttons}</ButtonsContainer>
          </>
        )}
      </Card>
      {isExerciseModalOpen && (
        <ExerciseModal
          isOpen={isExerciseModalOpen}
          onConfirm={openConfirmModal}
          onRequestClose={closeExerciseModal}
          onTransactionFail={onTransactionFail}
          title={`Exercise`}
          exerciseData={exerciseData}
          refetch={refetchExerciseData}
          currentPrice={underlyingPrice}
        />
      )}
      {isCancelProtectionModalOpen && (
        <SellOETHEarlyModal
          isOpen={true}
          onConfirm={openConfirmModal}
          onTransactionFail={onTransactionFail}
          onRequestClose={closeCancelProtectionModal}
          title="Sell Early"
          option={option}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title={confirmModalTitle}
          isOpen={isConfirmModalOpen}
          onRequestClose={closeConfirmModal}
          url={etherscanTx({
            networkId,
            hash: recentTransactionHash,
          })}
        />
      )}
      {transactionFailed && (
        <FailedTransactionModal
          isOpen={transactionFailed}
          onRequestClose={() => setTransactionFailed(false)}
          themeColor={ThemeColorTypes.secondary}
        />
      )}
    </>
  )
}
