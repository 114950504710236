import { INFURA_PROJECT_ID } from '../common/constants'
import { entries, isNotNull } from '../utils/type-utils'

import { Token, CompoundTokens, OTokens } from './types'

export type NetworkId = 1 | 4

export enum NetworkIds {
  MAINNET = 1,
  RINKEBY = 4,
}

export const networkIds = {
  MAINNET: 1,
  RINKEBY: 4,
}

export const networkNames = {
  1: 'mainnet',
  4: 'rinkeby',
}

interface Network {
  label: string
  url: string
  contracts: {}
}

type KnownContracts = keyof Network['contracts']

interface KnownTokenData {
  symbol: string
  decimals: number
  addresses: {
    [K in NetworkId]?: string
  }
  cToken: boolean
  order: number
  exchangeRateDecimals?: number
}

const networks: { [K in NetworkId]: Network } = {
  1: {
    label: 'Mainnet',
    url: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    contracts: {},
  },
  4: {
    label: 'Rinkeby',
    url: `https://rinkeby.infura.io/v3/${INFURA_PROJECT_ID}`,
    contracts: {},
  },
}

export const getNetworkName = (networkId: NetworkId | -1) =>
  networkId !== -1 && networkNames[networkId] ? networkNames[networkId] : process.env.REACT_APP_GRAPH_DEFAULT_NETWORK

export const supportedNetworkIds = Object.keys(networks).map(Number) as NetworkId[]

export const supportedNetworkURLs = entries(networks).reduce<{
  [networkId: number]: string
}>(
  (acc, [networkId, network]) => ({
    ...acc,
    [networkId]: network.url,
  }),
  {},
)

export const getOracleAddressByNetwork = (networkId: NetworkId | -1) => {
  const envName = `REACT_APP_ORACLE_${(getNetworkName(networkId) as string).toUpperCase()}`
  return process.env[envName] as string
}

export const getUniswapFactoryAddressByNetwork = (networkId: NetworkId | -1) => {
  const envName = `REACT_APP_UNISWAP_FACTORY_${(getNetworkName(networkId) as string).toUpperCase()}`
  return process.env[envName] as string
}

export const infuraNetworkURL = networks[1].url

export const ETH_TOKEN = {
  address: '0x0000000000000000000000000000000000000000',
  symbol: 'ETH',
  decimals: 18,
}

export const knownTokens: { [name in KnownToken]: KnownTokenData } = {
  cdai: {
    symbol: 'CDAI',
    decimals: 8,
    addresses: {
      [networkIds.MAINNET]: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
      [networkIds.RINKEBY]: '0x6d7f0754ffeb405d23c51ce938289d4835be3b14',
    },
    cToken: true,
    order: 4,
    exchangeRateDecimals: 28,
  },
  dai: {
    symbol: 'DAI',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x6b175474e89094c44da98b954eedeac495271d0f',
      [networkIds.RINKEBY]: '0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea',
    },
    cToken: false,
    order: 1,
  },
  usdc: {
    symbol: 'USDC',
    decimals: 6,
    addresses: {
      [networkIds.MAINNET]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      [networkIds.RINKEBY]: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
    },
    cToken: false,
    order: 2,
  },
  cusdc: {
    symbol: 'CUSDC',
    decimals: 8,
    addresses: {
      [networkIds.MAINNET]: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
      [networkIds.RINKEBY]: '0x5b281a6dda0b271e91ae35de655ad301c976edb1',
    },
    cToken: true,
    order: 3,
    exchangeRateDecimals: 16,
  },
  eth: {
    symbol: 'ETH',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x0000000000000000000000000000000000000000',
      [networkIds.RINKEBY]: '0x0000000000000000000000000000000000000000',
    },
    cToken: false,
    order: 4,
    exchangeRateDecimals: 18,
  },
  weth: {
    symbol: 'WETH',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      [networkIds.RINKEBY]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    },
    cToken: false,
    order: 5,
  },
  comp: {
    symbol: 'COMP',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0xc00e94cb662c3520282e6f5717214004a7f26888',
      [networkIds.RINKEBY]: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    },
    cToken: false,
    order: 6,
  },
  bal: {
    symbol: 'bal',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0xba100000625a3754423978a60c9317c58a424e3d',
      [networkIds.RINKEBY]: '0xba100000625a3754423978a60c9317c58a424e3d',
    },
    cToken: false,
    order: 7,
  },
  yfi: {
    symbol: 'yfi',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      [networkIds.RINKEBY]: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    },
    cToken: false,
    order: 8,
  },
  uni: {
    symbol: 'uni',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      [networkIds.RINKEBY]: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    },
    cToken: false,
    order: 9,
  },
  snx: {
    symbol: 'snx',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
      [networkIds.RINKEBY]: '0x322A3346bf24363f451164d96A5b5cd5A7F4c337',
    },
    cToken: false,
    order: 10,
  },
  wbtc: {
    symbol: 'wbtc',
    decimals: 8,
    addresses: {
      [networkIds.MAINNET]: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      [networkIds.RINKEBY]: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    },
    cToken: false,
    order: 11,
  },
  crv: {
    symbol: 'crv',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0xd533a949740bb3306d119cc777fa900ba034cd52',
      [networkIds.RINKEBY]: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    },
    cToken: false,
    order: 12,
  },
  dpi: {
    symbol: 'dpi',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
      [networkIds.RINKEBY]: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    },
    cToken: false,
    order: 13,
  },
  dydx: {
    symbol: 'dydx',
    decimals: 18,
    addresses: {
      [networkIds.MAINNET]: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
      [networkIds.RINKEBY]: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    },
    cToken: false,
    order: 14,
  },
}

export enum ERC20Tokens {
  dai = 'dai',
  usdc = 'usdc',
  uni = 'uni',
  weth = 'weth',
  comp = 'comp',
  bal = 'bal',
  yfi = 'yfi',
  snx = 'snx',
  dpi = 'dpi',
  crv = 'crv',
  wbtc = 'wbtc',
}

export const erc20Tokens: ERC20Token[] = [
  'dai',
  'usdc',
  'uni',
  'weth',
  'comp',
  'bal',
  'yfi',
  'snx',
  'crv',
  'wbtc',
  'dpi',
  'dydx',
]

export const compoundTokenMap: Record<CompoundTokens, ERC20Token> = {
  cdai: 'dai',
  cusdc: 'usdc',
}

export const OTokenMap: Record<OTokens, KnownToken> = {
  odai: 'dai',
  ocdai: 'cdai',
  ocusdc: 'cusdc',
  oeth: 'eth',
}

export const isValidNetworkId = (networkId: any): networkId is NetworkId => networkId in NetworkIds

export const getContractAddress = (networkId: number, contract: KnownContracts) => {
  if (!isValidNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }
  return networks[networkId].contracts[contract]
}

export const getToken = (networkId: number, tokenId: KnownToken): Token => {
  if (!isValidNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }

  console.log(tokenId)

  const token = knownTokens[tokenId]
  if (!token) {
    throw new Error(`Unsupported token id: '${tokenId}'`)
  }

  const address = token.addresses[networkId]

  if (!address) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }

  return {
    address,
    decimals: token.decimals,
    symbol: token.symbol,
    cToken: token.cToken,
    exchangeRateDecimals: token.exchangeRateDecimals,
  }
}

export const getTokenOrPlaceHolder = (networkId: number, tokenId: KnownToken): Token => {
  if (isValidNetworkId(networkId)) {
    return getToken(networkId, tokenId)
  }

  const token = knownTokens[tokenId]

  return {
    address: '',
    decimals: token.decimals,
    symbol: token.symbol,
    cToken: token.cToken,
  }
}

export const getTokenFromAddress = (networkId: number, address: string, _default: any = 'error'): Token => {
  if (!isValidNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }

  if (!address) {
    throw new Error(`Unsupported address: '${address}'`)
  }

  for (const token of Object.values(knownTokens)) {
    const tokenAddress = token.addresses[networkId]

    // token might not be supported in the current network
    if (!tokenAddress) {
      continue
    }

    if (tokenAddress.toLowerCase() === address.toLowerCase()) {
      return {
        address: tokenAddress,
        decimals: token.decimals,
        symbol: token.symbol,
        cToken: token.cToken,
      }
    }
  }

  if (_default !== 'error') {
    return _default
  }
  throw new Error(`Couldn't find token with address '${address}' in network '${networkId}'`)
}

export const getContractAddressName = (networkId: number) => {
  const networkName = Object.keys(networkIds).find(key => (networkIds as any)[key] === networkId)
  const networkNameCase = networkName && networkName.substr(0, 1).toUpperCase() + networkName.substr(1).toLowerCase()
  return networkNameCase
}

export const getDefaultToken = (networkId: number) => {
  if (!isValidNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }

  return getToken(networkId, 'dai')
}

export const getTokensWithoutAddresses = (): Token[] => {
  return Object.values(knownTokens)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(token => ({
      symbol: token.symbol,
      decimals: token.decimals,
      cToken: token.cToken,
      address: '',
    }))
}
export const getTokensByNetwork = (networkId: number): Token[] => {
  if (!isValidNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`)
  }

  return Object.values(knownTokens)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(token => {
      const address = token.addresses[networkId]
      if (address) {
        return {
          symbol: token.symbol,
          decimals: token.decimals,
          cToken: token.cToken,
          address,
        }
      }
      return null
    })
    .filter(isNotNull)
}

export const getNetworkTokenAddresses = (networkId: number) => {
  if (isValidNetworkId(networkId)) {
    return getTokensByNetwork(networkId).map(({ address }: { address: string }) => address)
  }

  return []
}

export const getTokenPriceCoingecko = async (token: string): Promise<string> => {
  let url =
    token === '0x0000000000000000000000000000000000000000'
      ? 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      : `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${token}&vs_currencies=usd`

  let returnToken = token === '0x0000000000000000000000000000000000000000' ? 'ethereum' : token
  const res = await fetch(url)
  const price = (await res.json())[returnToken.toLowerCase()].usd
  return price as string
}

export const getBaseToken = (networkId: number, symbol: string) => {
  if (!isValidNetworkId(networkId)) {
    return null
  }

  const lowerCaseSymbol = symbol.toLowerCase()

  if (lowerCaseSymbol in CompoundTokens) {
    return getToken(networkId, compoundTokenMap[lowerCaseSymbol as CompoundTokens])
  }

  if (lowerCaseSymbol in OTokens) {
    return getToken(networkId, OTokenMap[lowerCaseSymbol as OTokens])
  }

  return getToken(networkId, lowerCaseSymbol as KnownToken)
}
