import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { BigNumber } from 'ethers/utils'
import { OptionsContract_optionsContract } from 'types/generatedGQL'
import { useConnection, useAsyncMemo, useOptionsExchange, useEthToUsdPrice, useOptionIsCall } from 'hooks'
import TokenBalance from 'components/Common/TokenBalance'
import {
  ThemeColorTypes,
  ETH_TOKEN,
  isValidNetworkId,
  getToken,
  unlockUnlimitedOToken,
  addToLocalStorageArray,
  SellOTokenAction,
  getTokenFromAddress,
} from 'utils'
import { Button } from 'components/Common/Button'
import { ArrowBreak } from '../ArrowBreak'
import { PoweredByUniswap } from '../PoweredByUniswap'
import { Big, BigSource } from 'big.js'
import { oToken } from 'services'
import { LOCK_STATES } from 'components/Common/TokensDropdownUnlocker'
import { TextLight } from 'components/Common/CommonStyled'
import DoubleBalance from 'components/Common/DoubleBalance'
import useEthToDaiPrice from 'hooks/useEthToDaiPrice'

const Detail = styled.div`
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  flex: 1;
`

const Row = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
`

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  font-size: 11px;
  height: 30px;
`

interface ModalProps extends ModalBasicProps {
  isOpen: boolean
  onConfirm?: (tx: any, title: string) => void
  onRequestClose?: (e?: any) => void
  onTransactionFail?: () => void
  option?: OptionsContract_optionsContract
}

export const SellOETHEarlyModal: React.FC<ModalProps> = props => {
  const { library, isConnected, networkId, account } = useConnection()
  const { option, onConfirm, onTransactionFail } = props
  const isCall = useOptionIsCall(option)
  const underlyingToken = getTokenFromAddress(networkId, option?.underlying || ETH_TOKEN)
  const strikeToken = getTokenFromAddress(networkId, option?.strike || ETH_TOKEN)
  const oTokenSymbol = isCall ? `o${strikeToken.symbol.toUpperCase()}c` : `o${underlyingToken.symbol.toUpperCase()}p`
  const callStrikePrice =
    isCall && option
      ? new Big(1).div(new Big(`${option.strikePriceValue}e${option.strikePriceExp - option.oTokenExchangeRateExp}`))
      : new Big(1)
  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const ethToUsdcPrice = useEthToUsdPrice()
  const ethToDaiPrice = useEthToDaiPrice()

  const [service, setService] = useState<oToken | null>(null)
  const [inputAmounToUninsure, setInputAmounToUninsure] = useState<BigSource>('0')
  const [refundWith, setRefundWith] = useState('usdc')
  const [isOTokenLocked, setOTokenLocked] = useState(LOCK_STATES.LOADING)

  useEffect(() => {
    if (isConnected && library && isValidNetworkId(networkId) && option) {
      setService(new oToken(library, account, option.address))
    }
  }, [library, account, networkId, option, isConnected])

  const bigOTokenEthBalance = useAsyncMemo<BigNumber>(
    async () => {
      if (service && account) {
        return service.getBalanceOf(account)
      }

      return new BigNumber(0)
    },
    new BigNumber(0),
    [account, service],
  )

  const allowance = useAsyncMemo<Maybe<BigNumber>>(
    async () => {
      if (account && service && option) {
        return service.allowance(account, option.optionsExchangeAddress)
      }

      return null
    },
    null,
    [account, service, option],
  )

  useEffect(() => {
    if (allowance) {
      setOTokenLocked(allowance.gt(0) ? LOCK_STATES.UNLOCKED : LOCK_STATES.LOCKED)
    }
  }, [allowance])

  const oTokenDecimals = useMemo(() => (option ? -option.oTokenExchangeRateExp : 1), [option])
  const bOTokenBalance = new Big(bigOTokenEthBalance.toString()).times(`1e-${oTokenDecimals}`)

  const updateAmounToUninsure = useCallback((value: string) => {
    if (Number(value) >= 0 || value === '') {
      setInputAmounToUninsure(value)
    }
  }, [])

  const setMaxoEthBalance = useCallback(() => {
    setInputAmounToUninsure(bOTokenBalance)
  }, [bOTokenBalance])

  const refundToken = useMemo(
    () => (isValidNetworkId(networkId) ? getToken(networkId, refundWith as KnownToken) : null),
    [networkId, refundWith],
  )

  const oTokensToSell = useMemo(() => {
    if (!inputAmounToUninsure || Number.isNaN(Number(inputAmounToUninsure))) {
      return new Big(0)
    }
    return new Big(inputAmounToUninsure).times(`1e${oTokenDecimals || 0}`)
  }, [inputAmounToUninsure, oTokenDecimals])

  const [bigPremiumToGet, premiumToGet] = useAsyncMemo(
    async () => {
      if (oTokensToSell.eq(0) || !refundToken) {
        return [new BigNumber(0), '0']
      }

      const bigPremiumToReceive =
        (await optionsExchangeContract?.getPremiumReceived(
          option?.address,
          refundToken.address,
          Number(oTokensToSell.toFixed(0)),
        )) || new BigNumber(0)

      const premiumToReceive = new Big(bigPremiumToReceive.toString()).times(`1e-${refundToken.decimals}`).toFixed(6)
      return [bigPremiumToReceive, premiumToReceive]
    },
    [new BigNumber(0), '0'],
    [optionsExchangeContract, option, refundToken, oTokensToSell],
  )

  const $premiumToGet = useAsyncMemo(
    async () => {
      if (!isValidNetworkId(networkId) || ethToUsdcPrice.eq(0) || ethToDaiPrice.eq(0)) {
        return '0'
      }

      if (refundWith === 'usdc') {
        return premiumToGet
      }

      if (refundWith === 'eth') {
        return new Big(premiumToGet.toString()).div(ethToUsdcPrice.toString()).toFixed(4)
      }
      // DAI
      return new Big(premiumToGet.toString())
        .times(new Big(ethToDaiPrice.toString()).div(ethToUsdcPrice.toString()))
        .toFixed(4)
    },
    '0',
    [networkId, premiumToGet, ethToUsdcPrice, ethToDaiPrice, refundToken],
  )

  const unlockOToken = useCallback(async () => {
    if (account && option && service) {
      setOTokenLocked(LOCK_STATES.LOADING)
      const newLockState = await unlockUnlimitedOToken(option.optionsExchangeAddress, service)
      setOTokenLocked(newLockState)
    }
  }, [account, option, service])

  const insufficientBalance = useMemo(() => {
    return oTokensToSell.gt(bigOTokenEthBalance.toString())
  }, [bigOTokenEthBalance, oTokensToSell])

  const isButtonDisabled = useMemo(() => {
    return !!(oTokensToSell.eq(0) || isOTokenLocked !== LOCK_STATES.UNLOCKED || insufficientBalance)
  }, [isOTokenLocked, oTokensToSell, insufficientBalance])

  const confirmHandler = useCallback(async () => {
    if (
      optionsExchangeContract &&
      account &&
      option?.address &&
      ethToUsdcPrice &&
      ethToDaiPrice &&
      refundToken &&
      oTokensToSell.gt(0)
    ) {
      try {
        const tx = await optionsExchangeContract?.sellOTokens(
          account,
          option?.address,
          refundToken.address,
          oTokensToSell.toString(),
        )

        addToLocalStorageArray('cancel-transactions', {
          transactionHash: tx.hash,
          timestamp: Math.round(Date.now() / 1000).toString(),
          oTokensToSell: oTokensToSell.toString(),
          payoutTokenAddress: refundToken.address,
          payoutTokensReceived: bigPremiumToGet.toString(),
          payoutTokenPrice:
            refundWith === 'usdc'
              ? ethToUsdcPrice.toString()
              : refundWith === 'eth'
              ? '1e18'
              : ethToDaiPrice.toString(),
          usdcPrice: ethToUsdcPrice.toString(),
          token: {
            oTokenExchangeRateValue: option.oTokenExchangeRateValue,
            oTokenExchangeRateExp: option.oTokenExchangeRateExp,
            underlying: ETH_TOKEN.address,
          },
          pending: true,
        } as SellOTokenAction)

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, 'Confirm Cancel Protection')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          onTransactionFail()
        }
      }
    }
  }, [
    account,
    optionsExchangeContract,
    option,
    onConfirm,
    onTransactionFail,
    ethToUsdcPrice,
    ethToDaiPrice,
    refundToken,
    oTokensToSell,
    bigPremiumToGet,
    refundWith,
  ])

  const errorMessage = insufficientBalance ? 'Insufficient oETH balance' : ''
  const balanceInfo = isCall ? (
    [
      <TokenBalance
        key="unit-otoken"
        value={{ value: bigOTokenEthBalance, decimals: oTokenDecimals }}
        token={oTokenSymbol}
      />,
      ' / ',
      <TokenBalance
        key="unit-calls"
        value={{ value: bigOTokenEthBalance.div(new BigNumber(Number(callStrikePrice))), decimals: oTokenDecimals }}
        token="Calls"
      />,
    ]
  ) : (
    <TokenBalance value={{ value: bigOTokenEthBalance, decimals: oTokenDecimals }} token={oTokenSymbol} />
  )

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        title={`${oTokenSymbol} to sell`}
        titleInfo={[
          {
            title: `Balance:`,
            value: balanceInfo,
            onClick: setMaxoEthBalance,
          },
        ]}
        dropdownIsDisabled
        value={inputAmounToUninsure.toString()}
        isLocked={isOTokenLocked}
        selectedToken={'otoken'}
        onUnlock={unlockOToken}
        includeOToken={true}
        onAmountChange={updateAmounToUninsure}
        error={insufficientBalance || isOTokenLocked === LOCK_STATES.LOCKED}
      />
      <ArrowBreak />
      <ModalInnerCardExtended
        inputIsReadOnly
        titleInfo={['']}
        selectedToken={refundWith}
        onPaymentTokenChange={setRefundWith}
        title={'Receive'}
        value={premiumToGet}
      />
      <PoweredByUniswap />
      <Detail>
        <Row>
          <TextLight>Receive</TextLight>
          <div>
            <DoubleBalance
              left={{
                token: refundToken || null,
                value: premiumToGet,
              }}
              right={{
                token: '$',
                value: $premiumToGet,
              }}
            />
          </div>
        </Row>
      </Detail>

      <ErrorWrapper>{errorMessage}</ErrorWrapper>

      <Button buttonStyle={ThemeColorTypes.primary} disabled={isButtonDisabled} onClick={confirmHandler}>
        Confirm
      </Button>
    </Modal>
  )
}
