import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { ArrowBreak } from '../ArrowBreak'
import { PoweredByUniswap } from '../PoweredByUniswap'
import { TotalCost } from '../TotalCost'
import { DetailRow } from '../DetailRow'
import { Button } from '../../Common/Button'
import { TextLight } from '../../Common/CommonStyled'
import { TextWithTooltip } from '../../Common/TextWithTooltip'
import { useContracts, useConnection } from '../../../hooks'
import { Contracts } from '../../../hooks/useContracts'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import {
  isERC20Token,
  getToken,
  ETH_TOKEN,
  BigFloat,
  durationGetter,
  CompoundBalance,
  convertERC20ToCToken,
  convertCTokenToOToken,
  isValidNetworkId,
  getERC20Balance,
  unlockToken,
  isBetween,
} from '../../../utils'
import { BigNumberish, BigNumber } from 'ethers/utils'
import Value from '../../Common/Value'
import TokenBalance from '../../Common/TokenBalance'
import useOptionsExchange from '../../../hooks/useOptionsExchange'
import { GetOptionsContractsByUnderlying_optionsContracts } from '../../../types/generatedGQL'
import useAsyncMemo from '../../../hooks/useAsyncMemo'
import { Big, BigSource } from 'big.js'
import { getMaxLoss } from '../../../utils/options'
import useUniswapFactory from '../../../hooks/useUniswapFactory'
import { useERC20Contract } from '../../../hooks/useERC20Contract'
import { LOCK_STATES } from '../../Common/TokensDropdownUnlocker'
import useEthToUsdPrice from '../../../hooks/useEthToUSDPrice'

const TermsWrapper = styled.div`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 15px;
`

const APPROVALS_QUERY = loader('../../../queries/approvals.graphql')

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  font-size: 11px;
  height: 30px;
`

interface ModalProps {
  cTokenId: CompoundToken
  isOpen: boolean
  onConfirm?: (
    oTokensToBuy: string,
    paymentTokenAddress: string,
    bigPremiumToPay: BigNumber,
    paymentTokenPrice: string,
    usdcPrice: string,
  ) => void
  onRequestClose?: (e?: any) => void
  title?: string
  insured?: any
  balance?: CompoundBalance
  option?: GetOptionsContractsByUnderlying_optionsContracts
}

const getInsuredBalance = (amountInCompound: BigNumberish, insured: number) =>
  new BigNumber(new Big(insured).times(new BigNumber(amountInCompound).toString()).toFixed(0))
const getUninsuredBalance = (amountInCompound: BigNumberish, insuredBalance: BigNumberish) =>
  new BigNumber(amountInCompound).sub(insuredBalance)

const getPaymentToken = (payWith: string, networkId: number) => {
  if (isERC20Token(payWith)) {
    return getToken(networkId, payWith)
  }
  return ETH_TOKEN
}

export const BuyInsuranceModal: React.FC<ModalProps> = props => {
  const { onConfirm, insured, balance, option, cTokenId } = props
  const { exchangeRate = null, token = null, amountInCompound = 0 } = balance || {}

  const { account, networkId, library } = useConnection()
  const [payWith, setPayWith] = useState('eth')
  const [payLockState, setPayLockState] = useState(LOCK_STATES.UNLOCKED)
  const isPayLocked = payLockState !== LOCK_STATES.UNLOCKED
  const cToken = getToken(networkId, cTokenId)
  const contracts: Contracts = useContracts()
  const optionsExchangeContract = useOptionsExchange(option?.optionsExchangeAddress)
  const uniswapFactoryContract = useUniswapFactory()
  const oTokenERC20Contract = useERC20Contract(option?.address)
  const paymentTokenContract = contracts?.[payWith.toLocaleUpperCase()]
  const bigInsuredBalance = useMemo(() => getInsuredBalance(amountInCompound, insured), [amountInCompound, insured])
  const bigUninsuredBalance = useMemo(() => getUninsuredBalance(amountInCompound, bigInsuredBalance), [
    amountInCompound,
    bigInsuredBalance,
  ])

  const bInitAmountToProtect = new Big(bigUninsuredBalance.toString()).times(`1e-${token?.decimals}`)

  const roundedInitAmountToProtect = bInitAmountToProtect.round(2, 0).toFixed()

  const [inputAmountToProtect, setInputAmountToProtect] = useState<BigSource>(roundedInitAmountToProtect)

  const updateInputAmount = (value: string) => {
    if (isBetween(value, 0, bInitAmountToProtect.toString())) {
      setInputAmountToProtect(value || '0')
    }
  }

  const setMaxInputAmountToProtect = () => {
    setInputAmountToProtect(bInitAmountToProtect.toString())
  }

  const cTokenAmountToProtect = useMemo<BigFloat>(() => {
    if (Number(inputAmountToProtect) !== 0 && bInitAmountToProtect.sub(inputAmountToProtect).lte(5e-5)) {
      return convertERC20ToCToken(bInitAmountToProtect.toString(), token, exchangeRate, cToken)
    }
    return convertERC20ToCToken(inputAmountToProtect, token, exchangeRate, cToken)
  }, [inputAmountToProtect, token, exchangeRate, cToken, bInitAmountToProtect])

  const oTokensToBuy = useMemo(
    () =>
      convertCTokenToOToken(
        cTokenAmountToProtect.value.toString(),
        cToken,
        option?.oTokenExchangeRateValue,
        option?.oTokenExchangeRateExp,
      ),
    [cTokenAmountToProtect, cToken, option],
  )

  const availableOTokens = useAsyncMemo<string>(
    async () => {
      if (oTokenERC20Contract && uniswapFactoryContract && option?.address) {
        const exchangeAddress = await uniswapFactoryContract.getExchange(option.address)

        return (await oTokenERC20Contract?.getBalanceOf(exchangeAddress)).toString()
      }
      return '0'
    },
    '0',
    [oTokenERC20Contract, uniswapFactoryContract, option],
  )

  const noBalance = bigUninsuredBalance.eq(0)

  const insufficientLiquidity = new Big(oTokensToBuy).gt(availableOTokens)

  const belowThreshold = new Big(`1e-${token?.decimals}`).gt(inputAmountToProtect ? inputAmountToProtect : 0)

  const confirmButtonDisabled = isPayLocked || insufficientLiquidity || belowThreshold || noBalance

  const errorMessage =
    (noBalance && `You have no uninsured ${token?.symbol} on Compound`) ||
    (insufficientLiquidity && 'Insufficient liquidity. Please enter a smaller amount to protect.')

  const paymentToken = useMemo(() => getPaymentToken(payWith, networkId), [networkId, payWith])

  const { data, refetch } = useQuery(APPROVALS_QUERY, {
    variables: {
      account,
    },
    skip: !account || !payWith,
  })

  useEffect(() => {
    if (isERC20Token(payWith)) {
      const unlockLog = !!data?.approvals?.find(
        ({ approvedTokenAddress, spender }: { approvedTokenAddress: string; spender: string }) =>
          approvedTokenAddress === paymentToken.address && spender === option?.optionsExchangeAddress,
      )

      setPayLockState(unlockLog ? LOCK_STATES.UNLOCKED : LOCK_STATES.LOCKED)
    } else {
      setPayLockState(LOCK_STATES.UNLOCKED)
    }
  }, [payWith, data, paymentToken, option])

  const bigPaymentTokenBalance = useAsyncMemo<BigNumber>(
    async () => getERC20Balance(account, paymentTokenContract, library),
    new BigNumber(0),
    [paymentTokenContract, account],
  )

  const bigPremiumToPay = useAsyncMemo<BigNumber>(
    async () =>
      Number(oTokensToBuy) === 0 || insufficientLiquidity
        ? new BigNumber(0)
        : (await optionsExchangeContract?.getPremiumToPay(
            option?.address,
            paymentToken.address,
            Number(oTokensToBuy),
          )) || new BigNumber(0),
    new BigNumber(0),
    [optionsExchangeContract, option, paymentToken, insufficientLiquidity, oTokensToBuy],
  )

  const premiumToPay = useMemo(
    () => new Big(bigPremiumToPay.toString()).times(`1e-${paymentToken.decimals}`).toFixed(4),
    [bigPremiumToPay, paymentToken],
  )

  const ethToUsdcPrice = useEthToUsdPrice()

  const $premiumToPay = useAsyncMemo(
    async () => {
      if (payWith !== 'eth') {
        return premiumToPay
      }
      if (!isValidNetworkId(networkId) || ethToUsdcPrice.eq(0)) {
        return '0'
      }

      return new Big(bigPremiumToPay.toString()).div(ethToUsdcPrice.toString()).toFixed(4)
    },
    premiumToPay,
    [networkId, premiumToPay, ethToUsdcPrice, ethToUsdcPrice],
  )

  const maxLoss = useMemo(() => {
    if (option && balance) {
      const totalOToken = new Big(cTokenAmountToProtect.value.toString())
        .div(`1e${balance.cToken?.decimals}`)
        .div(`1e${option.oTokenExchangeRateExp}`)

      return getMaxLoss(balance, option, totalOToken.toString())
    } else {
      return {
        value: 0,
        decimals: 3,
      }
    }
  }, [cTokenAmountToProtect, option, balance])

  const insufficientBalance = useMemo(
    () => new Big(bigPaymentTokenBalance.toString()).times(`1e-${paymentToken.decimals}`).lt(premiumToPay),
    [bigPaymentTokenBalance, paymentToken, premiumToPay],
  )

  const unlockPay = useCallback(async () => {
    const spender = option?.optionsExchangeAddress
    if (spender) {
      setPayLockState(LOCK_STATES.LOADING)
      unlockToken(spender, paymentTokenContract).then(newLockState => {
        setPayLockState(newLockState)
        refetch()
      })
    }
  }, [paymentTokenContract, refetch, option])

  const confirmHandler = useCallback(() => {
    if (onConfirm && ethToUsdcPrice && paymentToken) {
      const paymentTokenPrice = paymentToken.address === ETH_TOKEN.address ? '1e18' : '1'
      const usdcPrice = paymentToken.address === ETH_TOKEN.address ? ethToUsdcPrice.toString() : '1'
      onConfirm(oTokensToBuy, paymentToken.address, bigPremiumToPay, paymentTokenPrice, usdcPrice)
    }
  }, [oTokensToBuy, onConfirm, ethToUsdcPrice, paymentToken, bigPremiumToPay])

  return (
    <Modal {...props}>
      <ModalInnerCardExtended
        title={`Protect ${token?.symbol} Deposits on Compound`}
        titleInfo={[
          { title: 'Insured:', value: <TokenBalance value={bigInsuredBalance} token={token} /> },
          {
            title: 'Uninsured:',
            value: <TokenBalance value={bigUninsuredBalance} token={token} />,
            onClick: setMaxInputAmountToProtect,
          },
        ]}
        dropdownIsDisabled
        value={inputAmountToProtect.toString()}
        selectedToken={token?.symbol.toLocaleLowerCase()}
        onAmountChange={updateInputAmount}
        error={insufficientLiquidity || belowThreshold}
      />
      <ArrowBreak />
      <ModalInnerCardExtended
        inputIsReadOnly
        isLocked={payLockState}
        error={isPayLocked || insufficientBalance}
        onUnlock={unlockPay}
        selectedToken={payWith}
        onPaymentTokenChange={setPayWith}
        title={'Pay with'}
        titleInfo={[{ title: 'Balance:', value: <TokenBalance value={bigPaymentTokenBalance} token={paymentToken} /> }]}
        value={premiumToPay}
      />
      <PoweredByUniswap />
      <TotalCost value={premiumToPay} token={payWith} $value={$premiumToPay} />
      <TermsWrapper>Terms</TermsWrapper>
      <DetailRow
        title={
          <TextWithTooltip
            text="Max Loss"
            tooltipText={
              'Maximum loss you can face. The rest is covered by your policy. <a href="https://opyn.gitbook.io/opynv1/faq#what-is-max-loss" target="_blank">Learn more.</a>'
            }
            id="max-loss"
            place="right"
          />
        }
        value={[
          <Value key={token?.symbol} value={maxLoss} decimals={3} precision={4}>
            {val => `${val} ${token?.symbol}`}
          </Value>,
          <TextLight key="usd">
            <Value value={maxLoss} decimals={3} precision={4}>
              {val => ` $${val}`}
            </Value>
          </TextLight>,
        ]}
      />
      <DetailRow title={'Duration'} value={durationGetter(option)?.value} />

      <ErrorWrapper>{errorMessage}</ErrorWrapper>

      <Button onClick={confirmHandler} disabled={confirmButtonDisabled}>
        Confirm
      </Button>
    </Modal>
  )
}
