import React, { useState, useCallback, useEffect, useMemo, useReducer } from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { Button } from '../../Common/Button'
import { LOCK_STATES } from '../../Common/TokensDropdownUnlocker'
import DoubleBalance from 'components/Common/DoubleBalance'
import { TextLight } from 'components/Common/CommonStyled'
import { TextWithTooltip } from 'components/Common/TextWithTooltip'
import { ThemeColorTypes, getTokenFromAddress, isValidNetworkId } from 'utils'
import { Stepper, StepProps } from '../Stepper'
import stepsReducer, { StepKeys } from './stepsReducer'
import { SellOTokens_optionsContract } from 'types/generatedGQL'
import { oTokenData, useConnection } from 'hooks'
import { BigNumber } from 'ethers/utils'
import TokenBalance from 'components/Common/TokenBalance'
import { BigSource, Big } from 'big.js'
import useTokenPrice from 'hooks/useTokenPrice'

const Detail = styled.div`
  /* border-bottom: 1px solid ${props => props.theme.borders.borderColor}; */
  /* border-top: 1px solid ${props => props.theme.borders.borderColor}; */
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  flex: 1;

  & > div {
    border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  }

  & > div:last-child {
    border-bottom: none;
  }
`

const Row = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
`

const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
  font-size: 11px;
  height: 30px;
`

interface ModalProps extends ModalBasicProps {
  isOpen: boolean
  onConfirm?: (tx: any, amount: string, type: string, title: string) => void
  onRequestClose?: (e?: any) => void
  onTransactionFail?: () => void
  reload?(): void
  option?: SellOTokens_optionsContract
  oTokenData?: oTokenData
  tokenSymbol?: string
}

export const ReducePositionModal = (props: ModalProps) => {
  const { account, networkId } = useConnection()
  const [inputOTokens, setInputOTokens] = useState<BigSource>('0')
  const [oTokensToBurn, setOTokensToBurn] = useState<BigNumber>(new BigNumber(0))
  const [isBurning, setIsBurning] = useState(false)
  const [showRedeem, setShowRedeem] = useState(false)
  const [isRedeeming, setIsRedeeming] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(true)
  const [bigOTokenEthBalance, setBigOTokenEthBalance] = useState<BigNumber>(new BigNumber(0))

  const [steps, dispatchSteps] = useReducer(stepsReducer, {
    burn: { label: 'Burn oTokens', status: 'required' },
    redeem: { label: 'Redeem Collateral', status: 'required' },
  } as any)

  const { oTokenData, option, onConfirm, onTransactionFail, reload, tokenSymbol = 'oETH' } = props
  const { service } = oTokenData || {}
  const collateralToken = isValidNetworkId(networkId) && getTokenFromAddress(networkId, option?.collateral)

  const collateralizationRatio = useMemo(() => {
    if (option && option.vaults?.length && collateralToken) {
      const { strikePriceValue, strikePriceExp, vaults } = option
      const { collateral, oTokensIssued } = vaults[0]
      const bOTokensIssued = new Big(oTokensIssued)

      if (bOTokensIssued.gt(0)) {
        const bCollateral = new Big(collateral).times(`1e-${collateralToken.decimals}`)

        return Number(
          bCollateral
            .div(bOTokensIssued.times(`${strikePriceValue}e${strikePriceExp}`))
            .times(100)
            .toFixed(0),
        )
      }
    }
    return 0
  }, [option, collateralToken])

  const changeStep = useCallback(() => {
    dispatchSteps({ payload: 'burn', type: 'completed' })
    dispatchSteps({ payload: 'redeem', type: 'current' })
    setShowRedeem(true)
  }, [])

  useEffect(() => {
    if (collateralizationRatio > 100 || collateralizationRatio === 0) {
      changeStep()
    } else {
      dispatchSteps({ payload: 'burn', type: 'current' })
    }
  }, [collateralizationRatio, changeStep])

  const collateralToUsdcPrice = useTokenPrice(option?.collateral)

  const oTokenDecimals = useMemo(() => (option ? -option.oTokenExchangeRateExp : 1), [option])

  const bigOTokenIssued = useMemo(() => {
    if (option && option.vaults?.length) {
      const { oTokensIssued } = option.vaults[0]
      return new BigNumber(oTokensIssued)
    }
    return new BigNumber(0)
  }, [option])

  const bOTokenIssued = new Big(bigOTokenIssued.toString()).times(`1e-${oTokenDecimals}`)

  useEffect(() => {
    if (service && account && loadingBalance && option && option.vaults?.length) {
      const { oTokensIssued } = option.vaults[0]
      const issued = new BigNumber(oTokensIssued)
      service.getBalanceOf(account).then(balance => {
        setBigOTokenEthBalance(issued.gt(balance) ? balance : issued)
        setLoadingBalance(false)
      })
    }
  }, [account, service, loadingBalance, option])

  const calculateOTokensToBurn = useCallback(
    inputValue => {
      const oTokensToBurn = new BigNumber(new Big(inputValue.toString()).times(`1e${oTokenDecimals}`).toFixed(0))
      setOTokensToBurn(oTokensToBurn)
    },
    [oTokenDecimals],
  )

  const bOTokenBalance = new Big(bigOTokenEthBalance.toString()).times(`1e-${oTokenDecimals}`)
  const updateInputOTokens = useCallback(
    (value: string) => {
      if (Number(value) >= 0 || value === '') {
        setInputOTokens(value)
        calculateOTokensToBurn(value || '0')
      }
    },
    [calculateOTokensToBurn],
  )

  const setMaxoEthBalance = useCallback(() => {
    setInputOTokens(bOTokenBalance)
    calculateOTokensToBurn(bOTokenBalance)
  }, [calculateOTokensToBurn, bOTokenBalance])

  const collateralToRedeem = useMemo(() => {
    if (option && option.vaults?.length && oTokenDecimals && collateralToken) {
      const { strikePriceValue, strikePriceExp, vaults } = option
      if (collateralizationRatio > 100 || collateralizationRatio === 0) {
        const { collateral } = vaults[0]
        const bigCollateral = new Big(collateral)
        const toRedeem =
          collateralizationRatio !== 0
            ? bigCollateral.minus(bigCollateral.div(collateralizationRatio).times(100))
            : bigCollateral

        return {
          value: new BigNumber(toRedeem.toFixed(0)),
          decimals: collateralToken.decimals,
        }
      } else {
        return {
          value: new BigNumber(
            new Big(inputOTokens || 0)
              .times(`1e${oTokenDecimals}`)
              .times(`${strikePriceValue}e${strikePriceExp}`)
              .times(`1e${collateralToken.decimals}`)
              .toFixed(0),
          ),
          decimals: collateralToken.decimals,
        }
      }
    }
    return {
      value: new BigNumber(0),
    }
  }, [option, inputOTokens, oTokenDecimals, collateralToken, collateralizationRatio])

  const $collateralToRedeem = useMemo(() => {
    if (collateralToken) {
      return new Big(collateralToRedeem.value.toString())
        .times(`1e-${collateralToRedeem.decimals}`)
        .div(Number(collateralToUsdcPrice) || 1)
        .round(4)
        .toFixed()
    }

    return 0
  }, [collateralToken, collateralToRedeem, collateralToUsdcPrice])

  const burnedOTokens = useMemo(() => {
    if (option && oTokenDecimals && collateralToken) {
      const { strikePriceValue, strikePriceExp } = option
      const burned = new Big(collateralToRedeem.value.toString())
        .times(`1e-${collateralToken.decimals}`)
        .div(`${strikePriceValue}e${strikePriceExp}`)

      return {
        value: new BigNumber(burned.toFixed(0)),
        decimals: oTokenDecimals,
      }
    }
    return {
      value: new BigNumber(0),
      decimals: oTokenDecimals,
    }
  }, [oTokenDecimals, collateralToRedeem, option, collateralToken])

  const burnHandler = useCallback(async () => {
    if (service && oTokensToBurn.gt(0)) {
      setIsBurning(true)
      dispatchSteps({ payload: 'burn', type: 'loading' })
      try {
        const tx = await service.burnOTokens(oTokensToBurn.toString())
        setIsBurning(true)
        tx.wait().then(() => {
          reload?.()
          changeStep()
        })
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          onTransactionFail()
        }
      }
    }
  }, [oTokensToBurn, service, changeStep, onTransactionFail, reload])

  const redeemHandler = useCallback(async () => {
    if (service && collateralToRedeem.value.gt(0)) {
      setIsRedeeming(true)
      dispatchSteps({ payload: 'redeem', type: 'loading' })
      try {
        const tx = await service.removeCollateral(collateralToRedeem.value.toString())

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, collateralToRedeem.value.toString(), 'RemoveCollateralAction', 'Confirm Reduce Position')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          setIsRedeeming(false)
          onTransactionFail()
        }
      }
    }
  }, [collateralToRedeem, service, onTransactionFail, onConfirm])

  const isRedeemDisabled = useMemo(() => !!(isRedeeming || collateralToRedeem.value.eq(0)), [
    isRedeeming,
    collateralToRedeem,
  ])

  const errorMessage =
    !loadingBalance && bigOTokenEthBalance.eq(0)
      ? `You currently have 0 ${tokenSymbol}. You must buy back ${tokenSymbol} to burn.`
      : oTokensToBurn.gt(bigOTokenIssued)
      ? `You have issued ${bOTokenIssued.toFixed(
          4,
        )} of ${tokenSymbol}. You cannot burn more than ${bOTokenIssued.toFixed(4)} oETH.`
      : oTokensToBurn.gt(bigOTokenEthBalance)
      ? `You currently have ${bOTokenBalance.toFixed(
          4,
        )} ${tokenSymbol}. You must buy back more ${tokenSymbol} to burn ${inputOTokens.toString()}`
      : ''

  const isBurnDisabled = useMemo(
    () => !!(oTokensToBurn.eq(0) || isBurning || bigOTokenEthBalance.eq(0) || errorMessage),
    [oTokensToBurn, isBurning, bigOTokenEthBalance, errorMessage],
  )

  return (
    <Modal style={{ content: { minHeight: '420px' } }} {...props}>
      <Stepper
        steps={Object.keys(steps).reduce((acc: StepProps[], key: string) => {
          acc.push(steps[key as StepKeys])
          return acc
        }, [] as StepProps[])}
      />
      {!showRedeem && (
        <>
          <ModalInnerCardExtended
            dropdownIsDisabled
            isLocked={LOCK_STATES.UNLOCKED}
            error={!!errorMessage}
            selectedToken={'otoken'}
            includeOToken={true}
            title={`${tokenSymbol} to Burn`}
            titleInfo={[
              {
                title: `${tokenSymbol} Balance:`,
                value: (
                  <TokenBalance
                    value={{ value: bigOTokenEthBalance, decimals: oTokenDecimals }}
                    token={tokenSymbol}
                    precision={4}
                  />
                ),
                onClick: setMaxoEthBalance,
              },
            ]}
            value={inputOTokens.toString()}
            onAmountChange={updateInputOTokens}
          />
          <Detail>
            <Row>
              <TextWithTooltip
                text="Redeemable Collateral"
                tooltipText={
                  'The redeemable collateral is calculated by multiplying the amount of oToken burned, the strike price and the strike to collateral price'
                }
                id="redeemable-collateral-show"
                place="right"
              />
              <div>
                <DoubleBalance
                  left={{
                    token: collateralToken || null,
                    value: collateralToRedeem,
                  }}
                  right={{
                    token: '$',
                    value: $collateralToRedeem,
                  }}
                />
              </div>
            </Row>
            <Row>
              <TextLight>oTokens to Burn</TextLight>
              <div>
                <TokenBalance token={tokenSymbol} value={inputOTokens.toString()} precision={4} />
              </div>
            </Row>
          </Detail>

          <ErrorWrapper>{errorMessage}</ErrorWrapper>

          <Button buttonStyle={ThemeColorTypes.secondary} disabled={isBurnDisabled} onClick={burnHandler}>
            Burn oTokens
          </Button>
        </>
      )}
      {showRedeem && (
        <>
          <Detail>
            <Row>
              <TextWithTooltip
                text="Redeemable Collateral"
                tooltipText={
                  'The redeemable collateral is calculated by multiplying the amount of oToken burned, the strike price and the strike to collateral price'
                }
                id="redeemable-collateral"
                place="right"
              />
              <div>
                <DoubleBalance
                  left={{
                    token: collateralToken || null,
                    value: collateralToRedeem,
                  }}
                  right={{
                    token: '$',
                    value: $collateralToRedeem,
                  }}
                />
              </div>
            </Row>
            <Row>
              <TextLight>oTokens Burned</TextLight>
              <div>
                <TokenBalance token={'oETH'} value={burnedOTokens} precision={4} />
              </div>
            </Row>
          </Detail>

          <ErrorWrapper />

          <Button buttonStyle={ThemeColorTypes.secondary} disabled={isRedeemDisabled} onClick={redeemHandler}>
            Redeem Collateral
          </Button>
        </>
      )}
    </Modal>
  )
}
