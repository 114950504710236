import React from 'react'
import styled from 'styled-components'
import { TotalTitle, TotalValue } from '../../Common/CommonStyled'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const Remaining = styled(TotalValue)`
  text-transform: none;
`

interface Props {
  currPrice: any
  tokenSymbol: string
}

export const CurrentTokenPrice: React.FC<Props> = props => {
  const { currPrice, tokenSymbol, ...restProps } = props

  const currPriceToDisplay = '$' + currPrice.toString()

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Current {tokenSymbol === 'weth' ? 'ETH' : tokenSymbol.toUpperCase()} Price</TotalTitle>
      <Values>
        <Remaining>{currPriceToDisplay}</Remaining>
      </Values>
    </Wrapper>
  )
}
