import styled from 'styled-components'

export const TextLight = styled.span`
  color: ${props => props.theme.colors.textColorLight};
`

export const TextDark = styled.span`
  color: ${props => props.theme.colors.darkGray};
`

export const TextError = styled.span`
  color: ${props => props.theme.colors.error};
`

export const TextLink = styled.span`
  color: ${props => props.theme.colors.primary};
`

export const ContentsWide = styled.div`
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: ${props => props.theme.innerScrollContainer.maxWidth};
`

export const ContentsNarrow = styled.div`
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: ${props => props.theme.innerScrollContainer.narrowWidth};
`

export const TotalWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  border-top: 1px solid ${props => props.theme.borders.borderColor};
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 13px 0;
`

export const TotalTitle = styled.h4`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

export const TotalValue = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;
`
