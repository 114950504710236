export const tableDefaults = {
  headerHeight: 50,
  rowHeight: 66,
  width: 1016,
}

export const columnDefaults = {
  flexGrow: 1,
  flexShrink: 0,
  minWidth: 80,
  width: 80,
}

export const expiryColumnDefaults = {
  flexGrow: 1,
  flexShrink: 0,
  minWidth: 160,
  width: 160,
}

export const chevronColumnDefaults = {
  flexGrow: 0,
  minWidth: 40,
  width: 40,
}
