import { useMemo } from 'react'
import { getToken } from '../utils'
import { useConnection } from '../components/web3'

/**
 * Return True if the option is eth option
 */
export const useOptionIsPut = (option: { underlying?: string; strike?: string } | null | undefined) => {
  const { networkId } = useConnection()

  const isPut = useMemo(() => {
    if (networkId === -1) return true
    if (!option) return true
    const eth = getToken(networkId, 'eth')
    const usdc = getToken(networkId, 'usdc')
    const dai = getToken(networkId, 'dai')
    const isPut =
      option?.underlying === eth.address && (option?.strike === usdc.address || option?.strike === dai.address)
    return isPut
  }, [option, networkId])
  return isPut
}

export const useOptionIsCall = (option: { underlying?: string; strike?: string } | null | undefined) => {
  const { networkId } = useConnection()

  const isCall = useMemo(() => {
    if (networkId === -1) return true
    if (!option) return true
    const eth = getToken(networkId, 'eth')
    const uni = getToken(networkId, 'uni')
    const dydx = getToken(networkId, 'dydx')
    const snx = getToken(networkId, 'snx')
    const wbtc = getToken(networkId, 'wbtc')
    const dpi = getToken(networkId, 'dpi')
    const usdc = getToken(networkId, 'usdc')
    const dai = getToken(networkId, 'dai')
    const isCall =
      (option?.strike === eth.address ||
        option?.strike === uni.address ||
        option?.strike === dydx.address ||
        option?.strike === snx.address ||
        option?.strike === dpi.address ||
        option?.strike === wbtc.address) &&
      (option?.underlying === usdc.address || option?.underlying === dai.address)
    return isCall
  }, [option, networkId])
  return isCall
}

export const useCallType = (option: { underlying?: string; strike?: string } | null | undefined) => {
  const { networkId } = useConnection()

  const isCall = useMemo(() => {
    if (networkId === -1) return true
    if (!option) return true
    const eth = getToken(networkId, 'eth')
    const uni = getToken(networkId, 'uni')
    const dydx = getToken(networkId, 'dydx')
    const snx = getToken(networkId, 'snx')
    const dpi = getToken(networkId, 'dpi')
    const isCall =
      option?.strike === eth.address
        ? 'ETH'
        : option?.strike === uni.address
        ? 'UNI'
        : option?.strike === dydx.address
        ? 'DYDX'
        : option?.strike === snx.address
        ? 'SNX'
        : option?.strike === dpi.address
        ? 'DPI'
        : 'WBTC'
    return isCall
  }, [option, networkId])
  return isCall
}
