import React from 'react'
import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { getUnixTime } from 'date-fns'
import { useConnection, useOracle } from '.'
import { GetOptionsContractsByUnderlying } from 'types/generatedGQL'
import {
  isValidNetworkId,
  NetworkId,
  getTokenFromAddress,
  Token,
  convertOTokenToUnderlying,
  convertUnderlyingToOToken,
  getToken,
  convertCTokenToERC20,
  convertBigFloatToBig,
  getTokenPrice,
  ExerciseData,
  getTokenPriceCoingecko,
  // getTokenOrPlaceHolder,
} from 'utils'
import { Web3Provider } from 'ethers/providers'
import { oToken, ERC20Service, OracleService } from 'services'
import { BigNumber } from 'ethers/utils'
import DoubleBalanceNegative from 'components/Common/DoubleBalanceNegative'
import DoubleBalancePositive from 'components/Common/DoubleBalancePositive'
import { Big } from 'big.js'

const OPTION_CONTRACT_BY_UNDERLYING_QUERY = loader('../queries/options_contracts_by_underlying.graphql')

const cTokenToToken: any = {
  CDAI: 'dai',
  CUSDC: 'usdc',
}

const getUnderlyingToShow = async (
  networkId: NetworkId,
  library: Web3Provider,
  account: string,
  underlyingToken: Token,
  underlyingRequiredToExercise: BigNumber,
  oracleContract: OracleService,
) => {
  let baseToken = underlyingToken
  let underlyingBalance = convertBigFloatToBig({
    value: underlyingRequiredToExercise,
    decimals: underlyingToken.decimals,
  })
  if (underlyingToken.cToken) {
    baseToken = getToken(networkId, cTokenToToken[underlyingToken.symbol] as KnownToken)
    const underlyingContract = new ERC20Service(library, account, underlyingToken.address, underlyingToken.cToken)
    const exchangeRate = await underlyingContract.getExchangeRate()
    underlyingBalance = convertBigFloatToBig(
      convertCTokenToERC20(underlyingRequiredToExercise.toString(), underlyingToken, exchangeRate, baseToken),
    )
  }
  let $underlyingBalance = new Big(0)
  // const comp = getTokenOrPlaceHolder(networkId, 'comp').address
  // const bal = getTokenOrPlaceHolder(networkId, 'bal').address
  // const yfi = getTokenOrPlaceHolder(networkId, 'yfi').address
  // const crv = getTokenOrPlaceHolder(networkId, 'crv').address

  // if (
  //   baseToken.address === comp ||
  //   baseToken.address === bal ||
  //   baseToken.address === yfi ||
  //   baseToken.address === crv
  // ) {
  //   const underlyingTokenToUSDPrice = await getTokenPriceCoingecko(baseToken.address)
  //   $underlyingBalance = underlyingBalance.times(underlyingTokenToUSDPrice)
  // } else {
  //   const underlyingTokenToUSDPrice = await getTokenPrice(networkId, oracleContract, baseToken.address)
  //   $underlyingBalance = underlyingBalance.times(underlyingTokenToUSDPrice)
  // }

  // const underlyingTokenToUSDPrice = await getTokenPriceCoingecko(baseToken.address)

  return (
    <DoubleBalanceNegative
      left={{
        token: baseToken,
        value: underlyingBalance.round(5).toFixed(),
      }}
      right={{
        token: '$',
        value: $underlyingBalance.round(5).toFixed(),
      }}
    />
  )
}

export const getPayoutToShow = async (
  networkId: NetworkId,
  strikeToken: Token,
  collateralToken: Token,
  oTokensToExercise: BigNumber,
  oracleContract: OracleService,
  optionToExercise: any,
) => {
  const [
    // strikeToUSDPrice
    strikeToCollateralPrice,
  ] = await Promise.all([
    // getTokenPrice(networkId, oracleContract, strikeToken.address),
    getTokenPrice(networkId, oracleContract, strikeToken.address, collateralToken.address),
  ])

  const payout = new Big(oTokensToExercise.toString())
    .times(optionToExercise.option.strikePriceValue)
    .times(`1e${optionToExercise.option.strikePriceExp}`)
  // const $payout = payout.times(strikeToUSDPrice)
  const payoutInCollateral = payout.div(strikeToCollateralPrice)

  return (
    <DoubleBalancePositive
      left={{
        token: collateralToken,
        value: payoutInCollateral.round(5).toFixed(),
      }}
      right={{
        token: '',
        value: '',
      }}
    />
  )
}

const getUnderlyingBalance = async (library: Web3Provider, account: string, underlyingToken: Token) => {
  let balance = new BigNumber(0)
  if (underlyingToken.symbol === 'ETH') {
    try {
      const signer = await library?.getSigner()
      balance = (await signer?.getBalance()) || new BigNumber(0)
    } catch {
      // Do nothing
    }
  } else {
    const underlyingContract = new ERC20Service(library, account, underlyingToken.address, underlyingToken.cToken)
    balance = await underlyingContract.getBalanceOf(account)
  }

  return balance
}

const getValuesToExercise = async (
  library: Web3Provider,
  account: string,
  underlyingToken: Token,
  optionToExercise: any,
) => {
  const oTokenBalance = optionToExercise.accountBalance.amount
  const option = optionToExercise.option

  const underlyingBalance = await getUnderlyingBalance(library, account, underlyingToken)
  const oTokenBalanceToUnderlying = convertOTokenToUnderlying(
    oTokenBalance,
    underlyingToken,
    option.oTokenExchangeRateValue,
    option.oTokenExchangeRateExp,
  )

  if (underlyingBalance.lt(oTokenBalanceToUnderlying.value)) {
    const oTokensToExercise = convertUnderlyingToOToken(
      underlyingBalance.toString(),
      underlyingToken,
      option.oTokenExchangeRateValue,
      option.oTokenExchangeRateExp,
    )
    return [
      new BigNumber(oTokensToExercise),
      new BigNumber(underlyingBalance.toString()),
      underlyingBalance,
      oTokenBalance,
    ]
  } else {
    return [
      new BigNumber(oTokenBalance),
      new BigNumber(oTokenBalanceToUnderlying.value),
      underlyingBalance,
      oTokenBalance,
    ]
  }
}

const sortAscExpiry = (a: any, b: any) => {
  const byExpiry = parseInt(a.option.expiry) - parseInt(b.option.expiry)
  const byTimestamp = parseInt(a.option.timestamp) - parseInt(b.option.timestamp)

  return byExpiry ? byExpiry : byTimestamp
}

const formatData = async (
  networkId: NetworkId,
  account: string,
  library: Web3Provider,
  oracleContract: OracleService,
  options: GetOptionsContractsByUnderlying,
  optionAddress: string,
): Promise<ExerciseData> => {
  let optionContracts = null
  for (let i = 0; i < options.optionsContracts.length; i++) {
    if (options.optionsContracts[i].address === optionAddress) {
      optionContracts = [options.optionsContracts[i]]
    }
  }

  optionContracts = optionContracts ? optionContracts : options.optionsContracts

  const [optionToExercise] = optionContracts
    .reduce((acc, data) => {
      const accountBalance =
        data.holdersBalances &&
        data.holdersBalances?.find(
          balance =>
            balance.account.address.toLowerCase() === account.toLowerCase() && new BigNumber(balance.amount).gt(0),
        )

      if (accountBalance) {
        return [
          ...acc,
          {
            option: data,
            accountBalance,
          },
        ]
      }

      return acc
    }, [] as any)
    .sort(sortAscExpiry)

  if (optionToExercise) {
    const strikeToken = getTokenFromAddress(networkId, optionToExercise.option.strike)
    const collateralToken = getTokenFromAddress(networkId, optionToExercise.option.collateral)
    const underlyingToken = getTokenFromAddress(networkId, optionToExercise.option.underlying)

    const [
      oTokensToExercise,
      underlyingRequiredToExercise,
      underlyingBalance,
      oTokenBalance,
    ] = await getValuesToExercise(library, account, underlyingToken, optionToExercise)

    const [UnderlyingToShow, PayoutToShow, collaterlPrice, usdcPrice] = await Promise.all([
      getUnderlyingToShow(networkId, library, account, underlyingToken, underlyingRequiredToExercise, oracleContract),
      getPayoutToShow(networkId, strikeToken, collateralToken, oTokensToExercise, oracleContract, optionToExercise),
      // oracleContract.getPrice(optionToExercise.option.collateral),
      // oracleContract.getPrice(getToken(networkId, 'usdc' as KnownToken).address),
      getTokenPriceCoingecko(optionToExercise.option.collateral),
      getTokenPriceCoingecko(getToken(networkId, 'usdc' as KnownToken).address),
    ])
    const oTokenAddress = optionToExercise.option.address
    const oTokenContract = new ERC20Service(library, account, oTokenAddress)
    const oTokenService = new oToken(library, account, oTokenAddress)
    const underlyingContract = new ERC20Service(library, account, underlyingToken.address)

    return {
      optionToExercise,
      oTokenContract,
      oTokenService,
      underlyingContract,
      collateralToken,
      strikeToken,
      underlyingToken,
      oTokensToExercise,
      underlyingRequiredToExercise,
      collaterlPrice: collaterlPrice.toString(),
      usdcPrice: usdcPrice.toString(),
      UnderlyingToShow,
      PayoutToShow,
      underlyingBalance,
      oTokenBalance,
    }
  } else {
    return {} as ExerciseData
  }
}

export const useExerciseData = (underlying: Maybe<string>, optionAddress: Maybe<string>) => {
  const { networkId, account, library } = useConnection()
  const [loading, setLoading] = useState(true)
  const [exerciseData, setExerciseData] = useState<ExerciseData>({} as ExerciseData)
  const oracleContract = useOracle()

  const { loading: queryLoading, data: options, refetch } = useQuery<GetOptionsContractsByUnderlying>(
    OPTION_CONTRACT_BY_UNDERLYING_QUERY,
    {
      variables: { underlyings: [underlying], now: getUnixTime(Date.now()), account: account?.toLowerCase() || '' },
      skip: !account || !underlying,
    },
  )

  useEffect(() => {
    if (
      isValidNetworkId(networkId) &&
      library &&
      !queryLoading &&
      options &&
      account &&
      oracleContract &&
      optionAddress
    ) {
      formatData(networkId, account, library, oracleContract, options, optionAddress).then(result => {
        setExerciseData(result)
        setLoading(false)
      })
    }
  }, [networkId, queryLoading, options, account, library, oracleContract, optionAddress])

  return { exerciseData, loading, refetch }
}
