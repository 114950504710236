import { useMemo } from 'react'
import { getTokensByNetwork, isValidNetworkId } from '../utils'
import { ERC20Service } from '../services'
import { useConnection } from '../components/web3'

/**
 * This hook can only be used by components under the `Web3ReactConnector` component. Otherwise it will throw.
 */
export const useContracts = (): Record<string, Maybe<ERC20Service>> => {
  const { library, isConnected, networkId, account } = useConnection()

  return useMemo(() => {
    if (!isConnected || !library || !isValidNetworkId(networkId)) {
      return {}
    }

    return getTokensByNetwork(networkId).reduce((contracts, token) => {
      return {
        ...contracts,
        [token.symbol]: new ERC20Service(library, account, token.address, token.cToken),
      }
    }, {})
  }, [isConnected, networkId, account, library])
}

export type Contracts = ReturnType<typeof useContracts>
