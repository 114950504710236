import React from 'react'
import styled from 'styled-components'
import { InnerContainer } from '../../../../../components/Common/InnerContainer'
import ItemSVGTwo from './img/2.svg'
import ItemSVGFour from './img/4.svg'
import Chevron from './img/chevron.svg'
import Product from './img/product.svg'

const Wrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    margin-bottom: 80px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.darkGray};
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 20px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    font-size: 40px;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 60px;
`

const InnerContainerStyled = styled(InnerContainer)`
  flex-direction: column;
`

const Layout = styled.div`
  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    column-gap: 135px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  display: flex;
  margin: 0 0 36px;

  &:last-child {
    margin-bottom: 0;
  }
`

const IconWrapper = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 72px;
  justify-content: center;
  width: 72px;

  img {
    display: block;
  }
`

const ItemTextContainer = styled.div`
  padding-left: 30px;
`

const ItemTitle = styled.h2`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 10px 0 18px;
`

const ItemText = styled.p`
  color: ${props => props.theme.colors.textColorMedium};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 24px;
`

const ItemLink = styled.a`
  color: ${props => props.theme.colors.secondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-decoration: none;

  & > svg {
    margin-left: 8px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const InsureImg = styled.img`
  display: block;
  margin-top: 40px;
  max-width: 100%;
`

const items = [
  {
    title: 'Grow your ETH',
    text: 'Provide ETH collateral to mint and sell protection, earning premiums. Maintain 140% collateralized.',
    url: 'https://opyn.gitbook.io/opynv1/faq',
    icon: ItemSVGTwo,
  },
  {
    title: 'Noncustodial',
    text: 'All of your funds are stored in your wallet, so only you can access your funds - not us or anyone else.',
    url: 'https://opyn.gitbook.io/opynv1/faq',
    icon: ItemSVGFour,
  },
]

export const SellCompoundProtection: React.FC = props => {
  const { ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <InnerContainerStyled>
        <Title>Sell Compound deposit protection</Title>
        <Text>Use ETH collateral to provide Compound deposit protection to others.</Text>
        <Layout>
          <Column>
            {items.map((item, index) => {
              return (
                <Item key={index}>
                  <IconWrapper>
                    <img src={item.icon} alt="" />
                  </IconWrapper>
                  <ItemTextContainer>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemText>{item.text}</ItemText>
                    <ItemLink href={item.url} target="_blank">
                      Learn more <img src={Chevron} alt="" />
                    </ItemLink>
                  </ItemTextContainer>
                </Item>
              )
            })}
          </Column>
          <Column>
            <InsureImg src={Product} alt="" />
          </Column>
        </Layout>
      </InnerContainerStyled>
    </Wrapper>
  )
}
