import React from 'react'

interface IconProps {
  color?: string
}

export const RefreshIcon: React.FC<IconProps> = (props: IconProps) => {
  const { color = '#ccc' } = props

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C12.3936 2 14.4465 3.0787 15.8069 5H12V7H19V0H17V3.27035C15.2749 1.18251 12.8042 0 10 0C4.47715 0 0 4.47715 0 10H2C2 5.58172 5.58172 2 10 2ZM10 18C7.60638 18 5.55354 16.9213 4.19306 15H8V13H1V20H3V16.7297C4.72511 18.8175 7.19577 20 10 20C15.5228 20 20 15.5228 20 10H18C18 14.4183 14.4183 18 10 18Z"
        fill={color}
      />
    </svg>
  )
}
