import { useConnection } from 'components/web3'
import { useMemo } from 'react'
import { isValidNetworkId } from 'utils'
import OptionsExchange from 'services/OptionsExchange'

export const useOptionsExchange = (address?: string) => {
  const { library, isConnected, networkId, account } = useConnection()

  return useMemo(() => {
    if (!isConnected || !library || !isValidNetworkId(networkId)) {
      return null
    }

    return address ? new OptionsExchange(library, account, address) : null
  }, [isConnected, library, networkId, account, address])
}

export default useOptionsExchange
