import { BigNumber, BigNumberish } from 'ethers/utils'
import { isArrayish } from 'ethers/utils/bytes'
import {
  GetOptionsContractsByUnderlying_optionsContracts,
  GetOptionsContractsByUnderlying_optionsContracts_holdersBalances,
} from '../types/generatedGQL'
import { erc20Tokens } from '.'
import { BigSource } from 'big.js'
import { ERC20Service, oToken } from '../services'

export interface Token {
  address: string
  decimals: number
  symbol: string
  cToken?: boolean
  oToken?: boolean
  exchangeRateDecimals?: number
}

export enum CompoundTokens {
  cdai = 'cdai',
  cusdc = 'cusdc',
}

export enum InsuredTokens {
  cdai = 'cdai',
  cusdc = 'cusdc',
  eth = 'eth',
}

export enum OTokens {
  odai = 'odai',
  ocdai = 'ocdai',
  ocusdc = 'ocusdc',
  oeth = 'oeth',
}

export enum KnownTokens {
  cdai = 'cdai',
  cusdc = 'cusdc',
  dai = 'dai',
  usdc = 'usdc',
  eth = 'eth',
}

export const compoundTokens: CompoundToken[] = [CompoundTokens.cusdc, CompoundTokens.cdai]
export const insuredTokens: InsuredToken[] = [InsuredTokens.cusdc, InsuredTokens.cdai, InsuredTokens.eth]

/**
 * Checks if a string is a known ERC20 token
 * @param {string} token
 * @returns {boolean}
 */
export const isERC20Token = (token: string): token is ERC20Token => erc20Tokens.includes(token as ERC20Token)

/**
 * Checks if a string is an insured token
 * @param {string} token
 * @returns {boolean}
 */
export function isInsuredToken(token: string): token is InsuredTokens {
  return token in InsuredTokens
}

/**
 * Checks if a string is a known Compound token
 * @param {string} token
 * @returns {boolean}
 */
export function isCompoundToken(token: string): token is CompoundTokens {
  if (!token) {
    return false
  }
  return token.toLocaleLowerCase() in CompoundTokens
}

export enum Wallet {
  MetaMask = 'MetaMask',
}

export enum ThemeColorTypes {
  error = 1,
  primary = 2,
  secondary = 3,
  tertiary = 4,
  darkGray = 6,
  primaryLight = 5,
}

export enum ThemeColorTypesInverted {
  errorInverted = 101,
  primaryInverted = 102,
  secondaryInverted = 103,
  tertiaryInverted = 104,
  primaryLightInverted = 105,
  darkGrayInverted = 106,
}

export interface BuyerCompoundOptionData {
  option?: GetOptionsContractsByUnderlying_optionsContracts
  oTokenBalance?: GetOptionsContractsByUnderlying_optionsContracts_holdersBalances
  balance?: CompoundBalance
  insured?: BigSource
  maxLoss?: BigFloat
  insuredAPR?: BigFloat
}

export interface SellerCompoundOptionData {
  option?: GetOptionsContractsByUnderlying_optionsContracts
  balance?: CompoundBalance
  earnAPR?: BigFloat
}

interface OptionToExercise {
  option: GetOptionsContractsByUnderlying_optionsContracts
  accountBalance: GetOptionsContractsByUnderlying_optionsContracts_holdersBalances
}

export interface ExerciseData {
  optionToExercise: Maybe<OptionToExercise>
  oTokenContract: Maybe<ERC20Service>
  oTokenService: Maybe<oToken>
  underlyingContract: Maybe<ERC20Service>
  collateralToken: Maybe<Token>
  strikeToken: Maybe<Token>
  underlyingToken: Maybe<Token>
  oTokensToExercise: Maybe<BigNumber>
  collaterlPrice: Maybe<string>
  usdcPrice: Maybe<string>
  underlyingRequiredToExercise: Maybe<BigNumber>
  UnderlyingToShow: Maybe<JSX.Element>
  PayoutToShow: Maybe<JSX.Element>
  underlyingBalance: Maybe<BigNumber>
  oTokenBalance: Maybe<BigNumber>
}
export interface SellerOptionData {
  option?: GetOptionsContractsByUnderlying_optionsContracts
  earnAPR?: BigFloat
  earnPremium?: BigFloat
}

export interface BigFloat {
  value: BigNumberish
  decimals?: number
}

export function isBigNumberish(number: any): number is BigNumberish {
  return BigNumber.isBigNumber(number) || isArrayish(number) || typeof number === 'number' || typeof number === 'string'
}

export function isBigFloat(number: any): number is BigFloat {
  return (
    typeof number === 'object' &&
    Object.prototype.hasOwnProperty.call(number, 'value') &&
    isBigNumberish(number.value) &&
    (Object.prototype.hasOwnProperty.call(number, 'decimals') ? typeof number.decimals === 'number' : true)
  )
}

export type Numeric = BigNumberish | BigFloat

export interface BaseBalance {
  id: KnownToken
  token: Token | null
  protocol: string
  amount: BigNumberish
  status: string
  exchangeRate?: BigNumberish
}
export interface CompoundBalance extends BaseBalance {
  cToken: Maybe<Token>
  amountInCompound: BigNumberish
  exchangeRate: BigNumberish
  status: string
  uninsuredAPR: BigFloat
}

export type Balance = CompoundBalance | BaseBalance

export interface BuyOTokenAction {
  id?: string
  transactionHash: string
  oTokensToBuy: string
  timestamp: string
  paymentTokenAddress: string
  premiumPaid: string
  paymentTokenPrice: string
  usdcPrice: string
  exchangeRateCurrent: string
  token: {
    oTokenExchangeRateValue: string
    oTokenExchangeRateExp: string
    collateral: string
    underlying: string
    strike: string
  }
  pending?: boolean
}

export interface SellOTokenAction {
  id?: string
  transactionHash: string
  oTokensToSell: string
  timestamp: string
  seller: string
  payoutTokenAddress: string
  payoutTokensReceived: string
  payoutTokenPrice: string
  usdcPrice: string
  token: {
    oTokenExchangeRateValue: string
    oTokenExchangeRateExp: string
    collateral: string
    underlying: string
    strike: string
  }
  pending?: boolean
}

export interface ExerciseAction {
  id?: string
  exerciser: string
  transactionHash: string
  timestamp: string
  oTokensToBuy: string
  oTokensToExercise: string
  amtCollateralToPay: string
  optionsContract: { collateral: string; underlying: string; strike: string }
  collateralPrice: string
  usdcPrice: string
  pending?: boolean
}
