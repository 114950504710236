import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Modal } from '../Modal'
import { Button } from '../../Common/Button'
import Link from '../../Common/Link'
import SpinnerSVG from './img/spin.svg'
import SpinnerSVGSecondary from './img/spin_secondary.svg'
import { ThemeColorTypes } from '../../../utils'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg)
  }
`

const Spinner = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${spinAnimation};
  animation-timing-function: linear;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const SpinnerImage = styled.img`
  display: block;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

const Title = styled.h1<{ themeColor: ThemeColorTypes }>`
  color: ${props => props.theme.colors[ThemeColorTypes[props.themeColor]]};
  flex-grow: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 15px;
  padding: 0 10px;
  text-align: center;
`

const TitleInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const GradientWrapper = styled.div<{ themeColor: ThemeColorTypes }>`
  background: linear-gradient(
    90deg,
    ${props => props.theme.colors[ThemeColorTypes[props.themeColor]]} 0%,
    rgba(5, 177, 105, 0.11) 84.02%
  );
  display: block;
  flex-grow: 1;
  height: 7px;
  max-width: 100%;
`

const ButtonStyled = styled(Button)`
  margin-top: 50px;
`

const Text = styled.p`
  color: ${props => props.theme.colors.textColor};
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  margin: 0;
  text-align: center;
`

interface ModalProps {
  isOpen: boolean
  onRequestClose: ((e?: any) => void) | undefined
  title: string
  url?: string
  themeColor?: ThemeColorTypes
}

export const ConfirmModal: React.FC<ModalProps> = props => {
  const { onRequestClose, url, title, themeColor = ThemeColorTypes.primary } = props

  return (
    <Modal style={{ content: { width: '410px' } }} {...props}>
      <Spinner>
        <SpinnerImage alt="" src={themeColor === ThemeColorTypes.primary ? SpinnerSVG : SpinnerSVGSecondary} />
      </Spinner>
      <TitleWrapper>
        <TitleInner>
          <Title themeColor={themeColor}>{title}</Title>
          <GradientWrapper themeColor={themeColor} />
        </TitleInner>
      </TitleWrapper>
      <Text>
        Your transaction is pending. You
        <br /> can track its progress on{' '}
        <Link target="_blank" href={url || '#'} as="a" themeColor={themeColor}>
          Etherscan
        </Link>
      </Text>
      <ButtonStyled onClick={onRequestClose} buttonStyle={themeColor}>
        Done
      </ButtonStyled>
    </Modal>
  )
}
