import React from 'react'
import { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import {
  getTokenFromAddress,
  getNetworkTokenAddresses,
  isValidNetworkId,
  ETH_TOKEN,
  BuyOTokenAction,
  ExerciseAction,
  NetworkId,
  SellOTokenAction,
} from '../utils'
import { CheckIcon } from '../components/TransactionsCard/img/CheckIcon'
import { RefreshIcon } from '../components/TransactionsCard/img/RefreshIcon'
import { useConnection } from '../components/web3'
import Value from '../components/Common/Value'
import DoubleBalance from '../components/Common/DoubleBalance'
import { Big } from 'big.js'
import { useOptionIsCall, useCallType } from './useEthOptionType'
import { useEthOptionByAddress } from './useEthOptionByAddress'
const HISTORY = loader('../queries/buying_history_by_otoken.graphql')

const sorter = (a: any, b: any) => b.timestamp - a.timestamp

const getRefund = (action: SellOTokenAction, networkId: NetworkId | -1) => {
  const { payoutTokenAddress, payoutTokensReceived, payoutTokenPrice } = action
  let { usdcPrice } = action
  if (usdcPrice === '0') usdcPrice = (1e18).toString()
  const payoutToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, payoutTokenAddress) : null
  return new Big(payoutTokensReceived)
    .times(new Big(payoutTokenPrice).div(usdcPrice))
    .div(`1e${payoutToken ? payoutToken.decimals : 1}`)
}

const getPremiumPaid = (action: BuyOTokenAction, networkId: NetworkId | -1) => {
  const { paymentTokenAddress, premiumPaid } = action
  let { usdcPrice } = action
  const paymentToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, paymentTokenAddress) : null
  if (usdcPrice === '0') usdcPrice = (1e18).toString()
  return new Big(premiumPaid).div(`1e${paymentToken ? paymentToken.decimals : 1}`)
}

const getPremiumPaidInUsdc = (action: BuyOTokenAction, networkId: NetworkId | -1) => {
  const { paymentTokenAddress, premiumPaid, paymentTokenPrice } = action
  let { usdcPrice } = action
  const paymentToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, paymentTokenAddress) : null
  if (usdcPrice === '0') usdcPrice = (1e18).toString()
  return new Big(premiumPaid)
    .times(new Big(paymentTokenPrice).div(usdcPrice))
    .div(`1e${paymentToken ? paymentToken.decimals : 1}`)
}

const getPaymentToken = (action: BuyOTokenAction, networkId: NetworkId | -1) => {
  const { paymentTokenAddress } = action
  const paymentToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, paymentTokenAddress) : null
  return paymentToken
}

const getTotalPaid = (buyActions: Array<BuyOTokenAction>, networkId: NetworkId) => {
  return buyActions.reduce((acc, action) => {
    return acc.plus(getPremiumPaidInUsdc(action, networkId))
  }, new Big(0))
}

export const useBuyingTransactionHistoryByOToken = (
  oTokenAddress: string,
): [any[], any[], boolean, Function, Maybe<Big>] => {
  const { networkId, account } = useConnection()

  const approvalsFilter = useCallback(
    ({ approvedTokenAddress }: { approvedTokenAddress: string }) =>
      getNetworkTokenAddresses(networkId).includes(approvedTokenAddress),
    [networkId],
  )

  const parseApproval = useCallback(
    ({ id, approvedTokenAddress, timestamp, transactionHash }) => ({
      id,
      icon: CheckIcon,
      title: ['Unlocked ', getTokenFromAddress(networkId, approvedTokenAddress).symbol],
      timestamp,
      transactionHash,
    }),
    [networkId],
  )

  const { option } = useEthOptionByAddress(oTokenAddress).optionsData
  const isCall = useOptionIsCall(option)
  const callType = useCallType(option)

  const parseBuyOTokenAction = useCallback(
    (action: BuyOTokenAction) => {
      const {
        id,
        oTokensToBuy,
        transactionHash,
        timestamp,
        token: { oTokenExchangeRateExp = '0', underlying = '' },
        pending,
      } = action
      const decimals = -oTokenExchangeRateExp
      const underlyingToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, underlying) : null

      const callStrikePrice = option
        ? new Big(1).div(
            new Big(option?.strikePriceValue)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(`1e${-option?.oTokenExchangeRateExp || 0}`),
          )
        : new Big(1)
      const $paymentToken = getPaymentToken(action, networkId)
      const $premiumPaid = getPremiumPaid(action, networkId)
      const $premiumPaidInUsdc = getPremiumPaidInUsdc(action, networkId)

      return {
        id,
        icon: pending ? RefreshIcon : CheckIcon,
        title: [
          <Value key="token" precision={8} value={{ value: oTokensToBuy, decimals }}>
            {val =>
              isCall
                ? `${new Big(val).div(callStrikePrice).toFixed(2)} ${callType} Calls`
                : `${val} ${underlyingToken ? underlyingToken.symbol : ''}`
            }
          </Value>,
          isCall ? ' purchased, ' : underlying === ETH_TOKEN.address ? ' protected, ' : ' insured, ',
          `${$premiumPaid.toFixed(4)} ${$paymentToken?.symbol.toUpperCase()} 
          ${$paymentToken?.symbol.toUpperCase() !== 'USDC' ? '($' + $premiumPaidInUsdc.toFixed(2) + ')' : ''} paid`,
        ],
        timestamp,
        transactionHash,
      }
    },
    [networkId, option, isCall, callType],
  )

  const parseCancelAction = useCallback(
    (action: SellOTokenAction) => {
      const {
        id,
        oTokensToSell,
        transactionHash,
        timestamp,
        token: { oTokenExchangeRateExp = '0', underlying = '' },
        pending,
      } = action
      const decimals = -oTokenExchangeRateExp
      const underlyingToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, underlying) : null

      const callStrikePrice = option
        ? new Big(1).div(
            new Big(option?.strikePriceValue)
              .times(`1e${option?.strikePriceExp || 0}`)
              .times(`1e${-option?.oTokenExchangeRateExp || 0}`),
          )
        : new Big(1)
      const $refund = getRefund(action, networkId)

      return {
        id,
        icon: pending ? RefreshIcon : CheckIcon,
        title: [
          'Cancel ',
          <Value key="token" precision={8} value={{ value: oTokensToSell, decimals }}>
            {val =>
              isCall
                ? `${new Big(val).div(callStrikePrice).toFixed(2)} ${callType} Calls`
                : `${val} ${underlyingToken ? underlyingToken.symbol : ''}`
            }
          </Value>,
          underlying === ETH_TOKEN.address || isCall ? ' protection, ' : ' insurance, ',
          `$${$refund.toFixed(4)} refund`,
        ],
        timestamp,
        transactionHash,
      }
    },
    [networkId, option, isCall, callType],
  )

  const parseExerciseAction = useCallback(
    ({
      id,
      transactionHash,
      timestamp,
      amtCollateralToPay,
      optionsContract: { collateral = '', underlying = '' },
      collateralPrice,
      usdcPrice,
      pending,
    }: ExerciseAction) => {
      const collateralToken = isValidNetworkId(networkId) ? getTokenFromAddress(networkId, collateral) : null
      if (usdcPrice === '0') usdcPrice = (1e18).toString()
      const $amtCollateralToPay = new Big(amtCollateralToPay)
        .div(usdcPrice)
        .times(collateralPrice)
        .times(`1e-${collateralToken?.decimals || 0}`)
        .round(4)
        .toFixed()

      return {
        id,
        icon: pending ? RefreshIcon : CheckIcon,
        title: [
          underlying === ETH_TOKEN.address ? 'Exercised ' : 'Claimed ',
          <DoubleBalance
            key="token"
            left={{
              token: collateralToken,
              value: new Big(amtCollateralToPay)
                .times(`1e-${collateralToken?.decimals || 0}`)
                .round(4)
                .toFixed(),
            }}
            right={{
              token: '$',
              value: $amtCollateralToPay,
            }}
          />,
        ],
        timestamp,
        transactionHash,
      }
    },
    [networkId],
  )

  const locallyStoredBuy = JSON.parse(localStorage.getItem('buy-transactions') || '[]') as any[]
  const locallyStoredExercise = JSON.parse(localStorage.getItem('exercise-transactions') || '[]') as any[]
  const locallyStoredCancel = JSON.parse(localStorage.getItem('cancel-transactions') || '[]') as any[]
  const { loading, data, refetch } = useQuery(HISTORY, {
    variables: {
      account,
      oToken: oTokenAddress,
    },
    skip: !account,
  })

  const refresh = useCallback(async () => {
    if (account && oTokenAddress) {
      refetch({
        account,
        oToken: oTokenAddress,
      })
    }
  }, [account, oTokenAddress, refetch])

  let buyOTokenActions = [...(data?.buyOTokensActions || [])]
  let exerciseActions = [...(data?.exerciseActions || [])]
  let cancelActions = [...(data?.sellOTokensActions || [])]

  const pendingBuy = locallyStoredBuy.filter(
    ({ transactionHash: hash, timestamp }: BuyOTokenAction) =>
      Date.now() / 1000 - Number(timestamp) < 3600 &&
      !buyOTokenActions.find(({ transactionHash }: BuyOTokenAction) => hash === transactionHash),
  )

  if (pendingBuy.length) {
    buyOTokenActions = buyOTokenActions.concat(pendingBuy)
  }

  localStorage.setItem('buy-transactions', JSON.stringify(pendingBuy))

  const pendingExercise = locallyStoredExercise.filter(
    ({ transactionHash: hash, timestamp }: ExerciseAction) =>
      Date.now() / 1000 - Number(timestamp) < 3600 &&
      !exerciseActions.find(({ transactionHash }: ExerciseAction) => hash === transactionHash),
  )

  if (pendingExercise.length) {
    exerciseActions = exerciseActions.concat(pendingExercise)
  }

  localStorage.setItem('exercise-transactions', JSON.stringify(pendingExercise))

  const pendingCancel = locallyStoredCancel.filter(
    ({ transactionHash: hash, timestamp }: SellOTokenAction) =>
      Date.now() / 1000 - Number(timestamp) < 3600 &&
      !cancelActions.find(({ transactionHash }: BuyOTokenAction) => hash === transactionHash),
  )

  if (pendingCancel.length) {
    cancelActions = cancelActions.concat(pendingCancel)
  }

  localStorage.setItem('cancel-transactions', JSON.stringify(pendingCancel))

  const history = data
    ? [
        ...data.approvals.filter(approvalsFilter).map(parseApproval),
        ...buyOTokenActions.map(parseBuyOTokenAction),
        ...exerciseActions.map(parseExerciseAction),
        ...cancelActions.map(parseCancelAction),
      ].sort(sorter)
    : []

  const rawData = data ? [...buyOTokenActions, ...exerciseActions, ...cancelActions].sort(sorter) : []

  const totalPaid = isValidNetworkId(networkId) ? getTotalPaid(buyOTokenActions, networkId) : null

  return [history, rawData, loading, refresh, totalPaid]
}
