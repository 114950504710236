import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, ModalBasicProps } from '../Modal'
import { ModalInnerCardExtended } from '../ModalInnerCardExtended'
import { Button } from '../../Common/Button'
import { LOCK_STATES, UNLOCKER_POSITION, TokensDropdownUnlocker } from '../../Common/TokensDropdownUnlocker'
import { getProp, orderByProp, ETH_TOKEN, ExerciseData, addToLocalStorageArray, ExerciseAction } from '../../../utils'
import { TextLight } from '../../Common/CommonStyled'
import { TextError } from '../../Common/CommonStyled'
import { useConnection } from '../../web3'
import { BigNumber, parseUnits } from 'ethers/utils'
import { ERC20Service } from '../../../services'
import { getPayoutToShow } from '../../../hooks/useExerciseData'
import { useOracle, useAsyncMemo } from '../../../hooks'
import TokenBalance from '../../Common/TokenBalance'
import DoubleBalance from '../../Common/DoubleBalance'
import DoubleBalancePositive from '../../Common/DoubleBalancePositive'
import { Big } from 'big.js'

const Content = styled.div`
  /* border-bottom: 1px solid ${props => props.theme.borders.borderColor}; */
  /* border-top: 1px solid ${props => props.theme.borders.borderColor}; */
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  & > div {
    border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  }

  & > div:last-child {
    border-bottom: none;
  }
`

const Row = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  border-color: white;
`

const Step = styled.div`
  padding: 13px 0;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.colors.textColorLight};
`

const StepBadge = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.textColorLight};
  border-radius: 50%;
  color: ${props => props.theme.cards.backgroundColor};
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 10px;
`
const hasAllowance = async (
  account: Maybe<string>,
  spender: Maybe<string>,
  paymentTokenContract: Maybe<ERC20Service>,
  amount: BigNumber,
) => {
  if (account && spender) {
    try {
      return await paymentTokenContract?.hasEnoughAllowance(account, spender, amount)
    } catch {
      // Do nothing
    }
  }
  return false
}

const unlockToken = async (
  account: Maybe<string>,
  spender: Maybe<string>,
  paymentTokenContract: Maybe<ERC20Service>,
  amount: BigNumber,
) => {
  if (account && spender) {
    try {
      if (await hasAllowance(account, spender, paymentTokenContract, amount)) {
        return LOCK_STATES.UNLOCKED
      }
      await paymentTokenContract?.approve(spender, amount)
      return LOCK_STATES.UNLOCKED
    } catch {
      // Do nothing
    }
  }
  return LOCK_STATES.LOCKED
}

interface ModalProps extends ModalBasicProps {
  isOpen: boolean
  onConfirm?: (tx: any, type: string) => void
  onRequestClose?: (e?: any) => void
  onTransactionFail?: () => void
  tokenId?: string
  exerciseData?: ExerciseData
  refetch: Function
  currentPrice?: number
}

export const ExerciseModal = (props: ModalProps) => {
  const { tokenId, onConfirm, onTransactionFail, exerciseData, refetch, currentPrice } = props
  const { account, networkId } = useConnection()
  const oracleContract = useOracle()
  const {
    oTokenService,
    underlyingContract,
    optionToExercise,
    underlyingToken,
    strikeToken,
    collateralToken,
    underlyingBalance,
    oTokenBalance,
  } = exerciseData || {}

  const { option = null } = optionToExercise || {}
  const underlyingTokenSymbol = tokenId ? tokenId : underlyingToken ? underlyingToken.symbol : 'weth'

  const [inputUnderlyingAmt, setInputUnderlyingAmt] = useState<string>('0')
  const [amountError, setAmtError] = useState('')
  const [isAmountError, setIsAmtErr] = useState(false)

  const [inputOTokenAmt, setInputOTokenAmt] = useState<string>('0')

  const [pendingConfirm, setPendingConfirm] = useState(false)
  const [isUnderlyingLocked, setUnderlyingLocked] = useState(LOCK_STATES.UNLOCKED)
  const [isUnderlyingLockedChecked, setUnderlyingLockedChecked] = useState(false)

  const [finalUnderlyingToExercise, setFinalUnderlyingAmt] = useState<BigNumber>(new BigNumber(0))
  const [finalOTokenToExercise, setFinalOtokenAmt] = useState<BigNumber>(new BigNumber(0))

  const oTokenName = 'oToken'

  const isConfirmDisabled = useMemo(
    () =>
      !option ||
      isAmountError ||
      !isUnderlyingLockedChecked ||
      isUnderlyingLocked !== LOCK_STATES.UNLOCKED ||
      pendingConfirm,
    [option, isUnderlyingLocked, isUnderlyingLockedChecked, pendingConfirm, isAmountError],
  )

  const underlyingBalanceToDisplay = useMemo(() => {
    if (!underlyingToken || !underlyingBalance) return new Big(0)
    const unerlyingBalanceBig = new Big(underlyingBalance?.toString())
    return unerlyingBalanceBig.div(`1e${underlyingToken?.decimals}`)
  }, [underlyingToken, underlyingBalance])

  const oTokenBalanceToDisplay = useMemo(() => {
    if (!oTokenBalance || !option) return new Big(0)
    return new Big(oTokenBalance.toString()).times(`1e${option?.oTokenExchangeRateExp}`)
  }, [option, oTokenBalance])

  useEffect(() => {
    if (
      underlyingToken &&
      underlyingToken.address !== ETH_TOKEN.address &&
      account &&
      option &&
      underlyingContract &&
      finalUnderlyingToExercise
    ) {
      hasAllowance(account, option.address, underlyingContract, finalUnderlyingToExercise).then(res => {
        setUnderlyingLocked(res ? LOCK_STATES.UNLOCKED : LOCK_STATES.LOCKED)
        setUnderlyingLockedChecked(true)
      })
    } else if (underlyingToken && underlyingToken.address === ETH_TOKEN.address) {
      setUnderlyingLockedChecked(true)
    }
  }, [underlyingToken, account, option, underlyingContract, finalUnderlyingToExercise])

  const unlockUnderlying = useCallback(async () => {
    if (account && option && underlyingContract && finalUnderlyingToExercise) {
      setUnderlyingLocked(LOCK_STATES.LOADING)
      const newLockState = await unlockToken(account, option.address, underlyingContract, finalUnderlyingToExercise)
      setUnderlyingLocked(newLockState)
    }
  }, [account, option, underlyingContract, finalUnderlyingToExercise])
  const PayoutToShow = useAsyncMemo(
    async () => {
      if (networkId !== -1 && strikeToken && collateralToken && oracleContract)
        return await getPayoutToShow(
          networkId,
          strikeToken,
          collateralToken,
          finalOTokenToExercise,
          oracleContract,
          optionToExercise,
        )
    },
    <DoubleBalancePositive
      left={{
        token: collateralToken,
        value: '0',
      }}
      right={{
        token: '$',
        value: '0',
      }}
    />,
    [networkId, strikeToken, collateralToken, finalOTokenToExercise, oracleContract, optionToExercise],
  )

  const handleConfirmClick = useCallback(async () => {
    const { data: newData } = await refetch()
    const newOptionContract = newData.optionsContracts.find(
      (optionContract: any) => optionContract.address === option?.address,
    )
    const holders = newOptionContract?.vaults?.sort(orderByProp('oTokensIssued')).map(getProp('owner'))

    if (
      option &&
      finalUnderlyingToExercise &&
      finalOTokenToExercise &&
      holders?.length &&
      oTokenService &&
      exerciseData
    ) {
      setPendingConfirm(true)
      try {
        const overrides =
          option.underlying === ETH_TOKEN.address ? { value: new BigNumber(finalUnderlyingToExercise) } : {}
        const tx = await oTokenService?.exercise(finalOTokenToExercise.toString(), holders, overrides)

        addToLocalStorageArray('exercise-transactions', {
          amtCollateralToPay: exerciseData?.underlyingRequiredToExercise?.toString() || '1',
          optionsContract: { collateral: exerciseData?.optionToExercise?.option.collateral || '' },
          collateralPrice: exerciseData?.collaterlPrice || '1',
          usdcPrice: exerciseData?.usdcPrice || '1',
          transactionHash: tx.hash,
          timestamp: Math.round(Date.now() / 1000).toString(),
          pending: true,
        } as ExerciseAction)

        if (onConfirm && typeof onConfirm === 'function') {
          onConfirm(tx, 'ExerciseAction')
        }
      } catch {
        if (onTransactionFail && typeof onTransactionFail === 'function') {
          setPendingConfirm(false)
          onTransactionFail()
        }
      }
    }
  }, [
    option,
    finalUnderlyingToExercise,
    oTokenService,
    finalOTokenToExercise,
    onTransactionFail,
    onConfirm,
    exerciseData,
    refetch,
  ])

  const $underlyingToExercise = useMemo(() => {
    if (!currentPrice) return '0'
    try {
      return new Big(inputOTokenAmt).times(currentPrice).toString()
    } catch (error) {
      return '0'
    }
  }, [currentPrice, inputOTokenAmt])

  const onInputOTokenChange = useCallback(
    (value: string) => {
      try {
        setInputUnderlyingAmt(value)
        setInputOTokenAmt(value)
        setIsAmtErr(false)
        const underlyingAmount = parseUnits(value, underlyingToken?.decimals)
        setFinalUnderlyingAmt(underlyingAmount)
        if (underlyingBalance && underlyingAmount.gt(underlyingBalance)) {
          setIsAmtErr(true)
          setAmtError(
            `Insufficient ${underlyingTokenSymbol} balance, you only have ${underlyingBalanceToDisplay} ${underlyingTokenSymbol}`,
          )
        }

        const oTokenAmount = parseUnits(value, -option?.oTokenExchangeRateExp)
        setFinalOtokenAmt(oTokenAmount)
        if (oTokenBalance && oTokenAmount.gt(oTokenBalance)) {
          setIsAmtErr(true)
          setAmtError('Insufficient oToken balance.')
        }
      } catch (error) {
        try {
          new Big(value) // can create new Big: means that error comes from parseUnit decimals.
          setAmtError('Invalid amount: too many digits.')
        } catch {
          setAmtError('Invalid amount.')
        } finally {
          setIsAmtErr(true)
        }
      }
    },
    [oTokenBalance, option, underlyingBalance, underlyingBalanceToDisplay, underlyingToken, underlyingTokenSymbol],
  )

  return (
    <Modal {...props}>
      <Content>
        <Step>
          <StepBadge>1</StepBadge> Send your oTokens and {underlyingTokenSymbol}
        </Step>
        <Row>
          <ModalInnerCardExtended
            title={`${oTokenName}`}
            titleInfo={[
              {
                title: 'Balance:',
                value: <TokenBalance value={oTokenBalanceToDisplay.toString()} token={'oToken'} />,
                onClick: () => onInputOTokenChange(oTokenBalanceToDisplay.toString()),
              },
            ]}
            isLocked={LOCK_STATES.UNLOCKED}
            dropdownIsDisabled
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onUnlock={() => {}}
            value={inputOTokenAmt.toString()}
            selectedToken={'otoken'}
            onAmountChange={onInputOTokenChange}
            error={isAmountError}
          />
        </Row>
        <Row>
          <TokensDropdownUnlocker
            disabled={true}
            isLocked={isUnderlyingLocked}
            unlockerPosition={UNLOCKER_POSITION.RIGHT}
            onUnlock={unlockUnderlying}
            selectedToken={underlyingTokenSymbol.toLowerCase()}
            includeOToken={true}
          />
          <div>
            {currentPrice ? (
              <DoubleBalance
                left={{ token: underlyingToken, value: inputUnderlyingAmt }}
                right={{ token: '$', value: $underlyingToExercise, precision: 5 }}
              />
            ) : (
              <TokenBalance value={inputUnderlyingAmt.toString()} token={underlyingToken} />
            )}
          </div>
        </Row>
        <Step>
          <StepBadge>2</StepBadge> Receive protection payout
        </Step>
        <Row>
          <TextLight>Protection payout</TextLight>
          <div>{PayoutToShow || <div />}</div>
        </Row>
        {isAmountError && (
          <Row>
            <TextError> {amountError} </TextError>
          </Row>
        )}
      </Content>
      <Button onClick={handleConfirmClick} disabled={isConfirmDisabled}>
        Confirm
      </Button>
    </Modal>
  )
}
