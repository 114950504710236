import { useMemo } from 'react'
import { isValidNetworkId } from '../utils'
import { ERC20Service } from '../services'
import { useConnection } from '../components/web3'

/**
 * This hook can only be used by components under the `Web3ReactConnector` component. Otherwise it will throw.
 */
export const useERC20Contract = (address?: Maybe<string>): Maybe<ERC20Service> => {
  const { library, isConnected, networkId, account } = useConnection()

  return useMemo(() => {
    if (!isConnected || !library || !isValidNetworkId(networkId) || !address) {
      return null
    }

    return new ERC20Service(library, account, address)
  }, [isConnected, networkId, account, library, address])
}
