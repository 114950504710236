import React from 'react'

export const DiscordIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="iconBackground"
        d="M10.0146 9.94708C10.01 9.94708 10.0048 9.94687 10 9.94667C9.995 9.94687 9.98979 9.94708 9.98521 9.94708C8.74417 9.94708 7.65062 9.63292 7.00437 10.2262C6.61708 10.5823 6.45396 11.0112 6.45396 11.4731C6.45396 13.4025 7.99979 13.6394 9.985 13.6394H10.0146C11.9998 13.6394 13.5456 13.4023 13.5456 11.4731C13.5456 11.011 13.3825 10.5823 12.9952 10.2262C12.349 9.63292 11.2556 9.94708 10.0146 9.94708ZM8.32667 12.5835C7.94896 12.5835 7.64271 12.1594 7.64271 11.6362C7.64271 11.1131 7.94896 10.6892 8.32667 10.6892C8.70437 10.6892 9.01104 11.1131 9.01104 11.6362C9.01104 12.1594 8.70437 12.5835 8.32667 12.5835ZM11.6731 12.5835C11.2952 12.5835 10.9887 12.1594 10.9887 11.6362C10.9887 11.1131 11.2952 10.6892 11.6731 10.6892C12.0506 10.6892 12.3571 11.1131 12.3571 11.6362C12.3571 12.1594 12.0508 12.5835 11.6731 12.5835ZM10 0C4.47687 0 0 4.47708 0 10C0 15.5229 4.47687 20 10 20C15.5227 20 20 15.5229 20 10C20 4.47708 15.5227 0 10 0ZM10.9121 14.1044C10.7329 14.1044 10.3767 14.1048 10 14.1052C9.62313 14.1048 9.26667 14.1044 9.08771 14.1044C8.29542 14.1044 5.15792 14.0437 5.15792 10.2617C5.15792 9.39146 5.45625 8.75479 5.94438 8.22458C5.86646 8.03229 5.8625 6.94021 6.27812 5.89479C6.27812 5.89479 7.23146 5.99937 8.67396 6.98917C8.97625 6.90562 9.48812 6.86396 10 6.86396C10.5115 6.86396 11.0233 6.90542 11.326 6.98917C12.7683 5.99937 13.7215 5.89479 13.7215 5.89479C14.1371 6.94021 14.1333 8.03229 14.0554 8.22458C14.5438 8.75479 14.8421 9.39167 14.8421 10.2617C14.8421 14.0437 11.7044 14.1044 10.9121 14.1044Z"
        fill="#BDBDBD"
      />
    </svg>
  )
}
