import React from 'react'
import styled from 'styled-components'
import { withRouter, NavLink } from 'react-router-dom'

export const MainMenuWrapper = styled.nav`
  background-color: #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: row;
  }
`

export const Item = styled(NavLink)`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borders.borderColor};
  color: ${props => props.theme.header.color};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  margin: 0;
  text-align: center;
  text-decoration: none;

  &.activePrimary {
    color: ${props => props.theme.colors.primary};
  }

  &.activeSecondary {
    color: ${props => props.theme.colors.secondary};
  }

  &:active {
    opacity: 0.8;
  }

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    height: ${props => props.theme.header.height};
    justify-content: flex-start;
    margin: 0 0 0 15px;
    padding: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &.activePrimary {
      border-bottom-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }

    &.activeSecondary {
      border-bottom-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.secondary};
    }

    &:active {
      opacity: 1;
    }
  }
`

const MainMenu: React.FC = props => (
  <MainMenuWrapper {...props}>
    {/* <Item activeClassName="activePrimary" to={'/buy'}>
      Buy protection
    </Item> */}
    <Item activeClassName="activeSecondary" to={'/sell'}>
      Sell protection
    </Item>
  </MainMenuWrapper>
)

export default withRouter(MainMenu)
