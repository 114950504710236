import useAsyncMemo from './useAsyncMemo'
import Big from 'big.js'
import { getTokenPriceCoingecko, getTokenOrPlaceHolder } from '../utils'

export const useKnownTokenToUSDPrice = (tokenId: KnownToken): Big => {
  return useAsyncMemo(
    async () => {
      const token = getTokenOrPlaceHolder(1, tokenId)
      const price = await getTokenPriceCoingecko(token.address)
      return new Big(price)
    },
    new Big(0),
    [],
  )
}

export default useKnownTokenToUSDPrice
