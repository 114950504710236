import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Logo from 'components/Common/Logo'
import { Wrapper, HeaderInnerContainer } from 'components/Common/Header'
import { Button } from 'components/Common/Button'
import MainMenu from '../MainMenu'
import { ThemeColorTypesInverted } from '../../../../utils'

const WrapperStyled = styled(Wrapper)`
  position: relative;
`

const ButtonStyled = styled(Button)`
  height: 36px;
  min-width: 105px;
  margin-right: 12px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    height: 48px;
    min-width: 178px;
  }
`

const LogoWrapper = styled.div`
  cursor: pointer;
`

const MainMenuStyled = styled(MainMenu)`
  display: none;
  margin-left: auto;
  margin-right: 30px;

  @media (min-width: ${props => props.theme.themeBreakPoints.md}) {
    display: inherit;
  }
`

interface HeaderProps {
  sellClickHandler: () => void
  homeIconClickHandler: () => void
  isSell: boolean
}

export const Header = (props: HeaderProps) => {
  const { isSell, sellClickHandler, homeIconClickHandler, ...restProps } = props
  const history = useHistory()

  // const goToBuy = useCallback(() => history.push(`/buy`), [history])
  const goToSell = useCallback(() => history.push(`/sell`), [history])

  return (
    <WrapperStyled {...restProps}>
      <HeaderInnerContainer>
        <LogoWrapper onClick={homeIconClickHandler}>
          <Logo />
        </LogoWrapper>
        <MainMenuStyled sellClickHandler={sellClickHandler} isSell={isSell} />
        <ButtonStyled
          onClick={goToSell}
          buttonStyle={
            isSell ? ThemeColorTypesInverted.secondaryInverted : ThemeColorTypesInverted.primaryLightInverted
          }
        >
          App
        </ButtonStyled>
      </HeaderInnerContainer>
    </WrapperStyled>
  )
}
