import { createGlobalStyle } from 'styled-components'
import theme from '../../../theme'

type ThemeType = typeof theme

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  @import url('https://rsms.me/inter/inter.css');

  html {
    font-family: ${props => props.theme.fonts.fontFamily};
    font-size: ${props => props.theme.fonts.defaultSize};
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.colors.mainBodyBackground};
    color: ${props => props.theme.colors.textColor};
    font-family: ${props => props.theme.fonts.fontFamily};
    font-size: ${props => props.theme.fonts.defaultSize};
  }

  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter var', ${props => props.theme.fonts.fontFamily};
    }
  }

  code {
    font-family: ${props => props.theme.fonts.fontFamilyCode};
  }

  body,
  html,
  #root {
    height: 100vh;
  }

  /* Table */
  #root {
    .ReactVirtualized__Table.culo {
      width: auto;
    }

    .ReactVirtualized__Table__headerRow {
      border-bottom: 1px solid ${props => props.theme.borders.borderColor};
      border-top: 1px solid ${props => props.theme.borders.borderColor};
      font-weight: 500;
      text-transform: none;
    }

    .ReactVirtualized__Table__headerColumn {
      align-items: center;
      color: ${props => props.theme.colors.textColorLight};
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 100%;
      line-height: 1.2;
      margin-right: 0;
      padding-right: 10px;
      text-align: center;

      &:first-of-type {
        margin-left: 0;
        padding-left: 10px;
      }

      &.alignLeft {
        text-align: left;
      }

      &.ok {
        background-color: ${props => props.theme.table.th.backgroundOK};
      }
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: normal;
      width: 100%;
    }

    .ReactVirtualized__Table__row {
      border-bottom: 1px solid ${props => props.theme.borders.borderColor};
      cursor: pointer;

      &:hover {
        background-color: #fcfcfc;
      }
    }

    .ReactVirtualized__Table__rowColumn {
      align-items: center;
      color: ${props => props.theme.table.td.color};
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 100%;
      line-height: 1.2;
      margin-right: 0;
      padding-right: 10px;
      justify-content: center;

      &:first-of-type {
        margin-left: 0;
        padding-left: 10px;
      }

      &.alignLeft {
        justify-content: flex-start;
      }

      &.ok {
        background-color: ${props => props.theme.table.th.backgroundOK};
      }
    }
  }
`
