import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Token } from '../../utils'
import { Big } from 'big.js'
import TokenBalance from '../Common/TokenBalance'
import Percentage from '../Common/Percentage'
import { ProgressBar } from '../Common/ProgressBar'
import { BigNumberish } from 'ethers/utils'

const Wrapper = styled.div`
  margin-bottom: 10px;
`

const InsuredData = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 5px;
  text-transform: uppercase;
`

interface InsuranceProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  insuredPercentage?: string | number
  token?: Maybe<Token>
  balance?: BigNumberish
}

export const InsuranceProgressBar: React.FC<InsuranceProgressBarProps> = (props: InsuranceProgressBarProps) => {
  const { insuredPercentage = '0', token, balance, ...restProps } = props

  const fullInsurance: boolean = new Big(insuredPercentage).gte(95)

  return (
    <Wrapper {...restProps}>
      <ProgressBar
        label={
          <InsuredData>
            <Percentage value={insuredPercentage} precision={2} placeholder={false} /> Insured -{' '}
            <TokenBalance token={token} value={balance} />
          </InsuredData>
        }
        alignLabel={fullInsurance ? 'right' : 'left'}
        color={fullInsurance ? 'primary' : 'error'}
        progress={insuredPercentage}
      />
    </Wrapper>
  )
}
