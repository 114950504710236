import { useConnection } from 'components/web3'
import { isValidNetworkId, getToken, getTokenPriceCoingecko } from 'utils'
import useOracle from './useOracle'
import useAsyncMemo from './useAsyncMemo'
import { BigNumber } from 'ethers/utils'

const _default = new BigNumber(0)

export const useEthToUsdcPrice = (): BigNumber => {
  const { networkId } = useConnection()
  const oracleContract = useOracle()

  return useAsyncMemo(
    async () => {
      if (!isValidNetworkId(networkId) || !oracleContract) {
        return _default
      }

      const usdcAddress = getToken(networkId, 'usdc').address
      // const price = await oracleContract.getPrice(usdcAddress)
      const price = new BigNumber((Number(await getTokenPriceCoingecko(usdcAddress)) * 1e18).toString())
      return price
    },
    _default,
    [networkId, oracleContract],
  )
}

export default useEthToUsdcPrice
