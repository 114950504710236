import useAsyncMemo from './useAsyncMemo'
import Big from 'big.js'
import { BigNumber } from 'ethers/utils'

export const useEthToUsdPrice = (): BigNumber => {
  return useAsyncMemo(
    async () => {
      const res = await fetch('https://api.coinbase.com/v2/prices/ETH-USD/spot')
      const price = (await res.json()).data.amount
      const usdPriceInWei = new Big(10)
        .pow(18)
        .div(new Big(price))
        .toFixed(0)
      return new BigNumber(usdPriceInWei)
    },
    new BigNumber(0),
    [],
  )
}

export default useEthToUsdPrice
