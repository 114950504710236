import { useConnection } from 'components/web3'
import { useMemo } from 'react'
import { OracleService } from 'services'
import { isValidNetworkId } from 'utils'
import { getOracleAddressByNetwork } from 'utils'

export const useOracle = () => {
  const { library, isConnected, networkId, account } = useConnection()

  return useMemo(() => {
    if (!isConnected || !library || !isValidNetworkId(networkId)) {
      return null
    }

    return new OracleService(library, account, getOracleAddressByNetwork(networkId))
  }, [isConnected, library, networkId, account])
}

export default useOracle
