import React from 'react'
import styled from 'styled-components'
import { TotalTitle, TotalValue } from '../../Common/CommonStyled'
import { durationInDays } from '../../../utils'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`

const Values = styled.div`
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const Remaining = styled(TotalValue)`
  text-transform: none;
`

interface Props {
  expiry: string
}

export const Duration: React.FC<Props> = props => {
  const { expiry, ...restProps } = props

  return (
    <Wrapper {...restProps}>
      <TotalTitle>Duration</TotalTitle>
      <Values>
        <Remaining>{durationInDays(Date.now(), Number(expiry) * 1000)}</Remaining>
      </Values>
    </Wrapper>
  )
}
