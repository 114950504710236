import React, { HTMLAttributes, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { Card } from 'components/Common/Card'
import { ThemeColorTypes } from 'utils'
import { Loading } from 'components/Common/Loading'
import FormattedDate from 'components/Common/Date'
import { etherscanTx } from 'utils'
import { useConnection } from '../web3'
import { Big } from 'big.js'

const Row = styled.a`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  text-decoration: none;
`

const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    flex: none;
  }
`

const TitleText = styled.h4`
  color: ${props => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0 9px;
`

const DateP = styled.p`
  color: ${props => props.theme.colors.textColorLight};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  flex: none;
`

type HistoricalData = [Array<any>, any[], boolean, Function, Maybe<Big>]

interface TransactionsCardProps extends HTMLAttributes<HTMLDivElement> {
  isCall?: boolean
  theme?: any
  transactionsCardStyle?: ThemeColorTypes
  lastUpdate?: number
  historicalData: HistoricalData
}

const TransactionsCardWrapper: React.FC<TransactionsCardProps> = (props: TransactionsCardProps) => {
  const { networkId } = useConnection()
  const { transactionsCardStyle = ThemeColorTypes.primary, theme, lastUpdate, historicalData, ...restProps } = props
  const color = theme.colors[ThemeColorTypes[transactionsCardStyle]]
  const [history, , loading, refetch] = historicalData

  useEffect(() => {
    if (!loading) {
      refetch()
    }
  }, [lastUpdate, loading, refetch])

  if (!loading && !history.length) {
    return null
  }

  return (
    <Card {...restProps} maxHeight="300px" title="Transactions">
      {loading ? (
        <Loading />
      ) : (
        history.map((item, index) => {
          return (
            <Row
              key={index}
              target="_blank"
              href={etherscanTx({
                networkId,
                hash: item.transactionHash,
              })}
            >
              <Title>
                <item.icon color={color} />
                <TitleText>{item.title}</TitleText>
              </Title>
              <FormattedDate timestamp={item.timestamp}>{val => <DateP>{val}</DateP>}</FormattedDate>
            </Row>
          )
        })
      )}
    </Card>
  )
}

export const TransactionsCard = withTheme(TransactionsCardWrapper)
