import React from 'react'
import { ContentsWide } from 'components/Common/CommonStyled'
import { CompoundSection } from 'components/SellDash/CompoundSection'
// restore ETH puts. uncomment this
// import { EthPutSection } from 'components/SellDash/EthPutSection'
import { WethPutSection } from 'components/SellDash/WethPutSection'
import { EthCallSection } from 'components/SellDash/EthCallSection'
import { UniPutSection } from 'components/SellDash/UniPutSection'
import { UniCallSection } from 'components/SellDash/UniCallSection'
import { DydxPutSection } from 'components/SellDash/DydxPutSection'
import { DydxCallSection } from 'components/SellDash/DydxCallSection'
import { WbtcPutSection } from 'components/SellDash/WbtcPutSection'
import { WbtcCallSection } from 'components/SellDash/WbtcCallSection'
import { SnxPutSection } from 'components/SellDash/SnxPutSection'
import { SnxCallSection } from 'components/SellDash/SnxCallSection'
import { DpiPutSection } from 'components/SellDash/DpiPutSection'
import { DpiCallSection } from 'components/SellDash/DpiCallSection'
import { CompPutSection } from 'components/SellDash/CompPutSection'
import { BalPutSection } from 'components/SellDash/BalPutSection'
import { YfiPutSection } from 'components/SellDash/YfiPutSection'
import { YfiCallSection } from 'components/SellDash/YfiCallSection'
import { CrvPutSection } from 'components/SellDash/CrvPutSection'
//import { ReturnsSection } from 'components/SellDash/ReturnsSection'
import { Share } from '../../components/Common/Share'

export const Sell: React.FC = props => {
  const { ...restProps } = props

  return (
    <ContentsWide {...restProps}>
      {/* <ReturnsSection /> */}
      {/* <EthPutSection /> */}
      {/* <div style={{ margin: '10px 40px' }}>
        <h4>
          There was a recent vulnerability with the ETH puts. Affected users, please see{' '}
          <a
            href="https://medium.com/opyn/opyn-eth-put-exploit-c5565c528ad2?postPublishedType=repub"
            target="_blank"
            rel="noopener noreferrer"
          >
            this Medium post
          </a>
          .
          <br />
          <br />
          Please do not create any new oETH put vaults or buy/sell oETH puts except with the Opyn team, through the
          process defined in the above Medium post.
          <br />
          <br />
          For any questions, see{' '}
          <a href="https://tiny.cc/opyndiscord" target="_blank" rel="noopener noreferrer">
            Discord
          </a>
        </h4>
        .
      </div> */}
      <WethPutSection />
      <EthCallSection />
      <DpiPutSection />
      <DpiCallSection />
      <UniPutSection />
      <UniCallSection />
      <DydxPutSection />
      <DydxCallSection />
      <WbtcPutSection />
      <WbtcCallSection />
      <SnxPutSection />
      <SnxCallSection />
      <YfiPutSection />
      <YfiCallSection />
      <CompPutSection />
      <BalPutSection />
      <CrvPutSection />
      <CompoundSection />
      {/* <MakerSection /> */}
      <Share />
    </ContentsWide>
  )
}
