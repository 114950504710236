import React, { HTMLAttributes, useCallback } from 'react'
import styled, { css } from 'styled-components'

import { TokensDropdown } from '../TokensDropdown'
import { InlineLoading } from '../InlineLoading'

export enum LOCK_STATES {
  LOCKED,
  UNLOCKED,
  LOADING,
}

export enum UNLOCKER_POSITION {
  LEFT,
  RIGHT,
}

const Wrapper = styled.div`
  flex-shrink: 0;
  height: 26px;
  outline: none;
  position: relative;
  user-select: none;
  z-index: 0;

  &:focus-within {
    z-index: 1;
  }
`

const Dropdown = styled(TokensDropdown)`
  height: 100%;
  z-index: 5;
`

const Unlocker = styled.div<{ isLocked?: LOCK_STATES; unlockerPosition?: UNLOCKER_POSITION }>`
  align-items: center;
  background: rgba(223, 95, 103, 0.1);
  padding: 0 20px 0 9px;
  ${({ isLocked, unlockerPosition = UNLOCKER_POSITION.LEFT }) => {
    if (unlockerPosition === UNLOCKER_POSITION.LEFT) {
      return css`
        border-bottom-left-radius: 500px;
        border-right-color: transparent;
        border-top-left-radius: 500px;
        left: ${isLocked !== LOCK_STATES.UNLOCKED ? '-45px' : '1px'};
        ${isLocked === LOCK_STATES.LOADING && 'padding-right: 50px'};
        transition: left 0.4s ease-in-out;
      `
    } else {
      return css`
        border-bottom-right-radius: 500px;
        border-left: none;
        border-top-right-radius: 500px;
        right: ${isLocked !== LOCK_STATES.UNLOCKED ? '-49px' : '1px'};
        ${isLocked === LOCK_STATES.LOADING && 'padding-left: 50px'};
        transition: right 0.4s ease-in-out;
      `
    }
  }}

  border: 1px solid ${props => props.theme.colors.error};
  color: ${props => props.theme.colors.error};
  cursor: ${props => (props.isLocked === LOCK_STATES.LOCKED ? 'pointer' : 'default')};
  display: flex;
  font-size: 8px;
  height: 100%;
  line-height: 1.2;
  position: absolute;
  top: 0;
  z-index: 1;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean
  isLocked?: LOCK_STATES
  unlockerPosition?: UNLOCKER_POSITION
  onUnlock: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
  selectedToken?: Maybe<string>
  onTokenChange?: (token: string) => void
  includeOToken?: boolean
}

export const TokensDropdownUnlocker: React.FC<Props> = (props: Props) => {
  const {
    disabled = false,
    onUnlock,
    isLocked,
    unlockerPosition,
    selectedToken,
    onTokenChange,
    includeOToken,
    ...restProps
  } = props

  const isLoading = isLocked === LOCK_STATES.LOADING

  const unlockerClickHandler = useCallback(
    e => {
      if (isLocked === LOCK_STATES.LOCKED && onUnlock) {
        onUnlock(e)
      }
    },
    [onUnlock, isLocked],
  )

  return (
    <Wrapper tabIndex={-1} {...restProps}>
      <Dropdown
        onTokenChange={onTokenChange}
        disabled={disabled}
        selectedToken={selectedToken}
        includeOToken={includeOToken}
      />
      <Unlocker onClick={unlockerClickHandler} isLocked={isLocked} unlockerPosition={unlockerPosition}>
        {isLoading ? <InlineLoading /> : 'Unlock'}
      </Unlocker>
    </Wrapper>
  )
}
